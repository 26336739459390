export const SETTING_KILLSWITCH_REQUEST = "SETTING/SETTING_KILLSWITCH_REQUEST";
export const SETTING_KILLSWITCH_SUCCESS = "SETTING/SETTING_KILLSWITCH_SUCCESS";
export const SETTING_KILLSWITCH_FAILURE = "SETTING/SETTING_KILLSWITCH_FAILURE";

export const SETTING_KILLSWITCH_VIEW_REQUEST =
  "SETTING/SETTING_KILLSWITCH_VIEW_REQUEST";
export const SETTING_KILLSWITCH_VIEW_SUCCESS =
  "SETTING/SETTING_KILLSWITCH_VIEW_SUCCESS";
export const SETTING_KILLSWITCH_VIEW_FAILURE =
  "SETTING/SETTING_KILLSWITCH_VIEW_FAILURE";
