//
// SEKAT DATATABLE
//
export const SEKAT_DATATABLE_REQUEST = 'SEKAT/SEKAT_DATATABLE_REQUEST';
export const SEKAT_DATATABLE_SUCCESS = 'SEKAT/SEKAT_DATATABLE_SUCCESS';
export const SEKAT_DATATABLE_FAILURE = 'SEKAT/SEKAT_DATATABLE_FAILURE';

export const SEKAT_VIEW_REQUEST = 'SEKAT/SEKAT_VIEW_REQUEST';
export const SEKAT_VIEW_SUCCESS = 'SEKAT/SEKAT_VIEW_SUCCESS';
export const SEKAT_VIEW_FAILURE = 'SEKAT/SEKAT_VIEW_FAILURE';

export const SEKAT_SEARCH_BY_ID_REQUEST = 'SEKAT/SEKAT_SEARCH_BY_ID_REQUEST';
export const SEKAT_SEARCH_BY_ID_SUCCESS = 'SEKAT/SEKAT_SEARCH_BY_ID_SUCCESS';
export const SEKAT_SEARCH_BY_ID_FAILURE = 'SEKAT/SEKAT_SEARCH_BY_ID_FAILURE';