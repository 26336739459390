import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/show';
import * as constants from '../constant/show';
import * as functions from '../function/show';

//
// SHOW
//

function* getShow({show} : actions.ShowSetAction) {
    yield put(functions.SuccessSetShow(show));     
};

export function* watchShow() {
    yield takeLatest(constants.SET_SHOW, getShow);
};
