import * as actions from "../action/bayaran";
import * as constants from "../constant/bayaran";

//
// Bayaran Datatable
//
export function BayaranDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BayaranDatatableRequestAction {
  return {
    type: constants.BAYARAN_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}

export function BayaranDatatableSuccess(
  data: object
): actions.BayaranDatatableSuccessAction {
  return {
    type: constants.BAYARAN_DATATABLE_SUCCESS,
    data,
  };
}

export function BayaranDatatableFailure(
  error: string
): actions.BayaranDatatableFailureAction {
  return {
    type: constants.BAYARAN_DATATABLE_FAILURE,
    error,
  };
}

//
// Bayaran Create
//
export function BayaranCreateRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BayaranCreateRequestAction {
  return {
    type: constants.BAYARAN_CREATE_REQUEST,
    params,
    onCallback,
  };
}

export function BayaranCreateSuccess(
  data: object
): actions.BayaranCreateSuccessAction {
  return {
    type: constants.BAYARAN_CREATE_SUCCESS,
    data,
  };
}

export function BayaranCreateFailure(
  error: string
): actions.BayaranCreateFailureAction {
  return {
    type: constants.BAYARAN_CREATE_FAILURE,
    error,
  };
}

//
// Bayaran Delete
//
export function BayaranDeleteRequest(
  id: any,
  params:any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BayaranDeleteRequestAction {
  return {
    type: constants.BAYARAN_DELETE_REQUEST,
    id,
    params,
    onCallback,
  };
}

export function BayaranDeleteSuccess(
  data: object
): actions.BayaranDeleteSuccessAction {
  return {
    type: constants.BAYARAN_DELETE_SUCCESS,
    data,
  };
}

export function BayaranDeleteFailure(
  error: string
): actions.BayaranDeleteFailureAction {
  return {
    type: constants.BAYARAN_DELETE_FAILURE,
    error,
  };
}

//
// Bayaran Semak
//
export function BayaranSemakRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BayaranSemakRequestAction {
  return {
    type: constants.BAYARAN_SEMAK_REQUEST,
    params,
    onCallback,
  };
}

export function BayaranSemakSuccess(
  data: object
): actions.BayaranSemakSuccessAction {
  return {
    type: constants.BAYARAN_SEMAK_SUCCESS,
    data,
  };
}

export function BayaranSemakFailure(
  error: string
): actions.BayaranSemakFailureAction {
  return {
    type: constants.BAYARAN_SEMAK_FAILURE,
    error,
  };
}

//
// Bayaran Search
//
export function BayaranSearchRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BayaranSearchRequestAction {
  return {
    type: constants.BAYARAN_SEARCH_REQUEST,
    params,
    onCallback,
  };
}

export function BayaranSearchSuccess(
  data: object
): actions.BayaranSearchSuccessAction {
  return {
    type: constants.BAYARAN_SEARCH_SUCCESS,
    data,
  };
}

export function BayaranSearchFailure(
  error: string
): actions.BayaranSearchFailureAction {
  return {
    type: constants.BAYARAN_SEARCH_FAILURE,
    error,
  };
}


//
// Bayaran Pay Create
//
export function BayaranPayCreateRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BayaranPayCreateRequestAction {
  return {
    type: constants.BAYARAN_PAY_CREATE_REQUEST,
    params,
    onCallback,
  };
}

export function BayaranPayCreateSuccess(
  data: object
): actions.BayaranPayCreateSuccessAction {
  return {
    type: constants.BAYARAN_PAY_CREATE_SUCCESS,
    data,
  };
}

export function BayaranPayCreateFailure(
  error: string
): actions.BayaranPayCreateFailureAction {
  return {
    type: constants.BAYARAN_PAY_CREATE_FAILURE,
    error,
  };
}


//
// Bayaran Pay Redirect
//
export function BayaranPayRedirectRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BayaranPayRedirectRequestAction {
  return {
    type: constants.BAYARAN_PAY_REDIRECT_REQUEST,
    params,
    onCallback,
  };
}

export function BayaranPayRedirectSuccess(
  data: object
): actions.BayaranPayRedirectSuccessAction {
  return {
    type: constants.BAYARAN_PAY_REDIRECT_SUCCESS,
    data,
  };
}

export function BayaranPayRedirectFailure(
  error: string
): actions.BayaranPayRedirectFailureAction {
  return {
    type: constants.BAYARAN_PAY_REDIRECT_FAILURE,
    error,
  };
}

//
// Bayaran Fetch
//
export function BayaranFetchRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BayaranFetchRequestAction {
  return {
    type: constants.BAYARAN_FETCH_REQUEST,
    params,
    onCallback,
  };
}

export function BayaranFetchSuccess(
  data: object
): actions.BayaranFetchSuccessAction {
  return {
    type: constants.BAYARAN_FETCH_SUCCESS,
    data,
  };
}

export function BayaranFetchFailure(
  error: string
): actions.BayaranFetchFailureAction {
  return {
    type: constants.BAYARAN_FETCH_FAILURE,
    error,
  };
}

//
// Bayaran Update
//
export function BayaranUpdateRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BayaranUpdateRequestAction {
  return {
    type: constants.BAYARAN_UPDATE_REQUEST,
    params,
    onCallback,
  };
}

export function BayaranUpdateSuccess(
  data: object
): actions.BayaranUpdateSuccessAction {
  return {
    type: constants.BAYARAN_UPDATE_SUCCESS,
    data,
  };
}

export function BayaranUpdateFailure(
  error: string
): actions.BayaranUpdateFailureAction {
  return {
    type: constants.BAYARAN_UPDATE_FAILURE,
    error,
  };
}

