import * as actions from "../action/sekat";
import * as constants from "../constant/sekat";

//
// Sekat Datatable
//
export function SekatDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SekatDatatableRequestAction {
  return {
    type: constants.SEKAT_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}

export function SekatDatatableSuccess(
  data: object
): actions.SekatDatatableSuccessAction {
  return {
    type: constants.SEKAT_DATATABLE_SUCCESS,
    data,
  };
}

export function SekatDatatableFailure(
  error: string
): actions.SekatDatatableFailureAction {
  return {
    type: constants.SEKAT_DATATABLE_FAILURE,
    error,
  };
}



//VIEW
export function SekatViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SekatViewRequestAction {
  return {
    type: constants.SEKAT_VIEW_REQUEST,
    params,
    onCallback,
  };
}

export function SekatViewSuccess(data: object): actions.SekatViewSuccessAction {
  return {
    type: constants.SEKAT_VIEW_SUCCESS,
    data,
  };
}

export function SekatViewFailure(error: string): actions.SekatViewFailureAction {
  return {
    type: constants.SEKAT_VIEW_FAILURE,
    error,
  };
}

export function SekatSearchByIdRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SekatSearchByIdRequestAction {
  return {
    type: constants.SEKAT_SEARCH_BY_ID_REQUEST,
    params,
    onCallback,
  };
}

export function SekatSearchByIdSuccess(data: object): actions.SekatSearchByIdSuccessAction {
  return {
    type: constants.SEKAT_SEARCH_BY_ID_SUCCESS,
    data,
  };
}

export function SekatSearchByIdFailure(error: string): actions.SekatSearchByIdFailureAction {
  return {
    type: constants.SEKAT_SEARCH_BY_ID_FAILURE,
    error,
  };
}