import * as actions from "../action/psbanglo";
import * as constants from "../constant/psbanglo";

//
// PsBanglo Datatable
//
export function PsBangloDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.PsBangloDatatableRequestAction {
  return {
    type: constants.PSBANGLO_DATATABLE_REQUEST,
    params,
    onCallback
  };
}
export function PsBangloDatatableSuccess(data: object): actions.PsBangloDatatableSuccessAction {
  return {
    type: constants.PSBANGLO_DATATABLE_SUCCESS,
    data,
  };
}
export function PsBangloDatatableFailure(error: string): actions.PsBangloDatatableFailureAction {
  return {
    type: constants.PSBANGLO_DATATABLE_FAILURE,
    error,
  };
}

//
// PsBanglo Draft
//
export function PsBangloDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsBangloDraftRequestAction {
  return {
    type: constants.PSBANGLO_DRAFT_REQUEST,
    params,
    onCallback
  };
}
export function PsBangloDraftSuccess(data: object): actions.PsBangloDraftSuccessAction {
  return {
    type: constants.PSBANGLO_DRAFT_SUCCESS,
    data,
  };
}
export function PsBangloDraftFailure(error: string): actions.PsBangloDraftFailureAction {
  return {
    type: constants.PSBANGLO_DRAFT_FAILURE,
    error,
  };
}

//
// PsBanglo Edit
//
export function PsBangloEditRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsBangloEditRequestAction {
  return {
    type: constants.PSBANGLO_EDIT_REQUEST,
    params,
    onCallback
  };
}
export function PsBangloEditSuccess(data: object): actions.PsBangloEditSuccessAction {
  return {
    type: constants.PSBANGLO_EDIT_SUCCESS,
    data,
  };
}
export function PsBangloEditFailure(error: string): actions.PsBangloEditFailureAction {
  return {
    type: constants.PSBANGLO_EDIT_FAILURE,
    error,
  };
}

//
// PsBanglo Submit
//
export function PsBangloSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsBangloSubmitRequestAction {
  return {
    type: constants.PSBANGLO_SUBMIT_REQUEST,
    params,
    onCallback
  };
}
export function PsBangloSubmitSuccess(data: object): actions.PsBangloSubmitSuccessAction {
  return {
    type: constants.PSBANGLO_SUBMIT_SUCCESS,
    data,
  };
}
export function PsBangloSubmitFailure(error: string): actions.PsBangloSubmitFailureAction {
  return {
    type: constants.PSBANGLO_SUBMIT_FAILURE,
    error,
  };
}


//
// PsBanglo View
//
export function PsBangloViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsBangloViewRequestAction {
  return {
    type: constants.PSBANGLO_VIEW_REQUEST,
    params,
    onCallback
  };
}
export function PsBangloViewSuccess(data: object): actions.PsBangloViewSuccessAction {
  return {
    type: constants.PSBANGLO_VIEW_SUCCESS,
    data,
  };
}
export function PsBangloViewFailure(error: string): actions.PsBangloViewFailureAction {
  return {
    type: constants.PSBANGLO_VIEW_FAILURE,
    error,
  };
}

//
// PsBanglo Search
//
export function PsBangloSearchRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsBangloSearchRequestAction {
  return {
    type: constants.PSBANGLO_SEARCH_REQUEST,
    params,
    onCallback
  };
}
export function PsBangloSearchSuccess(data: object): actions.PsBangloSearchSuccessAction {
  return {
    type: constants.PSBANGLO_SEARCH_SUCCESS,
    data,
  };
}
export function PsBangloSearchFailure(error: string): actions.PsBangloSearchFailureAction {
  return {
    type: constants.PSBANGLO_SEARCH_FAILURE,
    error,
  };
}
