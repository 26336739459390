// import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/jualan";
import * as constants from "../constant/jualan";
import * as functions from "../function/jualan";

//
// CREATE JUALAN DATATABLE
//
function* getCreateJualan({
  params,
  onCallback,
}: actions.CreateJualanRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/jualan/`,
    method: "POST",
    params: {
      ...params,
    },
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.JualanDatatableSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.JualanDatatableFailure(error));
  }
}

//
// CANCEL JUALAN
//
function* getCancelJualan({
  noBill,
  onCallback,
}: actions.CancelJualanRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/jualan/cancel/${noBill}`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.JualanDatatableSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.JualanDatatableFailure(error));
  }
}

//
// JUALAN DATATABLE
//
function* getJualanDatatable({
  params,
  onCallback,
}: actions.JualanDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/jualan/list`,
    method: "POST",
    params: {
      paginate: false,
      ...params,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.JualanDatatableSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.JualanDatatableFailure(error));
  }
}

//
// JUALAN PRODUCTS
//
function* getJualanProducts({
  onCallback,
}: actions.JualanProductsRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/products`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.JualanProductsSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.JualanProductsFailure(error));
  }
}

//
// JUALAN PRODUCT
//
function* getJualanProduct({
  params,
  productId,
  onCallback,
}: actions.JualanProductRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/products/${productId}`,
    method: "GET",
    params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.JualanProductSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.JualanProductFailure(error));
  }
}

export function* watchJualan() {
  yield takeLatest(constants.CREATE_JUALAN_REQUEST, getCreateJualan);
  yield takeLatest(constants.CANCEL_JUALAN_REQUEST, getCancelJualan);
  yield takeLatest(constants.JUALAN_PRODUCTS_REQUEST, getJualanProducts);
  yield takeLatest(constants.JUALAN_PRODUCT_REQUEST, getJualanProduct);
  yield takeLatest(constants.JUALAN_DATATABLE_REQUEST, getJualanDatatable);
}
