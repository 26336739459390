import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/users";
import * as constants from "../constant/users";
import * as functions from "../function/users";

//
//  USERS - DATATABLE
//
function* getUsersDataTable({
  params,
  onCallback,
}: actions.UsersDataTableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/users/datatable`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    let newData = [];
    for (let i = 0; i < data.data.length; i++) {
      const item = data.data[i];
      const compIndex = item.companies.findIndex(
        (comp: any) => comp.name.match(/amp;/gm) !== null
      );
      if (item.companies.length > 0 && compIndex !== -1) {
        const company = item.companies[compIndex];
        company.name = company.name.replace(/amp;/gm, "");
      }

      newData.push(item);
    }

    onCallback({
      status: 1,
      data: { count: data.count, data: newData },
    });
    yield put(
      functions.usersDataTableSuccess({ count: data.count, data: newData })
    );
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.usersDataTableFailure(error));
  }
}
//
//  USERS - USERS
//
function* getUsersUsers({
  params,
  onCallback,
}: actions.UsersUsersRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/users/users`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.usersUsersSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.usersUsersFailure(error));
  }
}

export function* watchUsers() {
  yield takeLatest(constants.USERS_DATATABLE_REQUEST, getUsersDataTable);
  yield takeLatest(constants.USERS_USERS_REQUEST, getUsersUsers);
}
