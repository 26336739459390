import * as actions from "../action/lesen";
import * as constants from "../constant/lesen";

//
// LESEN Datatable Renew
//
export function LesenDatatableRenewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenDatatableRenewRequestAction {
  return {
    type: constants.LESEN_DATATABLE_RENEW_REQUEST,
    params,
    onCallback,
  };
}
export function LesenDatatableRenewSuccess(
  data: object
): actions.LesenDatatableRenewSuccessAction {
  return {
    type: constants.LESEN_DATATABLE_RENEW_SUCCESS,
    data,
  };
}
export function LesenDatatableRenewFailure(
  error: string
): actions.LesenDatatableRenewFailureAction {
  return {
    type: constants.LESEN_DATATABLE_RENEW_FAILURE,
    error,
  };
}

//
// LESEN Datatable Apply
//
export function LesenDatatableApplyRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenDatatableApplyRequestAction {
  return {
    type: constants.LESEN_DATATABLE_APPLY_REQUEST,
    params,
    onCallback,
  };
}
export function LesenDatatableApplySuccess(
  data: object
): actions.LesenDatatableApplySuccessAction {
  return {
    type: constants.LESEN_DATATABLE_APPLY_SUCCESS,
    data,
  };
}
export function LesenDatatableApplyFailure(
  error: string
): actions.LesenDatatableApplyFailureAction {
  return {
    type: constants.LESEN_DATATABLE_APPLY_FAILURE,
    error,
  };
}

//
// LESEN Renew
//
export function LesenRenewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenRenewRequestAction {
  return {
    type: constants.LESEN_RENEW_REQUEST,
    params,
    onCallback,
  };
}
export function LesenRenewSuccess(
  data: object
): actions.LesenRenewSuccessAction {
  return {
    type: constants.LESEN_RENEW_SUCCESS,
    data,
  };
}
export function LesenRenewFailure(
  error: string
): actions.LesenRenewFailureAction {
  return {
    type: constants.LESEN_RENEW_FAILURE,
    error,
  };
}

//
// LESEN Pay Create
//
export function LesenPayCreateRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenPayCreateRequestAction {
  return {
    type: constants.LESEN_PAY_CREATE_REQUEST,
    params,
    onCallback,
  };
}
export function LesenPayCreateSuccess(
  data: object
): actions.LesenPayCreateSuccessAction {
  return {
    type: constants.LESEN_PAY_CREATE_SUCCESS,
    data,
  };
}
export function LesenPayCreateFailure(
  error: string
): actions.LesenPayCreateFailureAction {
  return {
    type: constants.LESEN_PAY_CREATE_FAILURE,
    error,
  };
}

//
// LESEN Pay Redirect
//
export function LesenPayRedirectRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenPayRedirectRequestAction {
  return {
    type: constants.LESEN_PAY_REDIRECT_REQUEST,
    params,
    onCallback,
  };
}
export function LesenPayRedirectSuccess(
  data: object
): actions.LesenPayRedirectSuccessAction {
  return {
    type: constants.LESEN_PAY_REDIRECT_SUCCESS,
    data,
  };
}
export function LesenPayRedirectFailure(
  error: string
): actions.LesenPayRedirectFailureAction {
  return {
    type: constants.LESEN_PAY_REDIRECT_FAILURE,
    error,
  };
}

//
// LESEN Draft
//
export function LesenDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenDraftRequestAction {
  return {
    type: constants.LESEN_DRAFT_REQUEST,
    params,
    onCallback,
  };
}
export function LesenDraftSuccess(
  data: object
): actions.LesenDraftSuccessAction {
  return {
    type: constants.LESEN_DRAFT_SUCCESS,
    data,
  };
}
export function LesenDraftFailure(
  error: string
): actions.LesenDraftFailureAction {
  return {
    type: constants.LESEN_DRAFT_FAILURE,
    error,
  };
}

//
// LESEN Reset
//
export function EpbtReset(): actions.LesenResetAction {
  return {
    type: constants.LESEN_RESET,
  };
}

//
// LESEN Edit
//
export function LesenEditRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenEditRequestAction {
  return {
    type: constants.LESEN_EDIT_REQUEST,
    params,
    onCallback,
  };
}
export function LesenEditSuccess(data: object): actions.LesenEditSuccessAction {
  return {
    type: constants.LESEN_EDIT_SUCCESS,
    data,
  };
}
export function LesenEditFailure(
  error: string
): actions.LesenEditFailureAction {
  return {
    type: constants.LESEN_EDIT_FAILURE,
    error,
  };
}

//
// LESEN Submit
//
export function LesenSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenSubmitRequestAction {
  return {
    type: constants.LESEN_SUBMIT_REQUEST,
    params,
    onCallback,
  };
}
export function LesenSubmitSuccess(
  data: object
): actions.LesenSubmitSuccessAction {
  return {
    type: constants.LESEN_SUBMIT_SUCCESS,
    data,
  };
}
export function LesenSubmitFailure(
  error: string
): actions.LesenSubmitFailureAction {
  return {
    type: constants.LESEN_SUBMIT_FAILURE,
    error,
  };
}

//
// LESEN View
//
export function LesenViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenViewRequestAction {
  return {
    type: constants.LESEN_VIEW_REQUEST,
    params,
    onCallback,
  };
}
export function LesenViewSuccess(data: object): actions.LesenViewSuccessAction {
  return {
    type: constants.LESEN_VIEW_SUCCESS,
    data,
  };
}
export function LesenViewFailure(
  error: string
): actions.LesenViewFailureAction {
  return {
    type: constants.LESEN_VIEW_FAILURE,
    error,
  };
}

//
// LESEN Delete Draft
//
export function LesenDeleteDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenDeleteDraftRequestAction {
  return {
    type: constants.LESEN_DELETEDRAFT_REQUEST,
    params,
    onCallback,
  };
}
export function LesenDeleteDraftSuccess(
  data: object
): actions.LesenDeleteDraftSuccessAction {
  return {
    type: constants.LESEN_DELETEDRAFT_SUCCESS,
    data,
  };
}
export function LesenDeleteDraftFailure(
  error: string
): actions.LesenDeleteDraftFailureAction {
  return {
    type: constants.LESEN_DELETEDRAFT_FAILURE,
    error,
  };
}

//
// LESEN Document
//
export function LesenDocumentRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenDocumentRequestAction {
  return {
    type: constants.LESEN_DOCUMENT_REQUEST,
    params,
    onCallback,
  };
}
export function LesenDocumentSuccess(
  data: object
): actions.LesenDocumentSuccessAction {
  return {
    type: constants.LESEN_DOCUMENT_SUCCESS,
    data,
  };
}
export function LesenDocumentFailure(
  error: string
): actions.LesenDocumentFailureAction {
  return {
    type: constants.LESEN_DOCUMENT_FAILURE,
    error,
  };
}

//
// LESEN Verify Sekatan
//
export function LesenVerifySekatanRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenVerifySekatanRequestAction {
  return {
    type: constants.LESEN_VERIFYSEKATAN_REQUEST,
    params,
    onCallback,
  };
}
export function LesenVerifySekatanSuccess(
  data: object
): actions.LesenVerifySekatanSuccessAction {
  return {
    type: constants.LESEN_VERIFYSEKATAN_SUCCESS,
    data,
  };
}
export function LesenVerifySekatanFailure(
  error: string
): actions.LesenVerifySekatanFailureAction {
  return {
    type: constants.LESEN_VERIFYSEKATAN_FAILURE,
    error,
  };
}

//
// LESEN KategoriLesen
//
export function LesenKategoriLesenRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenKategoriLesenRequestAction {
  return {
    type: constants.LESEN_KATEGORILESEN_REQUEST,
    params,
    onCallback,
  };
}
export function LesenKategoriLesenSuccess(
  data: object
): actions.LesenKategoriLesenSuccessAction {
  return {
    type: constants.LESEN_KATEGORILESEN_SUCCESS,
    data,
  };
}
export function LesenKategoriLesenFailure(
  error: string
): actions.LesenKategoriLesenFailureAction {
  return {
    type: constants.LESEN_KATEGORILESEN_FAILURE,
    error,
  };
}

//
// LESEN SAVE DOCUMENT
//
export function LesenSaveDocRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenSaveDocRequestAction {
  return {
    type: constants.LESEN_SAVE_DOCS_REQUEST,
    params,
    onCallback,
  };
}
export function LesenSaveDocSuccess(
  data: object
): actions.LesenSaveDocSuccessAction {
  return {
    type: constants.LESEN_SAVE_DOCS_SUCCESS,
    data,
  };
}
export function LesenSaveDocFailure(
  error: string
): actions.LesenSaveDocFailureAction {
  return {
    type: constants.LESEN_SAVE_DOCS_FAILURE,
    error,
  };
}

//
// LESEN SAVE DOCUMENT TO SERVER
//
export function LesenSaveDocToServerRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenSaveDocToServerRequestAction {
  return {
    type: constants.LESEN_SAVE_DOCS_TO_SERVER_REQUEST,
    params,
    onCallback,
  };
}
export function LesenSaveDocToServerSuccess(
  data: object
): actions.LesenSaveDocToServerSuccessAction {
  return {
    type: constants.LESEN_SAVE_DOCS_TO_SERVER_SUCCESS,
    data,
  };
}
export function LesenSaveDocToServerFailure(
  error: string
): actions.LesenSaveDocToServerFailureAction {
  return {
    type: constants.LESEN_SAVE_DOCS_TO_SERVER_FAILURE,
    error,
  };
}

//
// LESEN SAVE DOCUMENT TO SERVER
//
export function LesenGetDocToServerRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenGetDocRequestAction {
  return {
    type: constants.LESEN_GET_DOCS_REQUEST,
    params,
    onCallback,
  };
}
export function LesenGetDocSuccess(
  data: object
): actions.LesenGetDocSuccessAction {
  return {
    type: constants.LESEN_GET_DOCS_SUCCESS,
    data,
  };
}
export function LesenGetDocFailure(
  error: string
): actions.LesenGetDocFailureAction {
  return {
    type: constants.LESEN_GET_DOCS_FAILURE,
    error,
  };
}

//
// LESEN JenisLesen
//
export function LesenJenisLesenRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenJenisLesenRequestAction {
  return {
    type: constants.LESEN_JENISLESEN_REQUEST,
    params,
    onCallback,
  };
}
export function LesenJenisLesenSuccess(
  data: object
): actions.LesenJenisLesenSuccessAction {
  return {
    type: constants.LESEN_JENISLESEN_SUCCESS,
    data,
  };
}
export function LesenJenisLesenFailure(
  error: string
): actions.LesenJenisLesenFailureAction {
  return {
    type: constants.LESEN_JENISLESEN_FAILURE,
    error,
  };
}

//
// LESEN KodHasilLesen
//
export function LesenKodHasilLesenRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenKodHasilLesenRequestAction {
  return {
    type: constants.LESEN_KODHASILLESEN_REQUEST,
    params,
    onCallback,
  };
}
export function LesenKodHasilLesenSuccess(
  data: object
): actions.LesenKodHasilLesenSuccessAction {
  return {
    type: constants.LESEN_KODHASILLESEN_SUCCESS,
    data,
  };
}
export function LesenKodHasilLesenFailure(
  error: string
): actions.LesenKodHasilLesenFailureAction {
  return {
    type: constants.LESEN_KODHASILLESEN_FAILURE,
    error,
  };
}

//
// LESEN JenisPremis
//
export function LesenJenisPremisRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenJenisPremisRequestAction {
  return {
    type: constants.LESEN_JENISPREMIS_REQUEST,
    params,
    onCallback,
  };
}
export function LesenJenisPremisSuccess(
  data: object
): actions.LesenJenisPremisSuccessAction {
  return {
    type: constants.LESEN_JENISPREMIS_SUCCESS,
    data,
  };
}
export function LesenJenisPremisFailure(
  error: string
): actions.LesenJenisPremisFailureAction {
  return {
    type: constants.LESEN_JENISPREMIS_FAILURE,
    error,
  };
}

//
// LESEN KedudukanPremis
//
export function LesenKedudukanPremisRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LesenKedudukanPremisRequestAction {
  return {
    type: constants.LESEN_KEDUDUKANPREMIS_REQUEST,
    params,
    onCallback,
  };
}
export function LesenKedudukanPremisSuccess(
  data: object
): actions.LesenKedudukanPremisSuccessAction {
  return {
    type: constants.LESEN_KEDUDUKANPREMIS_SUCCESS,
    data,
  };
}
export function LesenKedudukanPremisFailure(
  error: string
): actions.LesenKedudukanPremisFailureAction {
  return {
    type: constants.LESEN_KEDUDUKANPREMIS_FAILURE,
    error,
  };
}
