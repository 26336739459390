//
// KPM RESET
//
export const KPM_RESET = 'KPM/RESET';

//
// KPM DATATABLE
//
export const KPM_DATATABLE_REQUEST = 'KPM/KPM_DATATABLE_REQUEST';
export const KPM_DATATABLE_SUCCESS = 'KPM/KPM_DATATABLE_SUCCESS';
export const KPM_DATATABLE_FAILURE = 'KPM/KPM_DATATABLE_FAILURE';

//
// KPM DRAFT
//
export const KPM_DRAFT_REQUEST = 'KPM/KPM_DRAFT_REQUEST';
export const KPM_DRAFT_SUCCESS = 'KPM/KPM_DRAFT_SUCCESS';
export const KPM_DRAFT_FAILURE = 'KPM/KPM_DRAFT_FAILURE';

//
// KPM EDIT
//
export const KPM_EDIT_REQUEST = 'KPM/KPM_EDIT_REQUEST';
export const KPM_EDIT_SUCCESS = 'KPM/KPM_EDIT_SUCCESS';
export const KPM_EDIT_FAILURE = 'KPM/KPM_EDIT_FAILURE';

//
// KPM SUBMIT
//
export const KPM_SUBMIT_REQUEST = 'KPM/KPM_SUBMIT_REQUEST';
export const KPM_SUBMIT_SUCCESS = 'KPM/KPM_SUBMIT_SUCCESS';
export const KPM_SUBMIT_FAILURE = 'KPM/KPM_SUBMIT_FAILURE';

//
// KPM SEARCH
//
export const KPM_SEARCH_REQUEST = 'KPM/KPM_SEARCH_REQUEST';
export const KPM_SEARCH_SUCCESS = 'KPM/KPM_SEARCH_SUCCESS';
export const KPM_SEARCH_FAILURE = 'KPM/KPM_SEARCH_FAILURE';

//
// KPM VIEW
//
export const KPM_VIEW_REQUEST = 'KPM/KPM_VIEW_REQUEST';
export const KPM_VIEW_SUCCESS = 'KPM/KPM_VIEW_SUCCESS';
export const KPM_VIEW_FAILURE = 'KPM/KPM_VIEW_FAILURE';

//
// KPM PDF
//
export const KPM_PDF_REQUEST = 'KPM/KPM_PDF_REQUEST';
export const KPM_PDF_SUCCESS = 'KPM/KPM_PDF_SUCCESS';
export const KPM_PDF_FAILURE = 'KPM/KPM_PDF_FAILURE';

//
// KPM PAY CREATE
//
export const KPM_PAY_CREATE_REQUEST = 'KPM/KPM_PAY_CREATE_REQUEST';
export const KPM_PAY_CREATE_SUCCESS = 'KPM/KPM_PAY_CREATE_SUCCESS';
export const KPM_PAY_CREATE_FAILURE = 'KPM/KPM_PAY_CREATE_FAILURE';

//
// KPM PAY REDIRECT
//
export const KPM_PAY_REDIRECT_REQUEST = 'KPM/KPM_PAY_REDIRECT_REQUEST';
export const KPM_PAY_REDIRECT_SUCCESS = 'KPM/KPM_PAY_REDIRECT_SUCCESS';
export const KPM_PAY_REDIRECT_FAILURE = 'KPM/KPM_PAY_REDIRECT_FAILURE';

//
// KPM DELETE DRAFT
//
export const KPM_DELETEDRAFT_REQUEST = 'KPM/KPM_DELETEDRAFT_REQUEST';
export const KPM_DELETEDRAFT_SUCCESS = 'KPM/KPM_DELETEDRAFT_SUCCESS';
export const KPM_DELETEDRAFT_FAILURE = 'KPM/KPM_DELETEDRAFT_FAILURE';

//
// KPM PANEL
//
export const KPM_PANEL_REQUEST = 'KPM/KPM_PANEL_REQUEST';
export const KPM_PANEL_SUCCESS = 'KPM/KPM_PANEL_SUCCESS';
export const KPM_PANEL_FAILURE = 'KPM/KPM_PANEL_FAILURE';

//
// KPM KLINIK
//
export const KPM_KLINIK_REQUEST = 'KPM/KPM_KLINIK_REQUEST';
export const KPM_KLINIK_SUCCESS = 'KPM/KPM_KLINIK_SUCCESS';
export const KPM_KLINIK_FAILURE = 'KPM/KPM_KLINIK_FAILURE';


//
// PPM PREMISE TYPE
//
export const KPM_PREMISETYPE_REQUEST = 'PPM/KPM_PREMISETYPE_REQUEST';
export const KPM_PREMISETYPE_SUCCESS = 'PPM/KPM_PREMISETYPE_SUCCESS';
export const KPM_PREMISETYPE_FAILURE = 'PPM/KPM_PREMISETYPE_FAILURE';