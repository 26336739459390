import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/locale';
import * as constants from '../constant/locale';
import * as functions from '../function/locale';


//
// LOCALE ALL
//
function* getLocaleAll({localeCode, onCallback }: actions.LocaleAllRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/i18n/${localeCode}`,
        method: "GET"
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.localeSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error:error
        });
        yield put(functions.localeFailure(error));
    }
};


//
//  LOCALE - ADD
//
function* getLocaleAdd({localeCode, localeMs, localeEn, onCallback }: actions.LocaleAddRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/i18n/add`,
        method: "POST",
        data: {
            localeCode,
            localeMs,
            localeEn
        }
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.localeAddSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error:error
        });
        yield put(functions.localeAddFailure(error));
    }
};


//
//  LOCALE - EDIT
//
function* getLocaleEdit({id, localeCode, localeMs, localeEn, onCallback }: actions.LocaleEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/i18n/edit`,
        method: "POST",
        data: {
            id,
            localeCode,
            localeMs,
            localeEn
        }
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.localeEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error:error
        });
        yield put(functions.localeEditFailure(error));
    }
};

//
//  LOCALE - DELETE
//
function* getLocaleDelete({id, onCallback }: actions.LocaleDeleteRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/i18n/delete`,
        method: "POST",
        data: {
            id
        }
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.localeDeleteSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error:error
        });
        yield put(functions.localeDeleteFailure(error));
    }
};


//
//  LOCALE - VIEW
//
function* getLocaleView({id, onCallback }: actions.LocaleViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/i18n/view/`+id,
        method: "GET"
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.localeViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error:error
        });
        yield put(functions.localeViewFailure(error));
    }
};

//
//  LOCALE - DATATABLE
//
function* getLocaleDataTable({search,limit, onCallback }: actions.LocaleDataTableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/i18n/datatable`,
        method: "POST",
        data: {
            limit: limit,
            search: search
        }
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.localeDataTableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error:error
        });
        yield put(functions.localeDataTableFailure(error));
    }
};






export function* watchLocale() {
    yield takeLatest(constants.LOCALE_ALL_REQUEST, getLocaleAll);
    yield takeLatest(constants.LOCALE_DATATABLE_REQUEST, getLocaleDataTable);
    yield takeLatest(constants.LOCALE_ADD_REQUEST, getLocaleAdd);
    yield takeLatest(constants.LOCALE_EDIT_REQUEST, getLocaleEdit);
    yield takeLatest(constants.LOCALE_DELETE_REQUEST, getLocaleDelete);
    yield takeLatest(constants.LOCALE_VIEW_REQUEST, getLocaleView);
};
