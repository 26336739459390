import * as actions from '../action/pskilang';
import * as constants from '../constant/pskilang';

export interface PsKilangState {
  data: object;
  error: string;
  loading: boolean;
}

export const pskilangReducer = (
  state: PsKilangState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.PsKilangAction) => {    
    switch (action.type) {
      
      //
      // PSKILANG DATATABLE
      //
      case constants.PSKILANG_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKILANG_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKILANG_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKILANG DRAFT
      //
      case constants.PSKILANG_DRAFT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKILANG_DRAFT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKILANG_DRAFT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKILANG EDIT
      //
      case constants.PSKILANG_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKILANG_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKILANG_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKILANG SUBMIT
      //
      case constants.PSKILANG_SUBMIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKILANG_SUBMIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKILANG_SUBMIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }


      //
      // PSKILANG VIEW
      //
      case constants.PSKILANG_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKILANG_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKILANG_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKILANG SEARCH
      //
      case constants.PSKILANG_SEARCH_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKILANG_SEARCH_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKILANG_SEARCH_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  