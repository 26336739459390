import * as actions from "../action/sekat";
import * as constants from "../constant/sekat";

export interface SekatState {
  data: object;
  error: string;
  loading: boolean;
}

export const sekatReducer = (
  state: SekatState = {
    data: {},
    error: "",
    loading: false,
  },
  action: actions.SekatAction
) => {
  switch (action.type) {
    //
    // SEKAT DATATABLE
    //
    case constants.SEKAT_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SEKAT_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SEKAT_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //VIEW
    case constants.SEKAT_VIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SEKAT_VIEW_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    
    case constants.SEKAT_VIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case constants.SEKAT_SEARCH_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SEKAT_SEARCH_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    
    case constants.SEKAT_SEARCH_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
