import { useEffect, useState } from "react";
import { CgMenu } from "react-icons/cg";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AppRoutes from "../../../AppRoutes";
import ver from "../../../assets/version.json";
import useMediaQuery from "../../../components/useMediaQuery";
import { SET_SHOW } from "../../../redux/constant/show";
import { USERDETAILS_REQUEST } from "../../../redux/constant/user";
import useLoadChatBot from "../../../utils/load-chatbot";
import LeftMenu from "../components/LeftMenu";

const SecuredLayout = () => {
  const auth = useSelector((state: any) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const { version } = useSelector((state: any) => state.version);
  const { show } = useSelector((state: any) => state.show);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const chatBot = useLoadChatBot(location.pathname);
  const [roleId, setRoleId] = useState("0");

  useEffect(() => {
    if (auth.isLoggedIn) {
      setRoleId(auth.data.role_id);
    }
    dispatch({
      type: USERDETAILS_REQUEST,
    });
  }, []);

  useEffect(() => {
    if (auth.isImpersonating) {
      setRoleId(auth.data.role_id);
    }
    dispatch({
      type: USERDETAILS_REQUEST,
    });
  }, [auth.isImpersonating]);

  useEffect(() => {
    if (isMobile) {
      dispatch({
        type: SET_SHOW,
        show: false,
      });
    }
  }, [isMobile]);

  return (
    <div className="h-full">
      {chatBot}
      {/* body */}
      <main className="overscroll-none">
        <div className="flex flex-row item-between content-center gap-10">
          <div className={`w-80 relative ${show === false && "hidden"}`}>
            <div
              className="font-semibold hover:cursor-pointer absolute p-2 right-0"
              onClick={() =>
                dispatch({
                  type: SET_SHOW,
                  show: false,
                })
              }
            >
              <CgMenu size="1.5rem" className="float-right cursor-pointer" />
            </div>
            {show && <LeftMenu auth={auth.data} />}
          </div>
          <div className="xs:w-full sm:flex-1">
            <div className="relative">
              <div className="absolute w-full -z-30">
                <div className="bg-mymbsa-primary h-80 rounded-bl-3xl"></div>
              </div>

              <div className="w-full min-h-screen relative z-20">
                <AppRoutes />
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* footer */}
      <footer className="flex flex-col justify-end justify-items-center text-center w-full text-white bg-mymbsa-primary">
        <div className="flex flex-col justify-center justify-items-center pb-1 pt-1">
          <p className="text-white text-xs">
            <FormattedMessage id="footer.copyright" />
          </p>
          <p className="text-white text-xs">
            FE: {ver.version} {ver.date}
          </p>
          {version && version.current && version.datetime && (
            <p className="text-white text-xs">
              BE: {version.current} {version.datetime}
            </p>
          )}
        </div>
      </footer>
    </div>
  );
};

export default SecuredLayout;
