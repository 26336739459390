/* eslint-disable jsx-a11y/anchor-is-valid */
import { AnimatePresence, motion } from "framer-motion";
import React, { MutableRefObject, useEffect } from "react";
import { RiArrowGoForwardFill, RiShoppingCartLine } from "react-icons/ri";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import CircularProgress from "../../../components/ui/CircularProgress";
import Spinner from "../../../components/ui/Spinner";

const TroliPay = React.forwardRef<
  HTMLDivElement,
  {
    open: boolean;
    troliData: [];
    loyaltiCartData: [];
    onToggle: () => void;
    loading: boolean;
  }
>((props, ref) => {
  const navigate = useNavigate();
  const intl = useIntl();
  
  useEffect(() => {
    const mutableRef = ref as MutableRefObject<HTMLDivElement | null>;
    const handleClickOutside = (event: any) => {
      if (mutableRef.current && !mutableRef.current.contains(event.target) && props.open) {
        props.onToggle();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="pt-1" ref={ref}>
      <span
        onClick={(e) => {
          e.preventDefault();
          props.onToggle();
        }}
        className="relative inline-block cursor-pointer"
      >
        <RiShoppingCartLine size={25} className="text-white" />
        {(props.troliData.length > 0 || props.loyaltiCartData.length > 0) && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
            {props.troliData.length + props.loyaltiCartData.length}
          </span>
        )}
      </span>
      <AnimatePresence>
        {props.open && (
          <motion.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
            className="absolute top-1.5 right-1.5 z-10 w-96 bg-white text-base ring-1 shadow-xl rounded-lg ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabIndex={-1}
          >
            <div className="flex flex-col gap-5">
              <div>
                <div className="pt-5 pl-5 pb-5">
                  <h1 className="font-sans text-xl font-semibold">
                    <FormattedMessage id="bucket.title" />
                  </h1>
                </div>
                <div
                  className={
                    "scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 w-full pl-5 pr-2 max-h-96 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll"
                  }
                >
                  <hr className="bg-gray-100 mt-2" />
                  {!props.loading &&
                    props.troliData &&
                    props.troliData.length === 0 && (
                      <div className="p-3">
                        <div className="flex justify-center items-center">
                          <div>
                            <FormattedMessage id="listing.no.record" />
                          </div>
                        </div>
                      </div>
                    )}

                  {!props.loading &&
                    props.troliData &&
                    props.troliData.map((row: any) => {
                      const amount = row.amount;                      
                      const expiredIn = (row.expired_minute - row.minutes) + " minit";
                      const tooltip = intl.formatMessage({id: "troli.error.expired"}, {expiredIn: expiredIn});
                      return (
                        <div key={Math.random()} className="">
                          <div className="flex flex-column justify-between items-center space-x-5">
                            <div className="font-sans font-bold text-nd">
                              <FormattedMessage id={row.locale} />
                            </div>
                            <div className="font-sans font-bold text-xs">
                              RM{" "}
                              <FormattedNumber
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                value={amount}
                              />
                            </div>
                          </div>
                          <div className="flex flex-column justify-between items-center space-x-5">
                            <div className="font-sans text-xs">
                              {row.reference_no}
                            </div>
                            <div className="font-sans text-xs">
                              {row.description}
                            </div>
                            <div className="">                              
                              {row.expired_minute > 0 && <CircularProgress tooltip={tooltip} progress={row.progress} />}
                            </div>
                          </div>
                          <hr className="bg-gray-100 mt-2" />
                        </div>
                      );
                    })}

                  {!props.loading && props.troliData && (
                    <>
                      <div className="flex justify-end gap-3">
                        <p className="font-bold">
                          <FormattedMessage id="jumlah.bayar" /> :
                        </p>
                        <p className="font-bold">
                          {" "}
                          {Number(
                            props.troliData.reduce(
                              (partialSum, a: any) =>
                                partialSum + parseFloat(a.amount),
                              0
                            )
                          ).toFixed(2)}
                        </p>
                      </div>
                    </>
                  )}

                  {props.loading && (
                    <div className="p-3">
                      <div className="flex justify-center items-center">
                        <div>
                          <Spinner />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {props.troliData && props.troliData.length > 0 && (
                  <div className="p-3">
                    <Button
                      type="button"
                      onClick={() => {
                        navigate("/troli");
                      }}
                      className="bg-mymbsa-secondaryaccent text-white hover:bg-blue-500 h-10 pl-4 pr-3 rounded-md text font-bold w-full"
                      iconLeft={<RiArrowGoForwardFill />}
                      label={
                        <span>
                          <FormattedMessage id="button.terus.bayar" />
                        </span>
                      }
                    />
                  </div>
                )}
              </div>
              <div>
                <div className="pt-5 pl-5 pb-5">
                  <h1 className="font-sans text-xl font-semibold">
                    Penebusan Produk
                  </h1>
                </div>
                <div
                  className={
                    "scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 w-full pl-5 pr-2 max-h-96 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll"
                  }
                >
                  <hr className="bg-gray-100 mt-2" />
                  {!props.loading &&
                    props.loyaltiCartData &&
                    props.loyaltiCartData.length === 0 && (
                      <div className="p-3">
                        <div className="flex justify-center items-center">
                          <div>
                            <FormattedMessage id="listing.no.record" />
                          </div>
                        </div>
                      </div>
                    )}

                  {!props.loading &&
                    props.loyaltiCartData &&
                    props.loyaltiCartData.map((row: any) => {
                      const amount = row.product.repoint * row.quantity;
                      return (
                        <div key={Math.random()} className="">
                          <div className="flex flex-column justify-between items-center space-x-5">
                            <div className="font-sans font-bold text-nd">
                              {row.product.name}
                            </div>
                            <div className="font-sans font-bold text-xs">
                              <FormattedNumber
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                                value={amount}
                              />{" "}
                              Points
                            </div>
                          </div>
                          <div className="flex flex-column justify-between items-center space-x-5">
                            <div className="font-sans text-xs">
                              x {row.quantity}
                            </div>
                          </div>
                          <hr className="bg-gray-100 mt-2" />
                        </div>
                      );
                    })}

                  {props.loading && (
                    <div className="p-3">
                      <div className="flex justify-center items-center">
                        <div>
                          <Spinner />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {props.loyaltiCartData && props.loyaltiCartData.length > 0 && (
                  <div className="p-3">
                    <Button
                      type="button"
                      onClick={() => {
                        navigate("/loyalti/penebusanproduk");
                      }}
                      className="bg-mymbsa-secondaryaccent text-white hover:bg-blue-500 h-10 pl-4 pr-3 rounded-md text font-bold w-full"
                      iconLeft={<RiArrowGoForwardFill />}
                      label={<span>Tebus Produk</span>}
                    />
                  </div>
                )}
              </div>
            </div>
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
});

export default TroliPay;
