import * as actions from '../action/auth';
import * as constants from '../constant/auth';

//
// LOGIN
//
export function loginRequest(
    params: any,
    onCallback: React.FC<{
        status: number,
        data?: any,
        error?: any}>): actions.LoginRequestAction {
    return {
        type: constants.LOGIN_REQUEST,
        params,
        onCallback
    }
}
export function loginSuccess(data: object): actions.LoginSuccessAction {
    return {
        type: constants.LOGIN_SUCCESS,
        data
    }
}
export function loginFailure(error: string): actions.LoginFailureAction {
    return {
        type: constants.LOGIN_FAILURE,
        error
    }
}

//
// IMPERSONATE
//
export function impersonateRequest(
    params: any,
    onCallback: React.FC<{
        status: number,
        data?: any,
        error?: any}>): actions.ImpersonateRequestAction {
    return {
        type: constants.IMPERSONATE_REQUEST,
        params,
        onCallback
    }
}
export function impersonateSuccess(data: object): actions.ImpersonateSuccessAction {
    return {
        type: constants.IMPERSONATE_SUCCESS,
        data
    }
}
export function impersonateFailure(error: string): actions.ImpersonateFailureAction {
    return {
        type: constants.IMPERSONATE_FAILURE,
        error
    }
}

//
// TOKEN
//
export function tokenRequest(
    token: string, 
    onCallback: React.FC<{
        status: number,
        data?: any,
        error?: any}>): actions.TokenRequestAction {
    return {
        type: constants.TOKEN_REQUEST,
        token,
        onCallback
    }
}
export function tokenSuccess(data: object): actions.TokenSuccessAction {
    return {
        type: constants.TOKEN_SUCCESS,
        data
    }
}
export function tokenFailure(error: string): actions.TokenFailureAction {
    return {
        type: constants.TOKEN_FAILURE,
        error
    }
}

//
// LOGOUT
//
export function logoutRequest(
    token: string, 
    userId: number, 
    onCallback: React.FC<{
        status: number,
        data?: any,
        error?: any}>): actions.LogoutRequestAction {
    return {
        type: constants.LOGOUT_REQUEST,
        token,
        userId,
        onCallback
    }
}
export function logoutSuccess(data: object): actions.LogoutSuccessAction {
    return {
        type: constants.LOGOUT_SUCCESS,
        data
    }
}
export function logoutFailure(error: string): actions.LogoutFailureAction {
    return {
        type: constants.LOGOUT_FAILURE,
        error
    }
}


//
// FORGOT PASSWORD
//
export function forgotPasswordRequest(
    email: string, 
    onCallback: React.FC<{
        status: number,
        data?: any,
        error?: any}>): actions.ForgotPasswordRequestAction {
    return {
        type: constants.FORGOTPASSWORD_REQUEST,
        email,
        onCallback
    }
}
export function forgotPasswordSuccess(data: object): actions.ForgotPasswordSuccessAction {
    return {
        type: constants.FORGOTPASSWORD_SUCCESS,
        data
    }
}
export function forgotPasswordFailure(error: string): actions.ForgotPasswordFailureAction {
    return {
        type: constants.FORGOTPASSWORD_FAILURE,
        error
    }
}

//
// UPDATE PASSWORD
//
export function updatePasswordRequest(
    token: string, 
    password: string, 
    onCallback: React.FC<{
        status: number,
        data?: any,
        error?: any}>): actions.UpdatePasswordRequestAction {
    return {
        type: constants.UPDATEPASSWORD_REQUEST,
        token,
        password,
        onCallback
    }
}
export function updatePasswordSuccess(data: object): actions.UpdatePasswordSuccessAction {
    return {
        type: constants.UPDATEPASSWORD_SUCCESS,
        data
    }
}
export function updatePasswordFailure(error: string): actions.UpdatePasswordFailureAction {
    return {
        type: constants.UPDATEPASSWORD_FAILURE,
        error
    }
}