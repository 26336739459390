// import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/tempahan";
import * as constants from "../constant/tempahan";
import * as functions from "../function/tempahan";
import { IPBT_URL } from "../../config";

//
// TEMPAHAN ACTIVITY TYPE @ KATEGORI TEMPAHAN
//
function* getTempahanActivityType({
  onCallback,
}: actions.TempahanActivityTypeRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/activityTypes`,
    method: "GET",
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.TempahanActivityTypeSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanActivityTypeFailure(error));
  }
}

//
// TEMPAHAN ACTIVITY @ ACARA
//

function* getTempahanActivity({
  params,
  onCallback,
}: actions.TempahanActivityRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/activities`,
    method: "GET",
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.TempahanActivitySuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanActivityFailure(error));
  }
}

//
// TEMPAHAN SECTION @ SEKSYEN
//
function* getTempahanSection({
  onCallback,
}: actions.TempahanSectionRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/sections`,
    method: "GET",
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.TempahanSectionSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanSectionFailure(error));
  }
}

//
// TEMPAHAN ASSET @ LOKASI
//
function* getTempahanAsset({
  params,
  onCallback,
}: actions.TempahanAssetRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/assets`,
    method: "GET",
    params: params,
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.TempahanAssetSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanAssetFailure(error));
  }
}

function* getTempahanDatatable({
  params,
  onCallback,
}: actions.TempahanDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/tempahan/datatable`,
    method: "GET",
    params: {
      paginate: false,
      ...params,
    },
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.TempahanDatatableSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanDatatableFailure(error));
  }
}

// CREATE PRE BLOCK ORDER
function* postPreBlockOrder({
  params,
  onCallback,
}: actions.TempahanDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/tempahan/check/store`,
    method: "POST",
    params: {
      ...params,
      state: 1,
    },
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.TempahanPreBlockBookingSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanPreBlockBookingFailure(error));
  }
}
// DELETE BLOCK PRE ORDER
function* deletePreBlockOrder({
  params,
  onCallback,
}: actions.TempahanDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/tempahan/check/delete`,
    method: "POST",
    params: {
      ...params,
      state: 1,
    },
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.TempahanPreDeleteBookingSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanPreDeleteBookingFailure(error));
  }
}

function* getTempahanSubmit({
  params,
  onCallback,
}: actions.TempahanSubmitRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/ipbt/bookings`,
    method: "POST",
    data: params,
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.TempahanSubmitSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanSubmitFailure(error));
  }
}

function* getTempahanUpdate({
  params,
  onCallback,
}: actions.TempahanUpdateRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `${IPBT_URL}/bookings/${params.id}`,
    method: "PUT",
    data: params,
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.TempahanUpdateSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanUpdateFailure(error));
  }
}

function* getTempahanAssetTimeslot({
  params,
  onCallback,
}: actions.TempahanAssetTimeslotRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/assets/${params.asset_id}/timeslots`,
    method: "GET",
    params: {
      from: params.from,
      to: params.to,
      activity: params.activity,
    },
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.TempahanAssetTimeslotSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanAssetTimeslotFailure(error));
  }
}

function* getTempahanViewBill({
  params,
  onCallback,
}: actions.TempahanViewBillRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `${IPBT_URL}/bookings/bill/${params.id}`,
    method: "GET",
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.TempahanViewBillSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanViewBillFailure(error));
  }
}

function* getTempahanChecking({
  onCallback,
}: actions.TempahanCheckingRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/ipbt/tempahan/check`,
    method: "GET",
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.TempahanCheckingSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.TempahanCheckingFailure(error));
  }
}

export function* watchTempahan() {
  yield takeLatest(
    constants.TEMPAHAN_ACTIVITY_TYPE_REQUEST,
    getTempahanActivityType
  );
  // PRE BOOK
  yield takeLatest(
    constants.TEMPAHAN_PRE_BLOCK_BOOKING_REQUEST,
    postPreBlockOrder
  );
  // DELETE PRE BOOK
  yield takeLatest(
    constants.TEMPAHAN_PRE_DELETE_BOOKING_REQUEST,
    deletePreBlockOrder
  );
  yield takeLatest(constants.TEMPAHAN_ACTIVITY_REQUEST, getTempahanActivity);
  yield takeLatest(constants.TEMPAHAN_SECTION_REQUEST, getTempahanSection);
  yield takeLatest(constants.TEMPAHAN_ASSET_REQUEST, getTempahanAsset);
  yield takeLatest(constants.TEMPAHAN_DATATABLE_REQUEST, getTempahanDatatable);
  yield takeLatest(constants.TEMPAHAN_SUBMIT_REQUEST, getTempahanSubmit);
  yield takeLatest(constants.TEMPAHAN_UPDATE_REQUEST, getTempahanUpdate);
  yield takeLatest(
    constants.TEMPAHAN_ASSET_TIMESLOT_REQUEST,
    getTempahanAssetTimeslot
  );
  yield takeLatest(constants.TEMPAHAN_VIEW_BILL_REQUEST, getTempahanViewBill);
  yield takeLatest(
    constants.TEMPAHAN_CHECKING_ACTIVE_REQUEST,
    getTempahanChecking
  );
}
