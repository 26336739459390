//states
export const REFERENCE_STATES_REQUEST = "REFERENCE/STATES_REQUEST";
export const REFERENCE_STATES_SUCCESS = "REFERENCE/STATES_SUCCESS";
export const REFERENCE_STATES_FAILURE = "REFERENCE/STATES_FAILURE";

//countries
export const REFERENCE_COUNTRIES_REQUEST = "REFERENCE/COUNTRIES_REQUEST";
export const REFERENCE_COUNTRIES_SUCCESS = "REFERENCE/COUNTRIES_SUCCESS";
export const REFERENCE_COUNTRIES_FAILURE = "REFERENCE/COUNTRIES_FAILURE";

//sections
export const REFERENCE_SECTIONS_REQUEST = "REFERENCE/SECTIONS_REQUEST";
export const REFERENCE_SECTIONS_SUCCESS = "REFERENCE/SECTIONS_SUCCESS";
export const REFERENCE_SECTIONS_FAILURE = "REFERENCE/SECTIONS_FAILURE";

//postcode
export const REFERENCE_POSTCODE_REQUEST = "REFERENCE/POSTCODE_REQUEST";
export const REFERENCE_POSTCODE_SUCCESS = "REFERENCE/POSTCODE_SUCCESS";
export const REFERENCE_POSTCODE_FAILURE = "REFERENCE/POSTCODE_FAILURE";

//district || MUKIM
export const REFERENCE_DISTRICT_REQUEST = "REFERENCE/DISTRICT_REQUEST";
export const REFERENCE_DISTRICT_SUCCESS = "REFERENCE/DISTRICT_SUCCESS";
export const REFERENCE_DISTRICT_FAILURE = "REFERENCE/DISTRICT_FAILURE";

//section with mukim
export const REFERENCE_SECTION_WITH_MUKIM_REQUEST =
  "REFERENCE/SECTION_WITH_MUKIM_REQUEST";
export const REFERENCE_SECTION_WITH_MUKIM_SUCCESS =
  "REFERENCE/SECTION_WITH_MUKIM_SUCCESS";
export const REFERENCE_SECTION_WITH_MUKIM_FAILURE =
  "REFERENCE/SECTION_WITH_MUKIM_FAILURE";

//modules
export const REFERENCE_MODULES_REQUEST = "REFERENCE/MODULES_REQUEST";
export const REFERENCE_MODULES_SUCCESS = "REFERENCE/MODULES_SUCCESS";
export const REFERENCE_MODULES_FAILURE = "REFERENCE/MODULES_FAILURE";
