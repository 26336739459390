import * as actions from "../action/sewaan";
import * as constants from "../constant/sewaan";

//
// Sewaan Datatable
//
export function SewaanDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SewaanDatatableRequestAction {
  return {
    type: constants.SEWAAN_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}

export function SewaanDatatableSuccess(
  data: object
): actions.SewaanDatatableSuccessAction {
  return {
    type: constants.SEWAAN_DATATABLE_SUCCESS,
    data,
  };
}

export function SewaanDatatableFailure(
  error: string
): actions.SewaanDatatableFailureAction {
  return {
    type: constants.SEWAAN_DATATABLE_FAILURE,
    error,
  };
}

export function SewaanViewElectricRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SewaanViewElectricRequestAction {
  return {
    type: constants.SEWAAN_VIEW_ELECTRIC_REQUEST,
    params,
    onCallback,
  };
}

export function SewaanViewElectricSuccess(
  data: object
): actions.SewaanViewElectricSuccessAction {
  return {
    type: constants.SEWAAN_VIEW_ELECTRIC_SUCCESS,
    data,
  };
}

export function SewaanViewElectricFailure(
  error: string
): actions.SewaanViewElectricFailureAction {
  return {
    type: constants.SEWAAN_VIEW_ELECTRIC_FAILURE,
    error,
  };
}

export function SewaanViewWaterRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SewaanViewWaterRequestAction {
  return {
    type: constants.SEWAAN_VIEW_WATER_REQUEST,
    params,
    onCallback,
  };
}

export function SewaanViewWaterSuccess(
  data: object
): actions.SewaanViewWaterSuccessAction {
  return {
    type: constants.SEWAAN_VIEW_WATER_SUCCESS,
    data,
  };
}

export function SewaanViewWaterFailure(
  error: string
): actions.SewaanViewWaterFailureAction {
  return {
    type: constants.SEWAAN_VIEW_WATER_FAILURE,
    error,
  };
}

export function SewaanViewRentRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SewaanViewRentRequestAction {
  return {
    type: constants.SEWAAN_VIEW_RENT_REQUEST,
    params,
    onCallback,
  };
}

export function SewaanViewRentSuccess(
  data: object
): actions.SewaanViewRentSuccessAction {
  return {
    type: constants.SEWAAN_VIEW_RENT_SUCCESS,
    data,
  };
}

export function SewaanViewRentFailure(
  error: string
): actions.SewaanViewRentFailureAction {
  return {
    type: constants.SEWAAN_VIEW_RENT_FAILURE,
    error,
  };
}

export function SewaanViewNoticeRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SewaanViewNoticeRequestAction {
  return {
    type: constants.SEWAAN_VIEW_NOTICE_REQUEST,
    params,
    onCallback,
  };
}

export function SewaanViewNoticeSuccess(
  data: object
): actions.SewaanViewNoticeSuccessAction {
  return {
    type: constants.SEWAAN_VIEW_NOTICE_SUCCESS,
    data,
  };
}

export function SewaanViewNoticeFailure(
  error: string
): actions.SewaanViewNoticeFailureAction {
  return {
    type: constants.SEWAAN_VIEW_NOTICE_FAILURE,
    error,
  };
}

export function SewaanViewLicenseRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SewaanViewLicenseRequestAction {
  return {
    type: constants.SEWAAN_VIEW_LICENSE_REQUEST,
    params,
    onCallback,
  };
}

export function SewaanViewLicenseSuccess(
  data: object
): actions.SewaanViewLicenseSuccessAction {
  return {
    type: constants.SEWAAN_VIEW_LICENSE_SUCCESS,
    data,
  };
}

export function SewaanViewLicenseFailure(
  error: string
): actions.SewaanViewLicenseFailureAction {
  return {
    type: constants.SEWAAN_VIEW_LICENSE_FAILURE,
    error,
  };
}

export function SewaanViewTranscElectricRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SewaanViewTranscElectricRequestAction {
  return {
    type: constants.SEWAAN_VIEW_TRANSC_ELECTRIC_REQUEST,
    params,
    onCallback,
  };
}

export function SewaanViewTranscElectricSuccess(
  data: object
): actions.SewaanViewTranscElectricSuccessAction {
  return {
    type: constants.SEWAAN_VIEW_TRANSC_ELECTRIC_SUCCESS,
    data,
  };
}

export function SewaanViewTranscElectricFailure(
  error: string
): actions.SewaanViewTranscElectricFailureAction {
  return {
    type: constants.SEWAAN_VIEW_TRANSC_ELECTRIC_FAILURE,
    error,
  };
}

export function SewaanViewTranscWaterRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SewaanViewTranscWaterRequestAction {
  return {
    type: constants.SEWAAN_VIEW_TRANSC_WATER_REQUEST,
    params,
    onCallback,
  };
}

export function SewaanViewTranscWaterSuccess(
  data: object
): actions.SewaanViewTranscWaterSuccessAction {
  return {
    type: constants.SEWAAN_VIEW_TRANSC_WATER_SUCCESS,
    data,
  };
}

export function SewaanViewTranscWaterFailure(
  error: string
): actions.SewaanViewTranscWaterFailureAction {
  return {
    type: constants.SEWAAN_VIEW_TRANSC_WATER_FAILURE,
    error,
  };
}

export function SewaanViewTranscRentRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SewaanViewTranscRentRequestAction {
  return {
    type: constants.SEWAAN_VIEW_TRANSC_RENT_REQUEST,
    params,
    onCallback,
  };
}

export function SewaanViewTranscRentSuccess(
  data: object
): actions.SewaanViewTranscRentSuccessAction {
  return {
    type: constants.SEWAAN_VIEW_TRANSC_RENT_SUCCESS,
    data,
  };
}

export function SewaanViewTranscRentFailure(
  error: string
): actions.SewaanViewTranscRentFailureAction {
  return {
    type: constants.SEWAAN_VIEW_TRANSC_RENT_FAILURE,
    error,
  };
}