//
// SYARIKAT DATATABLE
//
export const SYARIKAT_DATATABLE_REQUEST = "SYARIKAT/SYARIKAT_DATATABLE_REQUEST";
export const SYARIKAT_DATATABLE_SUCCESS = "SYARIKAT/SYARIKAT_DATATABLE_SUCCESS";
export const SYARIKAT_DATATABLE_FAILURE = "SYARIKAT/SYARIKAT_DATATABLE_FAILURE";

//
// SYARIKAT GET_MOF_PEMBEKAL
//
export const SYARIKAT_GET_MOF_PEMBEKAL_REQUEST = "SYARIKAT/SYARIKAT_GET_MOF_PEMBEKAL_REQUEST";
export const SYARIKAT_GET_MOF_PEMBEKAL_SUCCESS = "SYARIKAT/SYARIKAT_GET_MOF_PEMBEKAL_SUCCESS";
export const SYARIKAT_GET_MOF_PEMBEKAL_FAILURE = "SYARIKAT/SYARIKAT_GET_MOF_PEMBEKAL_FAILURE";

//
// SYARIKAT GET_LIST_MOF_PEMBEKAL
//
export const SYARIKAT_GET_LIST_MOF_PEMBEKAL_REQUEST = "SYARIKAT/SYARIKAT_GET_LIST_MOF_PEMBEKAL_REQUEST";
export const SYARIKAT_GET_LIST_MOF_PEMBEKAL_SUCCESS = "SYARIKAT/SYARIKAT_GET_LIST_MOF_PEMBEKAL_SUCCESS";
export const SYARIKAT_GET_LIST_MOF_PEMBEKAL_FAILURE = "SYARIKAT/SYARIKAT_GET_LIST_MOF_PEMBEKAL_FAILURE";

//
// SYARIKAT GET_BIDANG
//
export const SYARIKAT_GET_BIDANG_REQUEST = "SYARIKAT/SYARIKAT_GET_BIDANG_REQUEST";
export const SYARIKAT_GET_BIDANG_SUCCESS = "SYARIKAT/SYARIKAT_GET_BIDANG_SUCCESS";
export const SYARIKAT_GET_BIDANG_FAILURE = "SYARIKAT/SYARIKAT_GET_BIDANG_FAILURE";

//
// SYARIKAT GET_SUB_BIDANG
//
export const SYARIKAT_GET_SUB_BIDANG_REQUEST = "SYARIKAT/SYARIKAT_GET_SUB_BIDANG_REQUEST";
export const SYARIKAT_GET_SUB_BIDANG_SUCCESS = "SYARIKAT/SYARIKAT_GET_SUB_BIDANG_SUCCESS";
export const SYARIKAT_GET_SUB_BIDANG_FAILURE = "SYARIKAT/SYARIKAT_GET_SUB_BIDANG_FAILURE";

//
// SYARIKAT GET_PECAHAN
//
export const SYARIKAT_GET_PECAHAN_REQUEST = "SYARIKAT/SYARIKAT_GET_PECAHAN_REQUEST";
export const SYARIKAT_GET_PECAHAN_SUCCESS = "SYARIKAT/SYARIKAT_GET_PECAHAN_SUCCESS";
export const SYARIKAT_GET_PECAHAN_FAILURE = "SYARIKAT/SYARIKAT_GET_PECAHAN_FAILURE";

//
// SYARIKAT ADD_MOF_PEMBEKAL
//
export const SYARIKAT_ADD_MOF_PEMBEKAL_REQUEST = "SYARIKAT/SYARIKAT_ADD_MOF_PEMBEKAL_REQUEST";
export const SYARIKAT_ADD_MOF_PEMBEKAL_SUCCESS = "SYARIKAT/SYARIKAT_ADD_MOF_PEMBEKAL_SUCCESS";
export const SYARIKAT_ADD_MOF_PEMBEKAL_FAILURE = "SYARIKAT/SYARIKAT_ADD_MOF_PEMBEKAL_FAILURE";

//
// SYARIKAT DELETE_MOF_PEMBEKAL
//
export const SYARIKAT_DELETE_MOF_PEMBEKAL_REQUEST = "SYARIKAT/SYARIKAT_DELETE_MOF_PEMBEKAL_REQUEST";
export const SYARIKAT_DELETE_MOF_PEMBEKAL_SUCCESS = "SYARIKAT/SYARIKAT_DELETE_MOF_PEMBEKAL_SUCCESS";
export const SYARIKAT_DELETE_MOF_PEMBEKAL_FAILURE = "SYARIKAT/SYARIKAT_DELETE_MOF_PEMBEKAL_FAILURE";

//
// SYARIKAT GET_CIDB_PEMBEKAL
//
export const SYARIKAT_GET_CIDB_PEMBEKAL_REQUEST = "SYARIKAT/SYARIKAT_GET_CIDB_PEMBEKAL_REQUEST";
export const SYARIKAT_GET_CIDB_PEMBEKAL_SUCCESS = "SYARIKAT/SYARIKAT_GET_CIDB_PEMBEKAL_SUCCESS";
export const SYARIKAT_GET_CIDB_PEMBEKAL_FAILURE = "SYARIKAT/SYARIKAT_GET_CIDB_PEMBEKAL_FAILURE";

//
// SYARIKAT GET_LIST_CIDB_PEMBEKAL
//
export const SYARIKAT_GET_LIST_CIDB_PEMBEKAL_REQUEST = "SYARIKAT/SYARIKAT_GET_LIST_CIDB_PEMBEKAL_REQUEST";
export const SYARIKAT_GET_LIST_CIDB_PEMBEKAL_SUCCESS = "SYARIKAT/SYARIKAT_GET_LIST_CIDB_PEMBEKAL_SUCCESS";
export const SYARIKAT_GET_LIST_CIDB_PEMBEKAL_FAILURE = "SYARIKAT/SYARIKAT_GET_LIST_CIDB_PEMBEKAL_FAILURE";

//
// SYARIKAT GET_GRED
//
export const SYARIKAT_GET_GRED_REQUEST = "SYARIKAT/SYARIKAT_GET_GRED_REQUEST";
export const SYARIKAT_GET_GRED_SUCCESS = "SYARIKAT/SYARIKAT_GET_GRED_SUCCESS";
export const SYARIKAT_GET_GRED_FAILURE = "SYARIKAT/SYARIKAT_GET_GRED_FAILURE";

//
// SYARIKAT GET_KATEGORI
//
export const SYARIKAT_GET_KATEGORI_REQUEST = "SYARIKAT/SYARIKAT_GET_KATEGORI_REQUEST";
export const SYARIKAT_GET_KATEGORI_SUCCESS = "SYARIKAT/SYARIKAT_GET_KATEGORI_SUCCESS";
export const SYARIKAT_GET_KATEGORI_FAILURE = "SYARIKAT/SYARIKAT_GET_KATEGORI_FAILURE";

//
// SYARIKAT GET_PENGKHUSUSAN
//
export const SYARIKAT_GET_PENGKHUSUSAN_REQUEST = "SYARIKAT/SYARIKAT_GET_PENGKHUSUSAN_REQUEST";
export const SYARIKAT_GET_PENGKHUSUSAN_SUCCESS = "SYARIKAT/SYARIKAT_GET_PENGKHUSUSAN_SUCCESS";
export const SYARIKAT_GET_PENGKHUSUSAN_FAILURE = "SYARIKAT/SYARIKAT_GET_PENGKHUSUSAN_FAILURE";

//
// SYARIKAT ADD_CIDB_PEMBEKAL
//
export const SYARIKAT_ADD_CIDB_PEMBEKAL_REQUEST = "SYARIKAT/SYARIKAT_ADD_CIDB_PEMBEKAL_REQUEST";
export const SYARIKAT_ADD_CIDB_PEMBEKAL_SUCCESS = "SYARIKAT/SYARIKAT_ADD_CIDB_PEMBEKAL_SUCCESS";
export const SYARIKAT_ADD_CIDB_PEMBEKAL_FAILURE = "SYARIKAT/SYARIKAT_ADD_CIDB_PEMBEKAL_FAILURE";

//
// SYARIKAT DELETE_MOF_PEMBEKAL
//
export const SYARIKAT_DELETE_CIDB_PEMBEKAL_REQUEST = "SYARIKAT/SYARIKAT_DELETE_CIDB_PEMBEKAL_REQUEST";
export const SYARIKAT_DELETE_CIDB_PEMBEKAL_SUCCESS = "SYARIKAT/SYARIKAT_DELETE_CIDB_PEMBEKAL_SUCCESS";
export const SYARIKAT_DELETE_CIDB_PEMBEKAL_FAILURE = "SYARIKAT/SYARIKAT_DELETE_CIDB_PEMBEKAL_FAILURE";

//
// SYARIKAT SAVE_MOF
//
export const SYARIKAT_SAVE_MOF_REQUEST = "SYARIKAT/SYARIKAT_SAVE_MOF_REQUEST";
export const SYARIKAT_SAVE_MOF_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_MOF_SUCCESS";
export const SYARIKAT_SAVE_MOF_FAILURE = "SYARIKAT/SYARIKAT_SAVE_MOF_FAILURE";

//
// SYARIKAT SAVE_CIDB
//
export const SYARIKAT_SAVE_CIDB_REQUEST = "SYARIKAT/SYARIKAT_SAVE_CIDB_REQUEST";
export const SYARIKAT_SAVE_CIDB_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_CIDB_SUCCESS";
export const SYARIKAT_SAVE_CIDB_FAILURE = "SYARIKAT/SYARIKAT_SAVE_CIDB_FAILURE";

//
// SYARIKAT SAVE_PEMBEKAL_SYARIKAT
//
export const SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_REQUEST = "SYARIKAT/SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_REQUEST";
export const SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_SUCCESS";
export const SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_FAILURE = "SYARIKAT/SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_FAILURE";

//
// SYARIKAT GET_JENIS_PEMILIKAN_PEJABAT
//
export const SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_REQUEST = "SYARIKAT/SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_REQUEST";
export const SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_SUCCESS = "SYARIKAT/SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_SUCCESS";
export const SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_FAILURE = "SYARIKAT/SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_FAILURE";

//
// SYARIKAT GET_JENIS_PENDAFTARAN
//
export const SYARIKAT_GET_JENIS_PENDAFTARAN_REQUEST = "SYARIKAT/SYARIKAT_GET_JENIS_PENDAFTARAN_REQUEST";
export const SYARIKAT_GET_JENIS_PENDAFTARAN_SUCCESS = "SYARIKAT/SYARIKAT_GET_JENIS_PENDAFTARAN_SUCCESS";
export const SYARIKAT_GET_JENIS_PENDAFTARAN_FAILURE = "SYARIKAT/SYARIKAT_GET_JENIS_PENDAFTARAN_FAILURE";

//
// SYARIKAT GET_JENIS_SYARIKAT
//
export const SYARIKAT_GET_JENIS_SYARIKAT_REQUEST = "SYARIKAT/SYARIKAT_GET_JENIS_SYARIKAT_REQUEST";
export const SYARIKAT_GET_JENIS_SYARIKAT_SUCCESS = "SYARIKAT/SYARIKAT_GET_JENIS_SYARIKAT_SUCCESS";
export const SYARIKAT_GET_JENIS_SYARIKAT_FAILURE = "SYARIKAT/SYARIKAT_GET_JENIS_SYARIKAT_FAILURE";

//
// SYARIKAT GET_DOK_FORM49
//
export const SYARIKAT_GET_DOK_FORM49_REQUEST = "SYARIKAT/SYARIKAT_GET_DOK_FORM49_REQUEST";
export const SYARIKAT_GET_DOK_FORM49_SUCCESS = "SYARIKAT/SYARIKAT_GET_DOK_FORM49_SUCCESS";
export const SYARIKAT_GET_DOK_FORM49_FAILURE = "SYARIKAT/SYARIKAT_GET_DOK_FORM49_FAILURE";

//
// SYARIKAT GET_DOK_MOF
//
export const SYARIKAT_GET_DOK_MOF_REQUEST = "SYARIKAT/SYARIKAT_GET_DOK_MOF_REQUEST";
export const SYARIKAT_GET_DOK_MOF_SUCCESS = "SYARIKAT/SYARIKAT_GET_DOK_MOF_SUCCESS";
export const SYARIKAT_GET_DOK_MOF_FAILURE = "SYARIKAT/SYARIKAT_GET_DOK_MOF_FAILURE";

//
// SYARIKAT GET_DOK_CIDB
//
export const SYARIKAT_GET_DOK_CIDB_REQUEST = "SYARIKAT/SYARIKAT_GET_DOK_CIDB_REQUEST";
export const SYARIKAT_GET_DOK_CIDB_SUCCESS = "SYARIKAT/SYARIKAT_GET_DOK_CIDB_SUCCESS";
export const SYARIKAT_GET_DOK_CIDB_FAILURE = "SYARIKAT/SYARIKAT_GET_DOK_CIDB_FAILURE";

//
// SYARIKAT GET_DOK_SST
//
export const SYARIKAT_GET_DOK_SST_REQUEST = "SYARIKAT/SYARIKAT_GET_DOK_SST_REQUEST";
export const SYARIKAT_GET_DOK_SST_SUCCESS = "SYARIKAT/SYARIKAT_GET_DOK_SST_SUCCESS";
export const SYARIKAT_GET_DOK_SST_FAILURE = "SYARIKAT/SYARIKAT_GET_DOK_SST_FAILURE";

//
// SYARIKAT GET_DOK_UPEN
//
export const SYARIKAT_GET_DOK_UPEN_REQUEST = "SYARIKAT/SYARIKAT_GET_DOK_UPEN_REQUEST";
export const SYARIKAT_GET_DOK_UPEN_SUCCESS = "SYARIKAT/SYARIKAT_GET_DOK_UPEN_SUCCESS";
export const SYARIKAT_GET_DOK_UPEN_FAILURE = "SYARIKAT/SYARIKAT_GET_DOK_UPEN_FAILURE";

//
// SYARIKAT GET_DOK_BANK
//
export const SYARIKAT_GET_DOK_BANK_REQUEST = "SYARIKAT/SYARIKAT_GET_DOK_BANK_REQUEST";
export const SYARIKAT_GET_DOK_BANK_SUCCESS = "SYARIKAT/SYARIKAT_GET_DOK_BANK_SUCCESS";
export const SYARIKAT_GET_DOK_BANK_FAILURE = "SYARIKAT/SYARIKAT_GET_DOK_BANK_FAILURE";

//
// SYARIKAT GET_DOK_IC
//
export const SYARIKAT_GET_DOK_IC_REQUEST = "SYARIKAT/SYARIKAT_GET_DOK_IC_REQUEST";
export const SYARIKAT_GET_DOK_IC_SUCCESS = "SYARIKAT/SYARIKAT_GET_DOK_IC_SUCCESS";
export const SYARIKAT_GET_DOK_IC_FAILURE = "SYARIKAT/SYARIKAT_GET_DOK_IC_FAILURE";

//
// SYARIKAT GET_DOK_SSM
//
export const SYARIKAT_GET_DOK_SSM_REQUEST = "SYARIKAT/SYARIKAT_GET_DOK_Ssm_REQUEST";
export const SYARIKAT_GET_DOK_SSM_SUCCESS = "SYARIKAT/SYARIKAT_GET_DOK_Ssm_SUCCESS";
export const SYARIKAT_GET_DOK_SSM_FAILURE = "SYARIKAT/SYARIKAT_GET_DOK_Ssm_FAILURE";

//
// SYARIKAT SAVE_DOK_FORM49
//
export const SYARIKAT_SAVE_DOK_FORM49_REQUEST = "SYARIKAT/SYARIKAT_SAVE_DOK_FORM49_REQUEST";
export const SYARIKAT_SAVE_DOK_FORM49_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_DOK_FORM49_SUCCESS";
export const SYARIKAT_SAVE_DOK_FORM49_FAILURE = "SYARIKAT/SYARIKAT_SAVE_DOK_FORM49_FAILURE";

//
// SYARIKAT SAVE_DOK_MOF
//
export const SYARIKAT_SAVE_DOK_MOF_REQUEST = "SYARIKAT/SYARIKAT_SAVE_DOK_MOF_REQUEST";
export const SYARIKAT_SAVE_DOK_MOF_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_DOK_MOF_SUCCESS";
export const SYARIKAT_SAVE_DOK_MOF_FAILURE = "SYARIKAT/SYARIKAT_SAVE_DOK_MOF_FAILURE";

//
// SYARIKAT SAVE_DOK_CIDB
//
export const SYARIKAT_SAVE_DOK_CIDB_REQUEST = "SYARIKAT/SYARIKAT_SAVE_DOK_CIDB_REQUEST";
export const SYARIKAT_SAVE_DOK_CIDB_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_DOK_CIDB_SUCCESS";
export const SYARIKAT_SAVE_DOK_CIDB_FAILURE = "SYARIKAT/SYARIKAT_SAVE_DOK_CIDB_FAILURE";

//
// SYARIKAT SAVE_DOK_SST
//
export const SYARIKAT_SAVE_DOK_SST_REQUEST = "SYARIKAT/SYARIKAT_SAVE_DOK_SST_REQUEST";
export const SYARIKAT_SAVE_DOK_SST_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_DOK_SST_SUCCESS";
export const SYARIKAT_SAVE_DOK_SST_FAILURE = "SYARIKAT/SYARIKAT_SAVE_DOK_SST_FAILURE";

//
// SYARIKAT SAVE_DOK_UPEN
//
export const SYARIKAT_SAVE_DOK_UPEN_REQUEST = "SYARIKAT/SYARIKAT_SAVE_DOK_UPEN_REQUEST";
export const SYARIKAT_SAVE_DOK_UPEN_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_DOK_UPEN_SUCCESS";
export const SYARIKAT_SAVE_DOK_UPEN_FAILURE = "SYARIKAT/SYARIKAT_SAVE_DOK_UPEN_FAILURE";

//
// SYARIKAT SAVE_DOK_BANK
//
export const SYARIKAT_SAVE_DOK_BANK_REQUEST = "SYARIKAT/SYARIKAT_SAVE_DOK_BANK_REQUEST";
export const SYARIKAT_SAVE_DOK_BANK_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_DOK_BANK_SUCCESS";
export const SYARIKAT_SAVE_DOK_BANK_FAILURE = "SYARIKAT/SYARIKAT_SAVE_DOK_BANK_FAILURE";

//
// SYARIKAT SAVE_DOK_IC
//
export const SYARIKAT_SAVE_DOK_IC_REQUEST = "SYARIKAT/SYARIKAT_SAVE_DOK_IC_REQUEST";
export const SYARIKAT_SAVE_DOK_IC_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_DOK_IC_SUCCESS";
export const SYARIKAT_SAVE_DOK_IC_FAILURE = "SYARIKAT/SYARIKAT_SAVE_DOK_IC_FAILURE";

//
// SYARIKAT SAVE_DOK_SSM
//
export const SYARIKAT_SAVE_DOK_SSM_REQUEST = "SYARIKAT/SYARIKAT_SAVE_DOK_Ssm_REQUEST";
export const SYARIKAT_SAVE_DOK_SSM_SUCCESS = "SYARIKAT/SYARIKAT_SAVE_DOK_Ssm_SUCCESS";
export const SYARIKAT_SAVE_DOK_SSM_FAILURE = "SYARIKAT/SYARIKAT_SAVE_DOK_Ssm_FAILURE";

