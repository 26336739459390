import * as actions from '../action/company';
import * as constants from '../constant/company';

export interface CompanyState {
  data: object;
  error: string;
  loading: boolean;
}

export const companyReducer = (
  state: CompanyState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.CompanyAction) => {    
    switch (action.type) {
      
      //
      // COMPANY DATATABLE
      //
      case constants.COMPANY_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.COMPANY_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.COMPANY_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      
      //
      // COMPANY EDIT
      //
      case constants.COMPANY_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.COMPANY_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.COMPANY_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // COMPANY ADD
      //
      case constants.COMPANY_ADD_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.COMPANY_ADD_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.COMPANY_ADD_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // COMPANY VIEW
      //
      case constants.COMPANY_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.COMPANY_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.COMPANY_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // COMPANY LIST
      //
      case constants.COMPANY_LIST_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.COMPANY_LIST_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.COMPANY_LIST_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      
      //
      // COMPANY DELETE
      //
      case constants.COMPANY_DELETE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.COMPANY_DELETE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.COMPANY_DELETE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  