export const DELETE_USER_REQUEST = "USER/DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "USER/DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "USER/DELETE_USER_FAILURE";

export const UPDATEPROFILE_REQUEST = "USER/UPDATEPROFILE_REQUEST";
export const UPDATEPROFILE_SUCCESS = "USER/UPDATEPROFILE_SUCCESS";
export const UPDATEPROFILE_FAILURE = "USER/UPDATEPROFILE_FAILURE";

export const UPDATEPASSWORD_REQUEST = "USER/UPDATEPASSWORD_REQUEST";
export const UPDATEPASSWORD_SUCCESS = "USER/UPDATEPASSWORD_SUCCESS";
export const UPDATEPASSWORD_FAILURE = "USER/UPDATEPASSWORD_FAILURE";

export const ADMINUPDATEPASSWORD_REQUEST = "USER/ADMINUPDATEPASSWORD_REQUEST";
export const ADMINUPDATEPASSWORD_SUCCESS = "USER/ADMINUPDATEPASSWORD_SUCCESS";
export const ADMINUPDATEPASSWORD_FAILURE = "USER/ADMINUPDATEPASSWORD_FAILURE";

export const USERDETAILS_REQUEST = "USER/DETAILS_REQUEST";
export const USERDETAILS_SUCCESS = "USER/DETAILS_SUCCESS";
export const USERDETAILS_FAILURE = "USER/DETAILS_FAILURE";

export const ADDRESS_REQUEST = "USER/ADDRESS_REQUEST";
export const ADDRESS_SUCCESS = "USER/ADDRESS_SUCCESS";
export const ADDRESS_FAILURE = "USER/ADDRESS_FAILURE";

export const UPDATEADDRESS_REQUEST = "USER/UPDATEADDRESS_REQUEST";
export const UPDATEADDRESS_SUCCESS = "USER/UPDATEADDRESS_SUCCESS";
export const UPDATEADDRESS_FAILURE = "USER/UPDATEADDRESS_FAILURE";

export const EMPLOYER_REQUEST = "EMPLOYER/EMPLOYER_REQUEST";
export const EMPLOYER_SUCCESS = "EMPLOYER/EMPLOYER_SUCCESS";
export const EMPLOYER_FAILURE = "EMPLOYER/EMPLOYER_FAILURE";

export const UPDATEEMPLOYER_REQUEST = "EMPLOYER/UPDATEEMPLOYER_REQUEST";
export const UPDATEEMPLOYER_SUCCESS = "EMPLOYER/UPDATEEMPLOYER_SUCCESS";
export const UPDATEEMPLOYER_FAILURE = "EMPLOYER/UPDATEEMPLOYER_FAILURE";

export const CONTACT_REQUEST = "CONTACT/CONTACT_REQUEST";
export const CONTACT_SUCCESS = "CONTACT/CONTACT_SUCCESS";
export const CONTACT_FAILURE = "CONTACT/CONTACT_FAILURE";

export const UPDATECONTACT_REQUEST = "CONTACT/UPDATECONTACT_REQUEST";
export const UPDATECONTACT_SUCCESS = "CONTACT/UPDATECONTACT_SUCCESS";
export const UPDATECONTACT_FAILURE = "CONTACT/UPDATECONTACT_FAILURE";

export const UPDATELOCALE_REQUEST = "CONTACT/UPDATELOCALE_REQUEST";
export const UPDATELOCALE_SUCCESS = "CONTACT/UPDATELOCALE_SUCCESS";
export const UPDATELOCALE_FAILURE = "CONTACT/UPDATELOCALE_FAILURE";

export const UPDATEEMAIL_REQUEST = "CONTACT/UPDATEEMAIL_REQUEST";
export const UPDATEEMAIL_SUCCESS = "CONTACT/UPDATEEMAIL_SUCCESS";
export const UPDATEEMAIL_FAILURE = "CONTACT/UPDATEEMAIL_FAILURE";
