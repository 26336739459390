import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/roro';
import * as constants from '../constant/roro';
import * as functions from '../function/roro';




//
// RORO SEARCH
//
function* getRoroSearch({params, onCallback}: actions.RoroSearchRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/roro/search`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.RoroSearchSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.RoroSearchFailure(error));
    }
    
};

//
// RORO DATATABLE
//
function* getRoroDatatable({params, onCallback}: actions.RoroDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/roro/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.RoroDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.RoroDatatableFailure(error));
    }
    
};



//
// RORO EDIT
//
function* getRoroEdit({params, onCallback}: actions.RoroEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/roro/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.RoroEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.RoroEditFailure(error));
    }
    
};


//
// RORO DRAFT
//
function* getRoroDraft({params, onCallback}: actions.RoroDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/roro/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.RoroDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.RoroDraftFailure(error));
    }
    
};

//
// RORO SUBMIT
//
function* getRoroSubmit({params, onCallback}: actions.RoroSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/roro/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.RoroSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.RoroSubmitFailure(error));
    }
    
};

//
// RORO VIEW
//
function* getRoroView({params, onCallback}: actions.RoroViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/roro/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.RoroViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.RoroViewFailure(error));
    }
    
};

//
// RORO PAY CREATE
//
function* getRoroPayCreate({params, onCallback}: actions.RoroPayCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/roro/payment/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.RoroPayCreateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.RoroPayCreateFailure(error));
    }
    
};

//
// RORO PAY DIRECT
//
function* getRoroPayRedirect({params, onCallback}: actions.RoroPayRedirectRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/roro/payment/redirect`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.RoroPayRedirectSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.RoroPayRedirectFailure(error));
    }
    
};

//
// RORO DELETE DRAFT
//
function* getRoroDeleteDraft({params, onCallback}: actions.RoroDeleteDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/roro/deleteDraft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.RoroDeleteDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.RoroDeleteDraftFailure(error));
    }
    
};

export function* watchRoro() {
    
    yield takeLatest(constants.RORO_SEARCH_REQUEST, getRoroSearch);
    yield takeLatest(constants.RORO_DATATABLE_REQUEST, getRoroDatatable);
    yield takeLatest(constants.RORO_EDIT_REQUEST, getRoroEdit);
    yield takeLatest(constants.RORO_DRAFT_REQUEST, getRoroDraft);
    yield takeLatest(constants.RORO_SUBMIT_REQUEST, getRoroSubmit);
    yield takeLatest(constants.RORO_VIEW_REQUEST, getRoroView);
    yield takeLatest(constants.RORO_PAY_CREATE_REQUEST, getRoroPayCreate);
    yield takeLatest(constants.RORO_PAY_REDIRECT_REQUEST, getRoroPayRedirect);
    yield takeLatest(constants.RORO_DELETEDRAFT_REQUEST, getRoroDeleteDraft);
};


