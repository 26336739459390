import * as actions from '../action/permit';
import * as constants from '../constant/permit';

// 1. /////////////////////////////////////////////////////////////////////////////// PTJ
//
// PTJ Datatable
//
export function PTJDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PTJDatatableRequestAction {
    return {
        type: constants.PTJ_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function PTJDatatableSuccess(data: object): actions.PTJDatatableSuccessAction {
    return {
        type: constants.PTJ_DATATABLE_SUCCESS,
        data
    }
}
export function PTJDatatableFailure(error: string): actions.PTJDatatableFailureAction {
    return {
        type: constants.PTJ_DATATABLE_FAILURE,
        error
    }
}


//
// PTJ View
//
export function PTJViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PTJViewRequestAction {
    return {
        type: constants.PTJ_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function PTJViewSuccess(data: object): actions.PTJViewSuccessAction {
    return {
        type: constants.PTJ_VIEW_SUCCESS,
        data
    }
}
export function PTJViewFailure(error: string): actions.PTJViewFailureAction {
    return {
        type: constants.PTJ_VIEW_FAILURE,
        error
    }
}

//
// PJT Pay Create
//
export function PTJPayCreateRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PTJPayCreateRequestAction {
    return {
        type: constants.PTJ_PAY_CREATE_REQUEST,
        params,
        onCallback
    }
}
export function PTJPayCreateSuccess(data: object): actions.PTJPayCreateSuccessAction {
    return {
        type: constants.PTJ_PAY_CREATE_SUCCESS,
        data
    }
}
export function PTJPayCreateFailure(error: string): actions.PTJPayCreateFailureAction {
    return {
        type: constants.PTJ_PAY_CREATE_FAILURE,
        error
    }
}

//
// PJT Pay Redirect
//
export function PTJPayRedirectRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PTJPayRedirectRequestAction {
    return {
        type: constants.PTJ_PAY_REDIRECT_REQUEST,
        params,
        onCallback
    }
}
export function PTJPayRedirectSuccess(data: object): actions.PTJPayRedirectSuccessAction {
    return {
        type: constants.PTJ_PAY_REDIRECT_SUCCESS,
        data
    }
}
export function PTJPayRedirectFailure(error: string): actions.PTJPayRedirectFailureAction {
    return {
        type: constants.PTJ_PAY_REDIRECT_FAILURE,
        error
    }
}

// 2. /////////////////////////////////////////////////////////////////////////////// PB
//
// PB Datatable
//
export function PBDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PBDatatableRequestAction {
    return {
        type: constants.PB_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function PBDatatableSuccess(data: object): actions.PBDatatableSuccessAction {
    return {
        type: constants.PB_DATATABLE_SUCCESS,
        data
    }
}
export function PBDatatableFailure(error: string): actions.PBDatatableFailureAction {
    return {
        type: constants.PB_DATATABLE_FAILURE,
        error
    }
}


//
// PB View
//
export function PBViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PBViewRequestAction {
    return {
        type: constants.PB_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function PBViewSuccess(data: object): actions.PBViewSuccessAction {
    return {
        type: constants.PB_VIEW_SUCCESS,
        data
    }
}
export function PBViewFailure(error: string): actions.PBViewFailureAction {
    return {
        type: constants.PB_VIEW_FAILURE,
        error
    }
}

//
// PB Pay Create
//
export function PBPayCreateRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PBPayCreateRequestAction {
    return {
        type: constants.PB_PAY_CREATE_REQUEST,
        params,
        onCallback
    }
}
export function PBPayCreateSuccess(data: object): actions.PBPayCreateSuccessAction {
    return {
        type: constants.PB_PAY_CREATE_SUCCESS,
        data
    }
}
export function PBPayCreateFailure(error: string): actions.PBPayCreateFailureAction {
    return {
        type: constants.PB_PAY_CREATE_FAILURE,
        error
    }
}

//
// PB Pay Redirect
//
export function PBPayRedirectRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PBPayRedirectRequestAction {
    return {
        type: constants.PB_PAY_REDIRECT_REQUEST,
        params,
        onCallback
    }
}
export function PBPayRedirectSuccess(data: object): actions.PBPayRedirectSuccessAction {
    return {
        type: constants.PB_PAY_REDIRECT_SUCCESS,
        data
    }
}
export function PBPayRedirectFailure(error: string): actions.PBPayRedirectFailureAction {
    return {
        type: constants.PB_PAY_REDIRECT_FAILURE,
        error
    }
}

//
// PB Draft
//
export function PBDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PBDraftRequestAction {
    return {
        type: constants.PB_DRAFT_REQUEST,
        params,
        onCallback
    }
}
export function PBDraftSuccess(data: object): actions.PBDraftSuccessAction {
    return {
        type: constants.PB_DRAFT_SUCCESS,
        data
    }
}
export function PBDraftFailure(error: string): actions.PBDraftFailureAction {
    return {
        type: constants.PB_DRAFT_FAILURE,
        error
    }
}

//
// PB Submit
//
export function PBSubmitRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PBSubmitRequestAction {
    return {
        type: constants.PB_SUBMIT_REQUEST,
        params,
        onCallback
    }
}
export function PBSubmitSuccess(data: object): actions.PBSubmitSuccessAction {
    return {
        type: constants.PB_SUBMIT_SUCCESS,
        data
    }
}
export function PBSubmitFailure(error: string): actions.PBSubmitFailureAction {
    return {
        type: constants.PB_SUBMIT_FAILURE,
        error
    }
}

//
// PB Edit
//
export function PBEditRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PBEditRequestAction {
    return {
        type: constants.PB_EDIT_REQUEST,
        params,
        onCallback
    }
}
export function PBEditSuccess(data: object): actions.PBEditSuccessAction {
    return {
        type: constants.PB_EDIT_SUCCESS,
        data
    }
}
export function PBEditFailure(error: string): actions.PBEditFailureAction {
    return {
        type: constants.PB_EDIT_FAILURE,
        error
    }
}

//
// PB Pelan
//
export function PBPelanRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PBPelanRequestAction {
    return {
        type: constants.PB_PELAN_REQUEST,
        params,
        onCallback
    }
}
export function PBPelanSuccess(data: object): actions.PBPelanSuccessAction {
    return {
        type: constants.PB_PELAN_SUCCESS,
        data
    }
}
export function PBPelanFailure(error: string): actions.PBPelanFailureAction {
    return {
        type: constants.PB_PELAN_FAILURE,
        error
    }
}


//
// PB DeleteDraft
//
export function PBDeleteDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PBDeleteDraftRequestAction {
    return {
        type: constants.PB_DELETEDRAFT_REQUEST,
        params,
        onCallback
    }
}
export function PBDeleteDraftSuccess(data: object): actions.PBDeleteDraftSuccessAction {
    return {
        type: constants.PB_DELETEDRAFT_SUCCESS,
        data
    }
}
export function PBDeleteDraftFailure(error: string): actions.PBDeleteDraftFailureAction {
    return {
        type: constants.PB_DELETEDRAFT_FAILURE,
        error
    }
}

// 3. /////////////////////////////////////////////////////////////////////////////// PMBB
//
// PMBB Datatable
//
export function PMBBDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PMBBDatatableRequestAction {
    return {
        type: constants.PMBB_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function PMBBDatatableSuccess(data: object): actions.PMBBDatatableSuccessAction {
    return {
        type: constants.PMBB_DATATABLE_SUCCESS,
        data
    }
}
export function PMBBDatatableFailure(error: string): actions.PMBBDatatableFailureAction {
    return {
        type: constants.PMBB_DATATABLE_FAILURE,
        error
    }
}


//
// PMBB View
//
export function PMBBViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PMBBViewRequestAction {
    return {
        type: constants.PMBB_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function PMBBViewSuccess(data: object): actions.PMBBViewSuccessAction {
    return {
        type: constants.PMBB_VIEW_SUCCESS,
        data
    }
}
export function PMBBViewFailure(error: string): actions.PMBBViewFailureAction {
    return {
        type: constants.PMBB_VIEW_FAILURE,
        error
    }
}

//
// PMBB Pay Create
//
export function PMBBPayCreateRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PMBBPayCreateRequestAction {
    return {
        type: constants.PMBB_PAY_CREATE_REQUEST,
        params,
        onCallback
    }
}
export function PMBBPayCreateSuccess(data: object): actions.PMBBPayCreateSuccessAction {
    return {
        type: constants.PMBB_PAY_CREATE_SUCCESS,
        data
    }
}
export function PMBBPayCreateFailure(error: string): actions.PMBBPayCreateFailureAction {
    return {
        type: constants.PMBB_PAY_CREATE_FAILURE,
        error
    }
}

//
// PMBB Pay Redirect
//
export function PMBBPayRedirectRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PMBBPayRedirectRequestAction {
    return {
        type: constants.PMBB_PAY_REDIRECT_REQUEST,
        params,
        onCallback
    }
}
export function PMBBPayRedirectSuccess(data: object): actions.PMBBPayRedirectSuccessAction {
    return {
        type: constants.PMBB_PAY_REDIRECT_SUCCESS,
        data
    }
}
export function PMBBPayRedirectFailure(error: string): actions.PMBBPayRedirectFailureAction {
    return {
        type: constants.PMBB_PAY_REDIRECT_FAILURE,
        error
    }
}

//
// PMBB Draft
//
export function PMBBDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PMBBDraftRequestAction {
    return {
        type: constants.PMBB_DRAFT_REQUEST,
        params,
        onCallback
    }
}
export function PMBBDraftSuccess(data: object): actions.PMBBDraftSuccessAction {
    return {
        type: constants.PMBB_DRAFT_SUCCESS,
        data
    }
}
export function PMBBDraftFailure(error: string): actions.PMBBDraftFailureAction {
    return {
        type: constants.PMBB_DRAFT_FAILURE,
        error
    }
}

//
// PMBB Submit
//
export function PMBBSubmitRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PMBBSubmitRequestAction {
    return {
        type: constants.PMBB_SUBMIT_REQUEST,
        params,
        onCallback
    }
}
export function PMBBSubmitSuccess(data: object): actions.PMBBSubmitSuccessAction {
    return {
        type: constants.PMBB_SUBMIT_SUCCESS,
        data
    }
}
export function PMBBSubmitFailure(error: string): actions.PMBBSubmitFailureAction {
    return {
        type: constants.PMBB_SUBMIT_FAILURE,
        error
    }
}

//
// PMBB Edit
//
export function PMBBEditRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PMBBEditRequestAction {
    return {
        type: constants.PMBB_EDIT_REQUEST,
        params,
        onCallback
    }
}
export function PMBBEditSuccess(data: object): actions.PMBBEditSuccessAction {
    return {
        type: constants.PMBB_EDIT_SUCCESS,
        data
    }
}
export function PMBBEditFailure(error: string): actions.PMBBEditFailureAction {
    return {
        type: constants.PMBB_EDIT_FAILURE,
        error
    }
}

//
// PMBB Pelan
//
export function PMBBPelanRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PMBBPelanRequestAction {
    return {
        type: constants.PMBB_PELAN_REQUEST,
        params,
        onCallback
    }
}
export function PMBBPelanSuccess(data: object): actions.PMBBPelanSuccessAction {
    return {
        type: constants.PMBB_PELAN_SUCCESS,
        data
    }
}
export function PMBBPelanFailure(error: string): actions.PMBBPelanFailureAction {
    return {
        type: constants.PMBB_PELAN_FAILURE,
        error
    }
}

//
// PMBB DeleteDraft
//
export function PMBBDeleteDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PMBBDeleteDraftRequestAction {
    return {
        type: constants.PMBB_DELETEDRAFT_REQUEST,
        params,
        onCallback
    }
}
export function PMBBDeleteDraftSuccess(data: object): actions.PMBBDeleteDraftSuccessAction {
    return {
        type: constants.PMBB_DELETEDRAFT_SUCCESS,
        data
    }
}
export function PMBBDeleteDraftFailure(error: string): actions.PMBBDeleteDraftFailureAction {
    return {
        type: constants.PMBB_DELETEDRAFT_FAILURE,
        error
    }
}

// 4. /////////////////////////////////////////////////////////////////////////////// PKT
//
// PKT Datatable
//
export function PKTDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTDatatableRequestAction {
    return {
        type: constants.PKT_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function PKTDatatableSuccess(data: object): actions.PKTDatatableSuccessAction {
    return {
        type: constants.PKT_DATATABLE_SUCCESS,
        data
    }
}
export function PKTDatatableFailure(error: string): actions.PKTDatatableFailureAction {
    return {
        type: constants.PKT_DATATABLE_FAILURE,
        error
    }
}


//
// PKT View
//
export function PKTViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTViewRequestAction {
    return {
        type: constants.PKT_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function PKTViewSuccess(data: object): actions.PKTViewSuccessAction {
    return {
        type: constants.PKT_VIEW_SUCCESS,
        data
    }
}
export function PKTViewFailure(error: string): actions.PKTViewFailureAction {
    return {
        type: constants.PKT_VIEW_FAILURE,
        error
    }
}

//
// PKT Pay Create
//
export function PKTPayCreateRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTPayCreateRequestAction {
    return {
        type: constants.PKT_PAY_CREATE_REQUEST,
        params,
        onCallback
    }
}
export function PKTPayCreateSuccess(data: object): actions.PKTPayCreateSuccessAction {
    return {
        type: constants.PKT_PAY_CREATE_SUCCESS,
        data
    }
}
export function PKTPayCreateFailure(error: string): actions.PKTPayCreateFailureAction {
    return {
        type: constants.PKT_PAY_CREATE_FAILURE,
        error
    }
}

//
// PKT Pay Redirect
//
export function PKTPayRedirectRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTPayRedirectRequestAction {
    return {
        type: constants.PKT_PAY_REDIRECT_REQUEST,
        params,
        onCallback
    }
}
export function PKTPayRedirectSuccess(data: object): actions.PKTPayRedirectSuccessAction {
    return {
        type: constants.PKT_PAY_REDIRECT_SUCCESS,
        data
    }
}
export function PKTPayRedirectFailure(error: string): actions.PKTPayRedirectFailureAction {
    return {
        type: constants.PKT_PAY_REDIRECT_FAILURE,
        error
    }
}


//
// PKT Draft
//
export function PKTDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTDraftRequestAction {
    return {
        type: constants.PKT_DRAFT_REQUEST,
        params,
        onCallback
    }
}
export function PKTDraftSuccess(data: object): actions.PKTDraftSuccessAction {
    return {
        type: constants.PKT_DRAFT_SUCCESS,
        data
    }
}
export function PKTDraftFailure(error: string): actions.PKTDraftFailureAction {
    return {
        type: constants.PKT_DRAFT_FAILURE,
        error
    }
}

//
// PKT Submit
//
export function PKTSubmitRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTSubmitRequestAction {
    return {
        type: constants.PKT_SUBMIT_REQUEST,
        params,
        onCallback
    }
}
export function PKTSubmitSuccess(data: object): actions.PKTSubmitSuccessAction {
    return {
        type: constants.PKT_SUBMIT_SUCCESS,
        data
    }
}
export function PKTSubmitFailure(error: string): actions.PKTSubmitFailureAction {
    return {
        type: constants.PKT_SUBMIT_FAILURE,
        error
    }
}

//
// PKT Edit
//
export function PKTEditRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTEditRequestAction {
    return {
        type: constants.PKT_EDIT_REQUEST,
        params,
        onCallback
    }
}
export function PKTEditSuccess(data: object): actions.PKTEditSuccessAction {
    return {
        type: constants.PKT_EDIT_SUCCESS,
        data
    }
}
export function PKTEditFailure(error: string): actions.PKTEditFailureAction {
    return {
        type: constants.PKT_EDIT_FAILURE,
        error
    }
}


//
// PKT Pelan
//
export function PKTPelanRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTPelanRequestAction {
    return {
        type: constants.PKT_PELAN_REQUEST,
        params,
        onCallback
    }
}
export function PKTPelanSuccess(data: object): actions.PKTPelanSuccessAction {
    return {
        type: constants.PKT_PELAN_SUCCESS,
        data
    }
}
export function PKTPelanFailure(error: string): actions.PKTPelanFailureAction {
    return {
        type: constants.PKT_PELAN_FAILURE,
        error
    }
}

//
// PKT DeleteDraft
//
export function PKTDeleteDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTDeleteDraftRequestAction {
    return {
        type: constants.PKT_DELETEDRAFT_REQUEST,
        params,
        onCallback
    }
}
export function PKTDeleteDraftSuccess(data: object): actions.PKTDeleteDraftSuccessAction {
    return {
        type: constants.PKT_DELETEDRAFT_SUCCESS,
        data
    }
}
export function PKTDeleteDraftFailure(error: string): actions.PKTDeleteDraftFailureAction {
    return {
        type: constants.PKT_DELETEDRAFT_FAILURE,
        error
    }
}


//
// PKT SeksyenJalan
//
export function PKTSeksyenJalanRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PKTSeksyenJalanRequestAction {
    return {
        type: constants.PKT_SEKSYENJALAN_REQUEST,
        params,
        onCallback
    }
}
export function PKTSeksyenJalanSuccess(data: object): actions.PKTSeksyenJalanSuccessAction {
    return {
        type: constants.PKT_SEKSYENJALAN_SUCCESS,
        data
    }
}
export function PKTSeksyenJalanFailure(error: string): actions.PKTSeksyenJalanFailureAction {
    return {
        type: constants.PKT_SEKSYENJALAN_FAILURE,
        error
    }
}


