import * as actions from "../action/reference";
import * as constants from "../constant/reference";

export interface ReferenceState {
  data: object;
  sections: object;
  states: object;
  countries: object;
  sectionWithMukims: any[];
  error: string;
  loadingSectionWithMukims: boolean;
  loading: boolean;
}

export const referenceReducer = (
  state: ReferenceState = {
    data: {},
    sections: {},
    states: {},
    countries: {},
    sectionWithMukims: [],
    error: "",
    loadingSectionWithMukims: false,
    loading: false,
  },
  action: actions.ReferenceAction
) => {
  switch (action.type) {
    //
    // REFERENCE POSTCODE
    //
    case constants.REFERENCE_POSTCODE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.REFERENCE_POSTCODE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.REFERENCE_POSTCODE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // REFERENCE DISTRICT || MUKIM
    //
    case constants.REFERENCE_DISTRICT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.REFERENCE_DISTRICT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.REFERENCE_DISTRICT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    //
    // REFERENCE SECTION WITH MUKIM
    //
    case constants.REFERENCE_SECTION_WITH_MUKIM_REQUEST: {
      return {
        ...state,
        loadingSectionWithMukims: true,
        error: "",
      };
    }
    case constants.REFERENCE_SECTION_WITH_MUKIM_SUCCESS: {
      return {
        ...state,
        sectionWithMukims: action.data as any,
        loadingSectionWithMukims: false,
      };
    }
    case constants.REFERENCE_SECTION_WITH_MUKIM_FAILURE: {
      return {
        ...state,
        loadingSectionWithMukims: false,
        error: action.error,
      };
    }

    //
    // REFERENCE STATES
    //
    case constants.REFERENCE_STATES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.REFERENCE_STATES_SUCCESS: {
      return {
        ...state,
        states: action.data as any,
        loading: false,
      };
    }
    case constants.REFERENCE_STATES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // REFERENCE COUNTRIES
    //
    case constants.REFERENCE_COUNTRIES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.REFERENCE_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.data as any,
        loading: false,
      };
    }
    case constants.REFERENCE_COUNTRIES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // REFERENCE SECTIONS
    //
    case constants.REFERENCE_SECTIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.REFERENCE_SECTIONS_SUCCESS: {
      return {
        ...state,
        sections: action.data as any,
        loading: false,
      };
    }
    case constants.REFERENCE_SECTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // REFERENCE MODULES
    //
    case constants.REFERENCE_MODULES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.REFERENCE_MODULES_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.REFERENCE_MODULES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
