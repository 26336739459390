import Spinner from "./Spinner";

export interface InputProps {
  checked?: boolean;
  label?: any;
  name: string;
  loading?: boolean;
  onChange?: any;
  onKeyPress?: any;
  onBlur?: any;
  error?: any;
  success?: any;
  type?: string;
  maxLength?: number;
  value: any;
  min?: number;
  max?: number;
  containerClassName?: string;
  labelClassName: string;
  inputClassName: string;
  errorClassName: string;
  helperClassName?: string;
  helper?: any;
  styleMui?: boolean;
  readOnly?: boolean;
  required?: boolean;
  iconLoading?: boolean;
  currency?: boolean;
  placeHolder?: string;
}

const isNumeric = (str: any) => {
  if (typeof str != "string") return false; // we only process strings!
  return !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
};

const Input = ({
  styleMui = true,
  readOnly = false,
  value,
  helper,
  helperClassName,
  inputClassName,
  containerClassName = "",
  errorClassName,
  required = false,
  labelClassName,
  name,
  label = "",
  placeHolder = "",
  type = "",
  maxLength,
  loading,
  onChange,
  onBlur,
  error,
  onKeyPress,
  success,
  iconLoading = false,
  currency = false,
  ...rest
}: InputProps) => {
  //style material-ui input
  if (styleMui) {
    const inputClass = [
      "block",
      "w-full",
      "py-2 px-3",
      "placeholder-input",
      "minput",
      error ? "bg-red-100" : "",
      inputClassName,
    ];
    const containerClass = [
      error ? "border border-red-900" : "border border-gray-300",
      "relative",
      "minput-container",
      error ? "bg-red-100" : "",
      containerClassName,
    ];
    const labelClass = ["minput-label-small", labelClassName];
    const errorClass = [
      "absolute top-12 right-0",
      "right-px",
      "text-xs",
      errorClassName,
    ];
    const helperClass = [
      "absolute top-12 left-0",
      "right-px",
      "text-xs",
      helperClassName,
    ];
    return (
      <div className={containerClass.join(" ")}>
        <input
          type={type}
          className={inputClass.join(" ")}
          name={name}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          disabled={loading}
          value={value}
          {...rest}
        />
        <label className="minput-label">
          <div className={labelClass.join(" ")}>{label}</div>
        </label>
        {helper && <small className={helperClass.join(" ")}>{helper}</small>}
        {error && <small className={errorClass.join(" ")}>{error}</small>}
      </div>
    );
  } else {
    //readonly
    if (readOnly) {
      const containerClass = ["", containerClassName];

      const inputClass = [
        "block",
        "w-full",
        "py-2 px-3",
        "border",
        "bg-gray-100",
        inputClassName,
      ];
      const labelClass = [labelClassName];
      const errorClass = [
        "text-xs",
        "flex items-end justify-end",
        errorClassName,
      ];

      //convert string to float
      if (currency && typeof value === "string") {
        value = value.replace(",", "").trim();
        if (value == "") {
          value = "0.00";
        }
        if (!isNumeric(value)) {
          value = "0.00";
        }
        value = parseFloat(value);
      }

      //format currency
      if (currency) {
        if (value && !isNaN(value)) {
          value = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
          value = "0.00";
        }
      }

      return (
        <div className={containerClass.join(" ")}>
          <label className={labelClass.join(" ")}>{label}</label>
          <label className={inputClass.join(" ")}>{value}&nbsp;</label>
          {error && <small className={errorClass.join(" ")}>{error}</small>}
        </div>
      );
    }

    const containerClass = ["", containerClassName];

    const inputClass = [
      "block",
      "w-full",
      "py-2 px-3",
      "border",
      "border-gray-300",
      error ? "bg-red-100" : "",
      inputClassName,
    ];
    const labelClass = [labelClassName];
    const errorClass = [
      "text-xs",
      "flex items-end justify-end",
      errorClassName,
    ];
    const helperClass = ["text-xs", helperClassName];

    if (iconLoading) {
      return (
        <div className={containerClass.join(" ")}>
          {label && (
            <label className={labelClass.join(" ")}>
              {label} {required ? <b className="text-red-500">*</b> : ""}
            </label>
          )}
          <div className="relative">
            <input
              type={type}
              className={inputClass.join(" ")}
              name={name}
              maxLength={maxLength}
              onChange={onChange}
              onBlur={onBlur}
              disabled={true}
              onKeyPress={onKeyPress}
              value={value}
            />
            <span className="absolute inset-y-0 right-5 flex items-center pl-2">
              <Spinner />
            </span>
          </div>
          {helper && <small className={helperClass.join(" ")}>{helper}</small>}
          {error && <small className={errorClass.join(" ")}>{error}</small>}
        </div>
      );
    }

    return (
      <div className={containerClass.join(" ")}>
        {label && (
          <label className={labelClass.join(" ")}>
            {label} {required ? <b className="text-red-500">*</b> : ""}
          </label>
        )}
        <input
          type={type}
          className={inputClass.join(" ")}
          name={name}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          disabled={loading}
          onKeyPress={onKeyPress}
          value={value}
          placeholder={placeHolder}
          {...rest}
        />
        {helper && <small className={helperClass.join(" ")}>{helper}</small>}
        {error && <small className={errorClass.join(" ")}>{error}</small>}
      </div>
    );
  }
};

export default Input;
