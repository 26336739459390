import * as actions from '../action/mapstatus';
import * as constants from '../constant/mapstatus';

export interface MapstatusState {
  data: object;
  error: string;
  loading: boolean;
}

export const mapstatusReducer = (
  state: MapstatusState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.MapstatusAction) => {    
    switch (action.type) {

      //
      // MAPSTATUS DATATABLE
      //
      case constants.MAPSTATUS_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.MAPSTATUS_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.MAPSTATUS_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      
      //
      // MAPSTATUS EDIT
      //
      case constants.MAPSTATUS_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.MAPSTATUS_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.MAPSTATUS_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // MAPSTATUS ADD
      //
      case constants.MAPSTATUS_ADD_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.MAPSTATUS_ADD_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.MAPSTATUS_ADD_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // MAPSTATUS VIEW
      //
      case constants.MAPSTATUS_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.MAPSTATUS_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.MAPSTATUS_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      
      //
      // MAPSTATUS DELETE
      //
      case constants.MAPSTATUS_DELETE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.MAPSTATUS_DELETE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.MAPSTATUS_DELETE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  