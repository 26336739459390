export const LOGIN_REQUEST = "AUTH/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "AUTH/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "AUTH/LOGIN_FAILURE";

export const IMPERSONATE_REQUEST = "AUTH/IMPERSONATE_REQUEST";
export const IMPERSONATE_SUCCESS = "AUTH/IMPERSONATE_SUCCESS";
export const IMPERSONATE_FAILURE = "AUTH/IMPERSONATE_FAILURE";

export const TOKEN_REQUEST = "AUTH/TOKEN_REQUEST";
export const TOKEN_SUCCESS = "AUTH/TOKEN_SUCCESS";
export const TOKEN_FAILURE = "AUTH/TOKEN_FAILURE";

export const LOGOUT_REQUEST = "AUTH/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "AUTH/LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "AUTH/LOGOUT_FAILURE";

export const FORGOTPASSWORD_REQUEST = "AUTH/FORGOTPASSWORD_REQUEST";
export const FORGOTPASSWORD_SUCCESS = "AUTH/FORGOTPASSWORD_SUCCESS";
export const FORGOTPASSWORD_FAILURE = "AUTH/FORGOTPASSWORD_FAILURE";

export const UPDATEPASSWORD_REQUEST = "AUTH/UPDATEPASSWORD_REQUEST";
export const UPDATEPASSWORD_SUCCESS = "AUTH/UPDATEPASSWORD_SUCCESS";
export const UPDATEPASSWORD_FAILURE = "AUTH/UPDATEPASSWORD_FAILURE";