import * as actions from "../action/pskilang";
import * as constants from "../constant/pskilang";

//
// PsKilang Datatable
//
export function PsKilangDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.PsKilangDatatableRequestAction {
  return {
    type: constants.PSKILANG_DATATABLE_REQUEST,
    params,
    onCallback
  };
}
export function PsKilangDatatableSuccess(data: object): actions.PsKilangDatatableSuccessAction {
  return {
    type: constants.PSKILANG_DATATABLE_SUCCESS,
    data,
  };
}
export function PsKilangDatatableFailure(error: string): actions.PsKilangDatatableFailureAction {
  return {
    type: constants.PSKILANG_DATATABLE_FAILURE,
    error,
  };
}

//
// PsKilang Draft
//
export function PsKilangDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKilangDraftRequestAction {
  return {
    type: constants.PSKILANG_DRAFT_REQUEST,
    params,
    onCallback
  };
}
export function PsKilangDraftSuccess(data: object): actions.PsKilangDraftSuccessAction {
  return {
    type: constants.PSKILANG_DRAFT_SUCCESS,
    data,
  };
}
export function PsKilangDraftFailure(error: string): actions.PsKilangDraftFailureAction {
  return {
    type: constants.PSKILANG_DRAFT_FAILURE,
    error,
  };
}

//
// PsKilang Edit
//
export function PsKilangEditRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKilangEditRequestAction {
  return {
    type: constants.PSKILANG_EDIT_REQUEST,
    params,
    onCallback
  };
}
export function PsKilangEditSuccess(data: object): actions.PsKilangEditSuccessAction {
  return {
    type: constants.PSKILANG_EDIT_SUCCESS,
    data,
  };
}
export function PsKilangEditFailure(error: string): actions.PsKilangEditFailureAction {
  return {
    type: constants.PSKILANG_EDIT_FAILURE,
    error,
  };
}

//
// PsKilang Submit
//
export function PsKilangSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKilangSubmitRequestAction {
  return {
    type: constants.PSKILANG_SUBMIT_REQUEST,
    params,
    onCallback
  };
}
export function PsKilangSubmitSuccess(data: object): actions.PsKilangSubmitSuccessAction {
  return {
    type: constants.PSKILANG_SUBMIT_SUCCESS,
    data,
  };
}
export function PsKilangSubmitFailure(error: string): actions.PsKilangSubmitFailureAction {
  return {
    type: constants.PSKILANG_SUBMIT_FAILURE,
    error,
  };
}


//
// PsKilang View
//
export function PsKilangViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKilangViewRequestAction {
  return {
    type: constants.PSKILANG_VIEW_REQUEST,
    params,
    onCallback
  };
}
export function PsKilangViewSuccess(data: object): actions.PsKilangViewSuccessAction {
  return {
    type: constants.PSKILANG_VIEW_SUCCESS,
    data,
  };
}
export function PsKilangViewFailure(error: string): actions.PsKilangViewFailureAction {
  return {
    type: constants.PSKILANG_VIEW_FAILURE,
    error,
  };
}


//
// PsKilang Search
//
export function PsKilangSearchRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKilangSearchRequestAction {
  return {
    type: constants.PSKILANG_SEARCH_REQUEST,
    params,
    onCallback
  };
}
export function PsKilangSearchSuccess(data: object): actions.PsKilangSearchSuccessAction {
  return {
    type: constants.PSKILANG_SEARCH_SUCCESS,
    data,
  };
}
export function PsKilangSearchFailure(error: string): actions.PsKilangSearchFailureAction {
  return {
    type: constants.PSKILANG_SEARCH_FAILURE,
    error,
  };
}
