import * as actions from "../action/psbuku";
import * as constants from "../constant/psbuku";

//
// PsBuku Verify Paid
//
export function PsBukuVerifyPaidRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.PsBukuVerifyPaidRequestAction {
  return {
    type: constants.PSBUKU_VERIFYPAID_REQUEST,
    params,
    onCallback
  };
}
export function PsBukuVerifyIsPaidSuccess(data: object): actions.PsBukuVerifyPaidSuccessAction {
  return {
    type: constants.PSBUKU_VERIFYPAID_SUCCESS,
    data,
  };
}
export function PsBukuVerifyIsPaidFailure(error: string): actions.PsBukuVerifyPaidFailureAction {
  return {
    type: constants.PSBUKU_VERIFYPAID_FAILURE,
    error,
  };
}


//
// PsBuku Create
//
export function PsBukuCreateRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.PsBukuCreateRequestAction {
  return {
    type: constants.PSBUKU_CREATE_REQUEST,
    params,
    onCallback
  };
}
export function PsBukuCreateSuccess(data: object): actions.PsBukuCreateSuccessAction {
  return {
    type: constants.PSBUKU_CREATE_SUCCESS,
    data,
  };
}
export function PsBukuCreateFailure(error: string): actions.PsBukuCreateFailureAction {
  return {
    type: constants.PSBUKU_CREATE_FAILURE,
    error,
  };
}


//
// PsBuku View
//
export function PsBukuViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.PsBukuViewRequestAction {
  return {
    type: constants.PSBUKU_VIEW_REQUEST,
    params,
    onCallback
  };
}
export function PsBukuViewSuccess(data: object): actions.PsBukuViewSuccessAction {
  return {
    type: constants.PSBUKU_VIEW_SUCCESS,
    data,
  };
}
export function PsBukuViewFailure(error: string): actions.PsBukuViewFailureAction {
  return {
    type: constants.PSBUKU_VIEW_FAILURE,
    error,
  };
}
