import * as actions from '../action/troli';
import * as constants from '../constant/troli';


//
// Troli - Datatable
//
export function TroliDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.TroliDatatableRequestAction {
    return {
        type: constants.TROLI_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function TroliDatatableSuccess(data: object): actions.TroliDatatableSuccessAction {
    return {
        type: constants.TROLI_DATATABLE_SUCCESS,
        data
    }
}
export function TroliDatatableFailure(error: string): actions.TroliDatatableFailureAction {
    return {
        type: constants.TROLI_DATATABLE_FAILURE,
        error
    }
}


//
// Troli - Add to Troli
//
export function TroliAddToTroliRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.TroliAddTroliRequestAction {
    return {
        type: constants.TROLI_ADDTROLI_REQUEST,
        params,
        onCallback
    }
}
export function TroliAddTroliSuccess(data: object): actions.TroliAddTroliSuccessAction {
    return {
        type: constants.TROLI_ADDTROLI_SUCCESS,
        data
    }
}
export function TroliAddTroliFailure(error: string): actions.TroliAddTroliFailureAction {
    return {
        type: constants.TROLI_ADDTROLI_FAILURE,
        error
    }
}


//
// Troli - Trolis
//
export function TroliTrolisRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.TroliTrolisRequestAction {
    return {
        type: constants.TROLI_TROLIS_REQUEST,
        params,
        onCallback
    }
}
export function TroliTrolisSuccess(data: object): actions.TroliTrolisSuccessAction {
    return {
        type: constants.TROLI_TROLIS_SUCCESS,
        data
    }
}
export function TroliTrolisFailure(error: string): actions.TroliTrolisFailureAction {
    return {
        type: constants.TROLI_TROLIS_FAILURE,
        error
    }
}

//
// Troli - Create Invoice
//
export function TroliCreateInvoiceRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.TroliCreateInvoiceRequestAction {
    return {
        type: constants.TROLI_CREATEINVOICE_REQUEST,
        params,
        onCallback
    }
}
export function TroliCreateInvoiceSuccess(data: object): actions.TroliCreateInvoiceSuccessAction {
    return {
        type: constants.TROLI_CREATEINVOICE_SUCCESS,
        data
    }
}
export function TroliCreateInvoiceFailure(error: string): actions.TroliCreateInvoiceFailureAction {
    return {
        type: constants.TROLI_CREATEINVOICE_FAILURE,
        error
    }
}


//
// Troli - Pay Create
//
export function TroliPayCreateRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.TroliPayCreateRequestAction {
    return {
        type: constants.TROLI_PAY_CREATE_REQUEST,
        params,
        onCallback
    }
}
export function TroliPayCreateSuccess(data: object): actions.TroliPayCreateSuccessAction {
    return {
        type: constants.TROLI_PAY_CREATE_SUCCESS,
        data
    }
}
export function TroliPayCreateFailure(error: string): actions.TroliPayCreateFailureAction {
    return {
        type: constants.TROLI_PAY_CREATE_FAILURE,
        error
    }
}

//
// Troli - Pay Redirect
//
export function TroliPayRedirectRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.TroliPayRedirectRequestAction {
    return {
        type: constants.TROLI_PAY_REDIRECT_REQUEST,
        params,
        onCallback
    }
}
export function TroliPayRedirectSuccess(data: object): actions.TroliPayRedirectSuccessAction {
    return {
        type: constants.TROLI_PAY_REDIRECT_SUCCESS,
        data
    }
}
export function TroliPayRedirectFailure(error: string): actions.TroliPayRedirectFailureAction {
    return {
        type: constants.TROLI_PAY_REDIRECT_FAILURE,
        error
    }
}

//
// Troli - View
//
export function TroliViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.TroliViewRequestAction {
    return {
        type: constants.TROLI_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function TroliViewSuccess(data: object): actions.TroliViewSuccessAction {
    return {
        type: constants.TROLI_VIEW_SUCCESS,
        data
    }
}
export function TroliViewFailure(error: string): actions.TroliViewFailureAction {
    return {
        type: constants.TROLI_VIEW_FAILURE,
        error
    }
}


//
// Troli - Delete
//
export function TroliDeleteRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.TroliDeleteRequestAction {
    return {
        type: constants.TROLI_DELETE_REQUEST,
        params,
        onCallback
    }
}
export function TroliDeleteSuccess(data: object): actions.TroliDeleteSuccessAction {
    return {
        type: constants.TROLI_DELETE_SUCCESS,
        data
    }
}
export function TroliDeleteFailure(error: string): actions.TroliDeleteFailureAction {
    return {
        type: constants.TROLI_DELETE_FAILURE,
        error
    }
}