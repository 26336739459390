import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/kpm';
import * as constants from '../constant/kpm';
import * as functions from '../function/kpm';




//
// KPM SEARCH
//
function* getKpmSearch({params, onCallback}: actions.KpmSearchRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/search`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmSearchSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmSearchFailure(error));
    }
    
};

//
// KPM DATATABLE
//
function* getKpmDatatable({params, onCallback}: actions.KpmDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmDatatableFailure(error));
    }
    
};



//
// KPM EDIT
//
function* getKpmEdit({params, onCallback}: actions.KpmEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmEditFailure(error));
    }
    
};


//
// KPM DRAFT
//
function* getKpmDraft({params, onCallback}: actions.KpmDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmDraftFailure(error));
    }
    
};

//
// KPM SUBMIT
//
function* getKpmSubmit({params, onCallback}: actions.KpmSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmSubmitFailure(error));
    }
    
};

//
// KPM VIEW
//
function* getKpmView({params, onCallback}: actions.KpmViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmViewFailure(error));
    }
    
};

//
// KPM PDF
//
function* getKpmPdf({params, onCallback}: actions.KpmPdfRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/kpm/pdf/${params.id}`,
        method: "GET",
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmPdfSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmPdfFailure(error));
    }
};

//
// KPM PAY CREATE
//
function* getKpmPayCreate({params, onCallback}: actions.KpmPayCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/payment/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmPayCreateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmPayCreateFailure(error));
    }
    
};

//
// KPM PAY REDIRECT
//
function* getKpmPayRedirect({params, onCallback}: actions.KpmPayRedirectRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/payment/redirect`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmPayRedirectSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmPayRedirectFailure(error));
    }
    
};

//
// KPM DELETE DRAFT
//
function* getKpmDeleteDraft({params, onCallback}: actions.KpmDeleteDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/deleteDraft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmDeleteDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmDeleteDraftFailure(error));
    }
    
};

//
// KPM PANEL
//
function* getKpmPanel({params, onCallback}: actions.KpmPanelRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/panel`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmPanelSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmPanelFailure(error));
    }
    
};

//
// KPM KLINIK
//
function* getKpmKlinik({params, onCallback}: actions.KpmKlinikRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/klinik`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmKlinikSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmKlinikFailure(error));
    }
    
};

//
// KPM Premise Type
//
function* getKpmPremiseType({params, onCallback}: actions.KpmPremiseTypeRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/kpm/premiseType`,
        method: "GET",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.KpmPremiseTypeSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.KpmPremiseTypeFailure(error));
    }
    
};


export function* watchKpm() {
    
    yield takeLatest(constants.KPM_SEARCH_REQUEST, getKpmSearch);
    yield takeLatest(constants.KPM_DATATABLE_REQUEST, getKpmDatatable);
    yield takeLatest(constants.KPM_EDIT_REQUEST, getKpmEdit);
    yield takeLatest(constants.KPM_DRAFT_REQUEST, getKpmDraft);
    yield takeLatest(constants.KPM_SUBMIT_REQUEST, getKpmSubmit);
    yield takeLatest(constants.KPM_VIEW_REQUEST, getKpmView);
    yield takeLatest(constants.KPM_PDF_REQUEST, getKpmPdf);
    yield takeLatest(constants.KPM_PAY_CREATE_REQUEST, getKpmPayCreate);
    yield takeLatest(constants.KPM_PAY_REDIRECT_REQUEST, getKpmPayRedirect);
    yield takeLatest(constants.KPM_DELETEDRAFT_REQUEST, getKpmDeleteDraft);
    yield takeLatest(constants.KPM_PANEL_REQUEST, getKpmPanel);
    yield takeLatest(constants.KPM_KLINIK_REQUEST, getKpmKlinik);
    yield takeLatest(constants.KPM_PREMISETYPE_REQUEST, getKpmPremiseType);

};


