import * as actions from '../action/psbuku';
import * as constants from '../constant/psbuku';

export interface PsBukuState {
  data: object;
  error: string;
  loading: boolean;
}

export const psbukuReducer = (
  state: PsBukuState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.PsBukuAction) => {    
    switch (action.type) {
      
      //
      // PsBuku Verify Paid
      //
      case constants.PSBUKU_VERIFYPAID_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSBUKU_VERIFYPAID_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSBUKU_VERIFYPAID_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PsBuku Create
      //
      case constants.PSBUKU_CREATE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSBUKU_CREATE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSBUKU_CREATE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PsBuku View
      //
      case constants.PSBUKU_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSBUKU_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSBUKU_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  