import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/mapstatus';
import * as constants from '../constant/mapstatus';
import * as functions from '../function/mapstatus';




//
// MAPSTATUS DATATABLE
//
function* getMapstatusDatatable({params, onCallback}: actions.MapstatusDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/mapstatus/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.MapstatusDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.MapstatusDatatableFailure(error));
    }
    
};



//
// MAPSTATUS EDIT
//
function* getMapstatusEdit({params, onCallback}: actions.MapstatusEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/mapstatus/update`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.MapstatusEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.MapstatusEditFailure(error));
    }
    
};


//
// MAPSTATUS ADD
//
function* getMapstatusAdd({params, onCallback}: actions.MapstatusAddRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/mapstatus/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.MapstatusAddSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.MapstatusAddFailure(error));
    }
    
};

//
// MAPSTATUS VIEW
//
function* getMapstatusView({params, onCallback}: actions.MapstatusViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/mapstatus/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.MapstatusViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.MapstatusViewFailure(error));
    }
    
};


//
// MAPSTATUS DELETE
//
function* getMapstatusDelete({params, onCallback}: actions.MapstatusDeleteRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/mapstatus/delete`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.MapstatusDeleteSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.MapstatusDeleteFailure(error));
    }
    
};

export function* watchMapstatus() {    

    yield takeLatest(constants.MAPSTATUS_DATATABLE_REQUEST, getMapstatusDatatable);
    yield takeLatest(constants.MAPSTATUS_EDIT_REQUEST, getMapstatusEdit);    
    yield takeLatest(constants.MAPSTATUS_ADD_REQUEST, getMapstatusAdd);
    yield takeLatest(constants.MAPSTATUS_VIEW_REQUEST, getMapstatusView);
    yield takeLatest(constants.MAPSTATUS_DELETE_REQUEST, getMapstatusDelete);
};


