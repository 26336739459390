//
// BAYAR DATATABLE
//
export const TROLI_DATATABLE_REQUEST = "TROLI/DATATABLE_REQUEST";
export const TROLI_DATATABLE_SUCCESS = "TROLI/DATATABLE_SUCCESS";
export const TROLI_DATATABLE_FAILURE = "TROLI/DATATABLE_FAILURE";

//
// BAYAR ADD TO TROLI
//
export const TROLI_ADDTROLI_REQUEST = "TROLI/ADDTROLI_REQUEST";
export const TROLI_ADDTROLI_SUCCESS = "TROLI/ADDTROLI_SUCCESS";
export const TROLI_ADDTROLI_FAILURE = "TROLI/ADDTROLI_FAILURE";

//
// BAYAR TROLIS
//
export const TROLI_TROLIS_REQUEST = "TROLI/TROLIS_REQUEST";
export const TROLI_TROLIS_SUCCESS = "TROLI/TROLIS_SUCCESS";
export const TROLI_TROLIS_FAILURE = "TROLI/TROLIS_FAILURE";

//
// BAYAR CREATE INVOICE
//
export const TROLI_CREATEINVOICE_REQUEST = "TROLI/CREATEINVOICE_REQUEST";
export const TROLI_CREATEINVOICE_SUCCESS = "TROLI/CREATEINVOICE_SUCCESS";
export const TROLI_CREATEINVOICE_FAILURE = "TROLI/CREATEINVOICE_FAILURE";

//
// BAYAR PAY CREATE
//
export const TROLI_PAY_CREATE_REQUEST = 'TROLI/TROLI_PAY_CREATE_REQUEST';
export const TROLI_PAY_CREATE_SUCCESS = 'TROLI/TROLI_PAY_CREATE_SUCCESS';
export const TROLI_PAY_CREATE_FAILURE = 'TROLI/TROLI_PAY_CREATE_FAILURE';

//
// BAYAR PAY REDIRECT
//
export const TROLI_PAY_REDIRECT_REQUEST = 'TROLI/TROLI_PAY_REDIRECT_REQUEST';
export const TROLI_PAY_REDIRECT_SUCCESS = 'TROLI/TROLI_PAY_REDIRECT_SUCCESS';
export const TROLI_PAY_REDIRECT_FAILURE = 'TROLI/TROLI_PAY_REDIRECT_FAILURE';


//
// BAYAR VIEW
//
export const TROLI_VIEW_REQUEST = 'TROLI/TROLI_VIEW_REQUEST';
export const TROLI_VIEW_SUCCESS = 'TROLI/TROLI_VIEW_SUCCESS';
export const TROLI_VIEW_FAILURE = 'TROLI/TROLI_VIEW_FAILURE';

//
// DELETE TROLI
//
export const TROLI_DELETE_REQUEST = 'TROLI/TROLI_DELETE_REQUEST';
export const TROLI_DELETE_SUCCESS = 'TROLI/TROLI_DELETE_SUCCESS';
export const TROLI_DELETE_FAILURE = 'TROLI/TROLI_DELETE_FAILURE';