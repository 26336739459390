import * as actions from "../action/kompaun";
import * as constants from "../constant/kompaun";

//
// Kompaun Datatable
//
export function KompaunDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.KompaunDatatableRequestAction {
  return {
    type: constants.KOMPAUN_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}

export function KompaunDatatableSuccess(
  data: object
): actions.KompaunDatatableSuccessAction {
  return {
    type: constants.KOMPAUN_DATATABLE_SUCCESS,
    data,
  };
}

export function KompaunDatatableFailure(
  error: string
): actions.KompaunDatatableFailureAction {
  return {
    type: constants.KOMPAUN_DATATABLE_FAILURE,
    error,
  };
}

//VIEW
export function KompaunViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.KompaunViewRequestAction {
  return {
    type: constants.KOMPAUN_VIEW_REQUEST,
    params,
    onCallback,
  };
}

export function KompaunViewSuccess(
  data: object
): actions.KompaunViewSuccessAction {
  return {
    type: constants.KOMPAUN_VIEW_SUCCESS,
    data,
  };
}

export function KompaunViewFailure(
  error: string
): actions.KompaunViewFailureAction {
  return {
    type: constants.KOMPAUN_VIEW_FAILURE,
    error,
  };
}

//TUNDA
export function KompaunTundaRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.KompaunTundaRequestAction {
  return {
    type: constants.KOMPAUN_TUNDA_REQUEST,
    params,
    onCallback,
  };
}

export function KompaunTundaSuccess(
  data: object
): actions.KompaunTundaSuccessAction {
  return {
    type: constants.KOMPAUN_TUNDA_SUCCESS,
    data,
  };
}

export function KompaunTundaFailure(
  error: string
): actions.KompaunTundaFailureAction {
  return {
    type: constants.KOMPAUN_TUNDA_FAILURE,
    error,
  };
}

//Carian
export function KompaunCarianRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.KompaunCarianRequestAction {
  return {
    type: constants.KOMPAUN_CARIAN_REQUEST,
    params,
    onCallback,
  };
}

export function KompaunCarianSuccess(
  data: object
): actions.KompaunCarianSuccessAction {
  return {
    type: constants.KOMPAUN_CARIAN_SUCCESS,
    data,
  };
}

export function KompaunCarianFailure(
  error: string
): actions.KompaunCarianFailureAction {
  return {
    type: constants.KOMPAUN_CARIAN_FAILURE,
    error,
  };
}

//Carian NOT SECURED
export function KompaunCarianNotSecuredRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.KompaunCarianNotSecuredRequestAction {
  return {
    type: constants.KOMPAUN_CARIAN_NOT_SECURED_REQUEST,
    params,
    onCallback,
  };
}

export function KompaunCarianNotSecuredSuccess(
  data: object
): actions.KompaunCarianNotSecuredSuccessAction {
  console.log("LALLAU MASUK SINI TAK II");
  return {
    type: constants.KOMPAUN_CARIAN_NOT_SECURED_SUCCESS,
    data,
  };
}

export function KompaunCarianNotSecuredFailure(
  error: string
): actions.KompaunCarianNotSecuredFailureAction {
  return {
    type: constants.KOMPAUN_CARIAN_NOT_SECURED_FAILURE,
    error,
  };
}
