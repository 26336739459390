import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/audit";
import * as constants from "../constant/audit";
import * as functions from "../function/audit";

//
//  USERS - DATATABLE
//
function* getAuditDatatable({
  params,
  onCallback,
}: actions.AuditDataTableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/audit-log/datatable`,
    method: "GET",
    params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.auditDataTableSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.auditDataTableFailure(error));
  }
}
export function* watchAudit() {
  yield takeLatest(constants.AUDIT_DATATABLE_REQUEST, getAuditDatatable);
}
