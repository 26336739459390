import { useSelector } from "react-redux";
import PublicLayout from "./layout/PublicLayout";
import SecuredLayout from "./layout/SecuredLayout";

const LandingPage = () => {

    const {isLoggedIn} = useSelector((state: any) => state.auth);
    
    
    if(isLoggedIn) {
        return <SecuredLayout />
    }else {
        return <PublicLayout />
    }

}

export default LandingPage;