import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/company";
import * as constants from "../constant/company";
import * as functions from "../function/company";

//
// COMPANY DATATABLE
//
function* getCompanyDatatable({
  params,
  onCallback,
}: actions.CompanyDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/company/datatable`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    // RESTRUCTURE DATA
    const result = data.data.map((item: any) => ({
      ...item,
      name: item.name.replace(/amp;/gm, ""),
    }));

    onCallback({
      status: 1,
      data: { count: data.count, data: result },
    });
    yield put(
      functions.CompanyDatatableSuccess({ count: data.count, data: result })
    );
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CompanyDatatableFailure(error));
  }
}

//
// COMPANY EDIT
//
function* getCompanyEdit({
  params,
  onCallback,
}: actions.CompanyEditRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/company/update`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CompanyEditSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CompanyEditFailure(error));
  }
}

//
// COMPANY ADD
//
function* getCompanyAdd({
  params,
  onCallback,
}: actions.CompanyAddRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/company/create`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CompanyAddSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CompanyAddFailure(error));
  }
}

//
// COMPANY VIEW
//
function* getCompanyView({
  params,
  onCallback,
}: actions.CompanyViewRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/company/view`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CompanyViewSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CompanyViewFailure(error));
  }
}

//
// COMPANY LIST
//
function* getCompanyList({
  params,
  onCallback,
}: actions.CompanyListRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/company/list`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CompanyListSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CompanyListFailure(error));
  }
}

//
// COMPANY DELETE
//
function* getCompanyDelete({
  params,
  onCallback,
}: actions.CompanyDeleteRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/company/delete`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CompanyDeleteSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CompanyDeleteFailure(error));
  }
}

export function* watchCompany() {
  yield takeLatest(constants.COMPANY_DATATABLE_REQUEST, getCompanyDatatable);
  yield takeLatest(constants.COMPANY_EDIT_REQUEST, getCompanyEdit);
  yield takeLatest(constants.COMPANY_ADD_REQUEST, getCompanyAdd);
  yield takeLatest(constants.COMPANY_VIEW_REQUEST, getCompanyView);
  yield takeLatest(constants.COMPANY_LIST_REQUEST, getCompanyList);
  yield takeLatest(constants.COMPANY_DELETE_REQUEST, getCompanyDelete);
}
