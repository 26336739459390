//
// PRODUCTS
//
export const JUALAN_PRODUCTS_REQUEST = 'JUALAN/PRODUCTS_REQUEST';
export const JUALAN_PRODUCTS_SUCCESS = 'JUALAN/PRODUCTS_SUCCESS';
export const JUALAN_PRODUCTS_FAILURE = 'JUALAN/PRODUCTS_FAILURE';

//
// PRODUCT
//
export const JUALAN_PRODUCT_REQUEST = 'JUALAN/PRODUCT_REQUEST';
export const JUALAN_PRODUCT_SUCCESS = 'JUALAN/PRODUCT_SUCCESS';
export const JUALAN_PRODUCT_FAILURE = 'JUALAN/PRODUCT_FAILURE';

//
// CREATE JUALAN
//
export const CREATE_JUALAN_REQUEST = 'JUALAN/CREATE_JUALAN_REQUEST';
export const CREATE_JUALAN_SUCCESS = 'JUALAN/CREATE_JUALAN_SUCCESS';
export const CREATE_JUALAN_FAILURE = 'JUALAN/CREATE_JUALAN_FAILURE';

//
// CANCEL JUALAN
//
export const CANCEL_JUALAN_REQUEST = 'JUALAN/CANCEL_JUALAN_REQUEST';
export const CANCEL_JUALAN_SUCCESS = 'JUALAN/CANCEL_JUALAN_SUCCESS';
export const CANCEL_JUALAN_FAILURE = 'JUALAN/CANCEL_JUALAN_FAILURE';

//
// JUALAN DATATABLE
//
export const JUALAN_DATATABLE_REQUEST = 'JUALAN/JUALAN_DATATABLE_REQUEST';
export const JUALAN_DATATABLE_SUCCESS = 'JUALAN/JUALAN_DATATABLE_SUCCESS';
export const JUALAN_DATATABLE_FAILURE = 'JUALAN/JUALAN_DATATABLE_FAILURE';
