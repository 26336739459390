import * as actions from '../action/version';
import * as constants from '../constant/version';

//
// VERSION
//
export function VersionRequest(): actions.VersionRequestAction {
    return {
        type: constants.VERSION_REQUEST
    }
}
export function VersionSuccess(data: object): actions.VersionSuccessAction {
    return {
        type: constants.VERSION_SUCCESS,
        data
    }
}
export function VersionFailure(error: string): actions.VersionFailureAction {
    return {
        type: constants.VERSION_FAILURE,
        error
    }
}
