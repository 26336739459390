import * as actions from "../action/loyalti";
import * as constants from "../constant/loyalti";

//
// LOYALTI EVENTS
//
export function LoyaltiEventsRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
  attend?: boolean,
): actions.LoyaltiEventsRequestAction {
  return {
    type: constants.LOYALTI_EVENTS_REQUEST,
    onCallback,
  };
}
export function LoyaltiEventsSuccess(data: object): actions.LoyaltiEventsSuccessAction {
  return {
    type: constants.LOYALTI_EVENTS_SUCCESS,
    data,
  };
}
export function LoyaltiEventsFailure(error: string): actions.LoyaltiEventsFailureAction {
  return {
    type: constants.LOYALTI_EVENTS_FAILURE,
    error,
  };
}

//
// LOYALTI EVENT
//
export function LoyaltiEventRequest(
  eventId: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiEventRequestAction {
  return {
    type: constants.LOYALTI_EVENT_REQUEST,
    eventId,
    onCallback,
  };
}
export function LoyaltiEventSuccess(data: object): actions.LoyaltiEventSuccessAction {
  return {
    type: constants.LOYALTI_EVENT_SUCCESS,
    data,
  };
}
export function LoyaltiEventFailure(error: string): actions.LoyaltiEventFailureAction {
  return {
    type: constants.LOYALTI_EVENT_FAILURE,
    error,
  };
}

//
// LOYALTI PRODUCTS
//
export function LoyaltiProductsRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiProductsRequestAction {
  return {
    type: constants.LOYALTI_PRODUCTS_REQUEST,
    onCallback,
  };
}
export function LoyaltiProductsSuccess(data: object): actions.LoyaltiProductsSuccessAction {
  return {
    type: constants.LOYALTI_PRODUCTS_SUCCESS,
    data,
  };
}
export function LoyaltiProductsFailure(error: string): actions.LoyaltiProductsFailureAction {
  return {
    type: constants.LOYALTI_PRODUCTS_FAILURE,
    error,
  };
}

//
// LOYALTI PRODUCT
//
export function LoyaltiProductRequest(
  productId: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiProductRequestAction {
  return {
    type: constants.LOYALTI_PRODUCT_REQUEST,
    productId,
    onCallback,
  };
}
export function LoyaltiProductSuccess(data: object): actions.LoyaltiProductSuccessAction {
  return {
    type: constants.LOYALTI_PRODUCT_SUCCESS,
    data,
  };
}
export function LoyaltiProductFailure(error: string): actions.LoyaltiProductFailureAction {
  return {
    type: constants.LOYALTI_PRODUCT_FAILURE,
    error,
  };
}

//
// LOYALTI REWARD
//
export function LoyaltiRewardRequest(
  customerId: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiRewardRequestAction {
  return {
    type: constants.LOYALTI_REWARD_REQUEST,
    onCallback,
  };
}
export function LoyaltiRewardSuccess(data: object): actions.LoyaltiRewardSuccessAction {
  return {
    type: constants.LOYALTI_REWARD_SUCCESS,
    data,
  };
}
export function LoyaltiRewardFailure(error: string): actions.LoyaltiRewardFailureAction {
  return {
    type: constants.LOYALTI_REWARD_FAILURE,
    error,
  };
}

//
// LOYALTI ADD TO CART
//
export function LoyaltiAddToCartRequest(
  productId: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiAddToCartRequestAction {
  return {
    type: constants.LOYALTI_ADD_TO_CART_REQUEST,
    productId,
    onCallback,
  };
}
export function LoyaltiAddToCartSuccess(data: object): actions.LoyaltiAddToCartSuccessAction {
  return {
    type: constants.LOYALTI_ADD_TO_CART_SUCCESS,
    data,
  };
}
export function LoyaltiAddToCartFailure(error: string): actions.LoyaltiAddToCartFailureAction {
  return {
    type: constants.LOYALTI_ADD_TO_CART_FAILURE,
    error,
  };
}

//
// LOYALTI DELETE FROM CART
//
export function LoyaltiDeleteFromCartRequest(
  productId: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiDeleteFromCartRequestAction {
  return {
    type: constants.LOYALTI_DELETE_FROM_CART_REQUEST,
    productId,
    onCallback,
  };
}
export function LoyaltiDeleteFromCartSuccess(data: object): actions.LoyaltiDeleteFromCartSuccessAction {
  return {
    type: constants.LOYALTI_DELETE_FROM_CART_SUCCESS,
    data,
  };
}
export function LoyaltiDeleteFromCartFailure(error: string): actions.LoyaltiDeleteFromCartFailureAction {
  return {
    type: constants.LOYALTI_DELETE_FROM_CART_FAILURE,
    error,
  };
}

//
// LOYALTI VIEW CART
//
export function LoyaltiViewCartRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiViewCartRequestAction {
  return {
    type: constants.LOYALTI_VIEW_CART_REQUEST,
    onCallback,
  };
}
export function LoyaltiViewCartSuccess(data: object): actions.LoyaltiViewCartSuccessAction {
  return {
    type: constants.LOYALTI_VIEW_CART_SUCCESS,
    data,
  };
}
export function LoyaltiViewCartFailure(error: string): actions.LoyaltiViewCartFailureAction {
  return {
    type: constants.LOYALTI_VIEW_CART_FAILURE,
    error,
  };
}

//
// LOYALTI VIEW ALL_PAYMENT_CATEGORY
//
export function LoyaltiViewAllPaymentCategoryRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiViewAllPaymentCategoryRequestAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_REQUEST,
    onCallback,
  };
}
export function LoyaltiViewAllPaymentCategorySuccess(data: object): actions.LoyaltiViewAllPaymentCategorySuccessAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_SUCCESS,
    data,
  };
}
export function LoyaltiViewAllPaymentCategoryFailure(error: string): actions.LoyaltiViewAllPaymentCategoryFailureAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_FAILURE,
    error,
  };
}

//
// LOYALTI VIEW PAYMENT_CATEGORY
//
export function LoyaltiViewPaymentCategoryRequest(
  paymentCategoryId: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiViewPaymentCategoryRequestAction {
  return {
    type: constants.LOYALTI_VIEW_PAYMENT_CATEGORY_REQUEST,
    paymentCategoryId,
    onCallback,
  };
}
export function LoyaltiViewPaymentCategorySuccess(data: object): actions.LoyaltiViewPaymentCategorySuccessAction {
  return {
    type: constants.LOYALTI_VIEW_PAYMENT_CATEGORY_SUCCESS,
    data,
  };
}
export function LoyaltiViewPaymentCategoryFailure(error: string): actions.LoyaltiViewPaymentCategoryFailureAction {
  return {
    type: constants.LOYALTI_VIEW_PAYMENT_CATEGORY_FAILURE,
    error,
  };
}

//
// LOYALTI VIEW ALL_PAYMENT_TYPE
//
export function LoyaltiViewAllPaymentTypeRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiViewAllPaymentTypeRequestAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_PAYMENT_TYPE_REQUEST,
    onCallback,
  };
}
export function LoyaltiViewAllPaymentTypeSuccess(data: object): actions.LoyaltiViewAllPaymentTypeSuccessAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_PAYMENT_TYPE_SUCCESS,
    data,
  };
}
export function LoyaltiViewAllPaymentTypeFailure(error: string): actions.LoyaltiViewAllPaymentTypeFailureAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_PAYMENT_TYPE_FAILURE,
    error,
  };
}

//
// LOYALTI VIEW PAYMENT_TYPE
//
export function LoyaltiViewPaymentTypeRequest(
  paymentTypeId: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiViewPaymentTypeRequestAction {
  return {
    type: constants.LOYALTI_VIEW_PAYMENT_TYPE_REQUEST,
    paymentTypeId,
    onCallback,
  };
}
export function LoyaltiViewPaymentTypeSuccess(data: object): actions.LoyaltiViewPaymentTypeSuccessAction {
  return {
    type: constants.LOYALTI_VIEW_PAYMENT_TYPE_SUCCESS,
    data,
  };
}
export function LoyaltiViewPaymentTypeFailure(error: string): actions.LoyaltiViewPaymentTypeFailureAction {
  return {
    type: constants.LOYALTI_VIEW_PAYMENT_TYPE_FAILURE,
    error,
  };
}

//
// LOYALTI VIEW ALL_ORDER
//
export function LoyaltiViewAllOrderRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiViewAllOrderRequestAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_ORDER_REQUEST,
    onCallback,
  };
}
export function LoyaltiViewAllOrderSuccess(data: object): actions.LoyaltiViewAllOrderSuccessAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_ORDER_SUCCESS,
    data,
  };
}
export function LoyaltiViewAllOrderFailure(error: string): actions.LoyaltiViewAllOrderFailureAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_ORDER_FAILURE,
    error,
  };
}

//
// LOYALTI VIEW ORDER
//
export function LoyaltiViewOrderRequest(
  orderId: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiViewOrderRequestAction {
  return {
    type: constants.LOYALTI_VIEW_ORDER_REQUEST,
    orderId,
    onCallback,
  };
}
export function LoyaltiViewOrderSuccess(data: object): actions.LoyaltiViewOrderSuccessAction {
  return {
    type: constants.LOYALTI_VIEW_ORDER_SUCCESS,
    data,
  };
}
export function LoyaltiViewOrderFailure(error: string): actions.LoyaltiViewOrderFailureAction {
  return {
    type: constants.LOYALTI_VIEW_ORDER_FAILURE,
    error,
  };
}

//
// LOYALTI CREATE_NEW_ORDER
//
export function LoyaltiCreateOrderRequest(
  formData: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiCreateOrderRequestAction {
  return {
    type: constants.LOYALTI_CREATE_NEW_ORDER_REQUEST,
    formData,
    onCallback,
  };
}
export function LoyaltiCreateOrderSuccess(data: object): actions.LoyaltiCreateOrderSuccessAction {
  return {
    type: constants.LOYALTI_CREATE_NEW_ORDER_SUCCESS,
    data,
  };
}
export function LoyaltiCreateOrderFailure(error: string): actions.LoyaltiCreateOrderFailureAction {
  return {
    type: constants.LOYALTI_CREATE_NEW_ORDER_FAILURE,
    error,
  };
}

//
// LOYALTI UPDATE_ORDER
//
export function LoyaltiUpdateOrderRequest(
  formData: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiUpdateOrderRequestAction {
  return {
    type: constants.LOYALTI_UPDATE_ORDER_REQUEST,
    formData,
    onCallback,
  };
}
export function LoyaltiUpdateOrderSuccess(data: object): actions.LoyaltiUpdateOrderSuccessAction {
  return {
    type: constants.LOYALTI_UPDATE_ORDER_SUCCESS,
    data,
  };
}
export function LoyaltiUpdateOrderFailure(error: string): actions.LoyaltiUpdateOrderFailureAction {
  return {
    type: constants.LOYALTI_UPDATE_ORDER_FAILURE,
    error,
  };
}

//
// LOYALTI VIEW ALL_TRANSACTION
//
export function LoyaltiViewAllTransactionRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiViewAllTransactionRequestAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_TRANSACTION_REQUEST,
    onCallback,
  };
}
export function LoyaltiViewAllTransactionSuccess(data: object): actions.LoyaltiViewAllTransactionSuccessAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_TRANSACTION_SUCCESS,
    data,
  };
}
export function LoyaltiViewAllTransactionFailure(error: string): actions.LoyaltiViewAllTransactionFailureAction {
  return {
    type: constants.LOYALTI_VIEW_ALL_TRANSACTION_FAILURE,
    error,
  };
}

//
// LOYALTI CREATE_NEW_TRANSACTION
//
export function LoyaltiCreateTransactionRequest(
  formData: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.LoyaltiCreateTransactionRequestAction {
  return {
    type: constants.LOYALTI_CREATE_NEW_TRANSACTION_REQUEST,
    formData,
    onCallback,
  };
}
export function LoyaltiCreateTransactionSuccess(data: object): actions.LoyaltiCreateTransactionSuccessAction {
  return {
    type: constants.LOYALTI_CREATE_NEW_TRANSACTION_SUCCESS,
    data,
  };
}
export function LoyaltiCreateTransactionFailure(error: string): actions.LoyaltiCreateTransactionFailureAction {
  return {
    type: constants.LOYALTI_CREATE_NEW_TRANSACTION_FAILURE,
    error,
  };
}
