import Circle from 'react-circle';
import ReactTooltip from 'react-tooltip';

export interface CircularProgressProps {
    progress: number;
    tooltip: string;
}

const CircularProgress = ({progress, tooltip}: CircularProgressProps) => {

    return  <div><ReactTooltip /> <div data-tip={tooltip}><Circle        
        animate={false}
        size={"25"} 
        lineWidth={"60"}
        progress={progress}
        progressColor="#FF5733"
        bgColor="#C0C0C0"
        textColor="hotpink"
        percentSpacing={0} 
        roundedStroke={true}
        showPercentage={false}
        showPercentageSymbol={false}
  /></div></div>;
}

export default CircularProgress;