import * as actions from "../action/reference";
import * as constants from "../constant/reference";

//
// REFERENCE POSTCODE
//
export function ReferencePostcodeRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.ReferencePostcodeRequestAction {
  return {
    type: constants.REFERENCE_POSTCODE_REQUEST,
    params,
    onCallback,
  };
}
export function ReferencePostcodeSuccess(
  data: object
): actions.ReferencePostcodeSuccessAction {
  return {
    type: constants.REFERENCE_POSTCODE_SUCCESS,
    data,
  };
}
export function ReferencePostcodeFailure(
  error: string
): actions.ReferencePostcodeFailureAction {
  return {
    type: constants.REFERENCE_POSTCODE_FAILURE,
    error,
  };
}

//
// REFERENCE DISCTRICT || MUKIM
//
export function ReferenceDistrictRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.ReferenceDistrictRequestAction {
  return {
    type: constants.REFERENCE_DISTRICT_REQUEST,
    params,
    onCallback,
  };
}
export function ReferenceDistrictSuccess(
  data: object
): actions.ReferenceDistrictSuccessAction {
  return {
    type: constants.REFERENCE_DISTRICT_SUCCESS,
    data,
  };
}
export function ReferenceDistrictFailure(
  error: string
): actions.ReferenceDistrictFailureAction {
  return {
    type: constants.REFERENCE_DISTRICT_FAILURE,
    error,
  };
}

//
// REFERENCE DISCTRICT || MUKIM
//
export function ReferenceSectionWithMukimRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.ReferenceSectionWithMukimRequestAction {
  return {
    type: constants.REFERENCE_SECTION_WITH_MUKIM_REQUEST,
    params,
    onCallback,
  };
}
export function ReferenceSectionWithMukimSuccess(
  data: object
): actions.ReferenceSectionWithMukimSuccessAction {
  return {
    type: constants.REFERENCE_SECTION_WITH_MUKIM_SUCCESS,
    data,
  };
}
export function ReferenceSectionWithMukimFailure(
  error: string
): actions.ReferenceSectionWithMukimFailureAction {
  return {
    type: constants.REFERENCE_SECTION_WITH_MUKIM_FAILURE,
    error,
  };
}

//
// REFERENCE STATES
//
export function ReferenceStatesRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.ReferenceStatesRequestAction {
  return {
    type: constants.REFERENCE_STATES_REQUEST,
    onCallback,
  };
}
export function ReferenceStatesSuccess(
  data: object
): actions.ReferenceStatesSuccessAction {
  return {
    type: constants.REFERENCE_STATES_SUCCESS,
    data,
  };
}
export function ReferenceStatesFailure(
  error: string
): actions.ReferenceStatesFailureAction {
  return {
    type: constants.REFERENCE_STATES_FAILURE,
    error,
  };
}

//
// REFERENCE COUNTRIES
//
export function ReferenceCounterisRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.ReferenceCountriesRequestAction {
  return {
    type: constants.REFERENCE_COUNTRIES_REQUEST,
    onCallback,
  };
}
export function ReferenceCountriesSuccess(
  data: object
): actions.ReferenceCountriesSuccessAction {
  return {
    type: constants.REFERENCE_COUNTRIES_SUCCESS,
    data,
  };
}
export function ReferenceCountriesFailure(
  error: string
): actions.ReferenceCountriesFailureAction {
  return {
    type: constants.REFERENCE_COUNTRIES_FAILURE,
    error,
  };
}

//
// REFERENCE SECTIONS
//
export function ReferenceSectionsRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.ReferenceSectionsRequestAction {
  return {
    type: constants.REFERENCE_SECTIONS_REQUEST,
    onCallback,
  };
}
export function ReferenceSectionsSuccess(
  data: object
): actions.ReferenceSectionsSuccessAction {
  return {
    type: constants.REFERENCE_SECTIONS_SUCCESS,
    data,
  };
}
export function ReferenceSectionsFailure(
  error: string
): actions.ReferenceSectionsFailureAction {
  return {
    type: constants.REFERENCE_SECTIONS_FAILURE,
    error,
  };
}

//
// REFERENCE MODULES
//
export function ReferenceModulesRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.ReferenceModulesRequestAction {
  return {
    type: constants.REFERENCE_MODULES_REQUEST,
    onCallback,
  };
}
export function ReferenceModulesSuccess(
  data: object
): actions.ReferenceModulesSuccessAction {
  return {
    type: constants.REFERENCE_MODULES_SUCCESS,
    data,
  };
}
export function ReferenceModulesFailure(
  error: string
): actions.ReferenceModulesFailureAction {
  return {
    type: constants.REFERENCE_MODULES_FAILURE,
    error,
  };
}
