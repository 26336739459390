import * as actions from "../action/syarikat";
import * as constants from "../constant/syarikat";

export interface SyarikatState {
  data: object;
  error: string;
  loading: boolean;
}

export const syarikatReducer = (
  state: SyarikatState = {
    data: {},
    error: "",
    loading: false,
  },
  action: actions.SyarikatAction
) => {
  switch (action.type) {
    //
    // SYARIKAT DATATABLE
    //
    case constants.SYARIKAT_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_MOF_PEMBEKAL
    //
    case constants.SYARIKAT_GET_MOF_PEMBEKAL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_MOF_PEMBEKAL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_MOF_PEMBEKAL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_LIST_MOF_PEMBEKAL
    //
    case constants.SYARIKAT_GET_LIST_MOF_PEMBEKAL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_LIST_MOF_PEMBEKAL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_LIST_MOF_PEMBEKAL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_BIDANG
    //
    case constants.SYARIKAT_GET_BIDANG_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_BIDANG_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_BIDANG_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_SUB_BIDANG
    //
    case constants.SYARIKAT_GET_SUB_BIDANG_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_SUB_BIDANG_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_SUB_BIDANG_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_PECAHAN
    //
    case constants.SYARIKAT_GET_PECAHAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_PECAHAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_PECAHAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT ADD_MOF_PEMBEKAL
    //
    case constants.SYARIKAT_ADD_MOF_PEMBEKAL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_ADD_MOF_PEMBEKAL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_ADD_MOF_PEMBEKAL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT DELETE_MOF_PEMBEKAL
    //
    case constants.SYARIKAT_DELETE_MOF_PEMBEKAL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_DELETE_MOF_PEMBEKAL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_DELETE_MOF_PEMBEKAL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_CIDB_PEMBEKAL
    //
    case constants.SYARIKAT_GET_CIDB_PEMBEKAL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_CIDB_PEMBEKAL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_CIDB_PEMBEKAL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_LIST_CIDB_PEMBEKAL
    //
    case constants.SYARIKAT_GET_LIST_CIDB_PEMBEKAL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_LIST_CIDB_PEMBEKAL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_LIST_CIDB_PEMBEKAL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_GRED
    //
    case constants.SYARIKAT_GET_GRED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_GRED_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_GRED_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_KATEGORI
    //
    case constants.SYARIKAT_GET_KATEGORI_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_KATEGORI_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_KATEGORI_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_PENGKHUSUSAN
    //
    case constants.SYARIKAT_GET_PENGKHUSUSAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_PENGKHUSUSAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_PENGKHUSUSAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT ADD_CIDB_PEMBEKAL
    //
    case constants.SYARIKAT_ADD_CIDB_PEMBEKAL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_ADD_CIDB_PEMBEKAL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_ADD_CIDB_PEMBEKAL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT DELETE_CIDB_PEMBEKAL
    //
    case constants.SYARIKAT_DELETE_CIDB_PEMBEKAL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_DELETE_CIDB_PEMBEKAL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_DELETE_CIDB_PEMBEKAL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_MOF
    //
    case constants.SYARIKAT_SAVE_MOF_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_MOF_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_MOF_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_CIDB
    //
    case constants.SYARIKAT_SAVE_CIDB_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_CIDB_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_CIDB_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_PEMBEKAL_SYARIKAT
    //
    case constants.SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_JENIS_PENDAFTARAN
    //
    case constants.SYARIKAT_GET_JENIS_PENDAFTARAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_JENIS_PENDAFTARAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_JENIS_PENDAFTARAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_JENIS_SYARIKAT
    //
    case constants.SYARIKAT_GET_JENIS_SYARIKAT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_JENIS_SYARIKAT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_JENIS_SYARIKAT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_JENIS_PEMILIKAN_PEJABAT
    //
    case constants.SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_DOK_FORM49
    //
    case constants.SYARIKAT_GET_DOK_FORM49_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_DOK_FORM49_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_DOK_FORM49_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_DOK_MOF
    //
    case constants.SYARIKAT_GET_DOK_MOF_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_DOK_MOF_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_DOK_MOF_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_DOK_CIDB
    //
    case constants.SYARIKAT_GET_DOK_CIDB_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_DOK_CIDB_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_DOK_CIDB_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_DOK_SST
    //
    case constants.SYARIKAT_GET_DOK_SST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_DOK_SST_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_DOK_SST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_DOK_UPEN
    //
    case constants.SYARIKAT_GET_DOK_UPEN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_DOK_UPEN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_DOK_UPEN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_DOK_BANK
    //
    case constants.SYARIKAT_GET_DOK_BANK_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_DOK_BANK_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_DOK_BANK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT GET_DOK_IC
    //
    case constants.SYARIKAT_GET_DOK_IC_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    //
    // SYARIKAT GET_DOK_SSM
    //
    case constants.SYARIKAT_GET_DOK_SSM_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_GET_DOK_SSM_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_DOK_SSM_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }


    case constants.SYARIKAT_GET_DOK_IC_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_GET_DOK_IC_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_DOK_FORM49
    //
    case constants.SYARIKAT_SAVE_DOK_FORM49_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_DOK_FORM49_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_DOK_FORM49_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_DOK_MOF
    //
    case constants.SYARIKAT_SAVE_DOK_MOF_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_DOK_MOF_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_DOK_MOF_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_DOK_CIDB
    //
    case constants.SYARIKAT_SAVE_DOK_CIDB_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_DOK_CIDB_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_DOK_CIDB_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_DOK_SST
    //
    case constants.SYARIKAT_SAVE_DOK_SST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_DOK_SST_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_DOK_SST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_DOK_UPEN
    //
    case constants.SYARIKAT_SAVE_DOK_UPEN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_DOK_UPEN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_DOK_UPEN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_DOK_BANK
    //
    case constants.SYARIKAT_SAVE_DOK_BANK_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_DOK_BANK_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_DOK_BANK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_DOK_IC
    //
    case constants.SYARIKAT_SAVE_DOK_IC_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_DOK_IC_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_DOK_IC_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SYARIKAT SAVE_DOK_SSM
    //
    case constants.SYARIKAT_SAVE_DOK_SSM_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SYARIKAT_SAVE_DOK_SSM_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SYARIKAT_SAVE_DOK_SSM_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
