import * as actions from "../action/pstelco";
import * as constants from "../constant/pstelco";

//
// PsTelco Datatable
//
export function PsTelcoDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.PsTelcoDatatableRequestAction {
  return {
    type: constants.PSTELCO_DATATABLE_REQUEST,
    params,
    onCallback
  };
}
export function PsTelcoDatatableSuccess(data: object): actions.PsTelcoDatatableSuccessAction {
  return {
    type: constants.PSTELCO_DATATABLE_SUCCESS,
    data,
  };
}
export function PsTelcoDatatableFailure(error: string): actions.PsTelcoDatatableFailureAction {
  return {
    type: constants.PSTELCO_DATATABLE_FAILURE,
    error,
  };
}

//
// PsTelco Draft
//
export function PsTelcoDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTelcoDraftRequestAction {
  return {
    type: constants.PSTELCO_DRAFT_REQUEST,
    params,
    onCallback
  };
}
export function PsTelcoDraftSuccess(data: object): actions.PsTelcoDraftSuccessAction {
  return {
    type: constants.PSTELCO_DRAFT_SUCCESS,
    data,
  };
}
export function PsTelcoDraftFailure(error: string): actions.PsTelcoDraftFailureAction {
  return {
    type: constants.PSTELCO_DRAFT_FAILURE,
    error,
  };
}

//
// PsTelco Edit
//
export function PsTelcoEditRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTelcoEditRequestAction {
  return {
    type: constants.PSTELCO_EDIT_REQUEST,
    params,
    onCallback
  };
}
export function PsTelcoEditSuccess(data: object): actions.PsTelcoEditSuccessAction {
  return {
    type: constants.PSTELCO_EDIT_SUCCESS,
    data,
  };
}
export function PsTelcoEditFailure(error: string): actions.PsTelcoEditFailureAction {
  return {
    type: constants.PSTELCO_EDIT_FAILURE,
    error,
  };
}

//
// PsTelco Submit
//
export function PsTelcoSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTelcoSubmitRequestAction {
  return {
    type: constants.PSTELCO_SUBMIT_REQUEST,
    params,
    onCallback
  };
}
export function PsTelcoSubmitSuccess(data: object): actions.PsTelcoSubmitSuccessAction {
  return {
    type: constants.PSTELCO_SUBMIT_SUCCESS,
    data,
  };
}
export function PsTelcoSubmitFailure(error: string): actions.PsTelcoSubmitFailureAction {
  return {
    type: constants.PSTELCO_SUBMIT_FAILURE,
    error,
  };
}


//
// PsTelco View
//
export function PsTelcoViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTelcoViewRequestAction {
  return {
    type: constants.PSTELCO_VIEW_REQUEST,
    params,
    onCallback
  };
}
export function PsTelcoViewSuccess(data: object): actions.PsTelcoViewSuccessAction {
  return {
    type: constants.PSTELCO_VIEW_SUCCESS,
    data,
  };
}
export function PsTelcoViewFailure(error: string): actions.PsTelcoViewFailureAction {
  return {
    type: constants.PSTELCO_VIEW_FAILURE,
    error,
  };
}

//
// PsTelco Search
//
export function PsTelcoSearchRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTelcoSearchRequestAction {
  return {
    type: constants.PSTELCO_SEARCH_REQUEST,
    params,
    onCallback
  };
}
export function PsTelcoSearchSuccess(data: object): actions.PsTelcoSearchSuccessAction {
  return {
    type: constants.PSTELCO_SEARCH_SUCCESS,
    data,
  };
}
export function PsTelcoSearchFailure(error: string): actions.PsTelcoSearchFailureAction {
  return {
    type: constants.PSTELCO_SEARCH_FAILURE,
    error,
  };
}
