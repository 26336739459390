/* eslint-disable jsx-a11y/anchor-is-valid */
import { AnimatePresence, motion } from "framer-motion";
import React, { MutableRefObject, useEffect } from "react";
import { CgLogOff } from "react-icons/cg";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IdentityTypeOps from "../../../enums/IdentityTypeOps";
import { LOGOUT_REQUEST } from "../../../redux/constant/auth";
import AvatarUser from "./AvatarUser";
import SelectLocale from "./SelectLocale";

const UserMenu = React.forwardRef<
  HTMLDivElement,
  {
    open: boolean;
    onToggle: () => void;
    auth: any;
  }
>((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    const mutableRef = ref as MutableRefObject<HTMLDivElement | null>;
    const handleClickOutside = (event: any) => {
      if (mutableRef.current && !mutableRef.current.contains(event.target) && props.open) {
        props.onToggle();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  //callback from logout
  const onCallback = () => {
    navigate("/");
    window.location.reload();
  };

  // handle logout
  const handleLogout = () => {
    const impersonateToken = localStorage.getItem("mymbsa-impersonate-token");
    if (impersonateToken) {
      localStorage.removeItem("mymbsa-impersonate-token");
      const token = props.auth.token;
      const userId = props.auth.userId;
      dispatch({
        type: LOGOUT_REQUEST,
        token,
        userId,
        onCallback: onCallback,
      });
    } else {
      localStorage.removeItem("mymbsa-token");
      const token = props.auth.token;
      const userId = props.auth.userId;
      dispatch({
        type: LOGOUT_REQUEST,
        token,
        userId,
        onCallback: onCallback,
      });
    }
  };

  // get shortname
  let shortName = "";
  let name = "";
  let identity_no = "";
  let identity_id = "";
  let email = "";
  let uuid = "";
  let phone = "";
  if (props.auth && props.auth.name) {
    name = props.auth.name;
    uuid = props.auth.uuid;
    email = props.auth.email;
    phone = props.auth.mobile_no;
    identity_no = user && user.user ? user.user.identity_no : "";
    identity_id = user && user.user ? user.user.identity_type : "";
    if (name && name.indexOf(" ") != -1) {
      const tmp = name.split(" ");
      shortName = tmp[0][0] + tmp[1][0];
      shortName = shortName.toUpperCase();
    } else {
      shortName = name.substring(0, 1);
      shortName = shortName.toUpperCase();
    }
  }

  const selectLocaleRef = React.createRef<HTMLDivElement>();

  return (
    <div ref={ref}>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          props.onToggle();
        }}
        className="relative flex items-center justify-center w-12 h-12 text-lg uppercase bg-white rounded-full shadow-lg cursor-pointer text-mymbsa-primary">
        {shortName}
      </a>
      <AnimatePresence>
        {props.open && (
          <motion.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
            className="absolute top-1.5 right-1.5 z-10 w-96 bg-white text-base ring-1 shadow-xl rounded-lg ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabIndex={-1}
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3">
            <div
              className={
                "scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 w-full pl-5 pr-2 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin"
              }>
              {/* avatar */}
              <div className="flex flex-col items-start justify-center px-5 py-5">
                <div className="items-center justify-center pb-2 m-auto">
                  <AvatarUser data={props.auth} />
                </div>

                <b className="text-lg font-bold">{name && name.toUpperCase()}</b>

                {/* identity no */}
                <p className="py-2 text-xs">
                  <b>{IdentityTypeOps.find((i: any) => i.value == identity_id)?.label}</b>{" "}
                  {identity_no}
                </p>

                {/* email */}
                <p className="py-2 text-xs">
                  <b>
                    <FormattedMessage id="user.email" />
                  </b>{" "}
                  {email}
                </p>

                <p className="py-2 text-xs">
                  <b>
                    <FormattedMessage id="company.nophone" />
                  </b>{" "}
                  {phone}
                </p>

                {/* no akaun */}
                <p className="py-2 text-xs">
                  <b>
                    <FormattedMessage id="user.accountno" />
                  </b>{" "}
                  {uuid}
                </p>
              </div>
              <div className="flex flex-row items-center justify-center">
                <button
                  onClick={() => {
                    navigate("/account");
                  }}
                  className="p-2 pl-4 pr-4 font-bold border border-gray-300 hover:bg-gray-200 rounded-xl">
                  <FormattedMessage id="dashboard.editprofile" />
                </button>
              </div>
              <div className="flex flex-row items-center justify-center pt-3">
                <div className="h-6 border border-white rounded-lg hover:border-gray-400">
                  <SelectLocale ref={selectLocaleRef} />
                </div>
              </div>

              <div className="h-24">&nbsp;</div>

              {/* logout */}
              <div className="flex flex-row items-center justify-center pt-3 pb-3">
                <button
                  onClick={() => {
                    handleLogout();
                  }}
                  className="inline-flex items-center justify-center py-2 pl-4 pr-4 font-bold text-white bg-red-500 border border-gray-300 hover:bg-red-900 rounded-xl">
                  <CgLogOff size="1rem" />
                  <FormattedMessage id="menu.logout" />
                </button>
              </div>
            </div>
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
});

export default UserMenu;
