import { MouseEventHandler } from "react";
import Spinner from "./Spinner";

export interface ButtonProps {
  id?: string;
  label?: React.ReactElement;
  loading?: boolean;
  type: "submit" | "button" | "reset";
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  className: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
}

const Button = ({
  loading,
  type,
  label,
  className,
  iconLeft,
  iconRight,
  disabled,
  onClick,
  id = "id_" + Math.random(),
}: ButtonProps) => {
  let buttonClass = [
    "transition-colors rounded-[8px] px-[15px] py-[4px] inline-flex justify-center items-center",
    className,
    disabled ? "opacity-25 cursor-not-allowed" : "",
  ];

  //if type submit
  if (loading) {
    return (
      <button id={id} type="button" className={`${buttonClass.join(" ")}`}>
        <Spinner size="1rem" /> <span className="pl-5 pr-5">{label}</span>
      </button>
    );
  }

  if (label) {
    return (
      <button
        id={id}
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={buttonClass.join(" ")}
      >
        {iconLeft && <span className="pr-2">{iconLeft}</span>}
        {label}
        {iconRight && <span className="pl-5">{iconRight}</span>}
      </button>
    );
  }

  //icon only
  buttonClass = [
    "transition-colors rounded-[8px] items-center h-8 h-8 justify-center",
    className,
  ];
  return (
    <button
      id={id}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={buttonClass.join(" ")}
    >
      {iconLeft && <span className="pr-5">{iconLeft}</span>}
      {iconRight && <span className="pl-5">{iconRight}</span>}
    </button>
  );
};

export default Button;
