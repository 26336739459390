//
// RORO DATATABLE
//
export const RORO_DATATABLE_REQUEST = 'RORO/RORO_DATATABLE_REQUEST';
export const RORO_DATATABLE_SUCCESS = 'RORO/RORO_DATATABLE_SUCCESS';
export const RORO_DATATABLE_FAILURE = 'RORO/RORO_DATATABLE_FAILURE';

//
// RORO DRAFT
//
export const RORO_DRAFT_REQUEST = 'RORO/RORO_DRAFT_REQUEST';
export const RORO_DRAFT_SUCCESS = 'RORO/RORO_DRAFT_SUCCESS';
export const RORO_DRAFT_FAILURE = 'RORO/RORO_DRAFT_FAILURE';

//
// RORO EDIT
//
export const RORO_EDIT_REQUEST = 'RORO/RORO_EDIT_REQUEST';
export const RORO_EDIT_SUCCESS = 'RORO/RORO_EDIT_SUCCESS';
export const RORO_EDIT_FAILURE = 'RORO/RORO_EDIT_FAILURE';

//
// RORO SUBMIT
//
export const RORO_SUBMIT_REQUEST = 'RORO/RORO_SUBMIT_REQUEST';
export const RORO_SUBMIT_SUCCESS = 'RORO/RORO_SUBMIT_SUCCESS';
export const RORO_SUBMIT_FAILURE = 'RORO/RORO_SUBMIT_FAILURE';

//
// RORO SEARCH
//
export const RORO_SEARCH_REQUEST = 'RORO/RORO_SEARCH_REQUEST';
export const RORO_SEARCH_SUCCESS = 'RORO/RORO_SEARCH_SUCCESS';
export const RORO_SEARCH_FAILURE = 'RORO/RORO_SEARCH_FAILURE';

//
// RORO VIEW
//
export const RORO_VIEW_REQUEST = 'RORO/RORO_VIEW_REQUEST';
export const RORO_VIEW_SUCCESS = 'RORO/RORO_VIEW_SUCCESS';
export const RORO_VIEW_FAILURE = 'RORO/RORO_VIEW_FAILURE';

//
// RORO PAY CREATE
//
export const RORO_PAY_CREATE_REQUEST = 'RORO/RORO_PAY_CREATE_REQUEST';
export const RORO_PAY_CREATE_SUCCESS = 'RORO/RORO_PAY_CREATE_SUCCESS';
export const RORO_PAY_CREATE_FAILURE = 'RORO/RORO_PAY_CREATE_FAILURE';

//
// RORO PAY REDIRECT
//
export const RORO_PAY_REDIRECT_REQUEST = 'RORO/RORO_PAY_REDIRECT_REQUEST';
export const RORO_PAY_REDIRECT_SUCCESS = 'RORO/RORO_PAY_REDIRECT_SUCCESS';
export const RORO_PAY_REDIRECT_FAILURE = 'RORO/RORO_PAY_REDIRECT_FAILURE';

//
// RORO DELETE DRAFT
//
export const RORO_DELETEDRAFT_REQUEST = 'RORO/RORO_DELETEDRAFT_REQUEST';
export const RORO_DELETEDRAFT_SUCCESS = 'RORO/RORO_DELETEDRAFT_SUCCESS';
export const RORO_DELETEDRAFT_FAILURE = 'RORO/RORO_DELETEDRAFT_FAILURE';
