import * as actions from "../action/tempahan";
import * as constants from "../constant/tempahan";

//
// TEMPAHAN ACTIVITY TYPE @ KATEGORI TEMPAHAN
//
export function TempahanActivityTypeRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanActivityTypeRequestAction {
  return {
    type: constants.TEMPAHAN_ACTIVITY_TYPE_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanActivityTypeSuccess(
  data: object
): actions.TempahanActivityTypeSuccessAction {
  return {
    type: constants.TEMPAHAN_ACTIVITY_TYPE_SUCCESS,
    data,
  };
}

export function TempahanActivityTypeFailure(
  error: string
): actions.TempahanActivityTypeFailureAction {
  return {
    type: constants.TEMPAHAN_ACTIVITY_TYPE_FAILURE,
    error,
  };
}
//
// TEMPAHAN PRE BLOCK BOOKING
//
export function TempahanPreBlockBookingRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanPreBlockBookingRequestAction {
  return {
    type: constants.TEMPAHAN_PRE_BLOCK_BOOKING_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanPreBlockBookingSuccess(
  data: object
): actions.TempahanPreBlockBookingSuccessAction {
  return {
    type: constants.TEMPAHAN_PRE_BLOCK_BOOKING_SUCCESS,
    data,
  };
}

export function TempahanPreBlockBookingFailure(
  error: string
): actions.TempahanPreBlockBookingFailureAction {
  return {
    type: constants.TEMPAHAN_PRE_BLOCK_BOOKING_FAILURE,
    error,
  };
}
//
// TEMPAHAN PRE BLOCK BOOKING
//
export function TempahanPreDeleteBookingRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanPreDeleteBookingRequestAction {
  return {
    type: constants.TEMPAHAN_PRE_DELETE_BOOKING_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanPreDeleteBookingSuccess(
  data: object
): actions.TempahanPreDeleteBookingSuccessAction {
  return {
    type: constants.TEMPAHAN_PRE_DELETE_BOOKING_SUCCESS,
    data,
  };
}

export function TempahanPreDeleteBookingFailure(
  error: string
): actions.TempahanPreDeleteBookingFailureAction {
  return {
    type: constants.TEMPAHAN_PRE_DELETE_BOOKING_FAILURE,
    error,
  };
}

//
//  TEMPAHAN ACTIVITY @ ACARA
//
export function TempahanActivityRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanActivityRequestAction {
  return {
    type: constants.TEMPAHAN_ACTIVITY_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanActivitySuccess(
  data: object
): actions.TempahanActivitySuccessAction {
  return {
    type: constants.TEMPAHAN_ACTIVITY_SUCCESS,
    data,
  };
}

export function TempahanActivityFailure(
  error: string
): actions.TempahanActivityFailureAction {
  return {
    type: constants.TEMPAHAN_ACTIVITY_FAILURE,
    error,
  };
}

//
//  TEMPAHAN SECTION @ SEKSYEN
//
export function TempahanSectionRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanSectionRequestAction {
  return {
    type: constants.TEMPAHAN_SECTION_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanSectionSuccess(
  data: object
): actions.TempahanSectionSuccessAction {
  return {
    type: constants.TEMPAHAN_SECTION_SUCCESS,
    data,
  };
}

export function TempahanSectionFailure(
  error: string
): actions.TempahanSectionFailureAction {
  return {
    type: constants.TEMPAHAN_SECTION_FAILURE,
    error,
  };
}

//
// TEMPAHAN ASSET @ LOKASI
//
export function TempahanAssetRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanAssetRequestAction {
  return {
    type: constants.TEMPAHAN_ASSET_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanAssetSuccess(
  data: object
): actions.TempahanAssetSuccessAction {
  return {
    type: constants.TEMPAHAN_ASSET_SUCCESS,
    data,
  };
}

export function TempahanAssetFailure(
  error: string
): actions.TempahanAssetFailureAction {
  return {
    type: constants.TEMPAHAN_ASSET_FAILURE,
    error,
  };
}

//
//  TEMPAHAN DATATABLE
//
export function TempahanDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanDatatableRequestAction {
  return {
    type: constants.TEMPAHAN_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanDatatableSuccess(
  data: object
): actions.TempahanDatatableSuccessAction {
  return {
    type: constants.TEMPAHAN_DATATABLE_SUCCESS,
    data,
  };
}

export function TempahanDatatableFailure(
  error: string
): actions.TempahanDatatableFailureAction {
  return {
    type: constants.TEMPAHAN_DATATABLE_FAILURE,
    error,
  };
}

//
//  TEMPAHAN SUBMIT
//
export function TempahanSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanSubmitRequestAction {
  return {
    type: constants.TEMPAHAN_SUBMIT_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanSubmitSuccess(
  data: object
): actions.TempahanSubmitSuccessAction {
  return {
    type: constants.TEMPAHAN_SUBMIT_SUCCESS,
    data,
  };
}

export function TempahanSubmitFailure(
  error: string
): actions.TempahanSubmitFailureAction {
  return {
    type: constants.TEMPAHAN_SUBMIT_FAILURE,
    error,
  };
}

//
//  TEMPAHAN UPDATE
//
export function TempahanUpdateRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanUpdateRequestAction {
  return {
    type: constants.TEMPAHAN_UPDATE_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanUpdateSuccess(
  data: object
): actions.TempahanUpdateSuccessAction {
  return {
    type: constants.TEMPAHAN_UPDATE_SUCCESS,
    data,
  };
}

export function TempahanUpdateFailure(
  error: string
): actions.TempahanUpdateFailureAction {
  return {
    type: constants.TEMPAHAN_UPDATE_FAILURE,
    error,
  };
}

//
//  TEMPAHAN ASSET TIMESLOT
//
export function TempahanAssetTimeslotRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanAssetTimeslotRequestAction {
  return {
    type: constants.TEMPAHAN_ASSET_TIMESLOT_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanAssetTimeslotSuccess(
  data: object
): actions.TempahanAssetTimeslotSuccessAction {
  return {
    type: constants.TEMPAHAN_ASSET_TIMESLOT_SUCCESS,
    data,
  };
}

export function TempahanAssetTimeslotFailure(
  error: string
): actions.TempahanAssetTimeslotFailureAction {
  return {
    type: constants.TEMPAHAN_ASSET_TIMESLOT_FAILURE,
    error,
  };
}

//
//  TEMPAHAN CHECKING
//
export function TempahanCheckingRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanCheckingRequestAction {
  return {
    type: constants.TEMPAHAN_CHECKING_ACTIVE_REQUEST,
    onCallback,
  };
}

export function TempahanCheckingSuccess(
  data: object
): actions.TempahanCheckingSuccessAction {
  return {
    type: constants.TEMPAHAN_CHECKING_ACTIVE_SUCCESS,
    data,
  };
}

export function TempahanCheckingFailure(
  error: string
): actions.TempahanCheckingFailureAction {
  return {
    type: constants.TEMPAHAN_CHECKING_ACTIVE_FAILURE,
    error,
  };
}

//
//  TEMPAHAN VIEW BIL
//
export function TempahanViewBillRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TempahanViewBillRequestAction {
  return {
    type: constants.TEMPAHAN_VIEW_BILL_REQUEST,
    params,
    onCallback,
  };
}

export function TempahanViewBillSuccess(
  data: object
): actions.TempahanViewBillSuccessAction {
  return {
    type: constants.TEMPAHAN_VIEW_BILL_SUCCESS,
    data,
  };
}

export function TempahanViewBillFailure(
  error: string
): actions.TempahanViewBillFailureAction {
  return {
    type: constants.TEMPAHAN_VIEW_BILL_FAILURE,
    error,
  };
}
