import * as actions from '../action/ppm';
import * as constants from '../constant/ppm';


//
// PPM Search
//
export function PpmSearchRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmSearchRequestAction {
    return {
        type: constants.PPM_SEARCH_REQUEST,
        params,
        onCallback
    }
}
export function PpmSearchSuccess(data: object): actions.PpmSearchSuccessAction {
    return {
        type: constants.PPM_SEARCH_SUCCESS,
        data
    }
}
export function PpmSearchFailure(error: string): actions.PpmSearchFailureAction {
    return {
        type: constants.PPM_SEARCH_FAILURE,
        error
    }
}

//
// PPM Datatable
//
export function PpmDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmDatatableRequestAction {
    return {
        type: constants.PPM_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function PpmDatatableSuccess(data: object): actions.PpmDatatableSuccessAction {
    return {
        type: constants.PPM_DATATABLE_SUCCESS,
        data
    }
}
export function PpmDatatableFailure(error: string): actions.PpmDatatableFailureAction {
    return {
        type: constants.PPM_DATATABLE_FAILURE,
        error
    }
}

//
// PPM Draft
//
export function PpmDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmDraftRequestAction {
    return {
        type: constants.PPM_DRAFT_REQUEST,
        params,
        onCallback
    }
}
export function PpmDraftSuccess(data: object): actions.PpmDraftSuccessAction {
    return {
        type: constants.PPM_DRAFT_SUCCESS,
        data
    }
}
export function PpmDraftFailure(error: string): actions.PpmDraftFailureAction {
    return {
        type: constants.PPM_DRAFT_FAILURE,
        error
    }
}


//
// PPM Edit
//
export function PpmEditRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmEditRequestAction {
    return {
        type: constants.PPM_EDIT_REQUEST,
        params,
        onCallback
    }
}
export function PpmEditSuccess(data: object): actions.PpmEditSuccessAction {
    return {
        type: constants.PPM_EDIT_SUCCESS,
        data
    }
}
export function PpmEditFailure(error: string): actions.PpmEditFailureAction {
    return {
        type: constants.PPM_EDIT_FAILURE,
        error
    }
}

//
// PPM Submit
//
export function PpmSubmitRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmSubmitRequestAction {
    return {
        type: constants.PPM_SUBMIT_REQUEST,
        params,
        onCallback
    }
}
export function PpmSubmitSuccess(data: object): actions.PpmSubmitSuccessAction {
    return {
        type: constants.PPM_SUBMIT_SUCCESS,
        data
    }
}
export function PpmSubmitFailure(error: string): actions.PpmSubmitFailureAction {
    return {
        type: constants.PPM_SUBMIT_FAILURE,
        error
    }
}

//
// PPM View
//
export function PpmViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmViewRequestAction {
    return {
        type: constants.PPM_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function PpmViewSuccess(data: object): actions.PpmViewSuccessAction {
    return {
        type: constants.PPM_VIEW_SUCCESS,
        data
    }
}
export function PpmViewFailure(error: string): actions.PpmViewFailureAction {
    return {
        type: constants.PPM_VIEW_FAILURE,
        error
    }
}

//
// PPM PAY CREATE
//
export function PpmPayCreateRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmPayCreateRequestAction {
    return {
        type: constants.PPM_PAY_CREATE_REQUEST,
        params,
        onCallback
    }
}
export function PpmPayCreateSuccess(data: object): actions.PpmPayCreateSuccessAction {
    return {
        type: constants.PPM_PAY_CREATE_SUCCESS,
        data
    }
}
export function PpmPayCreateFailure(error: string): actions.PpmPayCreateFailureAction {
    return {
        type: constants.PPM_PAY_CREATE_FAILURE,
        error
    }
}

//
// PPM PAY REDIRECT
//
export function PpmPayRedirectRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmPayRedirectRequestAction {
    return {
        type: constants.PPM_PAY_REDIRECT_REQUEST,
        params,
        onCallback
    }
}
export function PpmPayRedirectSuccess(data: object): actions.PpmPayRedirectSuccessAction {
    return {
        type: constants.PPM_PAY_REDIRECT_SUCCESS,
        data
    }
}
export function PpmPayRedirectFailure(error: string): actions.PpmPayRedirectFailureAction {
    return {
        type: constants.PPM_PAY_REDIRECT_FAILURE,
        error
    }
}


//
// PPM Verify License No
//
export function PpmVerifyLicenseNoRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmVerifyLicenseNoRequestAction {
    return {
        type: constants.PPM_VERIFYLICENCENO_REQUEST,
        params,
        onCallback
    }
}
export function PpmVerifyLicenseNoSuccess(data: object): actions.PpmVerifyLicenseNoSuccessAction {
    return {
        type: constants.PPM_VERIFYLICENCENO_SUCCESS,
        data
    }
}
export function PpmVerifyLicenseNoFailure(error: string): actions.PpmVerifyLicenseNoFailureAction {
    return {
        type: constants.PPM_VERIFYLICENSENO_FAILURE,
        error
    }
}


//
// PPM Verify Company No
//
export function PpmVerifyCompanyNoRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmVerifyCompanyNoRequestAction {
    return {
        type: constants.PPM_VERIFYCOMPANYNO_REQUEST,
        params,
        onCallback
    }
}
export function PpmVerifyCompanyNoSuccess(data: object): actions.PpmVerifyCompanyNoSuccessAction {
    return {
        type: constants.PPM_VERIFYCOMPANYNO_SUCCESS,
        data
    }
}
export function PpmVerifyCompanyNoFailure(error: string): actions.PpmVerifyCompanyNoFailureAction {
    return {
        type: constants.PPM_VERIFYCOMPANYNO_FAILURE,
        error
    }
}


//
// PPM Premise Type
//
export function PpmPremiseTypeRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmPremiseTypeRequestAction {
    return {
        type: constants.PPM_PREMISETYPE_REQUEST,
        params,
        onCallback
    }
}
export function PpmPremiseTypeSuccess(data: object): actions.PpmPremiseTypeSuccessAction {
    return {
        type: constants.PPM_PREMISETYPE_SUCCESS,
        data
    }
}
export function PpmPremiseTypeFailure(error: string): actions.PpmPremiseTypeFailureAction {
    return {
        type: constants.PPM_PREMISETYPE_FAILURE,
        error
    }
}

//
// PPM DELETE DRAFT
//
export function PpmDeleteDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.PpmDeleteDraftRequestAction {
    return {
        type: constants.PPM_DELETEDRAFT_REQUEST,
        params,
        onCallback
    }
}
export function PpmDeleteDraftSuccess(data: object): actions.PpmDeleteDraftSuccessAction {
    return {
        type: constants.PPM_DELETEDRAFT_SUCCESS,
        data
    }
}
export function PpmDeleteDraftFailure(error: string): actions.PpmDeleteDraftFailureAction {
    return {
        type: constants.PPM_DELETEDRAFT_FAILURE,
        error
    }
}

export function EpbtReset(): actions.PpmResetAction {
    return {
        type: constants.PPM_RESET
    }
}
