//
// EVENTS
//
export const LOYALTI_EVENTS_REQUEST = "LOYALTI/EVENTS_REQUEST";
export const LOYALTI_EVENTS_SUCCESS = "LOYALTI/EVENTS_SUCCESS";
export const LOYALTI_EVENTS_FAILURE = "LOYALTI/EVENTS_FAILURE";

//
// EVENT
//
export const LOYALTI_EVENT_REQUEST = "LOYALTI/EVENT_REQUEST";
export const LOYALTI_EVENT_SUCCESS = "LOYALTI/EVENT_SUCCESS";
export const LOYALTI_EVENT_FAILURE = "LOYALTI/EVENT_FAILURE";


//
// PRODUCTS
//
export const LOYALTI_PRODUCTS_REQUEST = "LOYALTI/PRODUCTS_REQUEST";
export const LOYALTI_PRODUCTS_SUCCESS = "LOYALTI/PRODUCTS_SUCCESS";
export const LOYALTI_PRODUCTS_FAILURE = "LOYALTI/PRODUCTS_FAILURE";

//
// PRODUCT
//
export const LOYALTI_PRODUCT_REQUEST = "LOYALTI/PRODUCT_REQUEST";
export const LOYALTI_PRODUCT_SUCCESS = "LOYALTI/PRODUCT_SUCCESS";
export const LOYALTI_PRODUCT_FAILURE = "LOYALTI/PRODUCT_FAILURE";


//
// REWARD
//
export const LOYALTI_REWARD_REQUEST = "LOYALTI/REWARD_REQUEST";
export const LOYALTI_REWARD_SUCCESS = "LOYALTI/REWARD_SUCCESS";
export const LOYALTI_REWARD_FAILURE = "LOYALTI/REWARD_FAILURE";

//
// ADD TO CART
//
export const LOYALTI_ADD_TO_CART_REQUEST = "LOYALTI/ADD_TO_CART_REQUEST";
export const LOYALTI_ADD_TO_CART_SUCCESS = "LOYALTI/ADD_TO_CART_SUCCESS";
export const LOYALTI_ADD_TO_CART_FAILURE = "LOYALTI/ADD_TO_CART_FAILURE";

//
// DELETE FROM CART
//
export const LOYALTI_DELETE_FROM_CART_REQUEST = "LOYALTI/DELETE_FROM_CART_REQUEST";
export const LOYALTI_DELETE_FROM_CART_SUCCESS = "LOYALTI/DELETE_FROM_CART_SUCCESS";
export const LOYALTI_DELETE_FROM_CART_FAILURE = "LOYALTI/DELETE_FROM_CART_FAILURE";

//
// VIEW CART
//
export const LOYALTI_VIEW_CART_REQUEST = "LOYALTI/VIEW_CART_REQUEST";
export const LOYALTI_VIEW_CART_SUCCESS = "LOYALTI/VIEW_CART_SUCCESS";
export const LOYALTI_VIEW_CART_FAILURE = "LOYALTI/VIEW_CART_FAILURE";

//
// VIEW ALL_PAYMENT_CATEGORY
//
export const LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_REQUEST = "LOYALTI/VIEW_ALL_PAYMENT_CATEGORY_REQUEST";
export const LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_SUCCESS = "LOYALTI/VIEW_ALL_PAYMENT_CATEGORY_SUCCESS";
export const LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_FAILURE = "LOYALTI/VIEW_ALL_PAYMENT_CATEGORY_FAILURE";

//
// VIEW PAYMENT_CATEGORY
//
export const LOYALTI_VIEW_PAYMENT_CATEGORY_REQUEST = "LOYALTI/VIEW_PAYMENT_CATEGORY_REQUEST";
export const LOYALTI_VIEW_PAYMENT_CATEGORY_SUCCESS = "LOYALTI/VIEW_PAYMENT_CATEGORY_SUCCESS";
export const LOYALTI_VIEW_PAYMENT_CATEGORY_FAILURE = "LOYALTI/VIEW_PAYMENT_CATEGORY_FAILURE";

//
// VIEW ALL_PAYMENT_TYPE
//
export const LOYALTI_VIEW_ALL_PAYMENT_TYPE_REQUEST = "LOYALTI/VIEW_ALL_PAYMENT_TYPE_REQUEST";
export const LOYALTI_VIEW_ALL_PAYMENT_TYPE_SUCCESS = "LOYALTI/VIEW_ALL_PAYMENT_TYPE_SUCCESS";
export const LOYALTI_VIEW_ALL_PAYMENT_TYPE_FAILURE = "LOYALTI/VIEW_ALL_PAYMENT_TYPE_FAILURE";

//
// VIEW PAYMENT_TYPE
//
export const LOYALTI_VIEW_PAYMENT_TYPE_REQUEST = "LOYALTI/VIEW_PAYMENT_TYPE_REQUEST";
export const LOYALTI_VIEW_PAYMENT_TYPE_SUCCESS = "LOYALTI/VIEW_PAYMENT_TYPE_SUCCESS";
export const LOYALTI_VIEW_PAYMENT_TYPE_FAILURE = "LOYALTI/VIEW_PAYMENT_TYPE_FAILURE";

//
// VIEW ALL_ORDER
//
export const LOYALTI_VIEW_ALL_ORDER_REQUEST = "LOYALTI/VIEW_ALL_ORDER_REQUEST";
export const LOYALTI_VIEW_ALL_ORDER_SUCCESS = "LOYALTI/VIEW_ALL_ORDER_SUCCESS";
export const LOYALTI_VIEW_ALL_ORDER_FAILURE = "LOYALTI/VIEW_ALL_ORDER_FAILURE";

//
// VIEW ORDER
//
export const LOYALTI_VIEW_ORDER_REQUEST = "LOYALTI/VIEW_ORDER_REQUEST";
export const LOYALTI_VIEW_ORDER_SUCCESS = "LOYALTI/VIEW_ORDER_SUCCESS";
export const LOYALTI_VIEW_ORDER_FAILURE = "LOYALTI/VIEW_ORDER_FAILURE";

//
// CREATE_NEW_ORDER
//
export const LOYALTI_CREATE_NEW_ORDER_REQUEST = "LOYALTI/CREATE_NEW_ORDER_REQUEST";
export const LOYALTI_CREATE_NEW_ORDER_SUCCESS = "LOYALTI/CREATE_NEW_ORDER_SUCCESS";
export const LOYALTI_CREATE_NEW_ORDER_FAILURE = "LOYALTI/CREATE_NEW_ORDER_FAILURE";

//
// VIEW ALL_TRANSACTION
//
export const LOYALTI_VIEW_ALL_TRANSACTION_REQUEST = "LOYALTI/VIEW_ALL_TRANSACTION_REQUEST";
export const LOYALTI_VIEW_ALL_TRANSACTION_SUCCESS = "LOYALTI/VIEW_ALL_TRANSACTION_SUCCESS";
export const LOYALTI_VIEW_ALL_TRANSACTION_FAILURE = "LOYALTI/VIEW_ALL_TRANSACTION_FAILURE";

//
// CREATE_NEW_TRANSACTION
//
export const LOYALTI_CREATE_NEW_TRANSACTION_REQUEST = "LOYALTI/CREATE_NEW_TRANSACTION_REQUEST";
export const LOYALTI_CREATE_NEW_TRANSACTION_SUCCESS = "LOYALTI/CREATE_NEW_TRANSACTION_SUCCESS";
export const LOYALTI_CREATE_NEW_TRANSACTION_FAILURE = "LOYALTI/CREATE_NEW_TRANSACTION_FAILURE";

//
// UPDATE_ORDER
//
export const LOYALTI_UPDATE_ORDER_REQUEST = "LOYALTI/UPDATE_ORDER_REQUEST";
export const LOYALTI_UPDATE_ORDER_SUCCESS = "LOYALTI/UPDATE_ORDER_SUCCESS";
export const LOYALTI_UPDATE_ORDER_FAILURE = "LOYALTI/UPDATE_ORDER_FAILURE";