import * as actions from '../action/psdoskmep';
import * as constants from '../constant/psdoskmep';

export interface PsDoskmepState {
  data: object;
  error: string;
  loading: boolean;
}

export const psDoskmepReducer = (
  state: PsDoskmepState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.PsDoskmepAction) => {    
    switch (action.type) {
      
      //
      // PSDOSKMEP DATATABLE
      //
      case constants.PSDOSKMEP_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSDOSKMEP_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSDOSKMEP_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSDOSKMEP DELETE DRAFT
      //
      case constants.PSDOSKMEP_DELETEDRAFT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSDOSKMEP_DELETEDRAFT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSDOSKMEP_DELETEDRAFT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSDOSKMEP DELETE DRAFT
      //
      case constants.PSDOSKMEP_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSDOSKMEP_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSDOSKMEP_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSDOSKMEP EDIT
      //
      case constants.PSDOSKMEP_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSDOSKMEP_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSDOSKMEP_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSDOSKMEP SUBMIT
      //
      case constants.PSDOSKMEP_SUBMIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSDOSKMEP_SUBMIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSDOSKMEP_SUBMIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }


      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  