export const MAPSTATUS_DATATABLE_REQUEST = "MAPSTATUS/MAPSTATUS_DATATABLE_REQUEST";
export const MAPSTATUS_DATATABLE_SUCCESS = "MAPSTATUS/MAPSTATUS_DATATABLE_SUCCESS";
export const MAPSTATUS_DATATABLE_FAILURE = "MAPSTATUS/MAPSTATUS_DATATABLE_FAILURE";

export const MAPSTATUS_ADD_REQUEST = "MAPSTATUS/MAPSTATUS_ADD_REQUEST";
export const MAPSTATUS_ADD_SUCCESS = "MAPSTATUS/MAPSTATUS_ADD_SUCCESS";
export const MAPSTATUS_ADD_FAILURE = "MAPSTATUS/MAPSTATUS_ADD_FAILURE";

export const MAPSTATUS_EDIT_REQUEST = "MAPSTATUS/MAPSTATUS_EDIT_REQUEST";
export const MAPSTATUS_EDIT_SUCCESS = "MAPSTATUS/MAPSTATUS_EDIT_SUCCESS";
export const MAPSTATUS_EDIT_FAILURE = "MAPSTATUS/MAPSTATUS_EDIT_FAILURE";

export const MAPSTATUS_DELETE_REQUEST = "MAPSTATUS/MAPSTATUS_DELETE_REQUEST";
export const MAPSTATUS_DELETE_SUCCESS = "MAPSTATUS/MAPSTATUS_DELETE_SUCCESS";
export const MAPSTATUS_DELETE_FAILURE = "MAPSTATUS/MAPSTATUS_DELETE_FAILURE";

export const MAPSTATUS_VIEW_REQUEST = "MAPSTATUS/MAPSTATUS_VIEW_REQUEST";
export const MAPSTATUS_VIEW_SUCCESS = "MAPSTATUS/MAPSTATUS_VIEW_SUCCESS";
export const MAPSTATUS_VIEW_FAILURE = "MAPSTATUS/MAPSTATUS_VIEW_FAILURE";