import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Locales from "./components/Locales";
import { VERSION_REQUEST } from "./redux/constant/version";
import LandingPage from "./views/LandingPage";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: VERSION_REQUEST,
    });
  }, []);

  return (
    <Locales>
      <LandingPage />
    </Locales>
  );
}

export default App;
