import * as actions from '../action/cagaran';
import * as constants from '../constant/cagaran';

export interface CagaranState {
  data: object;
  error: string;
  loading: boolean;
}

export const cagaranReducer = (
  state: CagaranState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.CukaiAction) => {
  switch (action.type) {

    //SEARCH BY ID
    case constants.CAGARAN_SEARCH_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case constants.CAGARAN_SEARCH_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CAGARAN_SEARCH_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }


    //SEARCH BY NO_BIL
    case constants.CAGARAN_SEARCH_BY_NO_BIL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case constants.CAGARAN_SEARCH_BY_NO_BIL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CAGARAN_SEARCH_BY_NO_BIL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }


    //VIEW
    case constants.CAGARAN_VIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case constants.CAGARAN_VIEW_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CAGARAN_VIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //PAY
    case constants.CAGARAN_PAY_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case constants.CAGARAN_PAY_CREATE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CAGARAN_PAY_CREATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //PAY REDIRECT
    case constants.CAGARAN_PAY_REDIRECT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case constants.CAGARAN_PAY_REDIRECT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CAGARAN_PAY_REDIRECT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
