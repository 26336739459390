import * as actions from '../action/locale';
import * as constants from '../constant/locale';

export interface LocaleState {
  data: object;
  error: string;
  loading: boolean;
}

export const localeReducer = (
  state: LocaleState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.LocaleAction) => {    
    switch (action.type) {

      //
      // LOCALE - ALL
      //
      case constants.LOCALE_ALL_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ""
        };
      }
      case constants.LOCALE_ALL_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false
        };
      }
      case constants.LOCALE_ALL_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      //
      // LOCALE - ADD
      //
      case constants.LOCALE_ADD_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ""
        };
      }
      case constants.LOCALE_ADD_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false
        };
      }
      case constants.LOCALE_ADD_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      //
      // LOCALE - EDIT
      //
      case constants.LOCALE_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ""
        };
      }
      case constants.LOCALE_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false
        };
      }
      case constants.LOCALE_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      //
      // LOCALE - VIEW
      //
      case constants.LOCALE_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ""
        };
      }
      case constants.LOCALE_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false
        };
      }
      case constants.LOCALE_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }


      //
      // LOCALE - DELETE
      //
      case constants.LOCALE_DELETE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ""
        };
      }
      case constants.LOCALE_DELETE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false
        };
      }
      case constants.LOCALE_DELETE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }


      //
      // LOCALE - DATATABLE
      //
      case constants.LOCALE_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ""
        };
      }
      case constants.LOCALE_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false
        };
      }
      case constants.LOCALE_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      
      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  

