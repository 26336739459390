//
// PSDOSKMEP DATATABLE
//
export const PSDOSKMEP_DATATABLE_REQUEST = 'PSDOSKMEP/PSDOSKMEP_DATATABLE_REQUEST';
export const PSDOSKMEP_DATATABLE_SUCCESS = 'PSDOSKMEP/PSDOSKMEP_DATATABLE_SUCCESS';
export const PSDOSKMEP_DATATABLE_FAILURE = 'PSDOSKMEP/PSDOSKMEP_DATATABLE_FAILURE';

export const PSDOSKMEP_EDIT_REQUEST = 'PSDOSKMEP/PSDOSKMEP_EDIT_REQUEST';
export const PSDOSKMEP_EDIT_SUCCESS = 'PSDOSKMEP/PSDOSKMEP_EDIT_SUCCESS';
export const PSDOSKMEP_EDIT_FAILURE = 'PSDOSKMEP/PSDOSKMEP_EDIT_FAILURE';

export const PSDOSKMEP_VIEW_REQUEST = 'PSDOSKMEP/PSDOSKMEP_VIEW_REQUEST';
export const PSDOSKMEP_VIEW_SUCCESS = 'PSDOSKMEP/PSDOSKMEP_VIEW_SUCCESS';
export const PSDOSKMEP_VIEW_FAILURE = 'PSDOSKMEP/PSDOSKMEP_VIEW_FAILURE';

export const PSDOSKMEP_SUBMIT_REQUEST = 'PSDOSKMEP/PSDOSKMEP_SUBMIT_REQUEST';
export const PSDOSKMEP_SUBMIT_SUCCESS = 'PSDOSKMEP/PSDOSKMEP_SUBMIT_SUCCESS';
export const PSDOSKMEP_SUBMIT_FAILURE = 'PSDOSKMEP/PSDOSKMEP_SUBMIT_FAILURE';

export const PSDOSKMEP_DELETEDRAFT_REQUEST = 'PSDOSKMEP/PSDOSKMEP_DELETEDRAFT_REQUEST';
export const PSDOSKMEP_DELETEDRAFT_SUCCESS = 'PSDOSKMEP/PSDOSKMEP_DELETEDRAFT_SUCCESS';
export const PSDOSKMEP_DELETEDRAFT_FAILURE = 'PSDOSKMEP/PSDOSKMEP_DELETEDRAFT_FAILURE';

