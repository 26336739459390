export const LOCALE_ALL_REQUEST = "LOCALE/LOCALE_ALL_REQUEST";
export const LOCALE_ALL_SUCCESS = "LOCALE/LOCALE_ALL_SUCCESS";
export const LOCALE_ALL_FAILURE = "LOCALE/LOCALE_ALL_FAILURE";

export const LOCALE_DATATABLE_REQUEST = "LOCALE/LOCALE_DATATABLE_REQUEST";
export const LOCALE_DATATABLE_SUCCESS = "LOCALE/LOCALE_DATATABLE_SUCCESS";
export const LOCALE_DATATABLE_FAILURE = "LOCALE/LOCALE_DATATABLE_FAILURE";

export const LOCALE_ADD_REQUEST = "LOCALE/LOCALE_ADD_REQUEST";
export const LOCALE_ADD_SUCCESS = "LOCALE/LOCALE_ADD_SUCCESS";
export const LOCALE_ADD_FAILURE = "LOCALE/LOCALE_ADD_FAILURE";

export const LOCALE_EDIT_REQUEST = "LOCALE/LOCALE_EDIT_REQUEST";
export const LOCALE_EDIT_SUCCESS = "LOCALE/LOCALE_EDIT_SUCCESS";
export const LOCALE_EDIT_FAILURE = "LOCALE/LOCALE_EDIT_FAILURE";

export const LOCALE_DELETE_REQUEST = "LOCALE/LOCALE_DELETE_REQUEST";
export const LOCALE_DELETE_SUCCESS = "LOCALE/LOCALE_DELETE_SUCCESS";
export const LOCALE_DELETE_FAILURE = "LOCALE/LOCALE_DELETE_FAILURE";

export const LOCALE_VIEW_REQUEST = "LOCALE/LOCALE_VIEW_REQUEST";
export const LOCALE_VIEW_SUCCESS = "LOCALE/LOCALE_VIEW_SUCCESS";
export const LOCALE_VIEW_FAILURE = "LOCALE/LOCALE_VIEW_FAILURE";