import * as actions from "../action/pstaska";
import * as constants from "../constant/pstaska";

//
// PsTaska Datatable
//
export function PsTaskaDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTaskaDatatableRequestAction {
  return {
    type: constants.PSTASKA_DATATABLE_REQUEST,
    params,
    onCallback
  };
}
export function PsTaskaDatatableSuccess(data: object): actions.PsTaskaDatatableSuccessAction {
  return {
    type: constants.PSTASKA_DATATABLE_SUCCESS,
    data,
  };
}
export function PsTaskaDatatableFailure(error: string): actions.PsTaskaDatatableFailureAction {
  return {
    type: constants.PSTASKA_DATATABLE_FAILURE,
    error,
  };
}

//
// PsTaska Draft
//
export function PsTaskaDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTaskaDraftRequestAction {
  return {
    type: constants.PSTASKA_DRAFT_REQUEST,
    params,
    onCallback
  };
}
export function PsTaskaDraftSuccess(data: object): actions.PsTaskaDraftSuccessAction {
  return {
    type: constants.PSTASKA_DRAFT_SUCCESS,
    data,
  };
}
export function PsTaskaDraftFailure(error: string): actions.PsTaskaDraftFailureAction {
  return {
    type: constants.PSTASKA_DRAFT_FAILURE,
    error,
  };
}

//
// PsTaska Edit
//
export function PsTaskaEditRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTaskaEditRequestAction {
  return {
    type: constants.PSTASKA_EDIT_REQUEST,
    params,
    onCallback
  };
}
export function PsTaskaEditSuccess(data: object): actions.PsTaskaEditSuccessAction {
  return {
    type: constants.PSTASKA_EDIT_SUCCESS,
    data,
  };
}
export function PsTaskaEditFailure(error: string): actions.PsTaskaEditFailureAction {
  return {
    type: constants.PSTASKA_EDIT_FAILURE,
    error,
  };
}

//
// PsTaska Submit
//
export function PsTaskaSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTaskaSubmitRequestAction {
  return {
    type: constants.PSTASKA_SUBMIT_REQUEST,
    params,
    onCallback
  };
}
export function PsTaskaSubmitSuccess(data: object): actions.PsTaskaSubmitSuccessAction {
  return {
    type: constants.PSTASKA_SUBMIT_SUCCESS,
    data,
  };
}
export function PsTaskaSubmitFailure(error: string): actions.PsTaskaSubmitFailureAction {
  return {
    type: constants.PSTASKA_SUBMIT_FAILURE,
    error,
  };
}

//
// PsTaska View
//
export function PsTaskaViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTaskaViewRequestAction {
  return {
    type: constants.PSTASKA_VIEW_REQUEST,
    params,
    onCallback
  };
}
export function PsTaskaViewSuccess(data: object): actions.PsTaskaViewSuccessAction {
  return {
    type: constants.PSTASKA_VIEW_SUCCESS,
    data,
  };
}
export function PsTaskaViewFailure(error: string): actions.PsTaskaViewFailureAction {
  return {
    type: constants.PSTASKA_VIEW_FAILURE,
    error,
  };
}

//
// PsTaska Search
//
export function PsTaskaSearchRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsTaskaSearchRequestAction {
  return {
    type: constants.PSTASKA_SEARCH_REQUEST,
    params,
    onCallback
  };
}
export function PsTaskaSearchSuccess(data: object): actions.PsTaskaSearchSuccessAction {
  return {
    type: constants.PSTASKA_SEARCH_SUCCESS,
    data,
  };
}
export function PsTaskaSearchFailure(error: string): actions.PsTaskaSearchFailureAction {
  return {
    type: constants.PSTASKA_SEARCH_FAILURE,
    error,
  };
}
