import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/cukai';
import * as constants from '../constant/cukai';
import * as functions from '../function/cukai';

//
// CUKAI SEARCH
//
function* getCukaiSearch({ params, onCallback }: actions.CukaiSearchRequestAction) {
  if (params.carian) {
    const { status, data, error } = yield call(axios.call, {
      url: `/secured/cukai/carian/${params.carian}`,
      method: 'GET',
    });
    if (status === 200) {
      onCallback({
        status: 1,
        data: data,
      });
      yield put(functions.CukaiSearchSuccess(data));
    } else {
      onCallback({
        status: 0,
        error: error,
      });
      yield put(functions.CukaiSearchFailure(error));
    }
  } else {
    const { status, data, error } = yield call(axios.call, {
      url: `/secured/cukai/search`,
      method: 'POST',
      data: params,
    });
    if (status === 200) {
      onCallback({
        status: 1,
        data: data,
      });
      yield put(functions.CukaiSearchSuccess(data));
    } else {
      onCallback({
        status: 0,
        error: error,
      });
      yield put(functions.CukaiSearchFailure(error));
    }
  }
}

//
// Cukai DATATABLE
//
function* getCukaiDatatable({ params, onCallback }: actions.CukaiDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/datatable`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiDatatableSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiDatatableFailure(error));
  }
}

//
// CUKAI EDIT
//
function* getCukaiEdit({ params, onCallback }: actions.CukaiEditRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/edit`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CukaiEditSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiEditFailure(error));
  }
}

//
// CUKAI DRAFT
//
function* getCukaiDraft({ params, onCallback }: actions.CukaiDraftRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/draft`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CukaiDraftSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiDraftFailure(error));
  }
}

//
// CUKAI SUBMIT
//
function* getCukaiSubmit({ params, onCallback }: actions.CukaiSubmitRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/submit`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CukaiSubmitSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiSubmitFailure(error));
  }
}

//
// CUKAI VIEW
//
function* getCukaiView({ params, onCallback }: actions.CukaiViewRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/view`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiViewSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewFailure(error));
  }
}

//
// CUKAI PAY CREATE
//
function* getCukaiPayCreate({ params, onCallback }: actions.CukaiPayCreateRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/payment/create`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CukaiPayCreateSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiPayCreateFailure(error));
  }
}

//
// CUKAI PAY REDIRECT
//
function* getCukaiPayRedirect({ params, onCallback }: actions.CukaiPayRedirectRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/payment/redirect`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CukaiPayRedirectSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiPayRedirectFailure(error));
  }
}

//
// CUKAI DELETE DRAFT
//
function* getCukaiDeleteDraft({ params, onCallback }: actions.CukaiDeleteDraftRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/deleteDraft`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CukaiDeleteDraftSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiDeleteDraftFailure(error));
  }
}

//
// CUKAI PRICE
//
function* getCukaiPrice({ params, onCallback }: actions.CukaiPriceRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/price`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiPriceSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiPriceFailure(error));
  }
}

//
// SENARAI AKAUN
//
function* getCukaiAccountDatatable({ params, onCallback }: actions.CukaiAccountDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/list-by-pelanggan/${params.idNo}`,
    method: 'GET',
  });

  if (status === 200) {
    const senaraiAkaun = [];

    // Check "individu" array
    if (data.individu) {
      data.individu.forEach((item) => {
        if (item.data && item.data.SENARAIAKAUN) {
          senaraiAkaun.push(...item.data.SENARAIAKAUN);
        }
      });
    }

    // Check "syarikat" array
    if (data.syarikat) {
      data.syarikat.forEach((item) => {
        if (item.data && item.data.SENARAIAKAUN) {
          senaraiAkaun.push(...item.data.SENARAIAKAUN);
        }
      });
    }
    onCallback({
      status: 1,
      data: senaraiAkaun,
    });
    yield put(functions.CukaiAccountDatatableSuccess(senaraiAkaun));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiAccountDatatableFailure(error));
  }
}

//
// CUKAI VIEW BILL
//
function* getCukaiViewBill({ params, onCallback }: actions.CukaiViewBillRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/papar/bil/ringkas`,
    method: 'GET',
    params: {
      rujukan_kemudahan: params.rujukan_kemudahan,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.CukaiViewBillSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewBillFailure(error));
  }
}

//
// CUKAI VIEW ACCOUNT INFO
//
function* getCukaiViewAccountInfo({ params, onCallback }: actions.CukaiViewAccountInfoRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/papar/maklumat-harta`,
    method: 'GET',
    params: {
      id_permohonan: params.id_permohonan,
      lot_id: params.lot_id,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.CukaiViewAccountInfoSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewAccountInfoFailure(error));
  }
}

//
// PINDAHMILIK SUBMIT
//
function* getCukaiPindahmilikSubmit({ params, onCallback }: actions.CukaiPindahmilikSubmitRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan-pindahmilik`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CukaiPindahmilikSubmitSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiPindahmilikSubmitFailure(error));
  }
}

//
// KEMASKINI SUBMIT
//
function* getCukaiKemaskiniSubmit({ params, onCallback }: actions.CukaiKemaskiniSubmitRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan-kemaskini`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CukaiKemaskiniSubmitSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiKemaskiniSubmitFailure(error));
  }
}

//
// PENGURANGAN SUBMIT
//
function* getCukaiPenguranganSubmit({ params, onCallback }: actions.CukaiPenguranganSubmitRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan-oku`,
    method: 'POST',
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.CukaiPenguranganSubmitSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiPenguranganSubmitFailure(error));
  }
}

//
// CUKAI VIEW BY ACCOUNT NO
// /secured/cukai/list-by-akaun/
function* getCukaiViewByAccountNo({ params, onCallback }: actions.CukaiViewByAccountNoRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/list-by-akaun/${params.cukaiNo}`,
    method: 'GET',
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiViewByAccountNoSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewByAccountNoFailure(error));
  }
}

//
// CUKAI VIEW LIST PERMOHONAN
//
function* getCukaiViewListPermohonan({ params, onCallback }: actions.CukaiViewListPermohonanRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan`,
    method: 'GET',
    params: {
      app_key: 'app_id_no',
      app_value: params.user_id_no,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiViewListPermohonanSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewListPermohonanFailure(error));
  }
}

//
// CUKAI VIEW LIST PINDAHMILIK
//
function* getCukaiViewListPindahmilik({ params, onCallback }: actions.CukaiViewListPindahmilikRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan-pindahmilik`,
    method: 'GET',
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiViewListPindahmilikSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewListPindahmilikFailure(error));
  }
}

//
// CUKAI VIEW LIST KEMASKINI
//
function* getCukaiViewListKemaskini({ params, onCallback }: actions.CukaiViewListKemaskiniRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan-kemaskini`,
    method: 'GET',
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiViewListKemaskiniSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewListKemaskiniFailure(error));
  }
}
//
// CUKAI VIEW LIST PENGURANGAN
//
function* getCukaiViewListPengurangan({ params, onCallback }: actions.CukaiViewListPenguranganRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan-oku`,
    method: 'GET',
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiViewListPenguranganSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewListPenguranganFailure(error));
  }
}

//
// CUKAI VIEW DRAF PINDAHMILIK
//
function* getCukaiViewDrafPindahmilik({ params, onCallback }: actions.CukaiViewDrafPindahmilikRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan-pindahmilik`,
    method: 'GET',
    params: {
      app_key: 'app_number',
      app_value: params.app_number,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiViewDrafPindahmilikSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewDrafPindahmilikFailure(error));
  }
}

//
// CUKAI VIEW DRAF KEMASKINI
//
function* getCukaiViewDrafKemaskini({ params, onCallback }: actions.CukaiViewDrafKemaskiniRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan-kemaskini`,
    method: 'GET',
    params: {
      app_key: 'app_number',
      app_value: params.app_number,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiViewDrafKemaskiniSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewDrafKemaskiniFailure(error));
  }
}

//
// CUKAI VIEW DRAF PENGURANGAN
//
function* getCukaiViewDrafPengurangan({ params, onCallback }: actions.CukaiViewDrafPenguranganRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/cukai/permohonan-oku`,
    method: 'GET',
    params: {
      app_key: 'app_number',
      app_value: params.app_number,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.CukaiViewDrafPenguranganSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.CukaiViewDrafPenguranganFailure(error));
  }
}

export function* watchCukai() {
  yield takeLatest(constants.CUKAI_SEARCH_REQUEST, getCukaiSearch);
  yield takeLatest(constants.CUKAI_DATATABLE_REQUEST, getCukaiDatatable);
  yield takeLatest(constants.CUKAI_EDIT_REQUEST, getCukaiEdit);
  yield takeLatest(constants.CUKAI_DRAFT_REQUEST, getCukaiDraft);
  yield takeLatest(constants.CUKAI_SUBMIT_REQUEST, getCukaiSubmit);
  yield takeLatest(constants.CUKAI_VIEW_REQUEST, getCukaiView);
  yield takeLatest(constants.CUKAI_PAY_CREATE_REQUEST, getCukaiPayCreate);
  yield takeLatest(constants.CUKAI_PAY_REDIRECT_REQUEST, getCukaiPayRedirect);
  yield takeLatest(constants.CUKAI_DELETEDRAFT_REQUEST, getCukaiDeleteDraft);
  yield takeLatest(constants.CUKAI_PRICE_REQUEST, getCukaiPrice);
  yield takeLatest(constants.CUKAI_ACCOUNT_DATATABLE_REQUEST, getCukaiAccountDatatable);
  yield takeLatest(constants.CUKAI_VIEW_BILL_REQUEST, getCukaiViewBill);
  yield takeLatest(constants.CUKAI_VIEW_ACCOUNT_INFO_REQUEST, getCukaiViewAccountInfo);
  yield takeLatest(constants.CUKAI_PINDAHMILIK_SUBMIT_REQUEST, getCukaiPindahmilikSubmit);
  yield takeLatest(constants.CUKAI_KEMASKINI_SUBMIT_REQUEST, getCukaiKemaskiniSubmit);
  yield takeLatest(constants.CUKAI_PENGURANGAN_SUBMIT_REQUEST, getCukaiPenguranganSubmit);
  yield takeLatest(constants.CUKAI_VIEW_BY_ACCOUNT_NO_REQUEST, getCukaiViewByAccountNo);
  yield takeLatest(constants.CUKAI_VIEW_LIST_PERMOHONAN_REQUEST, getCukaiViewListPermohonan);
  yield takeLatest(constants.CUKAI_VIEW_LIST_PINDAHMILIK_REQUEST, getCukaiViewListPindahmilik);
  yield takeLatest(constants.CUKAI_VIEW_LIST_KEMASKINI_REQUEST, getCukaiViewListKemaskini);
  yield takeLatest(constants.CUKAI_VIEW_LIST_PENGURANGAN_REQUEST, getCukaiViewListPengurangan);
  yield takeLatest(constants.CUKAI_VIEW_DRAF_PINDAHMILIK_REQUEST, getCukaiViewDrafPindahmilik);
  yield takeLatest(constants.CUKAI_VIEW_DRAF_KEMASKINI_REQUEST, getCukaiViewDrafKemaskini);
  yield takeLatest(constants.CUKAI_VIEW_DRAF_PENGURANGAN_REQUEST, getCukaiViewDrafPengurangan);
}
