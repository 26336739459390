import { all, fork } from "@redux-saga/core/effects";

import { watchAudit } from "./saga/audit";
import { watchAuth } from "./saga/auth";
import { watchBayaran } from "./saga/bayaran";
import { watchBil } from "./saga/bil";
import { watchCagaran } from "./saga/cagaran";
import { watchCompany } from "./saga/company";
import { watchCukai } from "./saga/cukai";
import { watchJualan } from "./saga/jualan";
import { watchKompaun } from "./saga/kompaun";
import { watchKpm } from "./saga/kpm";
import { watchLesen } from "./saga/lesen";
import { watchLocale } from "./saga/locale";
import { watchLoyalti } from "./saga/loyalti";
import { watchMapstatus } from "./saga/mapstatus";
import { watchPermit } from "./saga/permit";
import { watchPpm } from "./saga/ppm";
import { watchPsBanglo } from "./saga/psbanglo";
import { watchPsBuku } from "./saga/psbuku";
import { watchPsDoskmep } from "./saga/psdoskmep";
import { watchPsKedai } from "./saga/pskedai";
import { watchPsKediaman } from "./saga/pskediaman";
import { watchPsKilang } from "./saga/pskilang";
import { watchPsTaska } from "./saga/pstaska";
import { watchPsTelco } from "./saga/pstelco";
import { watchReference } from "./saga/reference";
import { watchRegistration } from "./saga/registration";
import { watchRoro } from "./saga/roro";
import { watchSekat } from "./saga/sekat";
import { watchSetting } from "./saga/setting";
import { watchSewaan } from "./saga/sewaan";
import { watchShow } from "./saga/show";
import { watchSyarikat } from "./saga/syarikat";
import { watchTempahan } from "./saga/tempahan";
import { watchTransaction } from "./saga/transaction";
import { watchTroli } from "./saga/troli";
import { watchUser } from "./saga/user";
import { watchUsers } from "./saga/users";
import { watchVersion } from "./saga/version";

function* rootSaga() {
  yield all([
    fork(watchAuth),
    fork(watchAudit),
    fork(watchLocale),
    fork(watchRegistration),
    fork(watchVersion),
    fork(watchLoyalti),
    fork(watchJualan),
    fork(watchUser),
    fork(watchUsers),
    fork(watchReference),
    fork(watchKpm),
    fork(watchPpm),
    fork(watchRoro),
    fork(watchCukai),
    fork(watchLesen),
    fork(watchPermit),
    fork(watchCagaran),
    fork(watchCompany),
    fork(watchMapstatus),
    fork(watchShow),
    fork(watchTroli),
    fork(watchPsTelco),
    fork(watchPsKilang),
    fork(watchPsKedai),
    fork(watchPsKediaman),
    fork(watchPsTaska),
    fork(watchPsBuku),
    fork(watchTransaction),
    fork(watchBil),
    fork(watchBayaran),
    fork(watchSekat),
    fork(watchKompaun),
    fork(watchSyarikat),
    fork(watchSewaan),
    fork(watchPsDoskmep),
    fork(watchPsBanglo),
    fork(watchTempahan),
    fork(watchSetting),
  ]);
}

export default rootSaga;
