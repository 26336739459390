import * as actions from "../action/jualan";
import * as constants from "../constant/jualan";

//
//  CREATE JUALAN
//
export function CreateJualanRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CreateJualanRequestAction {
  return {
    type: constants.CREATE_JUALAN_REQUEST,
    params,
    onCallback,
  };
}

export function CreateJualanSuccess(
  data: object
): actions.CreateJualanSuccessAction {
  return {
    type: constants.CREATE_JUALAN_SUCCESS,
    data,
  };
}

export function CreateJualanFailure(
  error: string
): actions.CreateJualanFailureAction {
  return {
    type: constants.CREATE_JUALAN_FAILURE,
    error,
  };
}

//
//  CANCEL JUALAN
//
export function CancelJualanRequest(
  noBill: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CancelJualanRequestAction {
  return {
    type: constants.CANCEL_JUALAN_REQUEST,
    noBill,
    onCallback,
  };
}

export function CancelJualanSuccess(
  data: object
): actions.CancelJualanSuccessAction {
  return {
    type: constants.CANCEL_JUALAN_SUCCESS,
    data,
  };
}

export function CancelJualanFailure(
  error: string
): actions.CancelJualanFailureAction {
  return {
    type: constants.CANCEL_JUALAN_FAILURE,
    error,
  };
}

//
//  JUALAN DATATABLE
//
export function JualanDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.JualanDatatableRequestAction {
  return {
    type: constants.JUALAN_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}

export function JualanDatatableSuccess(
  data: object
): actions.JualanDatatableSuccessAction {
  return {
    type: constants.JUALAN_DATATABLE_SUCCESS,
    data,
  };
}

export function JualanDatatableFailure(
  error: string
): actions.JualanDatatableFailureAction {
  return {
    type: constants.JUALAN_DATATABLE_FAILURE,
    error,
  };
}

//
//  JUALAN PRODUCTS
//
export function JualanProductsRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.JualanProductsRequestAction {
  return {
    type: constants.JUALAN_PRODUCTS_REQUEST,
    onCallback,
  };
}

export function JualanProductsSuccess(
  data: object
): actions.JualanProductsSuccessAction {
  return {
    type: constants.JUALAN_PRODUCTS_SUCCESS,
    data,
  };
}

export function JualanProductsFailure(
  error: string
): actions.JualanProductsFailureAction {
  return {
    type: constants.JUALAN_PRODUCTS_FAILURE,
    error,
  };
}

//
//  JUALAN PRODUCT
//
export function JualanProductRequest(
  params: any,
  productId: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.JualanProductRequestAction {
  return {
    type: constants.JUALAN_PRODUCT_REQUEST,
    productId,
    onCallback,
    params,
  };
}

export function JualanProductSuccess(
  data: object
): actions.JualanProductSuccessAction {
  return {
    type: constants.JUALAN_PRODUCT_SUCCESS,
    data,
  };
}

export function JualanProductFailure(
  error: string
): actions.JualanProductFailureAction {
  return {
    type: constants.JUALAN_PRODUCT_FAILURE,
    error,
  };
}
