import * as actions from "../action/users";
import * as constants from "../constant/users";

export interface UsersState {
  data: object;
  error: string;
  loading: boolean;
}

export const usersReducer = (
  state: UsersState = {
    data: {},
    error: "",
    loading: false,
  },
  action: actions.UsersAction
) => {
  switch (action.type) {
    //
    // USERS - DATATABLE
    //
    case constants.USERS_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.USERS_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.USERS_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    //
    // USERS - DATATABLE
    //
    case constants.USERS_USERS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.USERS_USERS_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.USERS_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
