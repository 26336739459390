import * as ax from "axios";
import {
  API_URL,
  BASICAUTH_PASS,
  BASICAUTH_USER,
  IPBTAUTH_USER,
  IPBTAUTH_PASS,
  ISPROD,
  IPBT_URL,
} from "../config";
import * as AxiosLogger from "axios-logger";

const axios = ax.default.create();
if (!ISPROD) {
  axios.interceptors.request.use(AxiosLogger.requestLogger);
}

// interceptor for http
axios.interceptors.response.use(
  (response) => {
    if (!ISPROD) {
      return AxiosLogger.responseLogger(response);
    }
    return response;
  },
  (error) => {
    if (!error.response) {
      error.error = "error.connection";
      error.status = 0;
    }
    return Promise.reject(error);
  }
);

export interface AxiosResponse {
  status?: number;
  error?: string;
  data?: any;
}

export interface AxiosProps {
  url: string;
  method: string;
  token?: string;
  data?: any;
  params?: any;
  customContentType?: string;
  ignoreRedirect401?: boolean;
}

const getCsrfToken = async (apiUrl: string) => {
  try {
    const resp = await axios.get(API_URL + `/_csrf`, {
      headers: {
        "x-csrf-url": apiUrl,
      },
    });
    if (resp.status === 202) {
      return resp.data;
    }
  } catch (error) {
    console.log(error);
  }
  return "";
};

export const call = async (config: AxiosProps): Promise<AxiosResponse> => {
  let apiUrl = "";
  if (config.url.includes(IPBT_URL)) {
    apiUrl = config.url;
  } else {
    apiUrl = API_URL + config.url;
  }

  //success
  const onSuccess = (response: any): AxiosResponse => {
    const CallResponse: AxiosResponse = {
      status: 200,
      data: response.data,
    };
    return CallResponse;
  };

  //error
  const onError = (error: any): AxiosResponse => {
    let statusCode = 0;
    let errorMsg = "error.connection";
    if (error.response) {
      statusCode = error.response.status;
      if (typeof error.response.data !== "string") {
        errorMsg = error.response.data.error;
      } else {
        errorMsg = error.response.data;
      }
    } else {
      statusCode = error.status;
      errorMsg = error.error;
    }

    //401
    if (statusCode === 401 && !config.ignoreRedirect401) {
      window.location.reload();
    }

    //0
    else if (statusCode === 0) {
      //ignore
    }

    //500
    else if (statusCode === 500 && errorMsg !== "") {
      if (
        typeof errorMsg === "string" &&
        errorMsg.indexOf("HTTP Status 500") !== -1
      ) {
        errorMsg = "error." + statusCode;
      } else if (typeof errorMsg !== "string") {
        errorMsg = "error." + statusCode;
      }
    } else {
      //set error message (use react 118n)
      errorMsg = "error." + statusCode;
    }

    const CallResponse: AxiosResponse = {
      status: statusCode,
      error: errorMsg,
    };
    return CallResponse;
  };
  const headers: any = {};

  //add header authorization for pattern /secured/
  if (apiUrl.indexOf("/secured/") != -1 || apiUrl.indexOf("cukai") != -1) {
    const token = localStorage.getItem("mymbsa-token");
    const impersonateToken = localStorage.getItem("mymbsa-impersonate-token");

    if (!token) {
      console.log(apiUrl + " <-- JWT token required!!");
      //throw new Error(apiUrl + ' <-- JWT token required!!');
    }

    headers["X-Auth"] = "Bearer " + token;

    if (impersonateToken) {
      headers["X-Impersonate"] = "Bearer " + impersonateToken;
    }

    //only appplies to cukai API
    if (apiUrl.indexOf("cukai") != -1) {
      headers["X-Auth"] = token;
    }
  }

  if (config.customContentType) {
    headers["Content-Type"] = config.customContentType;
  }

  if (config.method === "POST") {
    /*
         const csrfToken = await getCsrfToken(apiUrl);
         headers['x-csrf-token'] = csrfToken;
         if(csrfToken === '') {
             const CallResponse: AxiosResponse = {
                 status: 403,
                 error: 'error.403'
             };
             return CallResponse;
         }*/
  }

  let authConfig = {
    username: BASICAUTH_USER,
    password: BASICAUTH_PASS,
  };

  if (apiUrl.includes(IPBT_URL)) {
    authConfig = {
      username: IPBTAUTH_USER,
      password: IPBTAUTH_PASS,
    };
  }

  if (apiUrl.indexOf("/ipbt/") != -1) {
    authConfig = {
      username: IPBTAUTH_USER,
      password: IPBTAUTH_PASS,
    };
  }

  return axios({
    method: config.method as ax.Method,
    url: apiUrl,
    data: config.data,
    params: config.params,
    headers: headers,
    auth: authConfig,
  })
    .then(onSuccess)
    .catch(onError);
};

export default axios;
