import * as actions from '../action/auth';
import * as constants from '../constant/auth';

export interface AuthState {
  data: object;
  token: string;
  impersonateToken: string;
  error: string;
  loading: boolean;
  isLoggedIn: boolean;
  isImpersonating: boolean;
}

export const authReducer = (
  state: AuthState = {
    data: {},
    token: "",
    impersonateToken: "",
    error: "",
    loading: false,
    isLoggedIn: localStorage.getItem('mymbsa-token') != null,
    isImpersonating: localStorage.getItem('mymbsa-impersonate-token') != null
  },
  action: actions.AuthAction) => {    
    switch (action.type) {

      //
      // LOGIN
      //
      case constants.LOGIN_REQUEST: {
        return {
          ...state,
          isLoggedIn: false,
          loading: true,
          error: ""
        };
      }
      case constants.LOGIN_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          token: (action.data as any).token,
          loading: false,
          isLoggedIn: true,
        };
      }
      case constants.LOGIN_FAILURE: {
        return {
          ...state,
          isLoggedIn: false,
          loading: false,
          error: action.error
        };
      }

      //
      // IMPERSONATE
      //
      case constants.IMPERSONATE_REQUEST: {
        return {
          ...state,
          isImpersonating: false,
          loading: true,
          error: ""
        };
      }
      case constants.IMPERSONATE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          impersonateToken: (action.data as any).token,
          loading: false,
          isImpersonating: true,
        };
      }
      case constants.IMPERSONATE_FAILURE: {
        return {
          ...state,
          isImpersonating: false,
          loading: false,
          error: action.error
        };
      }


      //
      // TOKEN
      //
      case constants.TOKEN_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ""
        };
      }
      case constants.TOKEN_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          token: (action.data as any).token,
          loading: false,
          isLoggedIn: true,
        };
      }
      case constants.TOKEN_FAILURE: {
        return {
          ...state,
          isLoggedIn: false,
          loading: false
        };
      }


      //
      // LOGOUT
      //
      case constants.LOGOUT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ''
        };
      }
      case constants.LOGOUT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
          isLoggedIn: false,
        };
      }
      case constants.LOGOUT_FAILURE: {
        return {
          ...state,
          isLoggedIn: false,
          loading: false,
          error: action.error
        };
      }

      //
      // FORGOT PASSWORD
      //
      case constants.FORGOTPASSWORD_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ''
        };
      }
      case constants.FORGOTPASSWORD_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.FORGOTPASSWORD_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      //
      // UPDATE PASSWORD
      //
      case constants.UPDATEPASSWORD_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ''
        };
      }
      case constants.UPDATEPASSWORD_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.UPDATEPASSWORD_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      
      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  