/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CgMenu } from "react-icons/cg";
import { FaUser } from "react-icons/fa";
import * as RI from "react-icons/ri";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/icons/svg/Logo.svg";
import { API_URL } from "../../config";
import { LOYALTI_VIEW_CART_REQUEST } from "../../redux/constant/loyalti";
import { SET_SHOW } from "../../redux/constant/show";
import { TROLI_TROLIS_REQUEST } from "../../redux/constant/troli";
import HtmlEntites from "../../utils/htmlEntites";
import TroliPay from "../../views/LandingPage/components/TroliPay";
import UserMenu from "../../views/LandingPage/components/UserMenu";
import Spinner from "./Spinner";

export interface TitleProps {
  size?: number;
  title: string;
  showWelcome?: boolean;
}

const Header = ({ title, showWelcome }: TitleProps) => {
  const [roleId, setRoleId] = useState("0");
  useEffect(() => {
    document.title = HtmlEntites(tt);
    if (auth.isLoggedIn) {
      setRoleId(auth.data.role_id);
    }
  }, []);

  const auth = useSelector((state: any) => state.auth);
  const kpm = useSelector((state: any) => state.kpm);
  const ppm = useSelector((state: any) => state.ppm);
  const user = useSelector((state: any) => state.user);
  const users = useSelector((state: any) => state.users);
  const loyalti = useSelector((state: any) => state.loyalti);
  const reference = useSelector((state: any) => state.reference);
  const roro = useSelector((state: any) => state.roro);
  const permit = useSelector((state: any) => state.permit);
  const locale = useSelector((state: any) => state.locale);
  const cagaran = useSelector((state: any) => state.cagaran);
  const company = useSelector((state: any) => state.company);
  const cukai = useSelector((state: any) => state.cukai);
  const lesen = useSelector((state: any) => state.lesen);
  const psbuku = useSelector((state: any) => state.psbuku);
  const pstelco = useSelector((state: any) => state.pstelco);
  const pskedai = useSelector((state: any) => state.pskedai);
  const pskediaman = useSelector((state: any) => state.pskediaman);
  const pskilang = useSelector((state: any) => state.pskilang);
  const pstaska = useSelector((state: any) => state.pstaska);
  const troli = useSelector((state: any) => state.troli);
  const bil = useSelector((state: any) => state.bil);
  const sekat = useSelector((state: any) => state.sekat);
  const sewaan = useSelector((state: any) => state.sewaan);
  const kompaun = useSelector((state: any) => state.kompaun);
  const psdoskmep = useSelector((state: any) => state.psdoskmep);
  const { show } = useSelector((state: any) => state.show);

  const loading =
    auth.loading ||
    ppm.loading ||
    kpm.loading ||
    user.loading ||
    users.loading ||
    loyalti.loading ||
    reference.loading ||
    roro.loading ||
    locale.loading ||
    permit.loading ||
    cagaran.loading ||
    company.loading ||
    lesen.loading ||
    pstelco.loading ||
    pskilang.loading ||
    pskediaman.loading ||
    pstaska.loading ||
    pskedai.loading ||
    psbuku.loading ||
    cukai.loading ||
    bil.loading ||
    sekat.loading ||
    kompaun.loading ||
    psdoskmep.loading ||
    sewaan.loading;

  const [openUserMenu, setOpenUserMenu] = useState<boolean>(false);
  const [openTroliPay, setOpenTroliPay] = useState<boolean>(false);
  const userMenuRef = React.createRef<HTMLDivElement>();
  const backetPayRef = React.createRef<HTMLDivElement>();
  const intl = useIntl();
  const date = new Date();
  const tt = intl.formatMessage({ id: title });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [troliData, setTroliData] = useState<any>([]);
  const [loyaltiCartData, setLoyaltiCartData] = useState<any>([]);

  const fetchTroli = () => {
    const token = localStorage.getItem("mymbsa-token");
    const impersonateToken = localStorage.getItem("mymbsa-impersonate-token");
    if (impersonateToken) {
      fetch(API_URL + "/secured/troli/trolis", {
        method: "post",
        headers: new Headers({
          "X-Impersonate": "Bearer " + impersonateToken,
          "X-Auth": "Bearer " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((response) => response.json())
        .then((data) => setTroliData(data));
    } else {
      fetch(API_URL + "/secured/troli/trolis", {
        method: "post",
        headers: new Headers({
          "X-Auth": "Bearer " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((response) => response.json())
        .then((data) => setTroliData(data));
    }
  };

  const loadLoyaltiCart = () => {
    if (localStorage.getItem("carts") != null) {
      const existingCartItem = JSON.parse(localStorage.getItem("carts"));
      setLoyaltiCartData(existingCartItem);
    } else {
      setLoyaltiCartData([]);
    }
  };

  useEffect(() => {
    fetchTroli();
    loadLoyaltiCart();
  }, [loading]);

  return (
    <>
      <div className="flex w-full rounded-bl-3xl h-16">
        <div className="flex justify-end w-full bg-mymbsa-primary rounded-bl-3xl">
          <div className="flex items-center w-1/2">
            {show === false && (
              <div
                className="font-semibold ml-4"
                onClick={() =>
                  dispatch({
                    type: SET_SHOW,
                    show: true,
                  })
                }>
                <CgMenu size="1.5rem" className="text-white cursor-pointer" />
              </div>
            )}

            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}>
              <img src={logo} className="w-44 pl-5" alt="MBSA Logo" />
            </a>
          </div>
          <div className="flex justify-end w-1/2">
            <div className="flex flex-row h-full w-48 justify-between items-center">
              {/* Troli Pay */}
              <TroliPay
                open={openTroliPay}
                troliData={troliData}
                loyaltiCartData={loyaltiCartData}
                loading={troli.loading}
                onToggle={() => {
                  setOpenTroliPay(!openTroliPay);
                  if (!openTroliPay) {
                    dispatch({
                      type: TROLI_TROLIS_REQUEST,
                      onCallback: (props) => {
                        setTroliData(props.data);
                      },
                    });
                    dispatch({
                      type: LOYALTI_VIEW_CART_REQUEST,
                      onCallback: (props: any) => {
                        if (props.status === 1 && props.data) {
                          setLoyaltiCartData(props.data);
                          console.log(props.data);
                          //setLoyaltiProducts(props.data.data.data);
                        }
                      },
                    });
                  }
                }}
                ref={backetPayRef}
              />

              {/* Notification */}
              <span className="relative inline-block">
                <RI.RiNotificationLine size={25} className="text-white cursor-pointer" />
              </span>
              <div className="flex justify-end pr-6 pt-1">
                {loading && (
                  <div className="mr-2 w-14 h-14 shadow-lg relative flex justify-center items-center rounded-full bg-red-900 text-lg text-white uppercase">
                    <Spinner />
                  </div>
                )}
                {!loading && (
                  <UserMenu
                    auth={auth.data}
                    open={openUserMenu}
                    onToggle={() => setOpenUserMenu(!openUserMenu)}
                    ref={userMenuRef}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showWelcome !== true && (
        <>
          <div>
            <div className="flex flex-row w-full pt-5 px-5 pb-2">
              <div className="w-1/2">
                <h1 className="font-sans text-sm text-white">
                  <>{HtmlEntites(tt)}</>
                </h1>
              </div>
              <div className="flex w-1/2">
                <h1 className="font-sans text-sm font-bold text-white text-right w-full">
                  {moment(date).format("DD MMM YYYY")}
                </h1>
              </div>
            </div>
          </div>
        </>
      )}
      {showWelcome === true && !loading && (
        <div className="mx-5 my-5 flex">
          <div className="mr-3">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="mr-2 w-16 h-16 shadow-lg relative flex justify-center items-center rounded-full bg-white text-lg text-mymbsa-primary uppercase">
              <FaUser />
            </a>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-white">{intl.formatMessage({ id: "welcome.message" })}</h5>
            <h1 className="text-white text-2xl font-bold">
              {auth.data.name && auth.data.name.toUpperCase()}
            </h1>
          </div>
        </div>
      )}

      {showWelcome === true && loading && (
        <div className="mx-5 my-5 flex">
          <div className="mr-3">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
              }}
              className="mr-2 w-16 h-16 shadow-lg relative flex justify-center items-center rounded-full bg-white text-lg text-mymbsa-primary uppercase">
              <FaUser />
            </a>
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-white">{intl.formatMessage({ id: "welcome.message" })}</h5>
            <h1 className="text-white text-2xl font-bold">
              <p className="leading-relaxed w-full h-5 animate-pulse bg-mymbsa-primarylight"></p>
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
