import { useLocation, useNavigate } from "react-router-dom";
import eBOOK from "../../../assets/icons/svg/MyLoyalti.svg";
import eKebumi from "../../../assets/icons/svg/eKEBUMI.svg";
import eSTRATA from "../../../assets/icons/svg/eSTRATA.svg";
import home from "../../../assets/icons/svg/Home.svg";
import iAdu from "../../../assets/icons/svg/iAdu.svg";
import iAdu2 from "../../../assets/icons/svg/agreement.svg";
import iLIBRARY from "../../../assets/icons/svg/iLIBRARY.svg";
import myBayar from "../../../assets/icons/svg/myBayaran.svg";
import OSC3 from "../../../assets/icons/svg/OSC3.0+.svg";

const FloatingMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const menuList = [
    {
      href: "https://www.mbsa.gov.my/",
      title: "Portal MBSA",
      icon: <img src={home} className="w-6 h-6" alt="myBAYAR" />,
    },
    {
      href: "/eloyalti",
      title: "E-Loyalty",
      icon: <img src={eBOOK} className="w-6" alt="eBOOK" />,
    },
    {
      href: "#",
      title: "myBayar",
      icon: <img src={myBayar} className="w-6 h-6" alt="myBAYAR" />,
    },
    // {
    //   href: "https://aduan.mbsa.gov.my/",
    //   title: "iAdu",
    //   icon: <img src={iAdu} className="w-6" alt="iAduan" />,
    // },
    {
      href: "https://mbsa.spab.gov.my/",
      title: "SISPAA",
      icon: <img src={iAdu2} className="w-6" alt="iAduan" />,
    },
    // {
    //   href: "#",
    //   title: "eBAZAR",
    //   icon: <img src={eBAZAR} className="w-6" alt="eBAZAR" />,
    // },
    // { href: "#", title: "eSTRATA", icon: <img src={eSTRATA} className="w-6" alt="eSTRATA" /> },
    // {
    //   href: "https://ikerjaya.mbsa.gov.my/",
    //   title: "iKerjaya",
    //   icon: <img src={eSTRATA} className="w-6" alt="eBOOK" />,
    // },
    {
      href: "http://osconline.mbsa.gov.my/eOSC/first.page",
      title: "OSC 3.0+ Online",
      icon: <img src={OSC3} className="w-6" alt="OSC3" />,
    },
    {
      href: "http://library.mbsa.gov.my/",
      title: "iLIBRARY",
      icon: <img src={iLIBRARY} className="w-6" alt="iLIBRARY" />,
    },
    {
      href: "https://ipusara.mbsa.gov.my/",
      title: "iPUSARA",
      icon: <img src={eKebumi} className="w-6" alt="eKEBUMI" />,
    },
  ];

  return (
    <div className="shadow-lg sticky top-0 z-40 bg-white">
      <div className="container mx-auto">
        <div className="grid sm:grid-flow-col xs:grid-cols-4 gap-4 content-center">
          {menuList.map((menu) => {
            return (
              <div
                key={Math.random()}
                className={`flex justify-center flex-col items-center py-2 hover:border-b-4 hover:border-mymbsa-primarylight ${
                  pathname === "/bayaran/luar" &&
                  menu.title === "myBayar" &&
                  "border-b-4 border-mymbsa-primarylight"
                }`}
              >
                <button
                  onClick={() => {
                    if (menu.title === "myBayar") {
                      navigate("/bayaran/luar");
                    } else if (menu.title === "E-Loyalty") {
                      navigate("/eloyalti");
                    } else {
                      window.open(menu.href);
                    }
                  }}
                  key={Math.random()}
                  className="flex focus:outline-none focus:border-blue-600"
                >
                  <span className="grid grid-flow-row justify-center justify-items-center">
                    {menu.icon}
                    <span className="pt-2 font-bold text-sm">{menu.title}</span>
                  </span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FloatingMenu;
