import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/pskediaman';
import * as constants from '../constant/pskediaman';
import * as functions from '../function/pskediaman';



//
// PsKediaman Datatable
//
function* getPsKediamanDatatable({params, onCallback}: actions.PsKediamanDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kediaman/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKediamanDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKediamanDatatableFailure(error));
    }
    
};

//
// PsKediaman Draft
//
function* getPsKediamanDraft({params, onCallback}: actions.PsKediamanDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kediaman/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKediamanDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKediamanDraftFailure(error));
    }
};

//
// PsKediaman Edit
//
function* getPsKediamanEdit({params, onCallback}: actions.PsKediamanEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kediaman/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKediamanEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKediamanEditFailure(error));
    }
};

//
// PsKediaman Submit
//
function* getPsKediamanSubmit({params, onCallback}: actions.PsKediamanSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kediaman/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKediamanSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKediamanSubmitFailure(error));
    }
};

//
// PsKediaman View
//
function* getPsKediamanView({params, onCallback}: actions.PsKediamanViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kediaman/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKediamanViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKediamanViewFailure(error));
    }
};

//
// PsKediaman Search
//
function* getPsKediamanSearch({params, onCallback}: actions.PsKediamanSearchRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kediaman/search`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKediamanSearchSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKediamanSearchFailure(error));
    }
};


export function* watchPsKediaman() {
    
    yield takeLatest(constants.PSKEDIAMAN_DATATABLE_REQUEST, getPsKediamanDatatable);
    yield takeLatest(constants.PSKEDIAMAN_DRAFT_REQUEST, getPsKediamanDraft);
    yield takeLatest(constants.PSKEDIAMAN_EDIT_REQUEST, getPsKediamanEdit);
    yield takeLatest(constants.PSKEDIAMAN_SUBMIT_REQUEST, getPsKediamanSubmit);
    yield takeLatest(constants.PSKEDIAMAN_VIEW_REQUEST, getPsKediamanView);
    yield takeLatest(constants.PSKEDIAMAN_SEARCH_REQUEST, getPsKediamanSearch);
};


