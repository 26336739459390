import * as actions from '../action/show';
import * as constants from '../constant/show';

//
// SHOW
//
export function SetShow(show: boolean): actions.ShowSetAction {
    return {
        type: constants.SET_SHOW,
        show
    }
}

export function SuccessSetShow(data: boolean): actions.ShowSetSuccessAction {
    return {
        type: constants.SUCCESS_SET_SHOW,
        data
    }
}
