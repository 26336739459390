import * as actions from '../action/version';
import * as constants from '../constant/version';

export interface VersionState {
  version: object;
  error: string;
  loading: boolean;
}

export const versionReducer = (
  state: VersionState = {
    version: {},
    error: "",
    loading: false
  },
  action: actions.VersionAction) => {    
    switch (action.type) {

      //
      // VERSION
      //
      case constants.VERSION_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ""
        };
      }
      case constants.VERSION_SUCCESS: {
        return {
          ...state,
          version: action.data as any,
          loading: false
        };
      }
      case constants.VERSION_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      
      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  