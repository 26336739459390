import * as actions from "../action/users";
import * as constants from "../constant/users";

//
// USERS - DATATABLE
//
export function usersDataTableRequest(
  params: any,
  limit: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UsersDataTableRequestAction {
  return {
    type: constants.USERS_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}
export function usersDataTableSuccess(
  data: object
): actions.UsersDataTableSuccessAction {
  return {
    type: constants.USERS_DATATABLE_SUCCESS,
    data,
  };
}
export function usersDataTableFailure(
  error: string
): actions.UsersDataTableFailureAction {
  return {
    type: constants.USERS_DATATABLE_FAILURE,
    error,
  };
}

//
// USERS - DATATABLE
//
export function usersUsersRequest(
  params: any,
  limit: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UsersUsersRequestAction {
  return {
    type: constants.USERS_USERS_REQUEST,
    params,
    onCallback,
  };
}
export function usersUsersSuccess(
  data: object
): actions.UsersUsersSuccessAction {
  return {
    type: constants.USERS_USERS_SUCCESS,
    data,
  };
}
export function usersUsersFailure(
  error: string
): actions.UsersUsersFailureAction {
  return {
    type: constants.USERS_USERS_FAILURE,
    error,
  };
}
