//
//SEARCH BY ID
//
export const CAGARAN_SEARCH_BY_ID_REQUEST = 'CAGARAN/CAGARAN_SEARCH_BY_ID_REQUEST';
export const CAGARAN_SEARCH_BY_ID_SUCCESS = 'CAGARAN/CAGARAN_SEARCH_BY_ID_SUCCESS';
export const CAGARAN_SEARCH_BY_ID_FAILURE = 'CAGARAN/CAGARAN_SEARCH_BY_ID_FAILURE';

//
//SEARCH BY NO BIL
//
export const CAGARAN_SEARCH_BY_NO_BIL_REQUEST = 'CAGARAN/CAGARAN_SEARCH_BY_NO_BIL_REQUEST';
export const CAGARAN_SEARCH_BY_NO_BIL_SUCCESS = 'CAGARAN/CAGARAN_SEARCH_BY_NO_BIL_SUCCESS';
export const CAGARAN_SEARCH_BY_NO_BIL_FAILURE = 'CAGARAN/CAGARAN_SEARCH_BY_NO_BIL_FAILURE';

//
//SEARCH BY VIEW
//
export const CAGARAN_VIEW_REQUEST = 'CAGARAN/CAGARAN_VIEW_REQUEST';
export const CAGARAN_VIEW_SUCCESS = 'CAGARAN/CAGARAN_VIEW_SUCCESS';
export const CAGARAN_VIEW_FAILURE = 'CAGARAN/CAGARAN_VIEW_FAILURE';

//
//PAY CREATE
//
export const CAGARAN_PAY_CREATE_REQUEST = 'CAGARAN/CAGARAN_PAY_CREATE_REQUEST';
export const CAGARAN_PAY_CREATE_SUCCESS = 'CAGARAN/CAGARAN_PAY_CREATE_SUCCESS';
export const CAGARAN_PAY_CREATE_FAILURE = 'CAGARAN/CAGARAN_PAY_CREATE_FAILURE';

//
//PAY REDIRECT
//
export const CAGARAN_PAY_REDIRECT_REQUEST = 'CAGARAN/CAGARAN_PAY_REDIRECT_REQUEST';
export const CAGARAN_PAY_REDIRECT_SUCCESS = 'CAGARAN/CAGARAN_PAY_REDIRECT_SUCCESS';
export const CAGARAN_PAY_REDIRECT_FAILURE = 'CAGARAN/CAGARAN_PAY_REDIRECT_FAILURE';
