import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/pstelco';
import * as constants from '../constant/pstelco';
import * as functions from '../function/pstelco';



//
// Ps Telco DATATABLE
//
function* getPsTelcoDatatable({params, onCallback}: actions.PsTelcoDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/telco/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTelcoDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTelcoDatatableFailure(error));
    }
    
};

//
// PsTelco Draft
//
function* getPsTelcoDraft({params, onCallback}: actions.PsTelcoDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/telco/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTelcoDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTelcoDraftFailure(error));
    }
};

//
// PsTelco Edit
//
function* getPsTelcoEdit({params, onCallback}: actions.PsTelcoEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/telco/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTelcoEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTelcoEditFailure(error));
    }
};

//
// PsTelco Submit
//
function* getPsTelcoSubmit({params, onCallback}: actions.PsTelcoSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/telco/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTelcoSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTelcoSubmitFailure(error));
    }
};

//
// PsTelco View
//
function* getPsTelcoView({params, onCallback}: actions.PsTelcoViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/telco/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTelcoViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTelcoViewFailure(error));
    }
};

//
// PsTelco Search
//
function* getPsTelcoSearch({params, onCallback}: actions.PsTelcoSearchRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/telco/search`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTelcoSearchSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTelcoSearchFailure(error));
    }
};

export function* watchPsTelco() {
    
    yield takeLatest(constants.PSTELCO_DATATABLE_REQUEST, getPsTelcoDatatable);
    yield takeLatest(constants.PSTELCO_DRAFT_REQUEST, getPsTelcoDraft);
    yield takeLatest(constants.PSTELCO_EDIT_REQUEST, getPsTelcoEdit);
    yield takeLatest(constants.PSTELCO_SUBMIT_REQUEST, getPsTelcoSubmit);
    yield takeLatest(constants.PSTELCO_VIEW_REQUEST, getPsTelcoView);
    yield takeLatest(constants.PSTELCO_SEARCH_REQUEST, getPsTelcoSearch);

};


