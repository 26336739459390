import * as actions from '../action/psbanglo';
import * as constants from '../constant/psbanglo';

export interface PsBangloState {
  data: object;
  error: string;
  loading: boolean;
}

export const psbangloReducer = (
  state: PsBangloState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.PsBangloAction) => {    
    switch (action.type) {
      
      //
      // PSBANGLO DATATABLE
      //
      case constants.PSBANGLO_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSBANGLO_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSBANGLO_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSBANGLO DRAFT
      //
      case constants.PSBANGLO_DRAFT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSBANGLO_DRAFT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSBANGLO_DRAFT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSBANGLO EDIT
      //
      case constants.PSBANGLO_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSBANGLO_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSBANGLO_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSBANGLO SUBMIT
      //
      case constants.PSBANGLO_SUBMIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSBANGLO_SUBMIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSBANGLO_SUBMIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSBANGLO VIEW
      //
      case constants.PSBANGLO_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSBANGLO_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSBANGLO_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSBANGLO SEARCH
      //
      case constants.PSBANGLO_SEARCH_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSBANGLO_SEARCH_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSBANGLO_SEARCH_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  