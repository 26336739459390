import * as actions from '../action/roro';
import * as constants from '../constant/roro';


//
// RORO Search
//
export function RoroSearchRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.RoroSearchRequestAction {
    return {
        type: constants.RORO_SEARCH_REQUEST,
        params,
        onCallback
    }
}
export function RoroSearchSuccess(data: object): actions.RoroSearchSuccessAction {
    return {
        type: constants.RORO_SEARCH_SUCCESS,
        data
    }
}
export function RoroSearchFailure(error: string): actions.RoroSearchFailureAction {
    return {
        type: constants.RORO_SEARCH_FAILURE,
        error
    }
}


//
// RORO Datatable
//
export function RoroDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.RoroDatatableRequestAction {
    return {
        type: constants.RORO_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function RoroDatatableSuccess(data: object): actions.RoroDatatableSuccessAction {
    return {
        type: constants.RORO_DATATABLE_SUCCESS,
        data
    }
}
export function RoroDatatableFailure(error: string): actions.RoroDatatableFailureAction {
    return {
        type: constants.RORO_DATATABLE_FAILURE,
        error
    }
}


//
// RORO Draft
//
export function RoroDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.RoroDraftRequestAction {
    return {
        type: constants.RORO_DRAFT_REQUEST,
        params,
        onCallback
    }
}
export function RoroDraftSuccess(data: object): actions.RoroDraftSuccessAction {
    return {
        type: constants.RORO_DRAFT_SUCCESS,
        data
    }
}
export function RoroDraftFailure(error: string): actions.RoroDraftFailureAction {
    return {
        type: constants.RORO_DRAFT_FAILURE,
        error
    }
}


//
// RORO Edit
//
export function RoroEditRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.RoroEditRequestAction {
    return {
        type: constants.RORO_EDIT_REQUEST,
        params,
        onCallback
    }
}
export function RoroEditSuccess(data: object): actions.RoroEditSuccessAction {
    return {
        type: constants.RORO_EDIT_SUCCESS,
        data
    }
}
export function RoroEditFailure(error: string): actions.RoroEditFailureAction {
    return {
        type: constants.RORO_EDIT_FAILURE,
        error
    }
}


//
// RORO Submit
//
export function RoroSubmitRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.RoroSubmitRequestAction {
    return {
        type: constants.RORO_SUBMIT_REQUEST,
        params,
        onCallback
    }
}
export function RoroSubmitSuccess(data: object): actions.RoroSubmitSuccessAction {
    return {
        type: constants.RORO_SUBMIT_SUCCESS,
        data
    }
}
export function RoroSubmitFailure(error: string): actions.RoroSubmitFailureAction {
    return {
        type: constants.RORO_SUBMIT_FAILURE,
        error
    }
}


//
// RORO View
//
export function RoroViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.RoroViewRequestAction {
    return {
        type: constants.RORO_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function RoroViewSuccess(data: object): actions.RoroViewSuccessAction {
    return {
        type: constants.RORO_VIEW_SUCCESS,
        data
    }
}
export function RoroViewFailure(error: string): actions.RoroViewFailureAction {
    return {
        type: constants.RORO_VIEW_FAILURE,
        error
    }
}

//
// RORO Delete Draft
//
export function RoroDeleteDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.RoroDeleteDraftRequestAction {
    return {
        type: constants.RORO_DELETEDRAFT_REQUEST,
        params,
        onCallback
    }
}
export function RoroDeleteDraftSuccess(data: object): actions.RoroDeleteDraftSuccessAction {
    return {
        type: constants.RORO_DELETEDRAFT_SUCCESS,
        data
    }
}
export function RoroDeleteDraftFailure(error: string): actions.RoroDeleteDraftFailureAction {
    return {
        type: constants.RORO_DELETEDRAFT_FAILURE,
        error
    }
}


//
// RORO Pay Create
//
export function RoroPayCreateRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.RoroPayCreateRequestAction {
    return {
        type: constants.RORO_PAY_CREATE_REQUEST,
        params,
        onCallback
    }
}
export function RoroPayCreateSuccess(data: object): actions.RoroPayCreateSuccessAction {
    return {
        type: constants.RORO_PAY_CREATE_SUCCESS,
        data
    }
}
export function RoroPayCreateFailure(error: string): actions.RoroPayCreateFailureAction {
    return {
        type: constants.RORO_PAY_CREATE_FAILURE,
        error
    }
}

//
// RORO Pay Redirect
//
export function RoroPayRedirectRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.RoroPayRedirectRequestAction {
    return {
        type: constants.RORO_PAY_REDIRECT_REQUEST,
        params,
        onCallback
    }
}
export function RoroPayRedirectSuccess(data: object): actions.RoroPayRedirectSuccessAction {
    return {
        type: constants.RORO_PAY_REDIRECT_SUCCESS,
        data
    }
}
export function RoroPayRedirectFailure(error: string): actions.RoroPayRedirectFailureAction {
    return {
        type: constants.RORO_PAY_REDIRECT_FAILURE,
        error
    }
}
