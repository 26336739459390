import * as actions from '../action/transaction';
import * as constants from '../constant/transaction';

export interface TransactionState {
  data: object;
  error: string;
  loading: boolean;
}

export const transactionReducer = (
  state: TransactionState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.TransactionAction) => {    
    switch (action.type) {
      
      //
      // TRANSACTION DATATABLE
      //
      case constants.TRANSACTION_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.TRANSACTION_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.TRANSACTION_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }


      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  