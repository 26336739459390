import React, { useEffect } from "react";
import {
  SETTING_KILLSWITCH_REQUEST,
  SETTING_KILLSWITCH_VIEW_REQUEST,
} from "../../redux/constant/setting";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../components/ui/Button";
import { FormattedMessage } from "react-intl";
import Header from "../../components/ui/Header";
import { MdDangerous } from "react-icons/md";
import { useLocation } from "react-router-dom";

const KillSwitchCard = ({
  sample = false,
  activeStatus = false,
  onClick,
  loading,
  killSwitchButtonLabel = "Insert a staright forward name.",
  desc = "Dear Developer, this is an example of how to implement a new card for a kill switch. Please provide a description for the kill switch, making sure the name is concise and clear.",
  name = "Kill Switch Name",
}) => {
  return (
    <div className="border border-stone-400 bg-white shadow-xl rounded-xl p-3">
      <div className="mb-3 max-w-sm">
        <p
          className={`${
            sample ? "text-gray-400" : "text-stone-700"
          } font-bold mb-1`}
        >
          <FormattedMessage id={name} />
        </p>
        <p className={`${sample ? "text-gray-400" : "text-stone-700"} text-sm`}>
          <FormattedMessage id={desc} />
        </p>
      </div>
      <Button
        disabled={sample}
        type="button"
        onClick={onClick}
        loading={loading}
        iconLeft={<MdDangerous size={"1.5rem"} />}
        className={`${
          activeStatus ? "bg-green-400" : "bg-red-800"
        } text-white hover:bg-red-500 pl-1 pb-1 pt-1 pr-5 text-sm font-bold rounded-md`}
        label={
          <span>
            <FormattedMessage id={killSwitchButtonLabel} />
          </span>
        }
      />
    </div>
  );
};

const KillSwitchPage = () => {
  const { mobileStatus, loading } = useSelector((state: any) => state.setting);
  const dispatch = useDispatch();
  const location = useLocation();
  const killSwitchMobileTempahan = (enable: boolean) => {
    dispatch({
      type: SETTING_KILLSWITCH_REQUEST,
      params: {
        enable: String(enable),
      },
      onCallback: (response: any) => {
        console.log("RESPONSE DARI KILL POST", response);
        if (response.status === 1) {
          reloadStatus();
        }

        if (response.status === 0) {
          reloadStatus();
        }
      },
    });
  };

  const reloadStatus = () =>
    dispatch({
      type: SETTING_KILLSWITCH_VIEW_REQUEST,
      onCallback: (response: any) => {
        if (response.status === 1) {
          // DO WHATEVER U WANT IF THE RESPONSE SUCCESS
        }

        if (response.status === 0) {
          // HANDLE ERROR HERE
        }
      },
    });

  useEffect(() => {
    reloadStatus();
  }, []);

  return (
    <div>
      {/* Header */}
      <Header title={"titles-" + location.pathname} />
      <div className="bg-white shadow-xl rounded-3xl p-5 m-5">
        <div className="flex gap-5 flex-wrap ">
          {/* --- MOBILE TEMPAHAN --- */}
          <KillSwitchCard
            onClick={() => {
              killSwitchMobileTempahan(!mobileStatus);
            }}
            activeStatus={mobileStatus}
            loading={loading}
            name="killSwitch.name.mobile.tempahan"
            desc={`${
              mobileStatus
                ? "enableSwitch.description.mobile.tempahan"
                : "killSwitch.description.mobile.tempahan"
            }`}
            killSwitchButtonLabel={`${
              mobileStatus
                ? "enableSwitch.button.mobile.tempahan"
                : "killSwitch.button.mobile.tempahan"
            }`}
          />
          {/* --- FOR NEXT DEV REFERENCE --- */}
          <KillSwitchCard
            sample={true}
            onClick={killSwitchMobileTempahan}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default KillSwitchPage;
