import * as actions from '../action/roro';
import * as constants from '../constant/roro';

export interface RoroState {
  data: object;
  error: string;
  loading: boolean;
}

export const roroReducer = (
  state: RoroState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.RoroAction) => {    
    switch (action.type) {
      //
      // RORO SEARCH
      //
      case constants.RORO_SEARCH_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.RORO_SEARCH_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.RORO_SEARCH_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // RORO DATATABLE
      //
      case constants.RORO_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.RORO_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.RORO_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // RORO DRAFT
      //
      case constants.RORO_DRAFT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.RORO_DRAFT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.RORO_DRAFT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // RORO EDIT
      //
      case constants.RORO_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.RORO_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.RORO_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // RORO SUBMIT
      //
      case constants.RORO_SUBMIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.RORO_SUBMIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.RORO_SUBMIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // RORO VIEW
      //
      case constants.RORO_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.RORO_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.RORO_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // RORO PAY CREATE
      //
      case constants.RORO_PAY_CREATE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.RORO_PAY_CREATE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.RORO_PAY_CREATE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // RORO PAY REDIRECT
      //
      case constants.RORO_PAY_REDIRECT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.RORO_PAY_REDIRECT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.RORO_PAY_REDIRECT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // RORO DELETE DRAFT
      //
      case constants.RORO_DELETEDRAFT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.RORO_DELETEDRAFT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.RORO_DELETEDRAFT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  