//
// BAYARAN DATATABLE
//
export const BAYARAN_DATATABLE_REQUEST = 'BAYARAN/BAYARAN_DATATABLE_REQUEST';
export const BAYARAN_DATATABLE_SUCCESS = 'BAYARAN/BAYARAN_DATATABLE_SUCCESS';
export const BAYARAN_DATATABLE_FAILURE = 'BAYARAN/BAYARAN_DATATABLE_FAILURE';

//
// BAYARAN CREATE
//
export const BAYARAN_CREATE_REQUEST = 'BAYARAN/BAYARAN_CREATE_REQUEST';
export const BAYARAN_CREATE_SUCCESS = 'BAYARAN/BAYARAN_CREATE_SUCCESS';
export const BAYARAN_CREATE_FAILURE = 'BAYARAN/BAYARAN_CREATE_FAILURE';

//
// BAYARAN DELETE
//
export const BAYARAN_DELETE_REQUEST = 'BAYARAN/BAYARAN_DELETE_REQUEST';
export const BAYARAN_DELETE_SUCCESS = 'BAYARAN/BAYARAN_DELETE_SUCCESS';
export const BAYARAN_DELETE_FAILURE = 'BAYARAN/BAYARAN_DELETE_FAILURE';

//
// BAYARAN SEMAK
//
export const BAYARAN_SEMAK_REQUEST = 'BAYARAN/BAYARAN_SEMAK_REQUEST';
export const BAYARAN_SEMAK_SUCCESS = 'BAYARAN/BAYARAN_SEMAK_SUCCESS';
export const BAYARAN_SEMAK_FAILURE = 'BAYARAN/BAYARAN_SEMAK_FAILURE';

//
// BAYARAN SEARCH
//
export const BAYARAN_SEARCH_REQUEST = 'BAYARAN/BAYARAN_SEARCH_REQUEST';
export const BAYARAN_SEARCH_SUCCESS = 'BAYARAN/BAYARAN_SEARCH_SUCCESS';
export const BAYARAN_SEARCH_FAILURE = 'BAYARAN/BAYARAN_SEARCH_FAILURE';

//
// BAYARAN PAY CREATE
//
export const BAYARAN_PAY_CREATE_REQUEST = 'BAYARAN/BAYARAN_PAY_CREATE_REQUEST';
export const BAYARAN_PAY_CREATE_SUCCESS = 'BAYARAN/BAYARAN_PAY_CREATE_SUCCESS';
export const BAYARAN_PAY_CREATE_FAILURE = 'BAYARAN/BAYARAN_PAY_CREATE_FAILURE';


//
// BAYARAN PAY REDIRECT
//
export const BAYARAN_PAY_REDIRECT_REQUEST = 'BAYARAN/BAYARAN_PAY_REDIRECT_REQUEST';
export const BAYARAN_PAY_REDIRECT_SUCCESS = 'BAYARAN/BAYARAN_PAY_REDIRECT_SUCCESS';
export const BAYARAN_PAY_REDIRECT_FAILURE = 'BAYARAN/BAYARAN_PAY_REDIRECT_FAILURE';

//
// BAYARAN FETCH
//
export const BAYARAN_FETCH_REQUEST = 'BAYARAN/BAYARAN_FETCH_REQUEST';
export const BAYARAN_FETCH_SUCCESS = 'BAYARAN/BAYARAN_FETCH_SUCCESS';
export const BAYARAN_FETCH_FAILURE = 'BAYARAN/BAYARAN_FETCH_FAILURE';

//
// BAYARAN UPDATE
//
export const BAYARAN_UPDATE_REQUEST = 'BAYARAN/BAYARAN_UPDATE_REQUEST';
export const BAYARAN_UPDATE_SUCCESS = 'BAYARAN/BAYARAN_UPDATE_SUCCESS';
export const BAYARAN_UPDATE_FAILURE = 'BAYARAN/BAYARAN_UPDATE_FAILURE';