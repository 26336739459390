import { VscClose } from "react-icons/vsc";

export interface DialogProps {
  title: string;
  content: any;
  width: string;
  height: string;
  onClose?: any;
  actions?: any;
  contentClassName?: any;
  className?: string;
}

const Dialog = ({
  title,
  content,
  width,
  height,
  onClose,
  actions,
  contentClassName,
  className,
}: DialogProps) => {
  return (
    <div className="fixed z-50 inset-0 bg-gray-600  bg-opacity-50 h-full w-full">
      <div
        className={`relative top-5 xl:top-20 mx-auto pt-3 pl-1 pr-1 border shadow-lg rounded-md bg-white  ${className}`}
        style={{ width: `${width}`, height: `${height}` }}
      >
        <div className="flex justify-between p-3 border-b-2 border-gray-200">
          <h2 className="text-lg font-bold">{title}</h2>
          {onClose && (
            <VscClose
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                onClose();
              }}
              size={"30px"}
            />
          )}
        </div>
        <div className={contentClassName}>{content}</div>
        {actions && (
          <div className="flex justify-center space-x-3 mt-3">
            {actions.map((action: any) => {
              return <span key={Math.random()}>{action}</span>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dialog;
