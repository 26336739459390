import * as actions from "../action/cukai";
import * as constants from "../constant/cukai";

//
// CUKAI Search
//
export function CukaiSearchRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiSearchRequestAction {
  return {
    type: constants.CUKAI_SEARCH_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiSearchSuccess(
  data: object
): actions.CukaiSearchSuccessAction {
  return {
    type: constants.CUKAI_SEARCH_SUCCESS,
    data,
  };
}
export function CukaiSearchFailure(
  error: string
): actions.CukaiSearchFailureAction {
  return {
    type: constants.CUKAI_SEARCH_FAILURE,
    error,
  };
}

//
// CUKAI Datatable
//
export function CukaiDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiDatatableRequestAction {
  return {
    type: constants.CUKAI_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiDatatableSuccess(
  data: object
): actions.CukaiDatatableSuccessAction {
  return {
    type: constants.CUKAI_DATATABLE_SUCCESS,
    data,
  };
}
export function CukaiDatatableFailure(
  error: string
): actions.CukaiDatatableFailureAction {
  return {
    type: constants.CUKAI_DATATABLE_FAILURE,
    error,
  };
}

//
// CUKAI Price
//
export function CukaiPriceRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiPriceRequestAction {
  return {
    type: constants.CUKAI_PRICE_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiPriceSuccess(
  data: object
): actions.CukaiPriceSuccessAction {
  return {
    type: constants.CUKAI_PRICE_SUCCESS,
    data,
  };
}
export function CukaiPriceFailure(
  error: string
): actions.CukaiPriceFailureAction {
  return {
    type: constants.CUKAI_PRICE_FAILURE,
    error,
  };
}

//
// CUKAI Draft
//
export function CukaiDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiDraftRequestAction {
  return {
    type: constants.CUKAI_DRAFT_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiDraftSuccess(
  data: object
): actions.CukaiDraftSuccessAction {
  return {
    type: constants.CUKAI_DRAFT_SUCCESS,
    data,
  };
}
export function CukaiDraftFailure(
  error: string
): actions.CukaiDraftFailureAction {
  return {
    type: constants.CUKAI_DRAFT_FAILURE,
    error,
  };
}

//
// CUKAI Edit
//
export function CukaiEditRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiEditRequestAction {
  return {
    type: constants.CUKAI_EDIT_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiEditSuccess(data: object): actions.CukaiEditSuccessAction {
  return {
    type: constants.CUKAI_EDIT_SUCCESS,
    data,
  };
}
export function CukaiEditFailure(
  error: string
): actions.CukaiEditFailureAction {
  return {
    type: constants.CUKAI_EDIT_FAILURE,
    error,
  };
}

//
// CUKAI Submit
//
export function CukaiSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiSubmitRequestAction {
  return {
    type: constants.CUKAI_SUBMIT_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiSubmitSuccess(
  data: object
): actions.CukaiSubmitSuccessAction {
  return {
    type: constants.CUKAI_SUBMIT_SUCCESS,
    data,
  };
}
export function CukaiSubmitFailure(
  error: string
): actions.CukaiSubmitFailureAction {
  return {
    type: constants.CUKAI_SUBMIT_FAILURE,
    error,
  };
}

//
// CUKAI View
//
export function CukaiViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewRequestAction {
  return {
    type: constants.CUKAI_VIEW_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiViewSuccess(data: object): actions.CukaiViewSuccessAction {
  return {
    type: constants.CUKAI_VIEW_SUCCESS,
    data,
  };
}
export function CukaiViewFailure(
  error: string
): actions.CukaiViewFailureAction {
  return {
    type: constants.CUKAI_VIEW_FAILURE,
    error,
  };
}

//
// CUKAI Delete Draft
//
export function CukaiDeleteDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiDeleteDraftRequestAction {
  return {
    type: constants.CUKAI_DELETEDRAFT_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiDeleteDraftSuccess(
  data: object
): actions.CukaiDeleteDraftSuccessAction {
  return {
    type: constants.CUKAI_DELETEDRAFT_SUCCESS,
    data,
  };
}
export function CukaiDeleteDraftFailure(
  error: string
): actions.CukaiDeleteDraftFailureAction {
  return {
    type: constants.CUKAI_DELETEDRAFT_FAILURE,
    error,
  };
}

//
// CUKAI Pay Create
//
export function CukaiPayCreateRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiPayCreateRequestAction {
  return {
    type: constants.CUKAI_PAY_CREATE_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiPayCreateSuccess(
  data: object
): actions.CukaiPayCreateSuccessAction {
  return {
    type: constants.CUKAI_PAY_CREATE_SUCCESS,
    data,
  };
}
export function CukaiPayCreateFailure(
  error: string
): actions.CukaiPayCreateFailureAction {
  return {
    type: constants.CUKAI_PAY_CREATE_FAILURE,
    error,
  };
}

//
// CUKAI Pay Redirect
//
export function CukaiPayRedirectRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiPayRedirectRequestAction {
  return {
    type: constants.CUKAI_PAY_REDIRECT_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiPayRedirectSuccess(
  data: object
): actions.CukaiPayRedirectSuccessAction {
  return {
    type: constants.CUKAI_PAY_REDIRECT_SUCCESS,
    data,
  };
}
export function CukaiPayRedirectFailure(
  error: string
): actions.CukaiPayRedirectFailureAction {
  return {
    type: constants.CUKAI_PAY_REDIRECT_FAILURE,
    error,
  };
}

//
// SENARAI AKAUN
//
export function CukaiAccountDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiAccountDatatableRequestAction {
  return {
    type: constants.CUKAI_ACCOUNT_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiAccountDatatableSuccess(
  data: object
): actions.CukaiAccountDatatableSuccessAction {
  return {
    type: constants.CUKAI_ACCOUNT_DATATABLE_SUCCESS,
    data,
  };
}
export function CukaiAccountDatatableFailure(
  error: string
): actions.CukaiAccountDatatableFailureAction {
  return {
    type: constants.CUKAI_ACCOUNT_DATATABLE_FAILURE,
    error,
  };
}

//
// CUKAI View Account
//
export function CukaiViewBillRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewBillRequestAction {
  return {
    type: constants.CUKAI_VIEW_BILL_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiViewBillSuccess(
  data: object
): actions.CukaiViewBillSuccessAction {
  return {
    type: constants.CUKAI_VIEW_BILL_SUCCESS,
    data,
  };
}
export function CukaiViewBillFailure(
  error: string
): actions.CukaiViewBillFailureAction {
  return {
    type: constants.CUKAI_VIEW_BILL_FAILURE,
    error,
  };
}

//
// CUKAI View Account Info
//
export function CukaiViewAccountInfoRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewAccountInfoRequestAction {
  return {
    type: constants.CUKAI_VIEW_ACCOUNT_INFO_REQUEST,
    params,
    onCallback,
  };
}
export function CukaiViewAccountInfoSuccess(
  data: object
): actions.CukaiViewAccountInfoSuccessAction {
  return {
    type: constants.CUKAI_VIEW_ACCOUNT_INFO_SUCCESS,
    data,
  };
}
export function CukaiViewAccountInfoFailure(
  error: string
): actions.CukaiViewAccountInfoFailureAction {
  return {
    type: constants.CUKAI_VIEW_ACCOUNT_INFO_FAILURE,
    error,
  };
}

//
// PINDAHMILIK SUBMIT
//
export function CukaiPindahmilikSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiPindahmilikSubmitRequestAction {
  return {
    type: constants.CUKAI_PINDAHMILIK_SUBMIT_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiPindahmilikSubmitSuccess(
  data: object
): actions.CukaiPindahmilikSubmitSuccessAction {
  return {
    type: constants.CUKAI_PINDAHMILIK_SUBMIT_SUCCESS,
    data,
  };
}

export function CukaiPindahmilikSubmitFailure(
  error: string
): actions.CukaiPindahmilikSubmitFailureAction {
  return {
    type: constants.CUKAI_PINDAHMILIK_SUBMIT_FAILURE,
    error,
  };
}

//
// KEMASKINI SUBMIT
//
export function CukaiKemaskiniSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiKemaskiniSubmitRequestAction {
  return {
    type: constants.CUKAI_KEMASKINI_SUBMIT_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiKemaskiniSubmitSuccess(
  data: object
): actions.CukaiKemaskiniSubmitSuccessAction {
  return {
    type: constants.CUKAI_KEMASKINI_SUBMIT_SUCCESS,
    data,
  };
}

export function CukaiKemaskiniSubmitFailure(
  error: string
): actions.CukaiKemaskiniSubmitFailureAction {
  return {
    type: constants.CUKAI_KEMASKINI_SUBMIT_FAILURE,
    error,
  };
}


//
// PENGURANGAN SUBMIT
//
export function CukaiPenguranganSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiPenguranganSubmitRequestAction {
  return {
    type: constants.CUKAI_PENGURANGAN_SUBMIT_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiPenguranganSubmitSuccess(
  data: object
): actions.CukaiPenguranganSubmitSuccessAction {
  return {
    type: constants.CUKAI_PENGURANGAN_SUBMIT_SUCCESS,
    data,
  };
}

export function CukaiPenguranganSubmitFailure(
  error: string
): actions.CukaiPenguranganSubmitFailureAction {
  return {
    type: constants.CUKAI_PENGURANGAN_SUBMIT_FAILURE,
    error,
  };
}

//
// CUKAI VIEW BY ACCOUNT NO
//
export function CukaiViewByAccountNoRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewByAccountNoRequestAction {
  return {
    type: constants.CUKAI_VIEW_BY_ACCOUNT_NO_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiViewByAccountNoSuccess(
  data: object
): actions.CukaiViewByAccountNoSuccessAction {
  return {
    type: constants.CUKAI_VIEW_BY_ACCOUNT_NO_SUCCESS,
    data,
  };
}

export function CukaiViewByAccountNoFailure(
  error: string
): actions.CukaiViewByAccountNoFailureAction {
  return {
    type: constants.CUKAI_VIEW_BY_ACCOUNT_NO_FAILURE,
    error,
  };
}

//
// CUKAI VIEW LIST PERMOHONAN
//
export function CukaiViewListPermohonanRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewListPermohonanRequestAction {
  return {
    type: constants.CUKAI_VIEW_LIST_PERMOHONAN_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiViewListPermohonanSuccess(
  data: object
): actions.CukaiViewListPermohonanSuccessAction {
  return {
    type: constants.CUKAI_VIEW_LIST_PERMOHONAN_SUCCESS,
    data,
  };
}

export function CukaiViewListPermohonanFailure(
  error: string
): actions.CukaiViewListPermohonanFailureAction {
  return {
    type: constants.CUKAI_VIEW_LIST_PERMOHONAN_FAILURE,
    error,
  };
}

//
// CUKAI VIEW LIST PINDAHMILIK
//
export function CukaiViewListPindahmilikRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewListPindahmilikRequestAction {
  return {
    type: constants.CUKAI_VIEW_LIST_PINDAHMILIK_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiViewListPindahmilikSuccess(
  data: object
): actions.CukaiViewListPindahmilikSuccessAction {
  return {
    type: constants.CUKAI_VIEW_LIST_PINDAHMILIK_SUCCESS,
    data,
  };
}

export function CukaiViewListPindahmilikFailure(
  error: string
): actions.CukaiViewListPindahmilikFailureAction {
  return {
    type: constants.CUKAI_VIEW_LIST_PINDAHMILIK_FAILURE,
    error,
  };
}

//
// CUKAI VIEW LIST KEMASKINI
//
export function CukaiViewListKemaskiniRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewListKemaskiniRequestAction {
  return {
    type: constants.CUKAI_VIEW_LIST_KEMASKINI_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiViewListKemaskiniSuccess(
  data: object
): actions.CukaiViewListKemaskiniSuccessAction {
  return {
    type: constants.CUKAI_VIEW_LIST_KEMASKINI_SUCCESS,
    data,
  };
}

export function CukaiViewListKemaskiniFailure(
  error: string
): actions.CukaiViewListKemaskiniFailureAction {
  return {
    type: constants.CUKAI_VIEW_LIST_KEMASKINI_FAILURE,
    error,
  };
}

//
// CUKAI VIEW LIST PENGURANGAN
//
export function CukaiViewListPenguranganRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewListPenguranganRequestAction {
  return {
    type: constants.CUKAI_VIEW_LIST_PENGURANGAN_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiViewListPenguranganSuccess(
  data: object
): actions.CukaiViewListPenguranganSuccessAction {
  return {
    type: constants.CUKAI_VIEW_LIST_PENGURANGAN_SUCCESS,
    data,
  };
}

export function CukaiViewListPenguranganFailure(
  error: string
): actions.CukaiViewListPenguranganFailureAction {
  return {
    type: constants.CUKAI_VIEW_LIST_PENGURANGAN_FAILURE,
    error,
  };
}

//
// CUKAI VIEW DRAF PINDAHMILIK
//
export function CukaiViewDrafPindahmilikRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewDrafPindahmilikRequestAction {
  return {
    type: constants.CUKAI_VIEW_DRAF_PINDAHMILIK_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiViewDrafPindahmilikSuccess(
  data: object
): actions.CukaiViewDrafPindahmilikSuccessAction {
  return {
    type: constants.CUKAI_VIEW_DRAF_PINDAHMILIK_SUCCESS,
    data,
  };
}

export function CukaiViewDrafPindahmilikFailure(
  error: string
): actions.CukaiViewDrafPindahmilikFailureAction {
  return {
    type: constants.CUKAI_VIEW_DRAF_PINDAHMILIK_FAILURE,
    error,
  };
}

//
// CUKAI VIEW DRAF KEMASKINI
//
export function CukaiViewDrafKemaskiniRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewDrafKemaskiniRequestAction {
  return {
    type: constants.CUKAI_VIEW_DRAF_KEMASKINI_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiViewDrafKemaskiniSuccess(
  data: object
): actions.CukaiViewDrafKemaskiniSuccessAction {
  return {
    type: constants.CUKAI_VIEW_DRAF_KEMASKINI_SUCCESS,
    data,
  };
}

export function CukaiViewDrafKemaskiniFailure(
  error: string
): actions.CukaiViewDrafKemaskiniFailureAction {
  return {
    type: constants.CUKAI_VIEW_DRAF_KEMASKINI_FAILURE,
    error,
  };
}

//
// CUKAI VIEW DRAF PENGURANGAN
//
export function CukaiViewDrafPenguranganRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.CukaiViewDrafPenguranganRequestAction {
  return {
    type: constants.CUKAI_VIEW_DRAF_PENGURANGAN_REQUEST,
    params,
    onCallback,
  };
}

export function CukaiViewDrafPenguranganSuccess(
  data: object
): actions.CukaiViewDrafPenguranganSuccessAction {
  return {
    type: constants.CUKAI_VIEW_DRAF_PENGURANGAN_SUCCESS,
    data,
  };
}

export function CukaiViewDrafPenguranganFailure(
  error: string
): actions.CukaiViewDrafPenguranganFailureAction {
  return {
    type: constants.CUKAI_VIEW_DRAF_PENGURANGAN_FAILURE,
    error,
  };
}