import { DEFAULT_LOCALE } from '../../config';
import * as actions from '../action/customize';
import * as constants from '../constant/customize';

export interface CustomizeState {
  locale: string;
  type?: string;
  id?: string;
  opened: boolean;
  error: string;
  loading: boolean;
}

export const customizeReducer = (
  state: CustomizeState = {
    opened: true,
    locale: DEFAULT_LOCALE,
    error: "",
    loading: false
  },
  action: actions.CustomizeAction) => {    
    switch (action.type) {
      
      //
      // CUSTOMIZE
      //
      case constants.CUSTOMIZE_OPENMENU: {
        return {
            ...state,
            isOpen: [action.id]
        };
      }
      case constants.CUSTOMIZE_SETLOCALE: {
        return {
          ...state,
          locale: action.locale
        };
      }
      case constants.CUSTOMIZE_RELOCALE: {
        return {
          ...state,
          locale: action.locale
        };
      }
      case constants.CUSTOMIZE_SETMENU: {
        return {
            ...state,
            opened: action.opened
        };
      }
      case constants.CUSTOMIZE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ""
        };
      }
      case constants.CUSTOMIZE_SUCCESS: {
        const data = action.data as any;
        const locale = data.locale;
        return {
          ...state,
          locale: locale,
          loading: false,
        };
      }
      case constants.CUSTOMIZE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  