import * as actions from '../action/kpm';
import * as constants from '../constant/kpm';


//
// KPM Search
//
export function KpmSearchRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmSearchRequestAction {
    return {
        type: constants.KPM_SEARCH_REQUEST,
        params,
        onCallback
    }
}
export function KpmSearchSuccess(data: object): actions.KpmSearchSuccessAction {
    return {
        type: constants.KPM_SEARCH_SUCCESS,
        data
    }
}
export function KpmSearchFailure(error: string): actions.KpmSearchFailureAction {
    return {
        type: constants.KPM_SEARCH_FAILURE,
        error
    }
}


//
// KPM Datatable
//
export function KpmDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmDatatableRequestAction {
    return {
        type: constants.KPM_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function KpmDatatableSuccess(data: object): actions.KpmDatatableSuccessAction {
    return {
        type: constants.KPM_DATATABLE_SUCCESS,
        data
    }
}
export function KpmDatatableFailure(error: string): actions.KpmDatatableFailureAction {
    return {
        type: constants.KPM_DATATABLE_FAILURE,
        error
    }
}


//
// KPM Draft
//
export function KpmDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmDraftRequestAction {
    return {
        type: constants.KPM_DRAFT_REQUEST,
        params,
        onCallback
    }
}
export function KpmDraftSuccess(data: object): actions.KpmDraftSuccessAction {
    return {
        type: constants.KPM_DRAFT_SUCCESS,
        data
    }
}
export function KpmDraftFailure(error: string): actions.KpmDraftFailureAction {
    return {
        type: constants.KPM_DRAFT_FAILURE,
        error
    }
}


//
// KPM Edit
//
export function KpmEditRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmEditRequestAction {
    return {
        type: constants.KPM_EDIT_REQUEST,
        params,
        onCallback
    }
}
export function KpmEditSuccess(data: object): actions.KpmEditSuccessAction {
    return {
        type: constants.KPM_EDIT_SUCCESS,
        data
    }
}
export function KpmEditFailure(error: string): actions.KpmEditFailureAction {
    return {
        type: constants.KPM_EDIT_FAILURE,
        error
    }
}


//
// KPM Submit
//
export function KpmSubmitRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmSubmitRequestAction {
    return {
        type: constants.KPM_SUBMIT_REQUEST,
        params,
        onCallback
    }
}
export function KpmSubmitSuccess(data: object): actions.KpmSubmitSuccessAction {
    return {
        type: constants.KPM_SUBMIT_SUCCESS,
        data
    }
}
export function KpmSubmitFailure(error: string): actions.KpmSubmitFailureAction {
    return {
        type: constants.KPM_SUBMIT_FAILURE,
        error
    }
}

//
// KPM View
//
export function KpmPdfRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmPdfRequestAction {
    return {
        type: constants.KPM_PDF_REQUEST,
        params,
        onCallback
    }
}
export function KpmPdfSuccess(data: object): actions.KpmPdfSuccessAction {
    return {
        type: constants.KPM_PDF_SUCCESS,
        data
    }
}
export function KpmPdfFailure(error: string): actions.KpmPdfFailureAction {
    return {
        type: constants.KPM_PDF_FAILURE,
        error
    }
}

//
// KPM View
//
export function KpmViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmViewRequestAction {
    return {
        type: constants.KPM_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function KpmViewSuccess(data: object): actions.KpmViewSuccessAction {
    return {
        type: constants.KPM_VIEW_SUCCESS,
        data
    }
}
export function KpmViewFailure(error: string): actions.KpmViewFailureAction {
    return {
        type: constants.KPM_VIEW_FAILURE,
        error
    }
}

//
// KPM DELETE DRAFT
//
export function KpmDeleteDraftRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmDeleteDraftRequestAction {
    return {
        type: constants.KPM_DELETEDRAFT_REQUEST,
        params,
        onCallback
    }
}
export function KpmDeleteDraftSuccess(data: object): actions.KpmDeleteDraftSuccessAction {
    return {
        type: constants.KPM_DELETEDRAFT_SUCCESS,
        data
    }
}
export function KpmDeleteDraftFailure(error: string): actions.KpmDeleteDraftFailureAction {
    return {
        type: constants.KPM_DELETEDRAFT_FAILURE,
        error
    }
}


//
// KPM CREATE
//
export function KpmPayCreateRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmPayCreateRequestAction {
    return {
        type: constants.KPM_PAY_CREATE_REQUEST,
        params,
        onCallback
    }
}
export function KpmPayCreateSuccess(data: object): actions.KpmPayCreateSuccessAction {
    return {
        type: constants.KPM_PAY_CREATE_SUCCESS,
        data
    }
}
export function KpmPayCreateFailure(error: string): actions.KpmPayCreateFailureAction {
    return {
        type: constants.KPM_PAY_CREATE_FAILURE,
        error
    }
}

//
// KPM REDIRECT
//
export function KpmPayRedirectRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmPayRedirectRequestAction {
    return {
        type: constants.KPM_PAY_REDIRECT_REQUEST,
        params,
        onCallback
    }
}
export function KpmPayRedirectSuccess(data: object): actions.KpmPayRedirectSuccessAction {
    return {
        type: constants.KPM_PAY_REDIRECT_SUCCESS,
        data
    }
}
export function KpmPayRedirectFailure(error: string): actions.KpmPayRedirectFailureAction {
    return {
        type: constants.KPM_PAY_REDIRECT_FAILURE,
        error
    }
}

export function EpbtReset(): actions.KpmResetAction {
    return {
        type: constants.KPM_RESET
    }
}

//
// KPM PANEL
//
export function KpmPanelRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmPanelRequestAction {
    return {
        type: constants.KPM_PANEL_REQUEST,
        params,
        onCallback
    }
}
export function KpmPanelSuccess(data: object): actions.KpmPanelSuccessAction {
    return {
        type: constants.KPM_PANEL_SUCCESS,
        data
    }
}
export function KpmPanelFailure(error: string): actions.KpmPanelFailureAction {
    return {
        type: constants.KPM_PANEL_FAILURE,
        error
    }
}

//
// KPM KLINIK
//
export function KpmKlinikRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmKlinikRequestAction {
    return {
        type: constants.KPM_KLINIK_REQUEST,
        params,
        onCallback
    }
}
export function KpmKlinikSuccess(data: object): actions.KpmKlinikSuccessAction {
    return {
        type: constants.KPM_KLINIK_SUCCESS,
        data
    }
}
export function KpmKlinikFailure(error: string): actions.KpmKlinikFailureAction {
    return {
        type: constants.KPM_KLINIK_FAILURE,
        error
    }
}


//
// KPM Premise Type
//
export function KpmPremiseTypeRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.KpmPremiseTypeRequestAction {
    return {
        type: constants.KPM_PREMISETYPE_REQUEST,
        params,
        onCallback
    }
}
export function KpmPremiseTypeSuccess(data: object): actions.KpmPremiseTypeSuccessAction {
    return {
        type: constants.KPM_PREMISETYPE_SUCCESS,
        data
    }
}
export function KpmPremiseTypeFailure(error: string): actions.KpmPremiseTypeFailureAction {
    return {
        type: constants.KPM_PREMISETYPE_FAILURE,
        error
    }
}
