import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/bayaran";
import * as constants from "../constant/bayaran";
import * as functions from "../function/bayaran";

//
// BAYARAN DATATABLE
//
function* getBayaranDatatable({
  params,
  onCallback,
}: actions.BayaranDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/bayaran/akaun`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.BayaranDatatableSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.BayaranDatatableFailure(error));
  }
}

//
// BAYARAN CREATE
//
function* getBayaranCreate({
  params,
  onCallback,
}: actions.BayaranCreateRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/bayaran/akaun`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.BayaranCreateSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.BayaranCreateFailure(error));
  }
}

//
// BAYARAN DELETE
//
function* getBayaranDelete({
  id,
  params,
  onCallback,
}: actions.BayaranDeleteRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/bayaran/akaun/${id}`,
    method: "DELETE",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.BayaranDeleteSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.BayaranDeleteFailure(error));
  }
}

//
// BAYARAN SEMAK
//
function* getBayaranSemak({
  params,
  onCallback,
}: actions.BayaranSemakRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/bayaran/semak`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.BayaranSemakSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.BayaranSemakFailure(error));
  }
}

//
// BAYARAN SEARCH
//
function* getBayaranSearch({
  params,
  onCallback,
}: actions.BayaranSearchRequestAction) {
  // SEKATAN
  if (params.type === "sekatan") {
    const { status, data, error } = yield call(axios.call, {
      url: `/sekat/carianById`,
      method: "GET",
      params: {
        carian: params.accNo,
      },
    });

    if (status === 200) {
      onCallback({
        status: 1,
        data: data,
      });
      yield put(functions.BayaranSearchSuccess(data));
    } else {
      onCallback({
        status: 0,
        error: error,
      });
      yield put(functions.BayaranSearchFailure(error));
    }
  }

  // CAGARAN
  if (params.type === "cagaran") {
    const { status, data, error } = yield call(axios.call, {
      url: `/cagaran/viewByNoBil`,
      method: "GET",
      params: {
        no_bil: params.accNo,
      },
    });

    if (status === 200) {
      onCallback({
        status: 1,
        data: data,
      });
      yield put(functions.BayaranSearchSuccess(data));
    } else {
      onCallback({
        status: 0,
        error: error,
      });
      yield put(functions.BayaranSearchFailure(error));
    }
  }

  // KOMPAUN
  if (params.type === "kompaun") {
    const { status, data, error } = yield call(axios.call, {
      url: `/kompaun/list/${params.accNo}`,
      method: "GET",
      data: params,
    });

    if (status === 200) {
      onCallback({
        status: 1,
        data: data,
      });
      yield put(functions.BayaranSearchSuccess(data));
    } else {
      onCallback({
        status: 0,
        error: error,
      });
      yield put(functions.BayaranSearchFailure(error));
    }
  }

  // BIL
  if (params.type === "bil") {
    const { status, data, error } = yield call(axios.call, {
      url: `/bil/view/${params.accNo}`,
      method: "GET",
    });
    if (status === 200) {
      onCallback({
        status: 1,
        data: data,
      });
      yield put(functions.BayaranSearchSuccess(data));
    } else {
      onCallback({
        status: 0,
        error: error,
      });
      yield put(functions.BayaranSearchFailure(error));
    }
  }

  // CUKAI /cukai/list-by-akaun/
  if (params.type === "cukai") {
    const { status, data, error } = yield call(axios.call, {
      url: `/cukai/list-by-akaun/${params.accNo}`,
      method: "GET",
    });
    if (status === 200) {
      onCallback({
        status: 1,
        data: data,
      });
      yield put(functions.BayaranSearchSuccess(data));
    } else {
      onCallback({
        status: 0,
        error: error,
      });
      yield put(functions.BayaranSearchFailure(error));
    }
  }

  // LESEN
  if (params.type === "lesen") {
    const { status, data, error } = yield call(axios.call, {
      url: `/lesen/search`,
      method: "GET",
      params: {
        search: params.accNo,
      },
    });
    if (status === 200) {
      onCallback({
        status: 1,
        data: data,
      });
      yield put(functions.BayaranSearchSuccess(data));
    } else {
      onCallback({
        status: 0,
        error: error,
      });
      yield put(functions.BayaranSearchFailure(error));
    }
  }

  // SEWAAN
  if (params.type === "sewaan") {
    const { status, data, error } = yield call(axios.call, {
      url: `/sewaan/list`,
      method: "GET",
      params: {
        no_akaun: params.accNo,
      },
    });

    console.log("AIJIGSJGDISPIRSPOFDF", status, data);
    if (status === 200) {
      onCallback({
        status: 1,
        data: data,
      });
      yield put(functions.BayaranSearchSuccess(data));
    } else {
      onCallback({
        status: 0,
        error: error,
      });
      yield put(functions.BayaranSearchFailure(error));
    }
  }
}

//
// BAYARAN PAY CREATE
//
function* getBayaranPayCreate({
  params,
  onCallback,
}: actions.BayaranPayCreateRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/bayaran/payment/create`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.BayaranPayCreateSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.BayaranPayCreateFailure(error));
  }
}

//
// BAYARAN PAY REDIRECT
//
function* getBayaranPayRedirect({
  params,
  onCallback,
}: actions.BayaranPayRedirectRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/bayaran/payment/redirect`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.BayaranPayRedirectSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.BayaranPayRedirectFailure(error));
  }
}

//
// BAYARAN FETCH
//
function* getBayaranFetch({
  params,
  onCallback,
}: actions.BayaranFetchRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/bayaran/akaun/` + params.id,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.BayaranFetchSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.BayaranFetchFailure(error));
  }
}

//
// BAYARAN UPDATE
//
function* getBayaranUpdate({
  params,
  onCallback,
}: actions.BayaranUpdateRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/bayaran/akaun`,
    method: "PUT",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.BayaranUpdateSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.BayaranUpdateFailure(error));
  }
}

export function* watchBayaran() {
  yield takeLatest(constants.BAYARAN_DATATABLE_REQUEST, getBayaranDatatable);
  yield takeLatest(constants.BAYARAN_CREATE_REQUEST, getBayaranCreate);
  yield takeLatest(constants.BAYARAN_DELETE_REQUEST, getBayaranDelete);
  yield takeLatest(constants.BAYARAN_SEMAK_REQUEST, getBayaranSemak);
  yield takeLatest(constants.BAYARAN_SEARCH_REQUEST, getBayaranSearch);
  yield takeLatest(constants.BAYARAN_PAY_CREATE_REQUEST, getBayaranPayCreate);
  yield takeLatest(
    constants.BAYARAN_PAY_REDIRECT_REQUEST,
    getBayaranPayRedirect
  );
  yield takeLatest(constants.BAYARAN_FETCH_REQUEST, getBayaranFetch);
  yield takeLatest(constants.BAYARAN_UPDATE_REQUEST, getBayaranUpdate);
}
