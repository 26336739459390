import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/psbuku';
import * as constants from '../constant/psbuku';
import * as functions from '../function/psbuku';



//
// Ps Buku Verify Paid
//
function* getPsBukuVerifyPaid({params, onCallback}: actions.PsBukuVerifyPaidRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/buku/verifyPaid`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsBukuVerifyIsPaidSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsBukuVerifyIsPaidFailure(error));
    }
    
};


//
// Ps Buku Create
//
function* getPsBukuCreate({params, onCallback}: actions.PsBukuCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/buku/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsBukuCreateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsBukuCreateFailure(error));
    }
    
};


//
// Ps Buku View
//
function* getPsBukuView({params, onCallback}: actions.PsBukuViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/buku/view`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsBukuViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsBukuViewFailure(error));
    }
    
};


export function* watchPsBuku() {
    
    yield takeLatest(constants.PSBUKU_VERIFYPAID_REQUEST, getPsBukuVerifyPaid);
    yield takeLatest(constants.PSBUKU_CREATE_REQUEST, getPsBukuCreate);
    yield takeLatest(constants.PSBUKU_VIEW_REQUEST, getPsBukuView);

};


