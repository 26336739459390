import {
  BsApple,
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsWhatsapp,
  BsYoutube,
} from "react-icons/bs";
import { CgLock, CgMenu, CgUserAdd } from "react-icons/cg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import { AiOutlineUser } from "react-icons/ai";
import Announcement from "../components/Announcement";
import AppRoutes from "../../../AppRoutes";
import { BiPencil } from "react-icons/bi";
import CarouselImage from "../components/CarouselImage";
import Dialog from "../../../components/ui/Dialog";
import FloatingMenu from "../components/FloatingMenu";
import { FormattedMessage } from "react-intl";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import SelectLocale from "../components/SelectLocale";
import logo from "../../../assets/icons/svg/Logo.svg";
import logoMbsa from "../../../assets/logo/logo-mbsa.png";
import sectigo from "../../../assets/logo/geotrust.png";
import useLoadChatBot from "../../../utils/load-chatbot";
import useMediaQuery from "../../../components/useMediaQuery";
import { useSelector } from "react-redux";
import ver from "../../../assets/version.json";

const PublicLayout = () => {
  const location = useLocation();
  const isHome = location && location.pathname && location.pathname === "/";
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const chatBot = useLoadChatBot(location.pathname);
  const { version } = useSelector((state: any) => state.version);
  const [displayDialog, setDisplayDialog] = useState(true);

  const selectLocaleRef = React.createRef<HTMLDivElement>();

  const whiteProps = {
    whiteMode: true,
  };

  return (
    <div>
      {chatBot}
      {/* TEMPORARY WARNING START HERE IZZ 140924*/}
      {displayDialog && (
        <Dialog
          title="PENGUMUMAN"
          width=""
          height=""
          onClose={() => setDisplayDialog(false)}
          content={
            <div className="py-3 px-10 flex flex-col justify-center items-center">
              <img
                src={logoMbsa}
                className="w-14 h-14 m-5"
                alt="MBSA Logo"
              />
              <p className="mb-5 text-center text-3xl text-red-700 font-bold">
                NOTIS MAKLUMAN
              </p>
              <p className="mb-1 text-center text-xl">
                <FormattedMessage id="notice.maintenance.tempahan.line1" />
              </p>
              <p className="mb-1 text-center text-xl">
                <FormattedMessage id="notice.maintenance.line3" />
              </p>
              <p className="mb-5 text-center text-xl">
                <FormattedMessage id="thank.you" />
              </p>
            </div>
          }
          className="dialog-custom"
        />
      )}
      {/* TEMPORARY WARNING END HERE IZZ 140924*/}
      {/* header */}
      <div className="bg-mymbsa-primary">
        <div className="container mx-auto">
          <header className="bg-mymbsa-primary">
            <nav className="pl-5 pr-5 pt-5 pb-5">
              {isMobile && (
                <div className="flex item-between content-center w-full">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                      window.location.reload();
                    }}
                    className="w-2/3 pt-2 xs:w-full flex justify-start justify-items-center"
                  >
                    <img
                      src={logoMbsa}
                      className="w-14 h-14 mr-4"
                      alt="MBSA Logo"
                    />
                    <img
                      src={logo}
                      className="w-1/2 mr-4"
                      alt="MBSA Logo"
                    />
                  </a>
                  <span className="w-1/3 pt-2">
                    <div
                      className="flex items-center justify-end h-16 font-semibold text-asean-primary-500 hover:cursor-pointer"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      <CgMenu
                        size="2rem"
                        className="float-right text-white"
                      />
                    </div>
                  </span>
                </div>
              )}
              {!isMobile && (
                <div className="flex justify-between items-center w-full">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                      window.location.reload();
                    }}
                    className="w-4/12 xs:w-full flex justify-start justify-items-center"
                  >
                    <img
                      src={logoMbsa}
                      className="w-14 mr-4"
                      alt="MBSA Logo"
                    />
                    <img
                      src={logo}
                      className="w-1/2 mr-4"
                      alt="MBSA Logo"
                    />
                  </a>
                  <div className="w-8/12 flex flex-wrap justify-end justify-items-center items-center">
                    <div className="">
                      <Link
                        to="login"
                        className="font-bold flex justify-center items-center text-white"
                      >
                        <AiOutlineUser size="24" />
                        <span className="ml-2 mr-4 text-sm">
                          <FormattedMessage id="header.login" />
                        </span>
                      </Link>
                    </div>
                    <div className="">
                      <Link
                        to="registration"
                        className="font-bold flex justify-center items-center text-white"
                      >
                        <BiPencil size="24" />
                        <span className="ml-2 mr-4 text-sm">
                          <FormattedMessage id="header.register" />
                        </span>
                      </Link>
                    </div>

                    <div className="">
                      <SelectLocale
                        ref={selectLocaleRef}
                        {...whiteProps}
                      />
                    </div>
                  </div>
                </div>
              )}
            </nav>
          </header>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-96"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasRightLabel"
          >
            Menu
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto">
          <div className="flex flex-col">
            <div className="">
              <a
                data-bs-dismiss="offcanvas"
                onClick={(e) => navigate("/login")}
                className="font-bold flex mb-2 text-mymbsa-primary"
              >
                <CgLock size="24" />
                <span className="ml-2 mr-4 text-sm">
                  <FormattedMessage id="header.login" />
                </span>
              </a>
            </div>
            <div className="">
              <a
                data-bs-dismiss="offcanvas"
                onClick={(e) => navigate("/registration")}
                className="font-bold flex mb-2 text-mymbsa-primary"
              >
                <CgUserAdd size="24" />
                <span className="ml-2 mr-4 text-sm">
                  <FormattedMessage id="header.register" />
                </span>
              </a>
            </div>

            <div className="">
              <SelectLocale ref={selectLocaleRef} />
            </div>
          </div>
        </div>
      </div>

      {/* floating menu */}
      <FloatingMenu />

      {/* body */}
      {/* <main style={{ height: isHome ? "h-full" : "100vh" }}> */}
      {isHome ? <CarouselImage /> : <AppRoutes />}
      {/* </main> */}

      {/* announcement */}
      {isHome && (
        <div className="container mx-auto">
          <Announcement />
        </div>
      )}

      {/* footer */}
      <footer className="flex flex-col justify-end justify-items-center text-center w-full pt-2 text-white bg-mymbsa-primary ">
        <div className="container mx-auto xs:px-5">
          <div className="grid grid-cols-12 gap-4">
            <div className="sm:col-span-4 xs:col-span-12">
              <div className="flex flex-col gap-2">
                <h1 className="font-bold text-white text-2xl">
                  Muat Turun myMBSA App
                </h1>
                <div className="flex items-center justify-center gap-3">
                  <img
                    className="w-14"
                    src="https://www.mbsa.gov.my/SiteAssets/MBSAQRCode.jpg"
                    alt="qrCode"
                  />
                  <BsApple size={50} />
                  <IoLogoGooglePlaystore size={50} />
                </div>
              </div>
            </div>
            <div className="sm:col-span-4 xs:col-span-12">
              <div className="flex flex-col gap-2">
                <h1 className="font-bold text-white text-2xl">
                  Interaksi dengan kami
                </h1>
                <div className="mt-2 flex justify-center items-center gap-7">
                  <BsFacebook
                    size={40}
                    className="hover: cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/shahalamcitycouncil"
                      );
                    }}
                  />
                  <BsInstagram
                    size={40}
                    className="hover: cursor-pointer"
                    onClick={() => {
                      window.open("https://www.instagram.com/sacitycouncil/");
                    }}
                  />
                  <BsTwitter
                    size={40}
                    className="hover: cursor-pointer"
                    onClick={() => {
                      window.open("https://twitter.com/sacitycouncil");
                    }}
                  />
                  <BsYoutube
                    size={40}
                    className="hover: cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://www.youtube.com/user/shahalamcitycouncil"
                      );
                    }}
                  />

                  <BsWhatsapp
                    size={40}
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://api.whatsapp.com/send?phone=60124007035&text=Hi"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-4 xs:col-span-12">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-2">
                  <h1 className="font-bold text-white text-2xl">Keselamatan</h1>
                  <div className="flex items-center justify-center">
                    <div className="bg-white shadow-sm rounded-lg p-3">
                      <img
                        src={sectigo}
                        alt="logo"
                        className="w-24"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {!isMobile && (
          <div className="w-full flex flex-rows justify-between justify-items-center">
            <div className="w-1/2 text-white text-left">
              <h1 className="text-3xl text-bold ml-5">Mobile App</h1>
              <img
                className="mt-5 ml-5"
                src="https://www.mbsa.gov.my/SiteAssets/MBSAQRCode.jpg"
                style={{ width: "150px", height: "150px" }}
              />
            </div>
            <div className="w-1/2 text-white text-left">
              <div className="flex items-center">
                <img src="images/logo/mbsa.svg" className="h-20 mr-4" alt="MBSA Logo" />
                <div>
                  <p className="leading-8">Majlis Bandaraya Shah Alam</p>
                  <p className="leading-5">Wisma MBSA, Persiaran Perbandaran,</p>
                  <p className="leading-5">40000 Shah Alam, Selangor Darul Ehsan</p>
                </div>
              </div>
              <div>
                <p className="leading-8">&nbsp;</p>
                <span className="flex flex-inline cursor-pointer">
                  <CgPhone size="1.2rem" /> <p className="pl-2">03-55105133</p>
                </span>
                <span className="flex flex-inline cursor-pointer">
                  <CgPrinter size="1.2rem" /> <p className="pl-2">03-55108010</p>
                </span>
                <span className="flex flex-inline cursor-pointer">
                  <CgMail size="1.2rem" /> <p className="pl-2">mymbsa@mbsa.gov.my</p>
                </span>
                <span className="flex flex-inline cursor-pointer">
                  <CgZoomIn size="1.2rem" />{" "}
                  <p className="pl-2">
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Wisma+MBSA/@3.0733745,101.5172384,17z/data=!4m5!3m4!1s0x31cc5280b428dced:0x28c2dc1fd2dea36c!8m2!3d3.0733686!4d101.5194359"
                    >
                      Lat: 3° 4' 24.636" Long: 101° 31' 12.738"
                    </a>
                  </p>
                </span>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="w-full flex flex-rows justify-between justify-items-center">
            <div className="text-white text-left pl-10">
              <div className="flex items-center">
                <img src="images/logo/mbsa.svg" className="h-20 mr-4" alt="MBSA Logo" />
                <div>
                  <p className="leading-8">Majlis Bandaraya Shah Alam</p>
                  <p className="leading-5">Wisma MBSA, Persiaran Perbandaran,</p>
                  <p className="leading-5">40000 Shah Alam, Selangor Darul Ehsan</p>
                </div>
              </div>
              <div>
                <p className="leading-8">&nbsp;</p>
                <span className="flex flex-inline cursor-pointer">
                  <CgPhone size="1.2rem" /> <p className="pl-2">03-55105133</p>
                </span>
                <span className="flex flex-inline cursor-pointer">
                  <CgPrinter size="1.2rem" /> <p className="pl-2">03-55108010</p>
                </span>
                <span className="flex flex-inline cursor-pointer">
                  <CgMail size="1.2rem" /> <p className="pl-2">mymbsa@mbsa.gov.my</p>
                </span>
                <span className="flex flex-inline cursor-pointer">
                  <CgZoomIn size="1.2rem" />{" "}
                  <p className="pl-2">
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/Wisma+MBSA/@3.0733745,101.5172384,17z/data=!4m5!3m4!1s0x31cc5280b428dced:0x28c2dc1fd2dea36c!8m2!3d3.0733686!4d101.5194359"
                    >
                      Lat: 3° 4' 24.636" Long: 101° 31' 12.738"
                    </a>
                  </p>
                </span>
              </div>
            </div>
          </div>
        )} */}

        <div className="flex flex-col justify-center justify-items-center">
          <div className="flex justify-center gap-5 mt-2">
            <a
              href="/termandcondition"
              className="underline"
            >
              Terma & Syarat
            </a>
            <a
              href="/privacy"
              className="underline"
            >
              Dasar Privasi
            </a>
            <a
              href="/security"
              className="underline"
            >
              Dasar Keselamatan
            </a>
            <a
              href="/penafian"
              className="underline"
            >
              Penafian
            </a>
          </div>
          <p className="text-white text-xs">
            Paparan Terbaik Menggunakan IE Versi 9.0 & 10.0 & Chrome Versi
            Terkini & ke atas dengan Resolusi 1024 x 768
          </p>
          <p className="text-white text-xs">
            <FormattedMessage id="footer.copyright" />
          </p>
          <p className="text-white text-xs">Emel : mymbsa@mbsa.gov.my</p>
          <p className="text-white text-xs">
            FE: {ver.version} {ver.date}
          </p>
          {version && version.current && version.datetime && (
            <p className="text-white text-xs">
              BE: {version.current} {version.datetime}
            </p>
          )}
        </div>
      </footer>
    </div>
  );
};
export default PublicLayout;
