import * as actions from "../action/pskedai";
import * as constants from "../constant/pskedai";

//
// PsKedai Datatable
//
export function PsKedaiDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.PsKedaiDatatableRequestAction {
  return {
    type: constants.PSKEDAI_DATATABLE_REQUEST,
    params,
    onCallback
  };
}
export function PsKedaiDatatableSuccess(data: object): actions.PsKedaiDatatableSuccessAction {
  return {
    type: constants.PSKEDAI_DATATABLE_SUCCESS,
    data,
  };
}
export function PsKedaiDatatableFailure(error: string): actions.PsKedaiDatatableFailureAction {
  return {
    type: constants.PSKEDAI_DATATABLE_FAILURE,
    error,
  };
}

//
// PsKedai Draft
//
export function PsKedaiDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKedaiDraftRequestAction {
  return {
    type: constants.PSKEDAI_DRAFT_REQUEST,
    params,
    onCallback
  };
}
export function PsKedaiDraftSuccess(data: object): actions.PsKedaiDraftSuccessAction {
  return {
    type: constants.PSKEDAI_DRAFT_SUCCESS,
    data,
  };
}
export function PsKedaiDraftFailure(error: string): actions.PsKedaiDraftFailureAction {
  return {
    type: constants.PSKEDAI_DRAFT_FAILURE,
    error,
  };
}

//
// PsKedai Edit
//
export function PsKedaiEditRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKedaiEditRequestAction {
  return {
    type: constants.PSKEDAI_EDIT_REQUEST,
    params,
    onCallback
  };
}
export function PsKedaiEditSuccess(data: object): actions.PsKedaiEditSuccessAction {
  return {
    type: constants.PSKEDAI_EDIT_SUCCESS,
    data,
  };
}
export function PsKedaiEditFailure(error: string): actions.PsKedaiEditFailureAction {
  return {
    type: constants.PSKEDAI_EDIT_FAILURE,
    error,
  };
}

//
// PsKedai Submit
//
export function PsKedaiSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKedaiSubmitRequestAction {
  return {
    type: constants.PSKEDAI_SUBMIT_REQUEST,
    params,
    onCallback
  };
}
export function PsKedaiSubmitSuccess(data: object): actions.PsKedaiSubmitSuccessAction {
  return {
    type: constants.PSKEDAI_SUBMIT_SUCCESS,
    data,
  };
}
export function PsKedaiSubmitFailure(error: string): actions.PsKedaiSubmitFailureAction {
  return {
    type: constants.PSKEDAI_SUBMIT_FAILURE,
    error,
  };
}


//
// PsKedai View
//
export function PsKedaiViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKedaiViewRequestAction {
  return {
    type: constants.PSKEDAI_VIEW_REQUEST,
    params,
    onCallback
  };
}
export function PsKedaiViewSuccess(data: object): actions.PsKedaiViewSuccessAction {
  return {
    type: constants.PSKEDAI_VIEW_SUCCESS,
    data,
  };
}
export function PsKedaiViewFailure(error: string): actions.PsKedaiViewFailureAction {
  return {
    type: constants.PSKEDAI_VIEW_FAILURE,
    error,
  };
}

//
// PsKedai Search
//
export function PsKedaiSearchRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKedaiSearchRequestAction {
  return {
    type: constants.PSKEDAI_SEARCH_REQUEST,
    params,
    onCallback
  };
}
export function PsKedaiSearchSuccess(data: object): actions.PsKedaiSearchSuccessAction {
  return {
    type: constants.PSKEDAI_SEARCH_SUCCESS,
    data,
  };
}
export function PsKedaiSearchFailure(error: string): actions.PsKedaiSearchFailureAction {
  return {
    type: constants.PSKEDAI_SEARCH_FAILURE,
    error,
  };
}
