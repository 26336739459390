//
// REGISTRATION
//
export const REGISTRATION_REQUEST = "REGISTRATION/REGISTRATION_REQUEST";
export const REGISTRATION_SUCCESS = "REGISTRATION/REGISTRATION_SUCCESS";
export const REGISTRATION_FAILURE = "REGISTRATION/REGISTRATION_FAILURE";


//
// VERIFYTAC
//
export const VERIFYTAC_REQUEST = "REGISTRATION/VERIFYTAC_REQUEST";
export const VERIFYTAC_SUCCESS = "REGISTRATION/VERIFYTAC_SUCCESS";
export const VERIFYTAC_FAILURE = "REGISTRATION/VERIFYTAC_FAILURE";


//
// NEWPASSWORD
//
export const NEWPASSWORD_REQUEST = "REGISTRATION/NEWPASSWORD_REQUEST";
export const NEWPASSWORD_SUCCESS = "REGISTRATION/NEWPASSWORD_SUCCESS";
export const NEWPASSWORD_FAILURE = "REGISTRATION/NEWPASSWORD_FAILURE";