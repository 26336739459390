import * as actions from '../action/locale';
import * as constants from '../constant/locale';

//
// LOCALE - ALL
//
export function localeAllRequest(localeCode: string, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.LocaleAllRequestAction {
    return {
        type: constants.LOCALE_ALL_REQUEST,
        localeCode,
        onCallback
    }
}
export function localeSuccess(data: object): actions.LocaleAllSuccessAction {
    return {
        type: constants.LOCALE_ALL_SUCCESS,
        data
    }
}
export function localeFailure(error: string): actions.LocaleAllFailureAction {
    return {
        type: constants.LOCALE_ALL_FAILURE,
        error
    }
}

//
// LOCALE - ADD
//
export function localeAddRequest(localeCode: string, localeMs: string, localeEn: string, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.LocaleAddRequestAction {
    return {
        type: constants.LOCALE_ADD_REQUEST,
        localeCode,
        localeMs,
        localeEn,
        onCallback
    }
}
export function localeAddSuccess(data: object): actions.LocaleAddSuccessAction {
    return {
        type: constants.LOCALE_ADD_SUCCESS,
        data
    }
}
export function localeAddFailure(error: string): actions.LocaleAddFailureAction {
    return {
        type: constants.LOCALE_ADD_FAILURE,
        error
    }
}


//
// LOCALE - EDIT
//
export function localeEditRequest(id:number, localeCode: string, localeMs: string, localeEn: string, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.LocaleEditRequestAction {
    return {
        type: constants.LOCALE_EDIT_REQUEST,
        id,
        localeCode,
        localeMs,
        localeEn,
        onCallback
    }
}
export function localeEditSuccess(data: object): actions.LocaleEditSuccessAction {
    return {
        type: constants.LOCALE_EDIT_SUCCESS,
        data
    }
}
export function localeEditFailure(error: string): actions.LocaleEditFailureAction {
    return {
        type: constants.LOCALE_EDIT_FAILURE,
        error
    }
}

//
// LOCALE - DELETE
//
export function localeDeleteRequest(id:number, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.LocaleDeleteRequestAction {
    return {
        type: constants.LOCALE_DELETE_REQUEST,
        id,
        onCallback
    }
}
export function localeDeleteSuccess(data: object): actions.LocaleDeleteSuccessAction {
    return {
        type: constants.LOCALE_DELETE_SUCCESS,
        data
    }
}
export function localeDeleteFailure(error: string): actions.LocaleDeleteFailureAction {
    return {
        type: constants.LOCALE_DELETE_FAILURE,
        error
    }
}


//
// LOCALE - VIEW
//
export function localeViewRequest(id:number, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.LocaleViewRequestAction {
    return {
        type: constants.LOCALE_VIEW_REQUEST,
        id,
        onCallback
    }
}
export function localeViewSuccess(data: object): actions.LocaleViewSuccessAction {
    return {
        type: constants.LOCALE_VIEW_SUCCESS,
        data
    }
}
export function localeViewFailure(error: string): actions.LocaleViewFailureAction {
    return {
        type: constants.LOCALE_VIEW_FAILURE,
        error
    }
}


//
// LOCALE - DATATABLE
//
export function localeDataTableRequest(search: string, limit: number, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.LocaleDataTableRequestAction {
    return {
        type: constants.LOCALE_DATATABLE_REQUEST,
        search,
        limit,
        onCallback
    }
}
export function localeDataTableSuccess(data: object): actions.LocaleDataTableSuccessAction {
    return {
        type: constants.LOCALE_DATATABLE_SUCCESS,
        data
    }
}
export function localeDataTableFailure(error: string): actions.LocaleDataTableFailureAction {
    return {
        type: constants.LOCALE_DATATABLE_FAILURE,
        error
    }
}
