import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/pskilang';
import * as constants from '../constant/pskilang';
import * as functions from '../function/pskilang';



//
// Ps Kilang DATATABLE
//
function* getPsKilangDatatable({params, onCallback}: actions.PsKilangDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kilang/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKilangDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKilangDatatableFailure(error));
    }    
};

//
// PsKilang Draft
//
function* getPsKilangDraft({params, onCallback}: actions.PsKilangDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kilang/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKilangDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKilangDraftFailure(error));
    }
};

//
// PsKilang Edit
//
function* getPsKilangEdit({params, onCallback}: actions.PsKilangEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kilang/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKilangEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKilangEditFailure(error));
    }
};

//
// PsKilang Submit
//
function* getPsKilangSubmit({params, onCallback}: actions.PsKilangSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kilang/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKilangSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKilangSubmitFailure(error));
    }
};

//
// PsKilang View
//
function* getPsKilangView({params, onCallback}: actions.PsKilangViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kilang/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKilangViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKilangViewFailure(error));
    }
};

//
// PsKilang Search
//
function* getPsKilangSearch({params, onCallback}: actions.PsKilangSearchRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kilang/search`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKilangSearchSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKilangSearchFailure(error));
    }
};


export function* watchPsKilang() {
    
    yield takeLatest(constants.PSKILANG_DATATABLE_REQUEST, getPsKilangDatatable);
    yield takeLatest(constants.PSKILANG_DRAFT_REQUEST, getPsKilangDraft);
    yield takeLatest(constants.PSKILANG_EDIT_REQUEST, getPsKilangEdit);
    yield takeLatest(constants.PSKILANG_SUBMIT_REQUEST, getPsKilangSubmit);
    yield takeLatest(constants.PSKILANG_VIEW_REQUEST, getPsKilangView);
    yield takeLatest(constants.PSKILANG_SEARCH_REQUEST, getPsKilangSearch);

};


