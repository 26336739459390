import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { CgDesktop } from "react-icons/cg";
import { FaQuestion } from "react-icons/fa";
import { ISPROD } from "../../../config";
import Input from "../../../components/ui/Input";
import MyLoyalti from "../../../assets/icons/svg/MyLoyalti.svg";
import MySewaan from "../../../assets/icons/svg/MySewaan.svg";
import MyTempahan from "../../../assets/icons/svg/MyTempahan.svg";
import home from "../../../assets/icons/svg/Home.svg";
import logoMbsa from "../../../assets/logo/logo-mbsa.png";
import myBayaran from "../../../assets/icons/svg/myBayaran.svg";
import myBil from "../../../assets/icons/svg/MyBil.svg";
import myCagaran from "../../../assets/icons/svg/MyCagaran.svg";
import myCukai from "../../../assets/icons/svg/MyCukai.svg";
import myKPM from "../../../assets/icons/svg/MyKPM.svg";
import myKompaun from "../../../assets/icons/svg/myKompaun.svg";
import myPPM from "../../../assets/icons/svg/MyPPM.svg";
import myPelan from "../../../assets/icons/svg/MyPelan.svg";
import myPermit from "../../../assets/icons/svg/MyPermit.svg";
import mySekat from "../../../assets/icons/svg/mySekat.svg";
import mySyarikat from "../../../assets/icons/svg/mySyarikat.svg";
import setting from "../../../assets/icons/svg/Tetapan.svg";
import { useSelector } from "react-redux";
import { useState } from "react";

interface LeftMenuProps {
  auth: any;
}

interface TitleMenuProps {
  title: string;
}

const LeftMenu = ({ auth }: LeftMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [level, setLevel] = useState<Map<number, boolean>>(new Map());
  const { loading, data } = useSelector((state: any) => state.auth);
  const intl = useIntl();
  const [search, setSearch] = useState("");

  //
  //  Menu for Role Admin
  //
  const menuList = [
    //Dashboard
    {
      name: "menu.dashboard",
      path: "/",
      icon: (
        <img
          src={home}
          alt={intl.formatMessage({ id: "menu.dashboard" })}
          className="mr-5 w-5 h-5"
        />
      ),
    },

    //myJualan
    {
      name: "menu.jualan",
      path: "/jualan",
      // role: "admin",
      icon: (
        <img
          src={MyLoyalti}
          alt="sekat"
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        { name: "menu.jualan.produk", path: "/jualan" },
        { name: "menu.jualan.pembelian", path: "/jualan/pembelian" },
      ],
    },

    //mySekat
    {
      id: 18,
      name: "menu.sekat",
      path: "/sekat",
      icon: (
        <img
          src={mySekat}
          alt="sekat"
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [{ id: 181, name: "menu.sekat.listing", path: "/sekat" }],
    },

    //myLoyalti
    {
      id: 21,
      name: "menu.loyalti",
      path: "/loyalti",
      icon: (
        <img
          src={MyLoyalti}
          alt="tempahan"
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        { id: 211, name: "menu.loyalti.dashboard", path: "/loyalti" },
        {
          id: 215,
          name: "menu.loyalti.program",
          path: "/loyalti/program",
        },
        {
          id: 212,
          name: "menu.loyalti.produk",
          path: "/loyalti/produk",
        },
        {
          id: 213,
          name: "menu.loyalti.penebusan",
          path: "/loyalti/penebusan",
        },
        {
          id: 214,
          name: "menu.loyalti.transaksi",
          path: "/loyalti/transaksi",
        },
      ],
    },

    //myCukai
    {
      id: 6,
      name: "menu.cukai",
      path: "/cukai/akaun",
      // role: "admin",
      icon: (
        <img
          src={myCukai}
          alt={intl.formatMessage({ id: "menu.cukai" })}
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        { id: 67, name: "menu.cukai.akaun", path: "/cukai/akaun" },
        {
          id: 63,
          name: "menu.cukai.pindahmilik",
          path: "/cukai/pindahmilik",
        },
        { id: 68, name: "menu.cukai.kemaskini", path: "/cukai/kemaskini" },
        {
          id: 64,
          name: "menu.cukai.pengurangan",
          path: "/cukai/pengurangan",
        },
        { id: 61, name: "menu.cukai.senarai", path: "/cukai" },
        // { id: 65, name: "menu.cukai.pembersihan", path: "/cukai/pembersihan" },
        // { id: 66, name: "menu.cukai.waran", path: "/cukai/waran" },
      ],
    },

    //mySewaan
    {
      id: 15,
      name: "menu.sewaan",
      path: "/sewaan",
      icon: (
        <img
          src={MySewaan}
          alt="sewaan"
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [{ id: 151, name: "menu.sewaan.listing", path: "/sewaan" }],
    },

    //myKompaun
    {
      id: 19,
      name: "menu.kompaun",
      path: "/kompaun",
      icon: (
        <img
          src={myKompaun}
          alt="kompaun"
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        { id: 191, name: "menu.kompaun.listing", path: "/kompaun" },
        { id: 192, name: "menu.kompaun.carian", path: "/kompaun/carian" },
      ],
    },

    //myLesen
    {
      name: "menu.lesen",
      path: "/lesen",
      icon: (
        <CgDesktop
          className="mr-5 text-mymbsa-primary"
          size={20}
        />
      ),
      subMenu: [
        {
          id: 81,
          name: "menu.lesen.listing.renew",
          path: "/lesen/listing/renew",
        },
        {
          id: 82,
          name: "menu.lesen.listing.apply",
          path: "/lesen/listing/apply",
        },
      ],
    },

    //myTempahan
    {
      id: 20,
      name: "menu.tempahan",
      path: "",
      icon: (
        <img
          src={MyTempahan}
          alt="tempahan"
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        {
          id: 201,
          name: "menu.tempahan.listing",
          path: "/tempahan",
        },
        {
          id: 202,
          name: "menu.roro.listing",
          path: "/roro",
        },
      ],
    },

    //myPelan
    {
      name: "menu.pelan",
      path: "/pelan",
      icon: (
        <img
          src={myPelan}
          alt={intl.formatMessage({ id: "menu.pelan" })}
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        { name: "menu.ps.kediaman", path: "/ps/kediaman" },
        { name: "menu.ps.kedai", path: "/ps/kedai" },
        { name: "menu.ps.kilang", path: "/ps/kilang" },
        { name: "menu.ps.taska", path: "/ps/taska" },
        { name: "menu.ps.telco", path: "/ps/telco", role: "admin" },
        { name: "menu.ps.btp", path: "/ps/btp", role: "admin" },
        { name: "menu.ps.pkpl", path: "/ps/pkpl", role: "admin" },
        { name: "menu.ps.doskmep", path: "/ps/doskmep", role: "admin" },
      ],
    },

    //myPermit
    {
      name: "menu.permit",
      path: "/permit",
      icon: (
        <img
          src={myPermit}
          alt={intl.formatMessage({ id: "menu.permit" })}
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        { id: 51, name: "menu.permit.listing.pkt", path: "/pkt" },
        { id: 52, name: "menu.permit.listing.ptj", path: "/ptj" },
        { id: 53, name: "menu.permit.listing.pb", path: "/pb" },
        { id: 54, name: "menu.permit.listing.pmbb", path: "/pmbb" },
      ],
    },

    //myKPM
    {
      name: "menu.kpm",
      path: "/kpm",
      icon: (
        <img
          src={myKPM}
          alt={intl.formatMessage({ id: "menu.kpm" })}
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [{ id: 21, name: "menu.kpm.listing", path: "/kpm" }],
    },

    //myPPM
    {
      name: "menu.ppm",
      path: "/ppm",
      role: "admin",
      icon: (
        <img
          src={myPPM}
          alt={intl.formatMessage({ id: "menu.ppm" })}
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [{ id: 31, name: "menu.ppm.listing", path: "/ppm" }],
    },

    //mySyarikat
    {
      id: 22,
      name: "menu.syarikat",
      path: "/syarikat/kemaskini",
      icon: (
        <img
          src={mySyarikat}
          alt="syarikat"
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        { id: 221, name: "account.tab4.title", path: "/syarikat/kemaskini" },
        {
          id: 222,
          name: "menu.syarikat.permohonan",
          path: "/syarikat/new",
          // role: "admin",
        },
        // {
        //   id: 223,
        //   name: "menu.syarikat.tuntutan",
        //   path: "/syarikat/tuntutan",
        //   role: "admin",
        // },
      ],
    },

    //myCagaran
    {
      name: "menu.cagaran",
      path: "/cagaran",
      icon: (
        <img
          src={myCagaran}
          alt={intl.formatMessage({ id: "menu.cagaran" })}
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [{ id: 71, name: "menu.cagaran.listing", path: "/cagaran" }],
    },

    //myBil
    {
      id: 16,
      name: "menu.bil",
      path: "/bil",
      icon: (
        <img
          src={myBil}
          alt="bil"
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [{ id: 161, name: "menu.bil.listing", path: "/bil" }],
    },

    //myBayaran
    {
      id: 17,
      name: "menu.bayaran",
      path: "/bayaran",
      icon: (
        <img
          src={myBayaran}
          alt="bayaran"
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        { id: 171, name: "menu.bayaran.listing", path: "/bayaran" },
        {
          id: 172,
          name: "menu.bayaran.transaction",
          path: "/transaction",
        },
        { id: 173, name: "menu.kompaun.carian", path: "/bayaran/carian" },
      ],
    },

    //User Manual
    {
      name: "menu.usermanual",
      path: "/usermanual",
      icon: (
        <FaQuestion
          className="mr-5 text-mymbsa-primary"
          size={20}
        />
      ),
    },

    //Penyelenggaraan
    {
      name: "menu.maintenance",
      path: "/maintenance",
      role: "admin",
      icon: (
        <img
          src={setting}
          alt={intl.formatMessage({ id: "menu.maintenance" })}
          className="mr-5 w-5 h-5"
        />
      ),
      subMenu: [
        { name: "menu.user", path: "/user" },
        { name: "menu.audit", path: "/audit" },
        { name: "menu.locale", path: "/locale" },
        { name: "menu.mapstatus", path: "/mapstatus" },
        { name: "menu.report", path: "/report" },
        { name: "menu.killSwitch", path: "/killSwitch" },
      ],
    },
  ];

  let menuListFilter = [];
  let _id = 0;
  menuListFilter = menuList
    .filter((menu) => {
      //admin
      if (data.role_id == "1" && menu.role == "admin") {
        return false;
      }
      //user
      return true;
    })
    //search term
    .filter((menu: any) => {
      const sub =
        menu.subMenu &&
        menu.subMenu.filter((sub: any) => {
          //admin
          if (data.role_id == "1" && sub.role == "admin") {
            return false;
          }
          const name2 = intl.formatMessage({ id: sub.name });
          return name2.toLowerCase().includes(search.toLowerCase());
        });
      if (sub && sub.length > 0) {
        menu.subMenu = sub;
        return true;
      }
      const name2 = intl.formatMessage({ id: menu.name });
      return name2.toLowerCase().includes(search.toLowerCase());
    })
    //return menu item
    .map((menu) => {
      _id++;
      return {
        id: _id,
        name: menu.name,
        path: menu.path,
        icon: menu.icon,
        subMenu: menu.subMenu,
      };
    });

  return (
    <aside className="bg-white shadow-2xl h-full">
      <div className="flex flex-col item-start content-start w-full">
        <div className="h-full">
          {/* logo */}
          <div className="flex h-40 justify-center items-center">
            <Link
              to=""
              className="flex justify-start justify-items-center"
            >
              <img
                src={logoMbsa}
                className="h-24 mr-4"
                alt="MBSA Logo"
              />
            </Link>
          </div>
          <div className="w-full grow items-center">
            {loading && (
              <>
                <p className="leading-relaxed ml-10 mb-10 w-2/3 h-5 animate-pulse bg-mymbsa-primarylight"></p>
                <p className="leading-relaxed ml-10 mb-10 w-2/3 h-5 animate-pulse bg-mymbsa-primarylight"></p>
                <p className="leading-relaxed ml-10 mb-10 w-2/3 h-5 animate-pulse bg-mymbsa-primarylight"></p>
                <p className="leading-relaxed ml-10 mb-10 w-2/3 h-5 animate-pulse bg-mymbsa-primarylight"></p>
                <p className="leading-relaxed ml-10 mb-10 w-2/3 h-5 animate-pulse bg-mymbsa-primarylight"></p>
                <p className="leading-relaxed ml-10 mb-10 w-2/3 h-5 animate-pulse bg-mymbsa-primarylight"></p>
              </>
            )}
            {/* Search Menu */}
            {!loading && (
              <>
                <div className="pl-5 pr-5">
                  <Input
                    name="search"
                    styleMui={false}
                    value={search}
                    label={""}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    containerClassName="rounded-md bg-white"
                    labelClassName="font-din text-black text-sm"
                    inputClassName="font-din text-black rounded-md text-sm"
                    errorClassName=""
                    type="text"
                    placeHolder={intl.formatMessage({ id: "menu.search" })}
                  />
                </div>
              </>
            )}
            {!loading &&
              menuListFilter.map((menu) => {
                //sub menu
                if (menu.subMenu) {
                  return (
                    <div key={Math.random()}>
                      <div
                        key={Math.random()}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(menu.path);
                          level.clear();
                          level.set(menu.id, true);
                          console.log(level);
                          setLevel(level);
                        }}
                        className={`flex items-center px-3 py-3 font-sans text-sm font-medium font-din cursor-pointer hover:border-l-4 hover:border-mymbsa-primary hover:text-mymbsa-primary transition-all`}
                      >
                        <div
                          key={Math.random()}
                          className={`flex justify-between w-full items-center`}
                        >
                          <div
                            key={Math.random()}
                            className={`flex flex-inline item-center content-center px-3`}
                          >
                            {menu.icon}
                            <a
                              key={Math.random()}
                              className="font-bold"
                              href="#"
                            >
                              <FormattedMessage id={menu.name} />
                            </a>
                          </div>
                          {menu.subMenu && (
                            <div
                              key={Math.random()}
                              className="w-10"
                            >
                              {level.get(menu.id) ? (
                                <BsFillCaretUpFill className="float-right font-din text-mymbsa-primary " />
                              ) : (
                                <BsFillCaretDownFill className="float-right font-din text-mymbsa-primary" />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        key={Math.random()}
                        className=""
                      >
                        {level.get(menu.id) &&
                          menu.subMenu &&
                          menu.subMenu
                            .filter((sub) => {
                              //admin
                              if (data.role_id == "1" && sub.role == "admin") {
                                return false;
                              }
                              //user
                              return true;
                            })
                            .map((sub) => {
                              return (
                                <div
                                  key={Math.random()}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(sub.path);
                                  }}
                                  className={`${
                                    pathname === sub.path
                                      ? "border-l-4 border-mymbsa-primary text-mymbsa-primary bg-gray-100"
                                      : ""
                                  } flex items-center font-din px-3 py-3 text-xs font-medium cursor-pointer font-sans hover:border-l-4 hover:border-mymbsa-primary hover:text-mymbsa-primary hover:bg-gray-100 transition-all'}`}
                                >
                                  <div
                                    key={Math.random()}
                                    className={`flex flex-inline item-center content-center px-12`}
                                  >
                                    <a
                                      key={Math.random()}
                                      className="font-din"
                                      href="#"
                                    >
                                      <FormattedMessage id={sub.name} />
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  );

                  //single menu
                } else {
                  return (
                    <div
                      key={Math.random()}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(menu.path);
                      }}
                      className={`${
                        pathname === menu.path
                          ? "border-l-4 border-mymbsa-primary text-mymbsa-primary bg-gray-100"
                          : ""
                      } flex items-center font-din px-3 py-3 font-sans text-sm font-medium cursor-pointer hover:border-l-4 hover:border-mymbsa-primary hover:text-mymbsa-primary hover:bg-gray-100 transition-all'}`}
                    >
                      <div
                        key={Math.random()}
                        className="flex justify-between w-full items-center"
                      >
                        <div
                          key={Math.random()}
                          className={`flex flex-inline item-center content-center px-3`}
                        >
                          {menu.icon}
                          <a
                            className="font-bold font-din"
                            href="#"
                          >
                            <FormattedMessage id={menu.name} />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </aside>
  );
};
export default LeftMenu;
