import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/lesen";
import * as constants from "../constant/lesen";
import * as functions from "../function/lesen";

//
// Lesen DATATABLE Renew
//
function* getLesenDatatableRenew({
  params,
  onCallback,
}: actions.LesenDatatableRenewRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/datatable/renew`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenDatatableRenewSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenDatatableRenewFailure(error));
  }
}

//
// Lesen DATATABLE Apply
//
function* getLesenDatatableApply({
  params,
  onCallback,
}: actions.LesenDatatableApplyRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/datatable/apply`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    // RESTRUCTURE
    let result = {
      count: data.count,
      data: [],
    };
    for (let index = 0; index < data.count; index++) {
      const item = data.data[index];
      const newObj = { ...item };

      if (item.jenis_lesen === 1) {
        newObj["jumlah_bayaran"] = 100;
      } else {
        newObj["jumlah_bayaran"] = 50;
      }

      result.data.push(newObj);
    }

    console.log("DARI SAGGAAAA", result);
    onCallback({
      status: 1,
      data: result,
    });
    yield put(functions.LesenDatatableApplySuccess(result));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenDatatableApplyFailure(error));
  }
}

//
// LESEN RENEW
//
function* getLesenRenew({
  params,
  onCallback,
}: actions.LesenRenewRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/renew`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenRenewSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenRenewFailure(error));
  }
}

//
// LESEN PAY CREATE
//
function* getLesenPayCreate({
  params,
  onCallback,
}: actions.LesenPayCreateRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/payment/create`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenPayCreateSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenPayCreateFailure(error));
  }
}

//
// LESEN PAY REDIRECT
//
function* getLesenPayRedirect({
  params,
  onCallback,
}: actions.LesenPayRedirectRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/payment/redirect`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenPayRedirectSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenPayRedirectFailure(error));
  }
}

//
// LESEN DRAFT
//
function* getLesenDraft({
  params,
  onCallback,
}: actions.LesenDraftRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/draft`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenDraftSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenDraftFailure(error));
  }
}

//
// LESEN EDIT
//
function* getLesenEdit({ params, onCallback }: actions.LesenEditRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/edit`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenEditSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenEditFailure(error));
  }
}

//
// LESEN SUBMIT
//
function* getLesenSubmit({
  params,
  onCallback,
}: actions.LesenSubmitRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/submit`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenSubmitSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenSubmitFailure(error));
  }
}

//
// LESEN VIEW
//
function* getLesenView({ params, onCallback }: actions.LesenViewRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/viewDraft`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenViewSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenViewFailure(error));
  }
}

//
// LESEN DELETE DRAFT
//
function* getLesenDeleteDraft({
  params,
  onCallback,
}: actions.LesenDeleteDraftRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/deleteDraft`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenDeleteDraftSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenDeleteDraftFailure(error));
  }
}

//
// LESEN DELETE DRAFT
//
function* getLesenDocument({
  params,
  onCallback,
}: actions.LesenDeleteDraftRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/dokumen`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenDocumentSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenDeleteDraftFailure(error));
  }
}

//
// LESEN VERIFY SEKATAN
//
function* getLesenVerifySekatan({
  params,
  onCallback,
}: actions.LesenVerifySekatanRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/verifySekatan`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenVerifySekatanSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenVerifySekatanFailure(error));
  }
}

//
// LESEN KATEGORILESEN
//
function* getLesenKategoriLesen({
  params,
  onCallback,
}: actions.LesenKategoriLesenRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/kategoriLesen`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenKategoriLesenSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenKategoriLesenFailure(error));
  }
}

//
// LESEN JENISLESEN
//
function* getLesenJenisLesen({
  params,
  onCallback,
}: actions.LesenJenisLesenRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/jenisLesen`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenJenisLesenSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenJenisLesenFailure(error));
  }
}

//
// LESEN KODHASILLESEN
//
function* getLesenKodHasilLesen({
  params,
  onCallback,
}: actions.LesenKodHasilLesenRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/aktivitiLesen`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenKodHasilLesenSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenKodHasilLesenFailure(error));
  }
}

//
// LESEN JENISPREMIS
//
function* getLesenJenisPremis({
  params,
  onCallback,
}: actions.LesenJenisPremisRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/jenisPremis`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenJenisPremisSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenJenisPremisFailure(error));
  }
}

//
// LESEN SAVE DOC
//
function* postSaveDoc({
  params,
  onCallback,
}: actions.LesenSaveDocRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/saveDoc`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenSaveDocSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenSaveDocFailure(error));
  }
}

//
// LESEN GET DOC
//
function* getLicenseDocs({
  params,
  onCallback,
}: actions.LesenGetDocRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/dokumen/detail`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenGetDocSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenGetDocFailure(error));
  }
}

//
// LESEN JENISPREMIS
//
function* postSaveDocToServer({
  params,
  onCallback,
}: actions.LesenSaveDocToServerRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    // url: `/secured/lesen/saveFileToServer`,
    url: `/secured/lesen/saveFile`,
    method: "POST",
    data: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenSaveDocToServerSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenSaveDocToServerFailure(error));
  }
}

//
// LESEN KEDUDUKANPREMIS
//
function* getLesenKedudukanPremis({
  params,
  onCallback,
}: actions.LesenKedudukanPremisRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/kedudukanPremis`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LesenKedudukanPremisSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LesenKedudukanPremisFailure(error));
  }
}

export function* watchLesen() {
  yield takeLatest(
    constants.LESEN_DATATABLE_RENEW_REQUEST,
    getLesenDatatableRenew
  );
  yield takeLatest(
    constants.LESEN_DATATABLE_APPLY_REQUEST,
    getLesenDatatableApply
  );
  yield takeLatest(constants.LESEN_RENEW_REQUEST, getLesenRenew);
  yield takeLatest(constants.LESEN_PAY_CREATE_REQUEST, getLesenPayCreate);
  yield takeLatest(constants.LESEN_PAY_REDIRECT_REQUEST, getLesenPayRedirect);
  yield takeLatest(constants.LESEN_DRAFT_REQUEST, getLesenDraft);
  yield takeLatest(constants.LESEN_EDIT_REQUEST, getLesenEdit);
  yield takeLatest(constants.LESEN_SUBMIT_REQUEST, getLesenSubmit);
  yield takeLatest(constants.LESEN_VIEW_REQUEST, getLesenView);
  yield takeLatest(constants.LESEN_DELETEDRAFT_REQUEST, getLesenDeleteDraft);
  yield takeLatest(constants.LESEN_DOCUMENT_REQUEST, getLesenDocument);
  yield takeLatest(
    constants.LESEN_VERIFYSEKATAN_REQUEST,
    getLesenVerifySekatan
  );
  yield takeLatest(
    constants.LESEN_KATEGORILESEN_REQUEST,
    getLesenKategoriLesen
  );
  yield takeLatest(constants.LESEN_JENISLESEN_REQUEST, getLesenJenisLesen);
  yield takeLatest(
    constants.LESEN_KODHASILLESEN_REQUEST,
    getLesenKodHasilLesen
  );
  yield takeLatest(constants.LESEN_JENISPREMIS_REQUEST, getLesenJenisPremis);
  yield takeLatest(constants.LESEN_SAVE_DOCS_REQUEST, postSaveDoc);
  yield takeLatest(constants.LESEN_GET_DOCS_REQUEST, getLicenseDocs);
  yield takeLatest(
    constants.LESEN_SAVE_DOCS_TO_SERVER_REQUEST,
    postSaveDocToServer
  );
  yield takeLatest(
    constants.LESEN_KEDUDUKANPREMIS_REQUEST,
    getLesenKedudukanPremis
  );
}
