import * as actions from "../action/tempahan";
import * as constants from "../constant/tempahan";

export interface TempahanState {
  data: object;
  error: string;
  loading: boolean;
  tempahanChecking: any[];
}

export const tempahanReducer = (
  state: TempahanState = {
    data: {},
    error: "",
    loading: false,
    tempahanChecking: [],
  },
  action: actions.TempahanAction
) => {
  switch (action.type) {
    //
    // TEMPAHAN ACTIVITY TYPE @ KATEGORI TEMPAHAN
    //
    case constants.TEMPAHAN_ACTIVITY_TYPE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_ACTIVITY_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_ACTIVITY_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // TEMPAHAN ACTIVITY TYPE @ KATEGORI TEMPAHAN
    //
    case constants.TEMPAHAN_PRE_BLOCK_BOOKING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_PRE_BLOCK_BOOKING_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_PRE_BLOCK_BOOKING_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // TEMPAHAN ACTIVITY TYPE @ KATEGORI TEMPAHAN
    //
    case constants.TEMPAHAN_PRE_DELETE_BOOKING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_PRE_DELETE_BOOKING_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_PRE_DELETE_BOOKING_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // TEMPAHAN ACTIVITY @ ACARA
    //
    case constants.TEMPAHAN_ACTIVITY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_ACTIVITY_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_ACTIVITY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // TEMPAHAN SECTION @ SEKSYEN
    //
    case constants.TEMPAHAN_SECTION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_SECTION_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_SECTION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  TEMPAHAN ASSET @ LOKASI
    //
    case constants.TEMPAHAN_ASSET_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_ASSET_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_ASSET_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  TEMPAHAN DATATABLE
    //
    case constants.TEMPAHAN_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  TEMPAHAN SUBMIT
    //
    case constants.TEMPAHAN_SUBMIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_SUBMIT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_SUBMIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  TEMPAHAN UPDATE
    //
    case constants.TEMPAHAN_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_UPDATE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_UPDATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  TEMPAHAN ASSET TIMESLOT
    //
    case constants.TEMPAHAN_ASSET_TIMESLOT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_ASSET_TIMESLOT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_ASSET_TIMESLOT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  TEMPAHAN VIEW BIL
    //
    case constants.TEMPAHAN_VIEW_BILL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_VIEW_BILL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_VIEW_BILL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  TEMPAHAN VIEW BIL
    //
    case constants.TEMPAHAN_CHECKING_ACTIVE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.TEMPAHAN_CHECKING_ACTIVE_SUCCESS: {
      return {
        ...state,
        tempahanChecking: action.data as any,
        loading: false,
      };
    }

    case constants.TEMPAHAN_CHECKING_ACTIVE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
