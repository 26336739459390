import * as actions from '../action/company';
import * as constants from '../constant/company';


//
// COMPANY Datatable
//
export function CompanyDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.CompanyDatatableRequestAction {
    return {
        type: constants.COMPANY_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function CompanyDatatableSuccess(data: object): actions.CompanyDatatableSuccessAction {
    return {
        type: constants.COMPANY_DATATABLE_SUCCESS,
        data
    }
}
export function CompanyDatatableFailure(error: string): actions.CompanyDatatableFailureAction {
    return {
        type: constants.COMPANY_DATATABLE_FAILURE,
        error
    }
}


//
// COMPANY Edit
//
export function CompanyEditRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.CompanyEditRequestAction {
    return {
        type: constants.COMPANY_EDIT_REQUEST,
        params,
        onCallback
    }
}
export function CompanyEditSuccess(data: object): actions.CompanyEditSuccessAction {
    return {
        type: constants.COMPANY_EDIT_SUCCESS,
        data
    }
}
export function CompanyEditFailure(error: string): actions.CompanyEditFailureAction {
    return {
        type: constants.COMPANY_EDIT_FAILURE,
        error
    }
}


//
// COMPANY Add
//
export function CompanyAddRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.CompanyAddRequestAction {
    return {
        type: constants.COMPANY_ADD_REQUEST,
        params,
        onCallback
    }
}
export function CompanyAddSuccess(data: object): actions.CompanyAddSuccessAction {
    return {
        type: constants.COMPANY_ADD_SUCCESS,
        data
    }
}
export function CompanyAddFailure(error: string): actions.CompanyAddFailureAction {
    return {
        type: constants.COMPANY_ADD_FAILURE,
        error
    }
}


//
// COMPANY View
//
export function CompanyViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.CompanyViewRequestAction {
    return {
        type: constants.COMPANY_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function CompanyViewSuccess(data: object): actions.CompanyViewSuccessAction {
    return {
        type: constants.COMPANY_VIEW_SUCCESS,
        data
    }
}
export function CompanyViewFailure(error: string): actions.CompanyViewFailureAction {
    return {
        type: constants.COMPANY_VIEW_FAILURE,
        error
    }
}

//
// COMPANY Delete
//
export function CompanyDeleteRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.CompanyDeleteRequestAction {
    return {
        type: constants.COMPANY_DELETE_REQUEST,
        params,
        onCallback
    }
}
export function CompanyDeleteSuccess(data: object): actions.CompanyDeleteSuccessAction {
    return {
        type: constants.COMPANY_DELETE_SUCCESS,
        data
    }
}
export function CompanyDeleteFailure(error: string): actions.CompanyDeleteFailureAction {
    return {
        type: constants.COMPANY_DELETE_FAILURE,
        error
    }
}


//
// COMPANY List
//
export function CompanyListRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.CompanyListRequestAction {
    return {
        type: constants.COMPANY_LIST_REQUEST,
        params,
        onCallback
    }
}
export function CompanyListSuccess(data: object): actions.CompanyListSuccessAction {
    return {
        type: constants.COMPANY_LIST_SUCCESS,
        data
    }
}
export function CompanyListFailure(error: string): actions.CompanyListFailureAction {
    return {
        type: constants.COMPANY_LIST_FAILURE,
        error
    }
}
