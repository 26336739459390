import * as actions from "../action/setting";
import * as constants from "../constant/setting";

// REQUEST
export function settingKillSwitchRequest(
  params: any,
  limit: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.settingKillSwitchRequestAction {
  return {
    type: constants.SETTING_KILLSWITCH_REQUEST,
    params,
    onCallback,
  };
}
export function settingKillSwitchSuccess(
  data: object
): actions.settingKillSwitchSuccessAction {
  return {
    type: constants.SETTING_KILLSWITCH_SUCCESS,
    data,
  };
}
export function settingKillSwitchFailure(
  error: string
): actions.settingKillSwitchFailureAction {
  return {
    type: constants.SETTING_KILLSWITCH_FAILURE,
    error,
  };
}

// VIEW STATUS
export function settingKillSwitchViewRequest(
  params: any,
  limit: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.settingKillSwitchViewRequestAction {
  return {
    type: constants.SETTING_KILLSWITCH_VIEW_REQUEST,
    params,
    onCallback,
  };
}
export function settingKillSwitchViewSuccess(
  data: boolean
): actions.settingKillSwitchViewSuccessAction {
  return {
    type: constants.SETTING_KILLSWITCH_VIEW_SUCCESS,
    data,
  };
}
export function settingKillSwitchViewFailure(
  error: string
): actions.settingKillSwitchViewFailureAction {
  return {
    type: constants.SETTING_KILLSWITCH_VIEW_FAILURE,
    error,
  };
}
