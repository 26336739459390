import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import logoMbsa from "../../../assets/logo/logo-mbsa.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const CarouselImage = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const dataList = [
    {
      title: "Masjid Sultan Salahuddin Abdul Aziz",
      imageUrl: "images/carousel/mbsa6.jpg",
      link: "https://www.facebook.com/masjidssaas/",
    },
    {
      title: "Taman Rekreasi Shah Alam",
      imageUrl: "images/carousel/mbsa2.jpg",
      link: "https://www.facebook.com/pages/Taman-Tasik-Rekreasi-Seksyen-7-Shah-Alam/266903633394520",
    },
    {
      title: "I-City Shah Alam",
      imageUrl: "images/carousel/mbsa4.jpg",
      link: "https://www.facebook.com/iCityShahAlam",
    },
    {
      title: "Perpustakaan Raja Tun Uda",
      imageUrl: "images/carousel/mbsa5.jpg",
      link: "https://www.ppas.gov.my/",
    },
    {
      title: "Taman Botani Shah Alam",
      imageUrl: "images/carousel/mbsa3.jpg",
      link: "https://www.facebook.com/MyTBNSA8",
    },
  ];

  return (
    <div className="py-5 container mx-auto">
      <div className=" rounded-xl shadow-xl bg-white relative xs:h-full h-96">
        <div className="w-5 h-full absolute rounded-tl-xl rounded-bl-xl">
          <div className="flex flex-col h-full">
            <div className="w-5 bg-mymbsa-primary rounded-tl-xl flex-1">
              &nbsp;
            </div>
            <div className="w-5 bg-mymbsa-primarylight flex-1">&nbsp;</div>
            <div className="w-5 bg-mymbsa-primaryaccent flex-1">&nbsp;</div>
            <div className="w-5 bg-mymbsa-primaryalternate rounded-bl-xl flex-1">
              &nbsp;
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 rounded-tl-xl rounded-bl-xl">
          <div className="sm:col-span-4 xs:col-span-12">
            <div className="flex flex-col pl-16 py-10">
              <img src={logoMbsa} className="w-24 mb-4" alt="MBSA Logo" />
              <h1 className="font-bold text-2xl">
                Dengan myMBSA setiap permohonan, semakan dan bayaran boleh
                dibuat dimana- mana sahaja.
                <br /> <span className="text-mymbsa-primary">Mudah</span> .{" "}
                <span className="text-mymbsa-primarylight">Pantas</span> .{" "}
                <span className="text-mymbsa-primaryaccent">Diyakini .</span>
              </h1>
            </div>
          </div>
          <div className="sm:col-span-8 xs:col-span-12">
            <Carousel
              customRightArrow={
                <button
                  aria-label="Go to next slide"
                  className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right z-40"
                  type="button"
                />
              }
              customLeftArrow={
                <button
                  aria-label="Go to previous slide"
                  className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left z-40"
                  type="button"
                />
              }
              showDots
              autoPlaySpeed={5000}
              infinite={true}
              autoPlay={true}
              responsive={responsive}
            >
              {dataList.map((d) => {
                return (
                  <div
                    key={Math.random()}
                    className="relative rounded-tr-xl rounded-br-xl h-full"
                    // style={{
                    //   backgroundImage: `url(${d.imageUrl})`,
                    //   backgroundSize: "cover",
                    //   backgroundRepeat: "no-repeat",
                    //   backgroundPosition: "center",
                    //   height: "100vh",
                    //   borderRadius: "0 0.75rem 0.75rem 0",
                    // }}
                  >
                    <img
                      src={d.imageUrl}
                      alt="bg"
                      className="w-full object-cover h-96 rounded-tr-xl rounded-br-xl"
                    />
                    <h1
                      onClick={() => {
                        window.open(d.link);
                      }}
                      className="cursor-pointer bg-mymbsa-primarylight p-2 text-white font-bold text-xl absolute bottom-0"
                    >
                      {d.title}
                    </h1>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselImage;
