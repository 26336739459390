//
// PS KILANG DATATABLE
//
export const PSKILANG_DATATABLE_REQUEST = 'PS/KILANG_DATATABLE_REQUEST';
export const PSKILANG_DATATABLE_SUCCESS = 'PS/KILANG_DATATABLE_SUCCESS';
export const PSKILANG_DATATABLE_FAILURE = 'PS/KILANG_DATATABLE_FAILURE';

//
// PS KILANG - DRAFT
//
export const PSKILANG_DRAFT_REQUEST = 'PS/KILANG_DRAFT_REQUEST';
export const PSKILANG_DRAFT_SUCCESS = 'PS/KILANG_DRAFT_SUCCESS';
export const PSKILANG_DRAFT_FAILURE = 'PS/KILANG_DRAFT_FAILURE';

//
// PS KILANG - EDIT
//
export const PSKILANG_EDIT_REQUEST = 'PS/KILANG_EDIT_REQUEST';
export const PSKILANG_EDIT_SUCCESS = 'PS/KILANG_EDIT_SUCCESS';
export const PSKILANG_EDIT_FAILURE = 'PS/KILANG_EDIT_FAILURE';

//
// PS KILANG - SUBMIT
//
export const PSKILANG_SUBMIT_REQUEST = 'PS/KILANG_SUBMIT_REQUEST';
export const PSKILANG_SUBMIT_SUCCESS = 'PS/KILANG_SUBMIT_SUCCESS';
export const PSKILANG_SUBMIT_FAILURE = 'PS/KILANG_SUBMIT_FAILURE';

//
// PS KILANG - VIEW
//
export const PSKILANG_VIEW_REQUEST = 'PS/KILANG_VIEW_REQUEST';
export const PSKILANG_VIEW_SUCCESS = 'PS/KILANG_VIEW_SUCCESS';
export const PSKILANG_VIEW_FAILURE = 'PS/KILANG_VIEW_FAILURE';

//
// PS KILANG - SEARCH
//
export const PSKILANG_SEARCH_REQUEST = 'PS/KILANG_SEARCH_REQUEST';
export const PSKILANG_SEARCH_SUCCESS = 'PS/KILANG_SEARCH_SUCCESS';
export const PSKILANG_SEARCH_FAILURE = 'PS/KILANG_SEARCH_FAILURE';