import * as actions from "../action/setting";
import * as axios from "../../utils/axios";
import * as constants from "../constant/setting";
import * as functions from "../function/setting";

import { call, put, takeLatest } from "redux-saga/effects";

//
//  REQUEST TO KILL
//
function* setKillSwitchMobileTempahan({
  params,
  onCallback,
}: actions.settingKillSwitchRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/settings/enable-tempahan-mobile`,
    method: "POST",
    params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.settingKillSwitchSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.settingKillSwitchFailure(error));
  }
}
//
//  VIEW STATUS
//
function* getKillSwitchMobileTempahan({
  params,
  onCallback,
}: actions.settingKillSwitchRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/settings/show-tempahan-mobile`,
    method: "GET",
    params,
  });
  if (status === 200) {
    console.log("DATA DARI VIEW STATUS", data);
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.settingKillSwitchViewSuccess(data.enable));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.settingKillSwitchViewFailure(error));
  }
}

export function* watchSetting() {
  yield takeLatest(
    constants.SETTING_KILLSWITCH_REQUEST,
    setKillSwitchMobileTempahan
  );
  yield takeLatest(
    constants.SETTING_KILLSWITCH_VIEW_REQUEST,
    getKillSwitchMobileTempahan
  );
}
