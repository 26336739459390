import * as actions from '../action/cagaran';
import * as constants from '../constant/cagaran';

//SEARCH BY ID
export function CagaranSearchByIDRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any
}>): actions.CagaranSearchByIDRequestAction {
    return {
        type: constants.CAGARAN_SEARCH_BY_ID_REQUEST,
        params,
        onCallback
    }
}
export function CagaranSearchByIDSuccess(data: object): actions.CagaranSearchByIDSuccessAction {
    return {
        type: constants.CAGARAN_SEARCH_BY_ID_SUCCESS,
        data
    }
}
export function CagaranSearchByIDFailure(error: string): actions.CagaranSearchByIDFailureAction {
    return {
        type: constants.CAGARAN_SEARCH_BY_ID_FAILURE,
        error
    }
}


//SEARCH BY NO BIL
export function CagaranSearchByNoBilRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any
}>): actions.CagaranSearchByNoBilRequestAction {
    return {
        type: constants.CAGARAN_SEARCH_BY_NO_BIL_REQUEST,
        params,
        onCallback
    }
}
export function CagaranSearchByNoBilSuccess(data: object): actions.CagaranSearchByNoBilSuccessAction {
    return {
        type: constants.CAGARAN_SEARCH_BY_NO_BIL_SUCCESS,
        data
    }
}
export function CagaranSearchByNoBilFailure(error: string): actions.CagaranRequestByNoBilFailureAction {
    return {
        type: constants.CAGARAN_SEARCH_BY_NO_BIL_FAILURE,
        error
    }
}


//VIEW
export function CagaranViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any
}>): actions.CagaranViewRequestAction {
    return {
        type: constants.CAGARAN_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function CagaranViewSuccess(data: object): actions.CagaranViewSuccessAction {
    return {
        type: constants.CAGARAN_VIEW_SUCCESS,
        data
    }
}
export function CagaranViewFailure(error: string): actions.CagaranViewFailureAction {
    return {
        type: constants.CAGARAN_VIEW_FAILURE,
        error
    }
}

//PAY CREATE
export function CagaranPayCreateRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any
}>): actions.CagaranPayCreateRequestAction {
    return {
        type: constants.CAGARAN_PAY_CREATE_REQUEST,
        params,
        onCallback
    }
}
export function CagaranPayCreateSuccess(data: object): actions.CagaranPayCreateSuccessAction {
    return {
        type: constants.CAGARAN_PAY_CREATE_SUCCESS,
        data
    }
}
export function CagaranPayCreateFailure(error: string): actions.CagaranPayCreateFailureAction {
    return {
        type: constants.CAGARAN_PAY_CREATE_FAILURE,
        error
    }
}

//PAY REDIRECT
export function CagaranPayRedirectRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any
}>): actions.CagaranPayRedirectRequestAction {
    return {
        type: constants.CAGARAN_PAY_REDIRECT_REQUEST,
        params,
        onCallback
    }
}
export function CagaranPayRedirectSuccess(data: object): actions.CagaranPayRedirectSuccessAction {
    return {
        type: constants.CAGARAN_PAY_REDIRECT_SUCCESS,
        data
    }
}
export function CagaranPayRedirectFailure(error: string): actions.CagaranPayRedirectFailureAction {
    return {
        type: constants.CAGARAN_PAY_REDIRECT_FAILURE,
        error
    }
}
