import * as actions from "../action/setting";
import * as constants from "../constant/setting";

export interface UsersState {
  data: object;
  error: string;
  loading: boolean;
  mobileStatus: boolean;
}

export const settingReducer = (
  state: UsersState = {
    data: {},
    error: "",
    loading: false,
    mobileStatus: false,
  },
  action: actions.SettingAction
) => {
  switch (action.type) {
    //
    // REQUEST TO KILL
    //
    case constants.SETTING_KILLSWITCH_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SETTING_KILLSWITCH_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.SETTING_KILLSWITCH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // VIEW STATUS
    //
    case constants.SETTING_KILLSWITCH_VIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SETTING_KILLSWITCH_VIEW_SUCCESS: {
      return {
        ...state,
        mobileStatus: action.data as boolean,
        loading: false,
      };
    }
    case constants.SETTING_KILLSWITCH_VIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
