//
// PS TELCO DATATABLE
//
export const PSTELCO_DATATABLE_REQUEST = 'PELAN/TELCO_DATATABLE_REQUEST';
export const PSTELCO_DATATABLE_SUCCESS = 'PELAN/TELCO_DATATABLE_SUCCESS';
export const PSTELCO_DATATABLE_FAILURE = 'PELAN/TELCO_DATATABLE_FAILURE';

//
// PS TELCO - DRAFT
//
export const PSTELCO_DRAFT_REQUEST = 'PS/TELCO_DRAFT_REQUEST';
export const PSTELCO_DRAFT_SUCCESS = 'PS/TELCO_DRAFT_SUCCESS';
export const PSTELCO_DRAFT_FAILURE = 'PS/TELCO_DRAFT_FAILURE';

//
// PS TELCO - EDIT
//
export const PSTELCO_EDIT_REQUEST = 'PS/TELCO_EDIT_REQUEST';
export const PSTELCO_EDIT_SUCCESS = 'PS/TELCO_EDIT_SUCCESS';
export const PSTELCO_EDIT_FAILURE = 'PS/TELCO_EDIT_FAILURE';

//
// PS TELCO - SUBMIT
//
export const PSTELCO_SUBMIT_REQUEST = 'PS/TELCO_SUBMIT_REQUEST';
export const PSTELCO_SUBMIT_SUCCESS = 'PS/TELCO_SUBMIT_SUCCESS';
export const PSTELCO_SUBMIT_FAILURE = 'PS/TELCO_SUBMIT_FAILURE';

//
// PS TELCO - VIEW
//
export const PSTELCO_VIEW_REQUEST = 'PS/TELCO_VIEW_REQUEST';
export const PSTELCO_VIEW_SUCCESS = 'PS/TELCO_VIEW_SUCCESS';
export const PSTELCO_VIEW_FAILURE = 'PS/TELCO_VIEW_FAILURE';

//
// PS TELCO - SEARCH
//
export const PSTELCO_SEARCH_REQUEST = 'PS/TELCO_SEARCH_REQUEST';
export const PSTELCO_SEARCH_SUCCESS = 'PS/TELCO_SEARCH_SUCCESS';
export const PSTELCO_SEARCH_FAILURE = 'PS/TELCO_SEARCH_FAILURE';