import * as actions from '../action/jualan';
import * as constants from '../constant/jualan';

export interface JualanState {
  data: object;
  error: string;
  loading: boolean;
  loadingProducts: boolean;
  products: any;
  product: any;
}

export const jualanReducer = (
  state: JualanState = {
    data: {},
    error: '',
    loading: false,
    products: [],
    product: [],
    loadingProducts: false,
  },
  action: actions.JualanAction
) => {
  switch (action.type) {
    //
    //  CREATE JUALAN
    //
    case constants.CREATE_JUALAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }

    case constants.CREATE_JUALAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CREATE_JUALAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  CANCEL JUALAN
    //
    case constants.CANCEL_JUALAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }

    case constants.CANCEL_JUALAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CANCEL_JUALAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  JUALAN DATATABLE
    //
    case constants.JUALAN_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }

    case constants.JUALAN_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.JUALAN_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    //  JUALAN PRODUCTS
    //
    case constants.JUALAN_PRODUCTS_REQUEST: {
      return {
        ...state,
        loadingProducts: true,
        error: '',
      };
    }

    case constants.JUALAN_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loadingProducts: false,
        products: (action.data as any).data,
      };
    }

    case constants.JUALAN_PRODUCTS_FAILURE: {
      return {
        ...state,
        loadingProducts: false,
        error: action.error,
      };
    }

    //
    //  JUALAN PRODUCT
    //
    case constants.JUALAN_PRODUCT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }

    case constants.JUALAN_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        product: (action.data as any).data,
      };
    }

    case constants.JUALAN_PRODUCT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
