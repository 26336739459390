//
// PS TASKA DATATABLE
//
export const PSTASKA_DATATABLE_REQUEST = 'PS/TASKA_DATATABLE_REQUEST';
export const PSTASKA_DATATABLE_SUCCESS = 'PS/TASKA_DATATABLE_SUCCESS';
export const PSTASKA_DATATABLE_FAILURE = 'PS/TASKA_DATATABLE_FAILURE';

//
// PS TASKA - DRAFT
//
export const PSTASKA_DRAFT_REQUEST = 'PS/TASKA_DRAFT_REQUEST';
export const PSTASKA_DRAFT_SUCCESS = 'PS/TASKA_DRAFT_SUCCESS';
export const PSTASKA_DRAFT_FAILURE = 'PS/TASKA_DRAFT_FAILURE';

//
// PS TASKA - EDIT
//
export const PSTASKA_EDIT_REQUEST = 'PS/TASKA_EDIT_REQUEST';
export const PSTASKA_EDIT_SUCCESS = 'PS/TASKA_EDIT_SUCCESS';
export const PSTASKA_EDIT_FAILURE = 'PS/TASKA_EDIT_FAILURE';

//
// PS TASKA - SUBMIT
//
export const PSTASKA_SUBMIT_REQUEST = 'PS/TASKA_SUBMIT_REQUEST';
export const PSTASKA_SUBMIT_SUCCESS = 'PS/TASKA_SUBMIT_SUCCESS';
export const PSTASKA_SUBMIT_FAILURE = 'PS/TASKA_SUBMIT_FAILURE';

//
// PS TASKA - VIEW
//
export const PSTASKA_VIEW_REQUEST = 'PS/TASKA_VIEW_REQUEST';
export const PSTASKA_VIEW_SUCCESS = 'PS/TASKA_VIEW_SUCCESS';
export const PSTASKA_VIEW_FAILURE = 'PS/TASKA_VIEW_FAILURE';

//
// PS TASKA - SEARCH
//
export const PSTASKA_SEARCH_REQUEST = 'PS/TASKA_SEARCH_REQUEST';
export const PSTASKA_SEARCH_SUCCESS = 'PS/TASKA_SEARCH_SUCCESS';
export const PSTASKA_SEARCH_FAILURE = 'PS/TASKA_SEARCH_FAILURE';