import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/pskedai';
import * as constants from '../constant/pskedai';
import * as functions from '../function/pskedai';



//
// Ps Kedai DATATABLE
//
function* getPsKedaiDatatable({params, onCallback}: actions.PsKedaiDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kedai/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKedaiDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKedaiDatatableFailure(error));
    }    
};

//
// PsKedai Draft
//
function* getPsKedaiDraft({params, onCallback}: actions.PsKedaiDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kedai/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKedaiDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKedaiDraftFailure(error));
    }
};

//
// PsKedai Edit
//
function* getPsKedaiEdit({params, onCallback}: actions.PsKedaiEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kedai/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKedaiEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKedaiEditFailure(error));
    }
};

//
// PsKedai Submit
//
function* getPsKedaiSubmit({params, onCallback}: actions.PsKedaiSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kedai/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKedaiSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKedaiSubmitFailure(error));
    }
};


//
// PsKedai View
//
function* getPsKedaiView({params, onCallback}: actions.PsKedaiViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kedai/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKedaiViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKedaiViewFailure(error));
    }
};

//
// PsKedai Search
//
function* getPsKedaiSearch({params, onCallback}: actions.PsKedaiViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/kedai/search`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsKedaiSearchSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsKedaiSearchFailure(error));
    }
};


export function* watchPsKedai() {
    
    yield takeLatest(constants.PSKEDAI_DATATABLE_REQUEST, getPsKedaiDatatable);
    yield takeLatest(constants.PSKEDAI_DRAFT_REQUEST, getPsKedaiDraft);
    yield takeLatest(constants.PSKEDAI_EDIT_REQUEST, getPsKedaiEdit);
    yield takeLatest(constants.PSKEDAI_SUBMIT_REQUEST, getPsKedaiSubmit);
    yield takeLatest(constants.PSKEDAI_VIEW_REQUEST, getPsKedaiView);
    yield takeLatest(constants.PSKEDAI_SEARCH_REQUEST, getPsKedaiSearch);

};


