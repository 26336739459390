import * as actions from '../action/registration';
import * as constants from '../constant/registration';

export interface RegistrationState {
  data: object;
  error: string;
  loading: boolean;
  token: string;
}

export const registrationReducer = (
  state: RegistrationState = {
    data: {},
    error: "",
    token: "",
    loading: false
  },
  action: actions.RegistrationAction) => {    
    switch (action.type) {

      //
      // REGISTRATION
      //
      case constants.REGISTRATION_REQUEST: {
        return {
          ...state,
          loading: true,
          token: "",
          error: ""
        };
      }
      case constants.REGISTRATION_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          token: (action.data as any).token,
          loading: false
        };
      }
      case constants.REGISTRATION_FAILURE: {
        return {
          ...state,
          token: "",
          loading: false,
          error: action.error
        };
      }


      //
      // VERIFYTAC
      //
      case constants.VERIFYTAC_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ''
        };
      }
      case constants.VERIFYTAC_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          token: (action.data as any).token,
          ic: (action.data as any).ic,
          loading: false
        };
      }
      case constants.VERIFYTAC_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }


      //
      // NEWPASSWORD
      //
      case constants.NEWPASSWORD_REQUEST: {
        return {
          ...state,
          loading: true,
          error: ''
        };
      }
      case constants.NEWPASSWORD_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          ic: (action.data as any).ic,
          loading: false
        };
      }
      case constants.NEWPASSWORD_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

      
      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  