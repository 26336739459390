import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import quriobot from "../assets/logo/quriobot.png";
import { ISPROD } from "../config";

const useLoadChatBot = (url) => {
  const [chatBot, setChatbot] = useState(<></>);
  const [chatBotId, setChatBotId] = useState(
    ISPROD ? "MnyjZrg0ZyEdv40x" : "QeWyGENq11raLRj0"
  );
  const [listProdBot, setListProdBot] = useState([
    "/lesen",
    "/kompaun",
    "/sewaan",
    "/cukai/akaun",
    "/cukai/pengurangan",
    "/cukai/kemaskini",
    "/cukai/pindahmilik",
    "/cukai",
    "/loyalti",
    "/sekat",
  ]);

  useEffect(() => {
    console.log(url);
    const token = localStorage.getItem("mymbsa-token");
    let botId = "";
    if (ISPROD) {
      if (url.includes("/lesen")) {
        botId = "aPW6jrqdlvmR4ZXQ";
      } else if (url.includes("/kompaun")) {
        botId = "MqLdPEndKgm6RWpJ";
      } else if (url.includes("/sewaan")) {
        botId = "OKyGVEXX7dE7dMWX";
      } else if (url.includes("/cukai")) {
        botId = "5doqer3no0mR6ZL0";
      } else if (url.includes("/loyalti")) {
        botId = "nVWXyEMXwjrKk143";
      } else if (url.includes("/sekat")) {
        botId = "lD1XMmdMolrPVZKN";
      } else if (url === "/") {
        botId = token ? "R0nPpbv8kBrXz4w6" : "k69l1meZo7rQoAMe";
      } else {
        botId = "";
      }
    } else {
      switch (url) {
        case url.includes("/lesen"):
          botId = "pXNoar1GJkblBOn4";
          break;
        case url.includes("/bil"):
          botId = "zGgLNrKX4OEqRAoJ";
          break;
        case url.includes("/tempahan"):
          botId = "wKYPWr6kovEd0px4";
          break;
        case url.includes("/cagaran"):
          botId = "MqLdPEndGKm6RWpJ";
          break;
        case url.includes("/ppm"):
          botId = "p6yDjmQXAPb32lqX";
          break;
        default:
          botId = "8qJgArRXWgbdvoR9";
      }
    }

    setChatbot(
      <>
        <Helmet>
          {ISPROD ? (
            <script type="text/javascript">
              {`if (!Array.isArray(window.qbOptions)) {
              window.qbOptions = []
          }
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/k69l1meZo7rQoAMe", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/aPW6jrqdlvmR4ZXQ", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/MqLdPEndKgm6RWpJ", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/OKyGVEXX7dE7dMWX", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/5doqer3no0mR6ZL0", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/nVWXyEMXwjrKk143", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/lD1XMmdMolrPVZKN", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/R0nPpbv8kBrXz4w6", "showStickyAvatar":"never", "autoStart": false})`}
            </script>
          ) : (
            <script type="text/javascript">
              {`if (!Array.isArray(window.qbOptions)) {
              window.qbOptions = []
          }
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/pXNoar1GJkblBOn4", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/p6yDjmQXAPb32lqX", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/MqLdPEndGKm6RWpJ", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/wKYPWr6kovEd0px4", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/zGgLNrKX4OEqRAoJ", "showStickyAvatar":"never", "autoStart": false})
          window.qbOptions.push({"baseUrl":"https://botsrv2.com","use":"${chatBotId}/8qJgArRXWgbdvoR9", "showStickyAvatar":"never", "autoStart": false});`}
            </script>
          )}
          <script
            type="text/javascript"
            src="https://static.botsrv2.com/website/js/widget2.3e9974b2.min.js"
            integrity="sha384-uDN12abD8gCbw86PQZ6RmV6bmiezbeCPoVv0TLkiGfS3IZJq5JxBKR1iGe9S4nFZ"
            crossOrigin="anonymous"
            defer
            data-no-minify="1"
          ></script>
          <script>
            {`
            //alert("here")
              document.getElementById('hideTrigger').addEventListener("click", function(e){
                quriobot.hide_frame();
              })

              document.getElementById('hideTrigger').click()

              document.getElementById('chatTrigger').addEventListener("click", function(e){
                quriobot.start('${chatBotId}/${botId}');
              })
          `}
          </script>
        </Helmet>
        {ISPROD && (
          <>
            {url === "/" ? (
              <button
                id="chatTrigger"
                className="bg-mymbsa-primarylight px-5 py-2 text-white rounded-tl-full rounded-bl-full fixed right-0 bottom-10 cursor-pointer"
                style={{ zIndex: "999" }}
              >
                <img
                  src={quriobot}
                  alt="bot"
                  className="w-12 h-12 rounded-full bg-mymbsa-primary shadow"
                />
              </button>
            ) : (
              listProdBot.includes(url) && (
                <button
                  id="chatTrigger"
                  className="bg-mymbsa-primarylight px-5 py-2 text-white rounded-tl-full rounded-bl-full fixed right-0 bottom-10 cursor-pointer"
                  style={{ zIndex: "999" }}
                >
                  <img
                    src={quriobot}
                    alt="bot"
                    className="w-12 h-12 rounded-full bg-mymbsa-primary shadow"
                  />
                </button>
              )
            )}
          </>
        )}

        {!ISPROD && (
          <button
            id="chatTrigger"
            className="bg-mymbsa-primarylight px-5 py-2 text-white rounded-tl-full rounded-bl-full fixed right-0 bottom-10 cursor-pointer"
            style={{ zIndex: "999" }}
          >
            <img
              src={quriobot}
              alt="bot"
              className="w-12 h-12 rounded-full bg-mymbsa-primary shadow"
            />
          </button>
        )}

        <button
          id="hideTrigger"
          className="hidden"
          style={{ zIndex: "999" }}
        ></button>
      </>
    );
  }, [url]);

  return [chatBot];
};

export default useLoadChatBot;
