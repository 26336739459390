import * as actions from '../action/kpm';
import * as constants from '../constant/kpm';

export interface KpmState {
  data: object;
  error: string;
  loading: boolean;
}

export const kpmReducer = (
  state: KpmState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.KpmAction) => {    
    switch (action.type) {
      //
      // KPM SEARCH
      //
      case constants.KPM_SEARCH_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_SEARCH_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_SEARCH_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM DATATABLE
      //
      case constants.KPM_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM DRAFT
      //
      case constants.KPM_DRAFT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_DRAFT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_DRAFT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM EDIT
      //
      case constants.KPM_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM SUBMIT
      //
      case constants.KPM_SUBMIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_SUBMIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_SUBMIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM VIEW
      //
      case constants.KPM_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM PDF
      //
      case constants.KPM_PDF_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_PDF_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_PDF_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM PAY CREATE
      //
      case constants.KPM_PAY_CREATE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_PAY_CREATE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_PAY_CREATE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM PAY REDIRECT
      //
      case constants.KPM_PAY_REDIRECT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_PAY_REDIRECT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_PAY_REDIRECT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM DELETE DRAFT
      //
      case constants.KPM_DELETEDRAFT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_DELETEDRAFT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_DELETEDRAFT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM PANEL
      //
      case constants.KPM_PANEL_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_PANEL_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_PANEL_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM KLINIK
      //
      case constants.KPM_KLINIK_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_KLINIK_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_KLINIK_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // KPM PREMISE TYPE
      //
      case constants.KPM_PREMISETYPE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.KPM_PREMISETYPE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.KPM_PREMISETYPE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      

      //
      // KPM RESET
      //
      case constants.KPM_RESET: {
        return {
          ...state,
          data: {},
          error: '',
          loading: false,
        };
      }

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  