import { AnimatePresence, motion } from "framer-motion";
import React, { MutableRefObject, useEffect, useState } from "react";
import { BsFillCaretDownFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { CUSTOMIZE_SETLOCALE } from "../../../redux/constant/customize";
import { UPDATELOCALE_REQUEST } from "../../../redux/constant/user";

interface SelectMenuOptionProps {
  value: string;
  title: string;
  flag: string;
}

const Locales: SelectMenuOptionProps[] = [
  {
    title: "Malaysia",
    value: "ms",
    flag: "MY",
  },
  {
    title: "English",
    value: "en",
    flag: "GB",
  },
];

const SelectLocale = React.forwardRef<HTMLDivElement>((props: any, ref) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  // const customize = useSelector((state: any) => state.customize);
  const langCode = localStorage.getItem("lang");
  const [selectedValue, setSelectedValue] = useState<SelectMenuOptionProps>(
    Locales.find((l) => l.value == langCode) as SelectMenuOptionProps
  );
  const handleChangeLocale = (value: any) => {
    setSelectedValue(value);

    // STORE DEKAT LOCAL STORAGE DULU, JADIKAN SEMI-PERSIST
    localStorage.setItem("lang", value.value);

    //update to DB then reload locale message
    dispatch({
      type: UPDATELOCALE_REQUEST,
      params: {
        locale_code: value.value,
      },
      // onCallback: (props: any) => {
      //   // dispatch({
      //   //   type: CUSTOMIZE_SETLOCALE,
      //   //   locale: value.value,
      //   // });
      // },
    });
  };

  useEffect(() => {
    const mutableRef = ref as MutableRefObject<HTMLDivElement | null>;
    const handleClickOutside = (event: any) => {
      if (
        mutableRef.current &&
        !mutableRef.current.contains(event.target) &&
        open
      ) {
        setOpen(!open);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref} className="">
      <div className="relative">
        <button
          type="button"
          className={`relative w-40 pl-2 cursor-pointer font-bold text-left ${
            props?.whiteMode && "text-white"
          } sm:text-sm `}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={() => setOpen(!open)}
        >
          <div className=" flex items-center">
            <span className="block truncate">
              <div className=" flex items-center">
                {/* <img
                  alt={`${selectedValue.value}`}
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedValue.flag}.svg`}
                  className={"inline mr-2 h-4 rounded-sm"}
                /> */}
                {selectedValue.title === "Malaysia"
                  ? "Bahasa Malaysia"
                  : selectedValue.title}
              </div>
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <BsFillCaretDownFill />
            </span>
          </div>
        </button>

        <AnimatePresence>
          {open && (
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-80 rounded-md text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <div
                className={
                  "max-h-64 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll"
                }
              >
                {Locales.map((value, index) => {
                  return (
                    <li
                      key={`${Math.random()}`}
                      className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 flex items-center hover:bg-gray-50 transition cursor-pointer"
                      id="listbox-option-0"
                      role="option"
                      onClick={() => {
                        handleChangeLocale(value);
                        setOpen(!open);
                      }}
                    >
                      {/* <img
                        alt={`${value.value}`}
                        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.flag}.svg`}
                        className={"inline mr-2 h-4 rounded-sm"}
                      /> */}
                      <span
                        className={`${
                          value.value === selectedValue.value
                            ? "font-bold"
                            : "font-normal"
                        } truncate`}
                      >
                        {value.title === "Malaysia"
                          ? "Bahasa Malaysia"
                          : value.title}
                      </span>
                    </li>
                  );
                })}
              </div>
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
});

export default SelectLocale;
