//////////////////////
// PRODUCTION
//////////////////////
export const API_URL = "https://api.mymbsa.gov.my/api/v1";
export const DEFAULT_LOCALE = "ms";
export const BASICAUTH_USER = "admin";
export const BASICAUTH_PASS = "dUfnXLj5zT6N42zn";
export const ISPROD = true;
export const IPBT_URL = "https://ipbt.mbsa.gov.my/api/v1";
export const IPBTAUTH_USER = "111111111111";
export const IPBTAUTH_PASS = "Mbsa@1234";

//////////////////////
// DEVELOPMENT
//////////////////////
//export const API_URL = "https://api-dev.mymbsa.gov.my/api/v1";
//export const DEFAULT_LOCALE = "ms";
//export const BASICAUTH_USER = "admin";
//export const BASICAUTH_PASS = "Admin@123";
//export const ISPROD = false;
//export const IPBT_URL = "https://ipbt-dev.mbsa.gov.my/api/v1";
//export const IPBTAUTH_USER = "111111111111";
//export const IPBTAUTH_PASS = "Mbsa@1234";

//////////////////////
// LOCAL
//////////////////////
//export const API_URL='http://localhost:8000/api/v1';
//export const DEFAULT_LOCALE='ms';
//export const BASICAUTH_USER='admin';
//export const BASICAUTH_PASS='Admin@123';
//export const ISPROD = false;
//export const IPBT_URL='https://ipbt-dev.mbsa.gov.my/api/v1';
//export const IPBTAUTH_USER='111111111111';
//export const IPBTAUTH_PASS='Mbsa@1234';
