//
// KOMPAUN DATATABLE
//
export const KOMPAUN_DATATABLE_REQUEST = "KOMPAUN/KOMPAUN_DATATABLE_REQUEST";
export const KOMPAUN_DATATABLE_SUCCESS = "KOMPAUN/KOMPAUN_DATATABLE_SUCCESS";
export const KOMPAUN_DATATABLE_FAILURE = "KOMPAUN/KOMPAUN_DATATABLE_FAILURE";

//
//SEARCH BY VIEW
//
export const KOMPAUN_VIEW_REQUEST = "KOMPAUN/KOMPAUN_VIEW_REQUEST";
export const KOMPAUN_VIEW_SUCCESS = "KOMPAUN/KOMPAUN_VIEW_SUCCESS";
export const KOMPAUN_VIEW_FAILURE = "KOMPAUN/KOMPAUN_VIEW_FAILURE";

//
//KOMPAUN TUNDA
//
export const KOMPAUN_TUNDA_REQUEST = "KOMPAUN/KOMPAUN_TUNDA_REQUEST";
export const KOMPAUN_TUNDA_SUCCESS = "KOMPAUN/KOMPAUN_TUNDA_SUCCESS";
export const KOMPAUN_TUNDA_FAILURE = "KOMPAUN/KOMPAUN_TUNDA_FAILURE";

//
//KOMPAUN CARIAN
//
export const KOMPAUN_CARIAN_REQUEST = "KOMPAUN/KOMPAUN_CARIAN_REQUEST";
export const KOMPAUN_CARIAN_SUCCESS = "KOMPAUN/KOMPAUN_CARIAN_SUCCESS";
export const KOMPAUN_CARIAN_FAILURE = "KOMPAUN/KOMPAUN_CARIAN_FAILURE";

//
//KOMPAUN CARIAN NOT SECURED
//
export const KOMPAUN_CARIAN_NOT_SECURED_REQUEST =
  "KOMPAUN/KOMPAUN_CARIAN_NOT_SECURED_REQUEST";
export const KOMPAUN_CARIAN_NOT_SECURED_SUCCESS =
  "KOMPAUN/KOMPAUN_CARIAN_NOT_SECURED_SUCCESS";
export const KOMPAUN_CARIAN_NOT_SECURED_FAILURE =
  "KOMPAUN/KOMPAUN_CARIAN_NOT_SECURED_FAILURE";
