import { call, put, select, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/user";
import * as constants from "../constant/user";
import * as functions from "../function/user";

//
// DELETE USER
//
function* deleteUser({ params, onCallback }: actions.DeleteUserRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/user/deleteUser`,
    method: "POST",
    token: impersonateToken ?? token,
    data: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.deleteUserSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.deleteUserFailure(error));
  }
}

//
// UPDATE PROFILE
//
function* updateProfile({
  params,
  onCallback,
}: actions.UpdateProfileRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/user/update`,
    method: "POST",
    token: impersonateToken ?? token,
    data: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.updateProfileSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.updateProfileFailure(error));
  }
}

//
// CHANGE PASSWORD
//
function* updatePassword({
  params,
  onCallback,
}: actions.UpdatePasswordRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/user/changePassword`,
    method: "POST",
    token: impersonateToken ?? token,
    data: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.updatePasswordSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.updatePasswordFailure(error));
  }
}

//
// ADMIN CHANGE PASSWORD
//
function* adminUpdatePassword({
  params,
  onCallback,
}: actions.AdminUpdatePasswordRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/user/adminChangePassword`,
    method: "POST",
    token: impersonateToken ?? token,
    data: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.adminUpdatePasswordSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.adminUpdatePasswordFailure(error));
  }
}

//
// USER DETAILS
//
function* userDetails({
  params,
  onCallback,
}: actions.UserDetailsRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/user`,
    method: "GET",
    params: params,
    token: impersonateToken ?? token,
  });
  if (status === 200) {
    console.log("DATA", data);
    const newData = {
      ...data,
      name: data.name.replace(/amp;/gm, "").replace(/&#039;/gm, "'"),
    };
    onCallback &&
      onCallback({
        status: 1,
        data: newData,
      });
    yield put(functions.userDetailsSuccess(newData));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.userDetailsFailure(error));
  }
}

//
// UPDATE ADDRESS
//
function* updateAddress({
  params,
  onCallback,
}: actions.UpdateAddressRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/address/update`,
    method: "POST",
    token: impersonateToken ?? token,
    data: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.updateAddressSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.updateAddressFailure(error));
  }
}

//
// UPDATE CONTACT
//
function* updateContact({
  params,
  onCallback,
}: actions.UpdateContactRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/contact/update`,
    method: "POST",
    token: impersonateToken ?? token,
    data: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.updateContactSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.updateContactFailure(error));
  }
}

//
// ADDRESS
//
function* getAddress({ params, onCallback }: actions.AddressRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/address`,
    method: "GET",
    token: impersonateToken ?? token,
    params: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.addressSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.addressFailure(error));
  }
}

//
// CONTRCT
//
function* getContact({ params, onCallback }: actions.ContactRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/contact`,
    method: "GET",
    token: impersonateToken ?? token,
    params: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.contactSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.contactFailure(error));
  }
}

//
// GET EMPLOYER
//
function* getEmployer({ onCallback }: actions.EmployerRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/employer`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.employerSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.employerFailure(error));
  }
}

//
// UPDATE EMPLOYER
//
function* updateEmployer({
  params,
  onCallback,
}: actions.UpdateEmployerRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/employer/update`,
    method: "POST",
    token: impersonateToken ?? token,
    data: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.updateEmployerSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.updateEmployerFailure(error));
  }
}

//
// UPDATE LOCALE
//
function* updateLocale({
  params,
  onCallback,
}: actions.UpdateLocaleRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/user/updateI18n`,
    method: "POST",
    token: impersonateToken ?? token,
    data: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.updateLocaleSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.updateLocaleFailure(error));
  }
}

//
// UPDATE EMAIL
//
function* updateEmail({
  params,
  onCallback,
}: actions.UpdateEmailRequestAction) {
  //JWT token
  const { token, impersonateToken } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/user/updateEmail`,
    method: "POST",
    token: impersonateToken ?? token,
    data: params,
  });
  if (status === 200) {
    onCallback &&
      onCallback({
        status: 1,
        data: data,
      });
    yield put(functions.updateEmailSuccess(data));
  } else {
    onCallback &&
      onCallback({
        status: 0,
        error: error,
      });
    yield put(functions.updateEmailFailure(error));
  }
}

export function* watchUser() {
  yield takeLatest(constants.DELETE_USER_REQUEST, deleteUser);
  yield takeLatest(constants.UPDATEPROFILE_REQUEST, updateProfile);
  yield takeLatest(constants.UPDATEPASSWORD_REQUEST, updatePassword);
  yield takeLatest(constants.ADMINUPDATEPASSWORD_REQUEST, adminUpdatePassword);
  yield takeLatest(constants.USERDETAILS_REQUEST, userDetails);

  yield takeLatest(constants.ADDRESS_REQUEST, getAddress);
  yield takeLatest(constants.UPDATEADDRESS_REQUEST, updateAddress);

  yield takeLatest(constants.CONTACT_REQUEST, getContact);
  yield takeLatest(constants.UPDATECONTACT_REQUEST, updateContact);

  yield takeLatest(constants.EMPLOYER_REQUEST, getEmployer);
  yield takeLatest(constants.UPDATEEMPLOYER_REQUEST, updateEmployer);
  yield takeLatest(constants.UPDATELOCALE_REQUEST, updateLocale);
  yield takeLatest(constants.UPDATEEMAIL_REQUEST, updateEmail);
}
