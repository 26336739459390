import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/reference";
import * as constants from "../constant/reference";
import * as functions from "../function/reference";

//
// REFERENCE POSTCODE
//
function* getReferencePostcode({
  params,
  onCallback,
}: actions.ReferencePostcodeRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/reference/postcode`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.ReferencePostcodeSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.ReferencePostcodeFailure(error));
  }
}

//
// REFERENCE DISCTRIC || MUKIM
//
function* getReferenceDistrict({
  params,
  onCallback,
}: actions.ReferencePostcodeRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/mukim-list`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.ReferenceDistrictSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.ReferenceDistrictFailure(error));
  }
}

//
// REFERENCE DISCTRIC || MUKIM
//
function* getReferenceSectionWithMukim({
  params,
  onCallback,
}: actions.ReferenceSectionWithMukimRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/lesen/seksyenMukim-list`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.ReferenceSectionWithMukimSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.ReferenceSectionWithMukimFailure(error));
  }
}

//
// REFERENCE STATES
//
function* getReferenceStates({
  onCallback,
}: actions.ReferenceStatesRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/reference/states`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.ReferenceStatesSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.ReferenceStatesFailure(error));
  }
}

//
// REFERENCE COUNTRIES
//
function* getRefereneCeountries({
  onCallback,
}: actions.ReferenceCountriesRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/reference/countries`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.ReferenceCountriesSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.ReferenceCountriesFailure(error));
  }
}

//
// REFERENCE SECTIONS
//
function* getReferenceSections({
  onCallback,
}: actions.ReferenceStatesRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/reference/sections`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.ReferenceSectionsSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.ReferenceSectionsFailure(error));
  }
}

//
// REFERENCE MODULES
//
function* getReferenceModules({
  onCallback,
}: actions.ReferenceModulesRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/reference/modules`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.ReferenceModulesSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.ReferenceModulesFailure(error));
  }
}

export function* watchReference() {
  yield takeLatest(constants.REFERENCE_POSTCODE_REQUEST, getReferencePostcode);
  yield takeLatest(constants.REFERENCE_DISTRICT_REQUEST, getReferenceDistrict);
  yield takeLatest(
    constants.REFERENCE_SECTION_WITH_MUKIM_REQUEST,
    getReferenceSectionWithMukim
  );
  yield takeLatest(constants.REFERENCE_STATES_REQUEST, getReferenceStates);
  yield takeLatest(
    constants.REFERENCE_COUNTRIES_REQUEST,
    getRefereneCeountries
  );
  yield takeLatest(constants.REFERENCE_SECTIONS_REQUEST, getReferenceSections);
  yield takeLatest(constants.REFERENCE_MODULES_REQUEST, getReferenceModules);
}
