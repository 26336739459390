import * as actions from "../action/bil";
import * as constants from "../constant/bil";

export interface BilState {
  data: object;
  error: string;
  loading: boolean;
}

export const bilReducer = (
  state: BilState = {
    data: {},
    error: "",
    loading: false,
  },
  action: actions.BilAction
) => {
  switch (action.type) {
    //
    // BIL DATATABLE
    //
    case constants.BIL_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.BIL_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.BIL_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //VIEW
    case constants.BIL_VIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case constants.BIL_VIEW_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.BIL_VIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
