import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/permit';
import * as constants from '../constant/permit';
import * as functions from '../function/permit';


// 1. /////////////////////////////////////////////////////////////////////////////// PTJ
//
// PTJ DATATABLE
//
function* getPTJDatatable({params, onCallback}: actions.PTJDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ptj/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PTJDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PTJDatatableFailure(error));
    }
    
};

//
// PTJ VIEW
//
function* getPTJView({params, onCallback}: actions.PTJViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ptj/view`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PTJViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PTJViewFailure(error));
    }
    
};

//
// PTJ PAY CREATE
//
function* getPTJPayCreate({params, onCallback}: actions.PTJPayCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ptj/payment/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PTJPayCreateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PTJPayCreateFailure(error));
    }
    
};

//
// PTJ PAY REDIRECT
//
function* getPTJPayRedirect({params, onCallback}: actions.PTJPayRedirectRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ptj/payment/redirect`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PTJPayRedirectSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PTJPayRedirectFailure(error));
    }
    
};


// 2. /////////////////////////////////////////////////////////////////////////////// PB
//
// PB DATATABLE
//
function* getPBDatatable({params, onCallback}: actions.PBDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pb/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PBDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PBDatatableFailure(error));
    }
    
};

//
// PB VIEW
//
function* getPBView({params, onCallback}: actions.PBViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pb/view`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PBViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PBViewFailure(error));
    }
    
};

//
// PB PAY CREATE
//
function* getPBPayCreate({params, onCallback}: actions.PBPayCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pb/payment/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PBPayCreateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PBPayCreateFailure(error));
    }
    
};

//
// PB PAY CREATE
//
function* getPBPayRedirect({params, onCallback}: actions.PBPayRedirectRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pb/payment/redirect`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PBPayRedirectSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PBPayRedirectFailure(error));
    }
    
};

//
// PB DRAFT
//
function* getPBDraft({params, onCallback}: actions.PBDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pb/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PBDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PBDraftFailure(error));
    }
    
};

//
// PMBB SUBMIT
//
function* getPBSubmit({params, onCallback}: actions.PBSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pb/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PBSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PBSubmitFailure(error));
    }
    
};


//
// PB EDIT
//
function* getPBEdit({params, onCallback}: actions.PBEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pb/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PBEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PBEditFailure(error));
    }
    
};

//
// PB PELAN
//
function* getPBPelan({params, onCallback}: actions.PBPelanRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pb/pelan`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PBPelanSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PBPelanFailure(error));
    }    
};

//
// PB DELETEDRAFT
//
function* getPBDeleteDraft({params, onCallback}: actions.PBDeleteDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pb/deleteDraft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PBDeleteDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PBDeleteDraftFailure(error));
    }
    
};

// 3. /////////////////////////////////////////////////////////////////////////////// PMBB
//
// PMBB DATATABLE
//
function* getPMBBDatatable({params, onCallback}: actions.PMBBDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pmbb/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PMBBDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PMBBDatatableFailure(error));
    }
    
};

//
// PMBB VIEW
//
function* getPMBBView({params, onCallback}: actions.PMBBViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pmbb/view`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PMBBViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PMBBViewFailure(error));
    }
    
};

//
// PMBB PAY
//
function* getPMBBPayCreate({params, onCallback}: actions.PMBBPayCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pmbb/payment/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PMBBPayCreateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PMBBPayCreateFailure(error));
    }
    
};

//
// PMBB PAY REDIRECT
//
function* getPMBBPayRedirect({params, onCallback}: actions.PMBBPayRedirectRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pmbb/payment/redirect`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PMBBPayRedirectSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PMBBPayRedirectFailure(error));
    }
    
};

//
// PMBB DRAFT
//
function* getPMBBDraft({params, onCallback}: actions.PMBBDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pmbb/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PMBBDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PMBBDraftFailure(error));
    }
    
};

//
// PMBB SUBMIT
//
function* getPMBBSubmit({params, onCallback}: actions.PMBBSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pmbb/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PMBBSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PMBBSubmitFailure(error));
    }
    
};


//
// PMBB EDIT
//
function* getPMBBEdit({params, onCallback}: actions.PMBBEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pmbb/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PMBBEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PMBBEditFailure(error));
    }
    
};

//
// PMBB PELAN
//
function* getPMBBPelan({params, onCallback}: actions.PMBBPelanRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pmbb/pelan`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PMBBPelanSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PMBBPelanFailure(error));
    }
    
};

//
// PMBB DELETEDRAFT
//
function* getPMBBDeleteDraft({params, onCallback}: actions.PMBBDeleteDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pmbb/deleteDraft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PMBBDeleteDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PMBBDeleteDraftFailure(error));
    }
    
};



// 4. /////////////////////////////////////////////////////////////////////////////// PKT
//
// PKT DATATABLE
//
function* getPKTDatatable({params, onCallback}: actions.PKTDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTDatatableFailure(error));
    }
    
};

//
// PKT VIEW
//
function* getPKTView({params, onCallback}: actions.PKTViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/view`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTViewFailure(error));
    }
    
};

//
// PKT PAY
//
function* getPKTPayCreate({params, onCallback}: actions.PKTPayCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/payment/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTPayCreateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTPayCreateFailure(error));
    }
    
};

//
// PKT PAY REDIRECT
//
function* getPKTPayRedirect({params, onCallback}: actions.PKTPayRedirectRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/payment/redirect`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTPayRedirectSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTPayRedirectFailure(error));
    }
    
};

//
// PKT DRAFT
//
function* getPKTDraft({params, onCallback}: actions.PKTDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTDraftFailure(error));
    }
    
};

//
// PKT SUBMIT
//
function* getPKTSubmit({params, onCallback}: actions.PKTSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTSubmitFailure(error));
    }
    
};


//
// PKT EDIT
//
function* getPKTEdit({params, onCallback}: actions.PKTEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTEditFailure(error));
    }
    
};

//
// PKT PELAN
//
function* getPKTPelan({params, onCallback}: actions.PKTPelanRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/pelan`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTPelanSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTPelanFailure(error));
    }
    
};

//
// PKT DELETEDRAFT
//
function* getPKTDeleteDraft({params, onCallback}: actions.PKTDeleteDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/deleteDraft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTDeleteDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTDeleteDraftFailure(error));
    }
    
};

//
// PKT SEKSYEN JALAN
//
function* getPKTSeksyenJalan({params, onCallback}: actions.PKTSeksyenJalanRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/pkt/seksyenJalan`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PKTSeksyenJalanSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PKTSeksyenJalanFailure(error));
    }
    
};


export function* watchPermit() {
    
// 1. /////////////////////////////////////////////////////////////////////////////// PTJ
yield takeLatest(constants.PTJ_DATATABLE_REQUEST, getPTJDatatable);
yield takeLatest(constants.PTJ_VIEW_REQUEST, getPTJView);
yield takeLatest(constants.PTJ_PAY_CREATE_REQUEST, getPTJPayCreate);
yield takeLatest(constants.PTJ_PAY_REDIRECT_REQUEST, getPTJPayRedirect);


// 2. /////////////////////////////////////////////////////////////////////////////// PB
yield takeLatest(constants.PB_DATATABLE_REQUEST, getPBDatatable);
yield takeLatest(constants.PB_VIEW_REQUEST, getPBView);
yield takeLatest(constants.PB_PAY_CREATE_REQUEST, getPBPayCreate);
yield takeLatest(constants.PB_PAY_REDIRECT_REQUEST, getPBPayRedirect);
yield takeLatest(constants.PB_DRAFT_REQUEST, getPBDraft);
yield takeLatest(constants.PB_SUBMIT_REQUEST, getPBSubmit);
yield takeLatest(constants.PB_EDIT_REQUEST, getPBEdit);
yield takeLatest(constants.PB_PELAN_REQUEST, getPBPelan);
yield takeLatest(constants.PB_DELETEDRAFT_REQUEST, getPBDeleteDraft);


// 3. /////////////////////////////////////////////////////////////////////////////// PMBB
yield takeLatest(constants.PMBB_DATATABLE_REQUEST, getPMBBDatatable);
yield takeLatest(constants.PMBB_VIEW_REQUEST, getPMBBView);
yield takeLatest(constants.PMBB_PAY_CREATE_REQUEST, getPMBBPayCreate);
yield takeLatest(constants.PMBB_PAY_REDIRECT_REQUEST, getPMBBPayRedirect);
yield takeLatest(constants.PMBB_DRAFT_REQUEST, getPMBBDraft);
yield takeLatest(constants.PMBB_SUBMIT_REQUEST, getPMBBSubmit);
yield takeLatest(constants.PMBB_EDIT_REQUEST, getPMBBEdit);
yield takeLatest(constants.PMBB_PELAN_REQUEST, getPMBBPelan);
yield takeLatest(constants.PMBB_DELETEDRAFT_REQUEST, getPMBBDeleteDraft);


// 4. /////////////////////////////////////////////////////////////////////////////// PKT
yield takeLatest(constants.PKT_DATATABLE_REQUEST, getPKTDatatable);
yield takeLatest(constants.PKT_VIEW_REQUEST, getPKTView);
yield takeLatest(constants.PKT_PAY_CREATE_REQUEST, getPKTPayCreate);
yield takeLatest(constants.PKT_PAY_REDIRECT_REQUEST, getPKTPayRedirect);
yield takeLatest(constants.PKT_DRAFT_REQUEST, getPKTDraft);
yield takeLatest(constants.PKT_SUBMIT_REQUEST, getPKTSubmit);
yield takeLatest(constants.PKT_EDIT_REQUEST, getPKTEdit);
yield takeLatest(constants.PKT_PELAN_REQUEST, getPKTPelan);
yield takeLatest(constants.PKT_DELETEDRAFT_REQUEST, getPKTDeleteDraft);
yield takeLatest(constants.PKT_SEKSYENJALAN_REQUEST, getPKTSeksyenJalan);

};


