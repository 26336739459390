//
// PS BUKU - VERIFYPAID
//
export const PSBUKU_VERIFYPAID_REQUEST = 'PS/BUKU_VERIFYPAID_REQUEST';
export const PSBUKU_VERIFYPAID_SUCCESS = 'PS/BUKU_VERIFYPAID_SUCCESS';
export const PSBUKU_VERIFYPAID_FAILURE = 'PS/BUKU_VERIFYPAID_FAILURE';


//
// PS BUKU - CREATE
//
export const PSBUKU_CREATE_REQUEST = 'PS/BUKU_CREATE_REQUEST';
export const PSBUKU_CREATE_SUCCESS = 'PS/BUKU_CREATE_SUCCESS';
export const PSBUKU_CREATE_FAILURE = 'PS/BUKU_CREATE_FAILURE';

//
// PS BUKU - VIEW
//
export const PSBUKU_VIEW_REQUEST = 'PS/BUKU_VIEW_REQUEST';
export const PSBUKU_VIEW_SUCCESS = 'PS/BUKU_VIEW_SUCCESS';
export const PSBUKU_VIEW_FAILURE = 'PS/BUKU_VIEW_FAILURE';