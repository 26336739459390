import * as actions from "../action/lesen";
import * as constants from "../constant/lesen";

export interface LesenState {
  data: object;
  error: string;
  loading: boolean;
}

export const lesenReducer = (
  state: LesenState = {
    data: {},
    error: "",
    loading: false,
  },
  action: actions.LesenAction
) => {
  switch (action.type) {
    //
    // LESEN DATATABLE RENEW
    //
    case constants.LESEN_DATATABLE_RENEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_DATATABLE_RENEW_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_DATATABLE_RENEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN DATATABLE APPLY
    //
    case constants.LESEN_DATATABLE_APPLY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_DATATABLE_APPLY_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_DATATABLE_APPLY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN RENEW
    //
    case constants.LESEN_RENEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_RENEW_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_RENEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN PAY CREATE
    //
    case constants.LESEN_PAY_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_PAY_CREATE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_PAY_CREATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN PAY REDIRECT
    //
    case constants.LESEN_PAY_REDIRECT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_PAY_REDIRECT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_PAY_REDIRECT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN DRAFT
    //
    case constants.LESEN_DRAFT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_DRAFT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_DRAFT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN EDIT
    //
    case constants.LESEN_EDIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_EDIT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_EDIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN SUBMIT
    //
    case constants.LESEN_SUBMIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_SUBMIT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_SUBMIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN VIEW
    //
    case constants.LESEN_VIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_VIEW_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_VIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN DELETE DRAFT
    //
    case constants.LESEN_DELETEDRAFT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_DELETEDRAFT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_DELETEDRAFT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN DELETE DRAFT
    //
    case constants.LESEN_DOCUMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_DOCUMENT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_DOCUMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN VERIFY SEKATAN
    //
    case constants.LESEN_VERIFYSEKATAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_VERIFYSEKATAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_VERIFYSEKATAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN KATEGORILESEN
    //
    case constants.LESEN_KATEGORILESEN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_KATEGORILESEN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_KATEGORILESEN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN JENISLESEN
    //
    case constants.LESEN_JENISLESEN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_JENISLESEN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_JENISLESEN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN KODHASILLESEN
    //
    case constants.LESEN_KODHASILLESEN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_KODHASILLESEN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_KODHASILLESEN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN JENISPREMIS
    //
    case constants.LESEN_JENISPREMIS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_JENISPREMIS_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_JENISPREMIS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN KEDUDUKANPREMIS
    //
    case constants.LESEN_KEDUDUKANPREMIS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_KEDUDUKANPREMIS_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_KEDUDUKANPREMIS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN SAVE DOCUMENTS
    //
    case constants.LESEN_SAVE_DOCS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_SAVE_DOCS_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_SAVE_DOCS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN GET DOCUMENTS
    //
    case constants.LESEN_GET_DOCS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_GET_DOCS_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_GET_DOCS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN SAVE DOCUMENTS TO SERVER
    //
    case constants.LESEN_SAVE_DOCS_TO_SERVER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LESEN_SAVE_DOCS_TO_SERVER_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.LESEN_SAVE_DOCS_TO_SERVER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LESEN RESET
    //
    case constants.LESEN_RESET: {
      return {
        ...state,
        data: {},
        error: "",
        loading: false,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
