import * as actions from "../action/sewaan";
import * as constants from "../constant/sewaan";

export interface SewaanState {
  data: object;
  error: string;
  loading: boolean;
}

export const sewaanReducer = (
  state: SewaanState = {
    data: {},
    error: "",
    loading: false,
  },
  action: actions.SewaanAction
) => {
  switch (action.type) {
    //
    // SEWAAN DATATABLE
    //
    case constants.SEWAAN_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.SEWAAN_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.SEWAAN_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case constants.SEWAAN_VIEW_ELECTRIC_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SEWAAN_VIEW_ELECTRIC_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.SEWAAN_VIEW_ELECTRIC_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case constants.SEWAAN_VIEW_WATER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SEWAAN_VIEW_WATER_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.SEWAAN_VIEW_WATER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case constants.SEWAAN_VIEW_RENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SEWAAN_VIEW_RENT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.SEWAAN_VIEW_RENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case constants.SEWAAN_VIEW_NOTICE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SEWAAN_VIEW_NOTICE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.SEWAAN_VIEW_NOTICE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case constants.SEWAAN_VIEW_LICENSE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SEWAAN_VIEW_LICENSE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.SEWAAN_VIEW_LICENSE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case constants.SEWAAN_VIEW_TRANSC_ELECTRIC_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SEWAAN_VIEW_TRANSC_ELECTRIC_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.SEWAAN_VIEW_TRANSC_ELECTRIC_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case constants.SEWAAN_VIEW_TRANSC_WATER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SEWAAN_VIEW_TRANSC_WATER_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.SEWAAN_VIEW_TRANSC_WATER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case constants.SEWAAN_VIEW_TRANSC_RENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.SEWAAN_VIEW_TRANSC_RENT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.SEWAAN_VIEW_TRANSC_RENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
