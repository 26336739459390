import { FormattedMessage } from "react-intl";

const IdentityTypeOps = [
    { value: '1', label: <FormattedMessage id='identity.mykad' /> },
    { value: '2', label: <FormattedMessage id='identity.passportNo' /> },
    { value: '3', label: <FormattedMessage id='identity.solider' /> },
    { value: '4', label: <FormattedMessage id='identity.police' /> },
    { value: '5', label: <FormattedMessage id='identity.company' /> },
];

export default IdentityTypeOps;