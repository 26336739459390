import { auditReducer } from "./reducer/audit";
import { authReducer } from "./reducer/auth";
import { bayaranReducer } from "./reducer/bayaran";
import { bilReducer } from "./reducer/bil";
import { cagaranReducer } from "./reducer/cagaran";
import { combineReducers } from "redux";
import { companyReducer } from "./reducer/company";
import { cukaiReducer } from "./reducer/cukai";
import { customizeReducer } from "./reducer/customize";
import { jualanReducer } from "./reducer/jualan";
import { kompaunReducer } from "./reducer/kompaun";
import { kpmReducer } from "./reducer/kpm";
import { lesenReducer } from "./reducer/lesen";
import { localeReducer } from "./reducer/locale";
import { loyaltiReducer } from "./reducer/loyalti";
import { mapstatusReducer } from "./reducer/mapstatus";
import { permitReducer } from "./reducer/permit";
import { ppmReducer } from "./reducer/ppm";
import { psDoskmepReducer } from "./reducer/psdoskmep";
import { psbangloReducer } from "./reducer/psbanglo";
import { psbukuReducer } from "./reducer/psbuku";
import { pskedaiReducer } from "./reducer/pskedai";
import { pskediamanReducer } from "./reducer/pskediaman";
import { pskilangReducer } from "./reducer/pskilang";
import { pstaskaReducer } from "./reducer/pstaska";
import { pstelcoReducer } from "./reducer/pstelco";
import { referenceReducer } from "./reducer/reference";
import { registrationReducer } from "./reducer/registration";
import { roroReducer } from "./reducer/roro";
import { sekatReducer } from "./reducer/sekat";
import { settingReducer } from "./reducer/setting";
import { sewaanReducer } from "./reducer/sewaan";
import { showReducer } from "./reducer/show";
import { syarikatReducer } from "./reducer/syarikat";
import { tempahanReducer } from "./reducer/tempahan";
import { transactionReducer } from "./reducer/transaction";
import { troliReducer } from "./reducer/troli";
import { userReducer } from "./reducer/user";
import { usersReducer } from "./reducer/users";
import { versionReducer } from "./reducer/version";

const rootReducer = combineReducers({
  //auth
  auth: authReducer,

  //audit
  audit: auditReducer,

  //customize
  customize: customizeReducer,

  //locale
  locale: localeReducer,

  //registration
  registration: registrationReducer,

  //version
  version: versionReducer,

  //loyalti
  loyalti: loyaltiReducer,

  //product
  jualan: jualanReducer,

  //user
  user: userReducer,

  //users
  users: usersReducer,

  //reference
  reference: referenceReducer,

  //kpm
  kpm: kpmReducer,

  //ppm
  ppm: ppmReducer,

  //roro
  roro: roroReducer,

  //cukai
  cukai: cukaiReducer,

  //lesen
  lesen: lesenReducer,

  //permit
  permit: permitReducer,

  //permit
  cagaran: cagaranReducer,

  //company
  company: companyReducer,

  //status
  mapstatus: mapstatusReducer,

  //show
  show: showReducer,

  //troli
  troli: troliReducer,

  //pstelco
  pstelco: pstelcoReducer,

  //pskilang
  pskilang: pskilangReducer,

  //pstaska
  pstaska: pstaskaReducer,

  //pskedai
  pskedai: pskedaiReducer,

  //pskediaman
  pskediaman: pskediamanReducer,

  //psbuku
  psbuku: psbukuReducer,

  //transaction
  transaction: transactionReducer,

  //bil
  bil: bilReducer,

  //bayaran
  bayaran: bayaranReducer,

  //sekat
  sekat: sekatReducer,

  //kompaun
  kompaun: kompaunReducer,

  //syarikat
  syarikat: syarikatReducer,

  //sewaan
  sewaan: sewaanReducer,

  //psdoskmep
  psdoskmep: psDoskmepReducer,

  //psbanglo
  psbanglo: psbangloReducer,

  // tempahan
  tempahan: tempahanReducer,

  // setting
  setting: settingReducer,
});

export default rootReducer;
