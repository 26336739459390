import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/pstaska';
import * as constants from '../constant/pstaska';
import * as functions from '../function/pstaska';



//
// Ps Taska DATATABLE
//
function* getPsTaskaDatatable({params, onCallback}: actions.PsTaskaDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/taska/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTaskaDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTaskaDatatableFailure(error));
    }    
};

//
// PsTaska Draft
//
function* getPsTaskaDraft({params, onCallback}: actions.PsTaskaDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/taska/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTaskaDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTaskaDraftFailure(error));
    }
};

//
// PsTaska Edit
//
function* getPsTaskaEdit({params, onCallback}: actions.PsTaskaEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/taska/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTaskaEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTaskaEditFailure(error));
    }
};

//
// PsTaska Submit
//
function* getPsTaskaSubmit({params, onCallback}: actions.PsTaskaSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/taska/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTaskaSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTaskaSubmitFailure(error));
    }
};

//
// PsTaska View
//
function* getPsTaskaView({params, onCallback}: actions.PsTaskaViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/taska/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTaskaViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTaskaViewFailure(error));
    }
};

//
// PsTaska Search
//
function* getPsTaskaSearch({params, onCallback}: actions.PsTaskaSearchRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/taska/search`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsTaskaSearchSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsTaskaSearchFailure(error));
    }
};


export function* watchPsTaska() {
    
    yield takeLatest(constants.PSTASKA_DATATABLE_REQUEST, getPsTaskaDatatable);
    yield takeLatest(constants.PSTASKA_DRAFT_REQUEST, getPsTaskaDraft);
    yield takeLatest(constants.PSTASKA_EDIT_REQUEST, getPsTaskaEdit);
    yield takeLatest(constants.PSTASKA_SUBMIT_REQUEST, getPsTaskaSubmit);
    yield takeLatest(constants.PSTASKA_VIEW_REQUEST, getPsTaskaView);
    yield takeLatest(constants.PSTASKA_SEARCH_REQUEST, getPsTaskaSearch);

};


