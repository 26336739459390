import * as actions from "../action/kompaun";
import * as constants from "../constant/kompaun";

export interface KompaunState {
  data: object;
  error: string;
  loading: boolean;
}

export const kompaunReducer = (
  state: KompaunState = {
    data: {},
    error: "",
    loading: false,
  },
  action: actions.KompaunAction
) => {
  switch (action.type) {
    //
    // KOMPAUN DATATABLE
    //
    case constants.KOMPAUN_CARIAN_NOT_SECURED_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.KOMPAUN_CARIAN_NOT_SECURED_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.KOMPAUN_CARIAN_NOT_SECURED_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // KOMPAUN DATATABLE
    //
    case constants.KOMPAUN_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.KOMPAUN_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.KOMPAUN_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //VIEW
    case constants.KOMPAUN_VIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.KOMPAUN_VIEW_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.KOMPAUN_VIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //TUNDA
    case constants.KOMPAUN_TUNDA_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.KOMPAUN_TUNDA_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.KOMPAUN_TUNDA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //CARIAN
    case constants.KOMPAUN_CARIAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.KOMPAUN_CARIAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.KOMPAUN_CARIAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
