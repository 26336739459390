import { call, put, select, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/loyalti";
import * as constants from "../constant/loyalti";
import * as functions from "../function/loyalti";

//
// LOYALTI EVENTS
//
function* getLoyaltiEvents({ onCallback, attend }: actions.LoyaltiEventsRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/${attend ? "attended-events" : "events"}`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiEventsSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiEventsFailure(error));
  }
}

//
// LOYALTI EVENT
//
function* getLoyaltiEvent({ eventId, onCallback }: actions.LoyaltiEventRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/event/${eventId}`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiEventSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiEventFailure(error));
  }
}

//
// LOYALTI PRODUCTS
//
function* getLoyaltiProducts({ onCallback }: actions.LoyaltiProductsRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/products`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiProductsSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiProductsFailure(error));
  }
}

//
// LOYALTI PRODUCT
//
function* getLoyaltiProduct({ productId, onCallback }: actions.LoyaltiProductRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/product/${productId}`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiProductSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiProductFailure(error));
  }
}

//
// LOYALTI REWARD
//
function* getLoyaltiReward({ onCallback }: actions.LoyaltiRewardRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/reward`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiRewardSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiRewardFailure(error));
  }
}

//
// LOYALTI ADD TO CART
//
function* loyaltiAddToCart({ productId, onCallback }: actions.LoyaltiAddToCartRequestAction) {
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/product/${productId}`,
    method: "GET",
    token: token,
  });

  const {
    status: statusCart,
    data: dataCart,
    error: errorCart,
  } = yield call(axios.call, {
    url: `/secured/loyalti/cart`,
    method: "GET",
    token: token,
  });

  if (statusCart === 200) {
    console.log(dataCart);
    if (dataCart.length > 0) {
      const existingCartItem = dataCart;
      const findItemIndex = existingCartItem.findIndex(
        (element) => element.product.id === productId
      );

      if (findItemIndex !== -1) {
        existingCartItem[findItemIndex].quantity++;
        const {
          status: statusUpdate,
          data: dataUpdate,
          error: errorUpdate,
        } = yield call(axios.call, {
          url: `/secured/loyalti/cart/${existingCartItem[findItemIndex].id}`,
          method: "PUT",
          data: existingCartItem[findItemIndex],
        });
      } else {
        const {
          status: statusAdd,
          data: dataAdd,
          error: errorAdd,
        } = yield call(axios.call, {
          url: `/secured/loyalti/cart`,
          method: "POST",
          data: {
            product: data.data.data,
            quantity: 1,
          },
        });
      }
    } else {
      const {
        status: statusAdd,
        data: dataAdd,
        error: errorAdd,
      } = yield call(axios.call, {
        url: `/secured/loyalti/cart`,
        method: "POST",
        data: {
          product: data.data.data,
          quantity: 1,
        },
      });
    }
  }

  const {
    status: statusCartUpdated,
    data: dataCartUpdated,
    error: errorCartUpdated,
  } = yield call(axios.call, {
    url: `/secured/loyalti/cart`,
    method: "GET",
    token: token,
  });

  if (statusCartUpdated === 200) {
    onCallback({
      status: 1,
      data: dataCartUpdated,
    });
    yield put(functions.LoyaltiAddToCartSuccess(dataCartUpdated));
  } else {
    onCallback({
      status: 0,
      error: errorCartUpdated,
    });
    yield put(functions.LoyaltiAddToCartFailure(errorCartUpdated));
  }
}

//
// LOYALTI DELETE FROM CART
//
function* loyaltiDeleteFromCart({
  productId,
  onCallback,
}: actions.LoyaltiDeleteFromCartRequestAction) {
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/product/${productId}`,
    method: "GET",
    token: token,
  });

  const {
    status: statusCart,
    data: dataCart,
    error: errorCart,
  } = yield call(axios.call, {
    url: `/secured/loyalti/cart`,
    method: "GET",
    token: token,
  });

  if (statusCart === 200) {
    console.log(dataCart);
    if (dataCart.length > 0) {
      const existingCartItem = dataCart;
      const findItemIndex = existingCartItem.findIndex(
        (element) => element.product.id === productId
      );

      if (findItemIndex !== -1) {
        const {
          status: statusDelete,
          data: dataDelete,
          error: errorDelete,
        } = yield call(axios.call, {
          url: `/secured/loyalti/cart/${existingCartItem[findItemIndex].id}`,
          method: "DELETE",
          token: token,
        });
      }
    }
  }

  const {
    status: statusCartUpdated,
    data: dataCartUpdated,
    error: errorCartUpdated,
  } = yield call(axios.call, {
    url: `/secured/loyalti/cart`,
    method: "GET",
    token: token,
  });

  if (statusCartUpdated === 200) {
    onCallback({
      status: 1,
      data: dataCartUpdated,
    });
    yield put(functions.LoyaltiDeleteFromCartSuccess(dataCartUpdated));
  } else {
    onCallback({
      status: 0,
      error: errorCartUpdated,
    });
    yield put(functions.LoyaltiDeleteFromCartFailure(errorCartUpdated));
  }
}

//
// LOYALTI VIEW CART
//
function* loyaltiViewCart({ onCallback }: actions.LoyaltiViewCartRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/cart`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiViewCartSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiViewCartFailure(error));
  }
}

//
// LOYALTI GET ALL ORDER
//
function* getLoyaltiViewAllOrder({ onCallback }: actions.LoyaltiViewAllOrderRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/user-orders`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiViewAllOrderSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiViewAllOrderFailure(error));
  }
}

//
// LOYALTI ORDER
//
function* getLoyaltiViewOrder({ orderId, onCallback }: actions.LoyaltiViewOrderRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/order/${orderId}`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiViewOrderSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiViewOrderFailure(error));
  }
}

//
// LOYALTI ALL PAYMENT CATEGORY
//
function* getLoyaltiViewAllPaymentCategory({
  onCallback,
}: actions.LoyaltiViewAllPaymentCategoryRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/payment-cats`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiViewAllPaymentCategorySuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiViewAllPaymentCategoryFailure(error));
  }
}

//
// LOYALTI VIEW PAYMENT CATEGORY
//
function* getLoyaltiViewPaymentCategory({
  paymentCategoryId,
  onCallback,
}: actions.LoyaltiViewPaymentCategoryRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/payment-cat/${paymentCategoryId}`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiViewPaymentCategorySuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiViewPaymentCategoryFailure(error));
  }
}

//
// LOYALTI EVENTS2
//
function* getLoyaltiViewAllPaymentType({
  onCallback,
}: actions.LoyaltiViewAllPaymentTypeRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/payment-types`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiViewAllPaymentTypeSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiViewAllPaymentTypeFailure(error));
  }
}

//
// LOYALTI VIEW PAYMENT TYPE
//
function* getLoyaltiViewPaymentType({
  paymentTypeId,
  onCallback,
}: actions.LoyaltiViewPaymentTypeRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/payment/${paymentTypeId}`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiViewPaymentTypeSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiViewPaymentTypeFailure(error));
  }
}

//
// LOYALTI CREATE ORDER
//
function* loyaltiCreateOrder({ formData, onCallback }: actions.LoyaltiCreateOrderRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/order`,
    method: "POST",
    data: formData,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiCreateOrderSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiCreateOrderFailure(error));
  }
}

//
// LOYALTI UPDATE ORDER
//
function* loyaltiUpdateOrder({ formData, onCallback }: actions.LoyaltiUpdateOrderRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/order/update`,
    method: "POST",
    data: formData,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiUpdateOrderSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiUpdateOrderFailure(error));
  }
}

//
// LOYALTI GET ALL TRANSACTION
//
function* getLoyaltiViewAllTransaction({
  onCallback,
}: actions.LoyaltiViewAllTransactionRequestAction) {
  //JWT token
  const { token } = yield select((state: any) => state.auth);

  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/user-transactions`,
    method: "GET",
    token: token,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiViewAllTransactionSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiViewAllTransactionFailure(error));
  }
}

//
// LOYALTI CREATE Transaction
//
function* loyaltiCreateTransaction({
  formData,
  onCallback,
}: actions.LoyaltiCreateTransactionRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/loyalti/transaction`,
    method: "POST",
    data: formData,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.LoyaltiCreateTransactionSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.LoyaltiCreateTransactionFailure(error));
  }
}

export function* watchLoyalti() {
  yield takeLatest(constants.LOYALTI_EVENTS_REQUEST, getLoyaltiEvents);
  yield takeLatest(constants.LOYALTI_EVENT_REQUEST, getLoyaltiEvent);
  yield takeLatest(constants.LOYALTI_PRODUCTS_REQUEST, getLoyaltiProducts);
  yield takeLatest(constants.LOYALTI_PRODUCT_REQUEST, getLoyaltiProduct);
  yield takeLatest(constants.LOYALTI_REWARD_REQUEST, getLoyaltiReward);
  yield takeLatest(constants.LOYALTI_ADD_TO_CART_REQUEST, loyaltiAddToCart);
  yield takeLatest(constants.LOYALTI_DELETE_FROM_CART_REQUEST, loyaltiDeleteFromCart);
  yield takeLatest(constants.LOYALTI_VIEW_CART_REQUEST, loyaltiViewCart);
  yield takeLatest(constants.LOYALTI_VIEW_ALL_ORDER_REQUEST, getLoyaltiViewAllOrder);
  yield takeLatest(constants.LOYALTI_VIEW_ORDER_REQUEST, getLoyaltiViewOrder);
  yield takeLatest(
    constants.LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_REQUEST,
    getLoyaltiViewAllPaymentCategory
  );
  yield takeLatest(constants.LOYALTI_VIEW_PAYMENT_CATEGORY_REQUEST, getLoyaltiViewPaymentCategory);
  yield takeLatest(constants.LOYALTI_VIEW_ALL_PAYMENT_TYPE_REQUEST, getLoyaltiViewAllPaymentType);
  yield takeLatest(constants.LOYALTI_VIEW_PAYMENT_TYPE_REQUEST, getLoyaltiViewPaymentType);
  yield takeLatest(constants.LOYALTI_CREATE_NEW_ORDER_REQUEST, loyaltiCreateOrder);
  yield takeLatest(constants.LOYALTI_UPDATE_ORDER_REQUEST, loyaltiUpdateOrder);
  yield takeLatest(constants.LOYALTI_VIEW_ALL_TRANSACTION_REQUEST, getLoyaltiViewAllTransaction);
  yield takeLatest(constants.LOYALTI_CREATE_NEW_TRANSACTION_REQUEST, loyaltiCreateTransaction);
}
