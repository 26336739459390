import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/psdoskmep';
import * as constants from '../constant/psdoskmep';
import * as functions from '../function/psdoskmep';



//
// PSDOSKMEP DATATABLE
//
function* getPsDoskmepDatatable({params, onCallback}: actions.PsDoskmepDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/doskmep/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsDoskmepDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsDoskmepDatatableFailure(error));
    }
    
};

//
// PSDOSKMEP DELETEDRAFT
//
function* getPsDoskmepDeleteDraft({params, onCallback}: actions.PsDoskmepDeleteDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/doskmep/deleteDraft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsDoskmepDeleteDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsDoskmepDeleteDraftFailure(error));
    }
    
};

//
// PSDOSKMEP VIEW
//
function* getPsDoskmepView({params, onCallback}: actions.PsDoskmepViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/doskmep/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsDoskmepViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsDoskmepViewFailure(error));
    }
    
};

//
// PSDOSKMEP EDIT
//
function* getPsDoskmepEdit({params, onCallback}: actions.PsDoskmepEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/doskmep/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsDoskmepEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsDoskmepEditFailure(error));
    }
    
};

//
// PSDOSKMEP SUBMIT
//
function* getPsDoskmepSubmit({params, onCallback}: actions.PsDoskmepSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/doskmep/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsDoskmepSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsDoskmepSubmitFailure(error));
    }
    
};

export function* watchPsDoskmep() {
    
    yield takeLatest(constants.PSDOSKMEP_DATATABLE_REQUEST, getPsDoskmepDatatable);
    yield takeLatest(constants.PSDOSKMEP_DELETEDRAFT_REQUEST, getPsDoskmepDeleteDraft);
    yield takeLatest(constants.PSDOSKMEP_VIEW_REQUEST, getPsDoskmepView);
    yield takeLatest(constants.PSDOSKMEP_EDIT_REQUEST, getPsDoskmepEdit);
    yield takeLatest(constants.PSDOSKMEP_SUBMIT_REQUEST, getPsDoskmepSubmit);

};


