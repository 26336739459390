import * as actions from "../action/audit";
import * as constants from "../constant/audit";

//
// USERS - DATATABLE
//
export function auditDataTableRequest(
  params: any,
  limit: number,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.AuditDataTableRequestAction {
  return {
    type: constants.AUDIT_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}
export function auditDataTableSuccess(
  data: object
): actions.AuditDataTableSuccessAction {
  return {
    type: constants.AUDIT_DATATABLE_SUCCESS,
    data,
  };
}
export function auditDataTableFailure(
  error: string
): actions.AuditDataTableFailureAction {
  return {
    type: constants.AUDIT_DATATABLE_FAILURE,
    error,
  };
}
