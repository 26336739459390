import Spinner from "../../components/ui/Spinner";

    
    const LoadingPage = () => {
        return <div className="flex justify-center items-center h-screen" >
            <Spinner className="text-black" />
        </div>;
    }


    export default LoadingPage;