import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/bil";
import * as constants from "../constant/bil";
import * as functions from "../function/bil";

//
// BIL DATATABLE
//
function* getBilDatatable({
  params,
  onCallback,
}: actions.BilDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/bil/list`,
    method: "GET",
    data: params,
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.BilDatatableSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.BilDatatableFailure(error));
  }
}

function* getBilView({params, onCallback}: actions.BilViewRequestAction) {
  const {status, data, error } = yield call(axios.call, {
      url: `/secured/bil/view/${params.accNo}`,
      method: "GET",
  });
  if(status === 200) {
      onCallback({
          status: 1,
          data: data.data
      });
      yield put(functions.BilViewSuccess(data.url));     
  }else {
      onCallback({
          status: 0,
          error: error
      });
      yield put(functions.BilViewFailure(error));
  }    
};

export function* watchBil() {
  yield takeLatest(constants.BIL_DATATABLE_REQUEST, getBilDatatable);
  yield takeLatest(constants.BIL_VIEW_REQUEST, getBilView);
}
