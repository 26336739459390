import * as actions from "../action/psdoskmep";
import * as constants from "../constant/psdoskmep";

//
// PsDoskmep Datatable
//
export function PsDoskmepDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.PsDoskmepDatatableRequestAction {
  return {
    type: constants.PSDOSKMEP_DATATABLE_REQUEST,
    params,
    onCallback
  };
}
export function PsDoskmepDatatableSuccess(data: object): actions.PsDoskmepDatatableSuccessAction {
  return {
    type: constants.PSDOSKMEP_DATATABLE_SUCCESS,
    data,
  };
}
export function PsDoskmepDatatableFailure(error: string): actions.PsDoskmepDatatableFailureAction {
  return {
    type: constants.PSDOSKMEP_DATATABLE_FAILURE,
    error,
  };
}


//
// PsDoskmep Delete Draft
//
export function PsDoskmepDeleteDraftRequest(params: any, onCallback: React.FC<{
  status: number,
  data?: any,
  error?: any}>): actions.PsDoskmepDeleteDraftRequestAction {
  return {
      type: constants.PSDOSKMEP_DELETEDRAFT_REQUEST,
      params,
      onCallback
  }
}
export function PsDoskmepDeleteDraftSuccess(data: object): actions.PsDoskmepDeleteDraftSuccessAction {
  return {
      type: constants.PSDOSKMEP_DELETEDRAFT_SUCCESS,
      data
  }
}
export function PsDoskmepDeleteDraftFailure(error: string): actions.PsDoskmepDeleteDraftFailureAction {
  return {
      type: constants.PSDOSKMEP_DELETEDRAFT_FAILURE,
      error
  }
}

//
// PsDoskmep View
//
export function PsDoskmepViewRequest(params: any, onCallback: React.FC<{
  status: number,
  data?: any,
  error?: any}>): actions.PsDoskmepViewRequestAction {
  return {
      type: constants.PSDOSKMEP_VIEW_REQUEST,
      params,
      onCallback
  }
}
export function PsDoskmepViewSuccess(data: object): actions.PsDoskmepViewSuccessAction {
  return {
      type: constants.PSDOSKMEP_VIEW_SUCCESS,
      data
  }
}
export function PsDoskmepViewFailure(error: string): actions.PsDoskmepViewFailureAction {
  return {
      type: constants.PSDOSKMEP_VIEW_FAILURE,
      error
  }
}

//
// PsDoskmep Edit
//
export function PsDoskmepEditRequest(params: any, onCallback: React.FC<{
  status: number,
  data?: any,
  error?: any}>): actions.PsDoskmepEditRequestAction {
  return {
      type: constants.PSDOSKMEP_EDIT_REQUEST,
      params,
      onCallback
  }
}
export function PsDoskmepEditSuccess(data: object): actions.PsDoskmepEditSuccessAction {
  return {
      type: constants.PSDOSKMEP_EDIT_SUCCESS,
      data
  }
}
export function PsDoskmepEditFailure(error: string): actions.PsDoskmepEditFailureAction {
  return {
      type: constants.PSDOSKMEP_EDIT_FAILURE,
      error
  }
}


//
// PsDoskmep Submit
//
export function PsDoskmepSubmitRequest(params: any, onCallback: React.FC<{
  status: number,
  data?: any,
  error?: any}>): actions.PsDoskmepSubmitRequestAction {
  return {
      type: constants.PSDOSKMEP_SUBMIT_REQUEST,
      params,
      onCallback
  }
}
export function PsDoskmepSubmitSuccess(data: object): actions.PsDoskmepSubmitSuccessAction {
  return {
      type: constants.PSDOSKMEP_SUBMIT_SUCCESS,
      data
  }
}
export function PsDoskmepSubmitFailure(error: string): actions.PsDoskmepSubmitFailureAction {
  return {
      type: constants.PSDOSKMEP_SUBMIT_FAILURE,
      error
  }
}
