import * as actions from "../action/user";
import * as constants from "../constant/user";

export interface UserState {
  data: object;
  user: object;
  error: string;
  loading: boolean;
}

export const userReducer = (
  state: UserState = {
    data: {},
    user: {},
    error: "",
    loading: false,
  },
  action: actions.UserAction
) => {
  switch (action.type) {
    //
    // DELETE USER
    //
    case constants.DELETE_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.DELETE_USER_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.DELETE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // UPDATE PORFILE
    //
    case constants.UPDATEPROFILE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.UPDATEPROFILE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.UPDATEPROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // UPDATE PASSWORD
    //
    case constants.UPDATEPASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.UPDATEPASSWORD_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.UPDATEPASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // ADMIN UPDATE PASSWORD
    //
    case constants.ADMINUPDATEPASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.ADMINUPDATEPASSWORD_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.ADMINUPDATEPASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // USER DETAILS
    //
    case constants.USERDETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.USERDETAILS_SUCCESS: {
      return {
        ...state,
        user: action.data as any,
        loading: false,
      };
    }
    case constants.USERDETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // UPDATE ADDRESS
    //
    case constants.UPDATEADDRESS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.UPDATEADDRESS_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.UPDATEADDRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // UPDATE ADDRESS
    //
    case constants.UPDATECONTACT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.UPDATECONTACT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.UPDATECONTACT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // ADDRESS
    //
    case constants.ADDRESS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.ADDRESS_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.ADDRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CONTACT
    //
    case constants.CONTACT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CONTACT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // EMPLOYER
    //
    case constants.EMPLOYER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.EMPLOYER_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.EMPLOYER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // UPDATE EMPLOYER
    //
    case constants.UPDATEEMPLOYER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.UPDATEEMPLOYER_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.UPDATEEMPLOYER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // UPDATE LOCALE
    //
    case constants.UPDATELOCALE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.UPDATELOCALE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.UPDATELOCALE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // UPDATE EMAIL
    //
    case constants.UPDATEEMAIL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.UPDATEEMAIL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.UPDATEEMAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
