import logo from "../../assets/logo/mymbsa.png";
import maintenance from "../../assets/bg/sistem_maintenance.jpg";

export interface ErrorPageProps {
  error: any;
}

const ErrorPage = ({ error }: ErrorPageProps) => {
  if (error === "error.connection") {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-blue-300">
        <img src={logo} className="h-10" alt="MBSA Logo" />

        <h1 className="pt-5">Please check your internet connection</h1>
        <a href="" className="underline text-blue-500">
          Try again
        </a>
      </div>
    );
    // if (error === "error.connection") {
    //   return (
    //     <div className="h-screen w-screen flex justify-center items-center">
    //       <img
    //         src={maintenance}
    //         className="w-screen object-cover"
    //         alt="maintenance"
    //       />
    //     </div>
    //   );
  } else if (error === "error.500") {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <img src={logo} className="h-10" alt="MBSA Logo" />
        <h1 className="p-5">
          Oppss Something went wrong, Please refresh this page or contact our
          helpdesk
        </h1>
      </div>
    );
  } else if (error.includes("Unable to connect:")) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <img src={logo} className="h-10" alt="MBSA Logo" />
        <h1 className="pt-5">Unable to connect database</h1>
        <a href="" className="underline text-blue-500">
          Try again
        </a>
      </div>
    );
  }
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <img src={logo} className="h-10" alt="MBSA Logo" />
      <h1 className="p-5">{error}</h1>
    </div>
  );
};

export default ErrorPage;
