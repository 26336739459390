const AvatarUser = (auth: any) => {
    let shortName = '';
    const name = auth.data.name;    
    if(name && name.indexOf(' ') != -1) {
        const tmp = name.split(' ');
        shortName = tmp[0][0] + tmp[1][0];
        shortName = shortName.toUpperCase();
    }else if(name) {
        shortName = name.substring(0, 1);
        shortName = shortName.toUpperCase();
    }
    return <div className="m-1 mr-2 w-16 h-16 relative flex justify-center items-center rounded-full bg-red-500 text-xl text-white uppercase">{shortName}</div>;
}

export default AvatarUser;