//
// TEMPAHAN ACTIVITY TYPE @ KATEGORI TEMPAHAN
//
export const TEMPAHAN_ACTIVITY_TYPE_REQUEST =
  "TEMPAHAN/TEMPAHAN_ACTIVITY_TYPE_REQUEST";
export const TEMPAHAN_ACTIVITY_TYPE_SUCCESS =
  "TEMPAHAN/TEMPAHAN_ACTIVITY_TYPE_SUCCESS";
export const TEMPAHAN_ACTIVITY_TYPE_FAILURE =
  "TEMPAHAN/TEMPAHAN_ACTIVITY_TYPE_FAILURE";

// TEMPAHAN PRE BLOCK BOOK ASSET
export const TEMPAHAN_PRE_BLOCK_BOOKING_REQUEST =
  "TEMPAHAN/TEMPAHAN_PRE_BLOCK_BOOKING_REQUEST";
export const TEMPAHAN_PRE_BLOCK_BOOKING_SUCCESS =
  "TEMPAHAN/TEMPAHAN_PRE_BLOCK_BOOKING_SUCCESS";
export const TEMPAHAN_PRE_BLOCK_BOOKING_FAILURE =
  "TEMPAHAN/TEMPAHAN_PRE_BLOCK_BOOKING_FAILURE";

// TEMPAHAN PRE BLOCK DELETE BOOK ASSET
export const TEMPAHAN_PRE_DELETE_BOOKING_REQUEST =
  "TEMPAHAN/TEMPAHAN_PRE_DELETE_BOOKING_REQUEST";
export const TEMPAHAN_PRE_DELETE_BOOKING_SUCCESS =
  "TEMPAHAN/TEMPAHAN_PRE_DELETE_BOOKING_SUCCESS";
export const TEMPAHAN_PRE_DELETE_BOOKING_FAILURE =
  "TEMPAHAN/TEMPAHAN_PRE_DELETE_BOOKING_FAILURE";

//
// TEMPAHAN ACTIVITY @ ACARA
//
export const TEMPAHAN_ACTIVITY_REQUEST = "TEMPAHAN/TEMPAHAN_ACTIVITY_REQUEST";
export const TEMPAHAN_ACTIVITY_SUCCESS = "TEMPAHAN/TEMPAHAN_ACTIVITY_SUCCESS";
export const TEMPAHAN_ACTIVITY_FAILURE = "TEMPAHAN/TEMPAHAN_ACTIVITY_FAILURE";

//
// TEMPAHAN SECTION @ SEKSYEN
//
export const TEMPAHAN_SECTION_REQUEST = "TEMPAHAN/TEMPAHAN_SECTION_REQUEST";
export const TEMPAHAN_SECTION_SUCCESS = "TEMPAHAN/TEMPAHAN_SECTION_SUCCESS";
export const TEMPAHAN_SECTION_FAILURE = "TEMPAHAN/TEMPAHAN_SECTION_FAILURE";

//
//  TEMPAHAN ASSET @ LOKASI
//
export const TEMPAHAN_ASSET_REQUEST = "TEMPAHAN/TEMPAHAN_ASSET_REQUEST";
export const TEMPAHAN_ASSET_SUCCESS = "TEMPAHAN/TEMPAHAN_ASSET_SUCCESS";
export const TEMPAHAN_ASSET_FAILURE = "TEMPAHAN/TEMPAHAN_ASSET_FAILURE";

//
// TEMPAHAN DATATABLE
//
export const TEMPAHAN_DATATABLE_REQUEST = "TEMPAHAN/TEMPAHAN_DATATABLE_REQUEST";
export const TEMPAHAN_DATATABLE_SUCCESS = "TEMPAHAN/TEMPAHAN_DATATABLE_SUCCESS";
export const TEMPAHAN_DATATABLE_FAILURE = "TEMPAHAN/TEMPAHAN_DATATABLE_FAILURE";

//
// TEMPAHAN SUBMIT
//
export const TEMPAHAN_SUBMIT_REQUEST = "TEMPAHAN/TEMPAHAN_SUBMIT_REQUEST";
export const TEMPAHAN_SUBMIT_SUCCESS = "TEMPAHAN/TEMPAHAN_SUBMIT_SUCCESS";
export const TEMPAHAN_SUBMIT_FAILURE = "TEMPAHAN/TEMPAHAN_SUBMIT_FAILURE";

//
// TEMPAHAN UPDATE
//
export const TEMPAHAN_UPDATE_REQUEST = "TEMPAHAN/TEMPAHAN_UPDATE_REQUEST";
export const TEMPAHAN_UPDATE_SUCCESS = "TEMPAHAN/TEMPAHAN_UPDATE_SUCCESS";
export const TEMPAHAN_UPDATE_FAILURE = "TEMPAHAN/TEMPAHAN_UPDATE_FAILURE";

//
// TEMPAHAN TIMESLOT
//
export const TEMPAHAN_ASSET_TIMESLOT_REQUEST =
  "TEMPAHAN/TEMPAHAN_ASSET_TIMESLOT_REQUEST";
export const TEMPAHAN_ASSET_TIMESLOT_SUCCESS =
  "TEMPAHAN/TEMPAHAN_ASSET_TIMESLOT_SUCCESS";
export const TEMPAHAN_ASSET_TIMESLOT_FAILURE =
  "TEMPAHAN/TEMPAHAN_ASSET_TIMESLOT_FAILURE";

//
// TEMPAHAN BOOK_CHECKING
//
export const TEMPAHAN_CHECKING_ACTIVE_REQUEST =
  "TEMPAHAN/TEMPAHAN_CHECKING_ACTIVE_REQUEST";
export const TEMPAHAN_CHECKING_ACTIVE_SUCCESS =
  "TEMPAHAN/TEMPAHAN_CHECKING_ACTIVE_SUCCESS";
export const TEMPAHAN_CHECKING_ACTIVE_FAILURE =
  "TEMPAHAN/TEMPAHAN_CHECKING_ACTIVE_FAILURE";

//
// TEMPAHAN VIEW BIL
//
export const TEMPAHAN_VIEW_BILL_REQUEST = "TEMPAHAN/TEMPAHAN_VIEW_BILL_REQUEST";
export const TEMPAHAN_VIEW_BILL_SUCCESS = "TEMPAHAN/TEMPAHAN_VIEW_BILL_SUCCESS";
export const TEMPAHAN_VIEW_BILL_FAILURE = "TEMPAHAN/TEMPAHAN_VIEW_BILL_FAILURE";
