import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/sekat";
import * as constants from "../constant/sekat";
import * as functions from "../function/sekat";

//
// SEKAT DATATABLE
//
function* getSekatDatatable({
  params,
  onCallback,
}: actions.SekatDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sekat/list`,
    method: "GET",
    params: params,
  });

  let finalData = [];

  for (const key in data) {
    const value = data[key];
    for (const item of value) {
      finalData = [...finalData, ...item.data];
    }
  }

  if (status === 200) {
    onCallback({
      status: 1,
      data: finalData,
    });
    yield put(functions.SekatDatatableSuccess(finalData));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SekatDatatableFailure(error));
  }
}

function* getSekatView({ params, onCallback }: actions.SekatViewRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sekat/carianById`,
    method: "GET",
    params: params,
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.SekatViewSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SekatViewFailure(error));
  }
}

//
// SEKAT DATATABLE
//
function* getSekatSearchById({
  params,
  onCallback,
}: actions.SekatSearchByIdRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sekat/carianById`,
    method: "GET",
    params: params,
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data.data,
    });
    yield put(functions.SekatSearchByIdSuccess(data.data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SekatSearchByIdFailure(error));
  }
}

export function* watchSekat() {
  yield takeLatest(constants.SEKAT_DATATABLE_REQUEST, getSekatDatatable);
  yield takeLatest(constants.SEKAT_VIEW_REQUEST, getSekatView);
  yield takeLatest(constants.SEKAT_SEARCH_BY_ID_REQUEST, getSekatSearchById);
}
