//
// PS BANGLO DATATABLE
//
export const PSBANGLO_DATATABLE_REQUEST = 'PS/BANGLO_DATATABLE_REQUEST';
export const PSBANGLO_DATATABLE_SUCCESS = 'PS/BANGLO_DATATABLE_SUCCESS';
export const PSBANGLO_DATATABLE_FAILURE = 'PS/BANGLO_DATATABLE_FAILURE';

//
// PS BANGLO - DRAFT
//
export const PSBANGLO_DRAFT_REQUEST = 'PS/BANGLO_DRAFT_REQUEST';
export const PSBANGLO_DRAFT_SUCCESS = 'PS/BANGLO_DRAFT_SUCCESS';
export const PSBANGLO_DRAFT_FAILURE = 'PS/BANGLO_DRAFT_FAILURE';

//
// PS BANGLO - EDIT
//
export const PSBANGLO_EDIT_REQUEST = 'PS/BANGLO_EDIT_REQUEST';
export const PSBANGLO_EDIT_SUCCESS = 'PS/BANGLO_EDIT_SUCCESS';
export const PSBANGLO_EDIT_FAILURE = 'PS/BANGLO_EDIT_FAILURE';

//
// PS BANGLO - SUBMIT
//
export const PSBANGLO_SUBMIT_REQUEST = 'PS/BANGLO_SUBMIT_REQUEST';
export const PSBANGLO_SUBMIT_SUCCESS = 'PS/BANGLO_SUBMIT_SUCCESS';
export const PSBANGLO_SUBMIT_FAILURE = 'PS/BANGLO_SUBMIT_FAILURE';

//
// PS BANGLO - VIEW
//
export const PSBANGLO_VIEW_REQUEST = 'PS/BANGLO_VIEW_REQUEST';
export const PSBANGLO_VIEW_SUCCESS = 'PS/BANGLO_VIEW_SUCCESS';
export const PSBANGLO_VIEW_FAILURE = 'PS/BANGLO_VIEW_FAILURE';

//
// PS BANGLO - SEARCH
//
export const PSBANGLO_SEARCH_REQUEST = 'PS/BANGLO_SEARCH_REQUEST';
export const PSBANGLO_SEARCH_SUCCESS = 'PS/BANGLO_SEARCH_SUCCESS';
export const PSBANGLO_SEARCH_FAILURE = 'PS/BANGLO_SEARCH_FAILURE';

