import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/ppm';
import * as constants from '../constant/ppm';
import * as functions from '../function/ppm';






//
// PPM SEARCH
//
function* getPpmSearch({params, onCallback}: actions.PpmSearchRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/search`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmSearchSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmSearchFailure(error));
    }
    
};

//
// PPM DATATABLE
//
function* getPpmDatatable({params, onCallback}: actions.PpmDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmDatatableFailure(error));
    }
    
};


//
// PPM EDIT
//
function* getPpmEdit({params, onCallback}: actions.PpmEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmEditFailure(error));
    }
    
};


//
// PPM DRAFT
//
function* getPpmDraft({params, onCallback}: actions.PpmDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmDraftFailure(error));
    }
    
};


//
// PPM SUBMIT
//
function* getPpmSubmit({params, onCallback}: actions.PpmSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmSubmitFailure(error));
    }
    
};

//
// PPM View
//
function* getPpmView({params, onCallback}: actions.PpmViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmViewFailure(error));
    }
    
};

//
// PPM PAY CREATE
//
function* getPpmPayCreate({params, onCallback}: actions.PpmPayCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/payment/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmPayCreateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmPayCreateFailure(error));
    }    
};

//
// PPM PAY REDIRECT
//
function* getPpmPayRedirect({params, onCallback}: actions.PpmPayRedirectRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/payment/redirect`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmPayRedirectSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmPayRedirectFailure(error));
    }
    
};


//
// PPM Premise Type
//
function* getPpmPremiseType({params, onCallback}: actions.PpmPremiseTypeRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/premiseType`,
        method: "GET",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmPremiseTypeSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmPremiseTypeFailure(error));
    }
    
};

//
// PPM Verify License No
//
function* getPpmvVrifyLicenseNo({params, onCallback}: actions.PpmVerifyLicenseNoRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/verifyLicenseNo`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmVerifyLicenseNoSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmVerifyLicenseNoFailure(error));
    }
    
};

//
// PPM Verify Company No
//
function* getPpmvVrifyCompanyNo({params, onCallback}: actions.PpmVerifyCompanyNoRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/verifyCompanyNo`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmVerifyCompanyNoSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmVerifyCompanyNoFailure(error));
    }
    
};

//
// PPM DELETE DRAFT
//
function* getPpmDeleteDraft({params, onCallback}: actions.PpmDeleteDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ppm/deleteDraft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PpmDeleteDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PpmDeleteDraftFailure(error));
    }
    
};


export function* watchPpm() {
    
    yield takeLatest(constants.PPM_SEARCH_REQUEST, getPpmSearch);    
    yield takeLatest(constants.PPM_DATATABLE_REQUEST, getPpmDatatable);
    yield takeLatest(constants.PPM_EDIT_REQUEST, getPpmEdit);
    yield takeLatest(constants.PPM_DRAFT_REQUEST, getPpmDraft);
    yield takeLatest(constants.PPM_SUBMIT_REQUEST, getPpmSubmit);
    yield takeLatest(constants.PPM_VIEW_REQUEST, getPpmView);
    yield takeLatest(constants.PPM_PAY_CREATE_REQUEST, getPpmPayCreate);
    yield takeLatest(constants.PPM_PAY_REDIRECT_REQUEST, getPpmPayRedirect);
    yield takeLatest(constants.PPM_DELETEDRAFT_REQUEST, getPpmDeleteDraft);

    yield takeLatest(constants.PPM_PREMISETYPE_REQUEST, getPpmPremiseType);
    yield takeLatest(constants.PPM_VERIFYLICENCENO_REQUEST, getPpmvVrifyLicenseNo);
    yield takeLatest(constants.PPM_VERIFYCOMPANYNO_REQUEST, getPpmvVrifyCompanyNo);
};


