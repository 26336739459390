import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/cagaran';
import * as constants from '../constant/cagaran';
import * as functions from '../function/cagaran';

//
// CAGARAN SEARCH BY ID
//
function* getCagaranSearchByID({params, onCallback}: actions.CagaranSearchByIDRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/cagaran/viewById`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.CagaranSearchByIDSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.CagaranSearchByIDFailure(error));
    }
    
};


//
// CAGARAN SEARCH BY NO_BIL
//
function* getCagaranSearchByNoBil({params, onCallback}: actions.CagaranSearchByNoBilRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/cagaran/viewByNoBil`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.CagaranSearchByNoBilSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.CagaranSearchByNoBilFailure(error));
    }
    
};


//
// CAGARAN VIEW
//
function* getCagaranView({params, onCallback}: actions.CagaranViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/cagaran/viewByNoBil`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.CagaranSearchByNoBilSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.CagaranSearchByNoBilFailure(error));
    }    
};

//
// CAGARAN PAY
//
function* getCagaranPayCreate({params, onCallback}: actions.CagaranPayCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/cagaran/payment/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.CagaranPayCreateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.CagaranPayCreateFailure(error));
    }
    
};

//
// CAGARAN PAY REDIRECT
//
function* getCagaranPayRedirect({params, onCallback}: actions.CagaranPayRedirectRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/cagaran/payment/redirect`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.CagaranPayRedirectSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.CagaranPayRedirectFailure(error));
    }
    
};


export function* watchCagaran() {
    
    yield takeLatest(constants.CAGARAN_SEARCH_BY_ID_REQUEST, getCagaranSearchByID);
    yield takeLatest(constants.CAGARAN_SEARCH_BY_NO_BIL_REQUEST, getCagaranSearchByNoBil);
    yield takeLatest(constants.CAGARAN_VIEW_REQUEST, getCagaranView);
    yield takeLatest(constants.CAGARAN_PAY_CREATE_REQUEST, getCagaranPayCreate);
    yield takeLatest(constants.CAGARAN_PAY_REDIRECT_REQUEST, getCagaranPayRedirect);

};


