import { decode } from "html-entities";

/*
    decode html entities
*/
const HtmlEntites = (value: string) => {

    let test = value;
    let i = 0;
    const max = 100;
    while(test.indexOf('&') != -1) {
        test = decode(test);
        i++;
        if(i > max) {
            break;
        }
    }
    return test;
}

export default HtmlEntites;