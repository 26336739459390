import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as constants from '../constant/version';
import * as functions from '../function/version';


//
// VERSION
//
function* getVersion({}) {

    const {status, data, error } = yield call(axios.call, {
        url: `/version`,
        method: "GET"
    });
    if(status === 200) {
        yield put(functions.VersionSuccess(data));     
    }else {
        yield put(functions.VersionFailure(error));
    }
    
};



export function* watchVersion() {
    yield takeLatest(constants.VERSION_REQUEST, getVersion);
};
