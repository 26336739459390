//
// PS KEDIAMAN DATATABLE
//
export const PSKEDIAMAN_DATATABLE_REQUEST = 'PS/KEDIAMAN_DATATABLE_REQUEST';
export const PSKEDIAMAN_DATATABLE_SUCCESS = 'PS/KEDIAMAN_DATATABLE_SUCCESS';
export const PSKEDIAMAN_DATATABLE_FAILURE = 'PS/KEDIAMAN_DATATABLE_FAILURE';

//
// PS KEDIAMAN - DRAFT
//
export const PSKEDIAMAN_DRAFT_REQUEST = 'PS/KEDIAMAN_DRAFT_REQUEST';
export const PSKEDIAMAN_DRAFT_SUCCESS = 'PS/KEDIAMAN_DRAFT_SUCCESS';
export const PSKEDIAMAN_DRAFT_FAILURE = 'PS/KEDIAMAN_DRAFT_FAILURE';

//
// PS KEDIAMAN - EDIT
//
export const PSKEDIAMAN_EDIT_REQUEST = 'PS/KEDIAMAN_EDIT_REQUEST';
export const PSKEDIAMAN_EDIT_SUCCESS = 'PS/KEDIAMAN_EDIT_SUCCESS';
export const PSKEDIAMAN_EDIT_FAILURE = 'PS/KEDIAMAN_EDIT_FAILURE';

//
// PS KEDIAMAN - SUBMIT
//
export const PSKEDIAMAN_SUBMIT_REQUEST = 'PS/KEDIAMAN_SUBMIT_REQUEST';
export const PSKEDIAMAN_SUBMIT_SUCCESS = 'PS/KEDIAMAN_SUBMIT_SUCCESS';
export const PSKEDIAMAN_SUBMIT_FAILURE = 'PS/KEDIAMAN_SUBMIT_FAILURE';

//
// PS KEDIAMAN - VIEW
//
export const PSKEDIAMAN_VIEW_REQUEST = 'PS/KEDIAMAN_VIEW_REQUEST';
export const PSKEDIAMAN_VIEW_SUCCESS = 'PS/KEDIAMAN_VIEW_SUCCESS';
export const PSKEDIAMAN_VIEW_FAILURE = 'PS/KEDIAMAN_VIEW_FAILURE';

//
// PS KEDIAMAN - SEARCH
//
export const PSKEDIAMAN_SEARCH_REQUEST = 'PS/KEDIAMAN_SEARCH_REQUEST';
export const PSKEDIAMAN_SEARCH_SUCCESS = 'PS/KEDIAMAN_SEARCH_SUCCESS';
export const PSKEDIAMAN_SEARCH_FAILURE = 'PS/KEDIAMAN_SEARCH_FAILURE';