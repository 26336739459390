//
// LESEN RESET
//
export const LESEN_RESET = "LESEN/RESET";

//
// LESEN DATATABLE RENEW
//
export const LESEN_DATATABLE_RENEW_REQUEST = "LESEN/DATATABLE_RENEW_REQUEST";
export const LESEN_DATATABLE_RENEW_SUCCESS = "LESEN/DATATABLE_RENEW_SUCCESS";
export const LESEN_DATATABLE_RENEW_FAILURE = "LESEN/DATATABLE_RENEW_FAILURE";

//
// LESEN DATATABLE APPLY
//
export const LESEN_DATATABLE_APPLY_REQUEST = "LESEN/DATATABLE_APPLY_REQUEST";
export const LESEN_DATATABLE_APPLY_SUCCESS = "LESEN/DATATABLE_APPLY_SUCCESS";
export const LESEN_DATATABLE_APPLY_FAILURE = "LESEN/DATATABLE_APPLY_FAILURE";

//
// LESEN RENEW
//
export const LESEN_RENEW_REQUEST = "LESEN/RENEW_REQUEST";
export const LESEN_RENEW_SUCCESS = "LESEN/RENEW_SUCCESS";
export const LESEN_RENEW_FAILURE = "LESEN/RENEW_FAILURE";

//
// LESEN PAY CREATE
//
export const LESEN_PAY_CREATE_REQUEST = "LESEN/LESEN_PAY_CREATE_REQUEST";
export const LESEN_PAY_CREATE_SUCCESS = "LESEN/LESEN_PAY_CREATE_SUCCESS";
export const LESEN_PAY_CREATE_FAILURE = "LESEN/LESEN_PAY_CREATE_FAILURE";

//
// LESEN PAY REDIRECT
//
export const LESEN_PAY_REDIRECT_REQUEST = "LESEN/LESEN_PAY_REDIRECT_REQUEST";
export const LESEN_PAY_REDIRECT_SUCCESS = "LESEN/LESEN_PAY_REDIRECT_SUCCESS";
export const LESEN_PAY_REDIRECT_FAILURE = "LESEN/LESEN_PAY_REDIRECT_FAILURE";

//
// LESEN DRAFT
//
export const LESEN_DRAFT_REQUEST = "LESEN/LESEN_DRAFT_REQUEST";
export const LESEN_DRAFT_SUCCESS = "LESEN/LESEN_DRAFT_SUCCESS";
export const LESEN_DRAFT_FAILURE = "LESEN/LESEN_DRAFT_FAILURE";

//
// LESEN EDIT
//
export const LESEN_EDIT_REQUEST = "LESEN/LESEN_EDIT_REQUEST";
export const LESEN_EDIT_SUCCESS = "LESEN/LESEN_EDIT_SUCCESS";
export const LESEN_EDIT_FAILURE = "LESEN/LESEN_EDIT_FAILURE";

//
// LESEN SUBMIT
//
export const LESEN_SUBMIT_REQUEST = "LESEN/LESEN_SUBMIT_REQUEST";
export const LESEN_SUBMIT_SUCCESS = "LESEN/LESEN_SUBMIT_SUCCESS";
export const LESEN_SUBMIT_FAILURE = "LESEN/LESEN_SUBMIT_FAILURE";

//
// LESEN VIEW
//
export const LESEN_VIEW_REQUEST = "LESEN/LESEN_VIEW_REQUEST";
export const LESEN_VIEW_SUCCESS = "LESEN/LESEN_VIEW_SUCCESS";
export const LESEN_VIEW_FAILURE = "LESEN/LESEN_VIEW_FAILURE";

//
// LESEN DELETEDRAFT
//
export const LESEN_DELETEDRAFT_REQUEST = "LESEN/LESEN_DELETEDRAFT_REQUEST";
export const LESEN_DELETEDRAFT_SUCCESS = "LESEN/LESEN_DELETEDRAFT_SUCCESS";
export const LESEN_DELETEDRAFT_FAILURE = "LESEN/LESEN_DELETEDRAFT_FAILURE";

//
// LESEN DOCUMENT
//
export const LESEN_DOCUMENT_REQUEST = "LESEN/LESEN_DOCUMENT_REQUEST";
export const LESEN_DOCUMENT_SUCCESS = "LESEN/LESEN_DOCUMENT_SUCCESS";
export const LESEN_DOCUMENT_FAILURE = "LESEN/LESEN_DOCUMENT_FAILURE";

//
// LESEN VERIFYSEKATAN
//
export const LESEN_VERIFYSEKATAN_REQUEST = "LESEN/LESEN_VERIFYSEKATAN_REQUEST";
export const LESEN_VERIFYSEKATAN_SUCCESS = "LESEN/LESEN_VERIFYSEKATAN_SUCCESS";
export const LESEN_VERIFYSEKATAN_FAILURE = "LESEN/LESEN_VERIFYSEKATAN_FAILURE";

//
// LESEN KATEGORILESEN
//
export const LESEN_KATEGORILESEN_REQUEST = "LESEN/LESEN_KATEGORILESEN_REQUEST";
export const LESEN_KATEGORILESEN_SUCCESS = "LESEN/LESEN_KATEGORILESEN_SUCCESS";
export const LESEN_KATEGORILESEN_FAILURE = "LESEN/LESEN_KATEGORILESEN_FAILURE";

//
// LESEN JENISLESEN
//
export const LESEN_JENISLESEN_REQUEST = "LESEN/LESEN_JENISLESEN_REQUEST";
export const LESEN_JENISLESEN_SUCCESS = "LESEN/LESEN_JENISLESEN_SUCCESS";
export const LESEN_JENISLESEN_FAILURE = "LESEN/LESEN_JENISLESEN_FAILURE";

//
// LESEN KODHASILLESEN
//
export const LESEN_KODHASILLESEN_REQUEST = "LESEN/LESEN_KODHASILLESEN_REQUEST";
export const LESEN_KODHASILLESEN_SUCCESS = "LESEN/LESEN_KODHASILLESEN_SUCCESS";
export const LESEN_KODHASILLESEN_FAILURE = "LESEN/LESEN_KODHASILLESEN_FAILURE";

//
// LESEN JENISPREMIS
//
export const LESEN_JENISPREMIS_REQUEST = "LESEN/LESEN_JENISPREMIS_REQUEST";
export const LESEN_JENISPREMIS_SUCCESS = "LESEN/LESEN_JENISPREMIS_SUCCESS";
export const LESEN_JENISPREMIS_FAILURE = "LESEN/LESEN_JENISPREMIS_FAILURE";

//
// LESEN SAVE DOCUMENT
//
export const LESEN_SAVE_DOCS_REQUEST = "LESEN/LESEN_SAVE_DOCS_REQUEST";
export const LESEN_SAVE_DOCS_SUCCESS = "LESEN/LESEN_SAVE_DOCS_SUCCESS";
export const LESEN_SAVE_DOCS_FAILURE = "LESEN/LESEN_SAVE_DOCS_FAILURE";

//
// LESEN GET DOCUMENT
//
export const LESEN_GET_DOCS_REQUEST = "LESEN/LESEN_GET_DOCS_REQUEST";
export const LESEN_GET_DOCS_SUCCESS = "LESEN/LESEN_GET_DOCS_SUCCESS";
export const LESEN_GET_DOCS_FAILURE = "LESEN/LESEN_GET_DOCS_FAILURE";

//
// LESEN SAVE DOCUMENT TO SERVER
//
export const LESEN_SAVE_DOCS_TO_SERVER_REQUEST =
  "LESEN/LESEN_SAVE_DOCS_TO_SERVER_REQUEST";
export const LESEN_SAVE_DOCS_TO_SERVER_SUCCESS =
  "LESEN/LESEN_SAVE_DOCS_TO_SERVER_SUCCESS";
export const LESEN_SAVE_DOCS_TO_SERVER_FAILURE =
  "LESEN/LESEN_SAVE_DOCS_TO_SERVER_FAILURE";

//
// LESEN KEDUDUKANPREMIS
//
export const LESEN_KEDUDUKANPREMIS_REQUEST =
  "LESEN/LESEN_KEDUDUKANPREMIS_REQUEST";
export const LESEN_KEDUDUKANPREMIS_SUCCESS =
  "LESEN/LESEN_KEDUDUKANPREMIS_SUCCESS";
export const LESEN_KEDUDUKANPREMIS_FAILURE =
  "LESEN/LESEN_KEDUDUKANPREMIS_FAILURE";
