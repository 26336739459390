
//
// PPM RESET
//
export const PPM_RESET = 'PPM/PPM_RESET';

//
// PPM SEARCH
//
export const PPM_SEARCH_REQUEST = 'PPM/PPM_SEARCH_REQUEST';
export const PPM_SEARCH_SUCCESS = 'PPM/PPM_SEARCH_SUCCESS';
export const PPM_SEARCH_FAILURE = 'PPM/PPM_SEARCH_FAILURE';

//
// PPM DATATABLE
//
export const PPM_DATATABLE_REQUEST = 'PPM/PPM_DATATABLE_REQUEST';
export const PPM_DATATABLE_SUCCESS = 'PPM/PPM_DATATABLE_SUCCESS';
export const PPM_DATATABLE_FAILURE = 'PPM/PPM_DATATABLE_FAILURE';

//
// PPM DRAFT
//
export const PPM_DRAFT_REQUEST = 'PPM/PPM_DRAFT_REQUEST';
export const PPM_DRAFT_SUCCESS = 'PPM/PPM_DRAFT_SUCCESS';
export const PPM_DRAFT_FAILURE = 'PPM/PPM_DRAFT_FAILURE';

//
// PPM EDIT
//
export const PPM_EDIT_REQUEST = 'PPM/PPM_EDIT_REQUEST';
export const PPM_EDIT_SUCCESS = 'PPM/PPM_EDIT_SUCCESS';
export const PPM_EDIT_FAILURE = 'PPM/PPM_EDIT_FAILURE';

//
// PPM SUBMIT
//
export const PPM_SUBMIT_REQUEST = 'PPM/PPM_SUBMIT_REQUEST';
export const PPM_SUBMIT_SUCCESS = 'PPM/PPM_SUBMIT_SUCCESS';
export const PPM_SUBMIT_FAILURE = 'PPM/PPM_SUBMIT_FAILURE';

//
// PPM VIEW
//
export const PPM_VIEW_REQUEST = 'PPM/PPM_VIEW_REQUEST';
export const PPM_VIEW_SUCCESS = 'PPM/PPM_VIEW_SUCCESS';
export const PPM_VIEW_FAILURE = 'PPM/PPM_VIEW_FAILURE';

//
// KPM PAY - CREATE
//
export const PPM_PAY_CREATE_REQUEST = 'PPM/PPM_PAY_CREATE_REQUEST';
export const PPM_PAY_CREATE_SUCCESS = 'PPM/PPM_PAY_CREATE_SUCCESS';
export const PPM_PAY_CREATE_FAILURE = 'PPM/PPM_PAY_CREATE_FAILURE';

//
// KPM PAY - REDIRECT
//
export const PPM_PAY_REDIRECT_REQUEST = 'PPM/PPM_PAY_REDIRECT_REQUEST';
export const PPM_PAY_REDIRECT_SUCCESS = 'PPM/PPM_PAY_REDIRECT_SUCCESS';
export const PPM_PAY_REDIRECT_FAILURE = 'PPM/PPM_PAY_REDIRECT_FAILURE';


//
// PPM PREMISE TYPE
//
export const PPM_PREMISETYPE_REQUEST = 'PPM/PPM_PREMISETYPE_REQUEST';
export const PPM_PREMISETYPE_SUCCESS = 'PPM/PPM_PREMISETYPE_SUCCESS';
export const PPM_PREMISETYPE_FAILURE = 'PPM/PPM_PREMISETYPE_FAILURE';

//
// PPM VERIFY COPMANY NO
//
export const PPM_VERIFYCOMPANYNO_REQUEST = 'PPM/PPM_VERIFYCOMPANYNO_REQUEST';
export const PPM_VERIFYCOMPANYNO_SUCCESS = 'PPM/PPM_VERIFYCOMPANYNO_SUCCESS';
export const PPM_VERIFYCOMPANYNO_FAILURE = 'PPM/PPM_VERIFYCOMPANYNO_FAILURE';

//
// PPM VERIFY LICENSE NO
//
export const PPM_VERIFYLICENCENO_REQUEST = 'PPM/PPM_VERIFYLICENCENO_REQUEST';
export const PPM_VERIFYLICENCENO_SUCCESS = 'PPM/PPM_VERIFYLICENCENO_SUCCESS';
export const PPM_VERIFYLICENSENO_FAILURE = 'PPM/PPM_VERIFYLICENSENO_FAILURE';

//
// PPM DELETE DRAFT
//
export const PPM_DELETEDRAFT_REQUEST = 'PPM/PPM_DELETEDRAFT_REQUEST';
export const PPM_DELETEDRAFT_SUCCESS = 'PPM/PPM_DELETEDRAFT_SUCCESS';
export const PPM_DELETEDRAFT_FAILURE = 'PPM/PPM_DELETEDRAFT_FAILURE';
