import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/troli';
import * as constants from '../constant/troli';
import * as functions from '../function/troli';


//
// Troli Datatable
//
function* getTroliDatatable({params, onCallback}: actions.TroliDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/troli/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.TroliDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.TroliDatatableFailure(error));
    }
    
};

//
// Troli Add to Troli
//
function* getTroliAddTroli({params, onCallback}: actions.TroliAddTroliRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/troli/addtroli`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.TroliAddTroliSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.TroliAddTroliFailure(error));
    }
    
};


//
// Troli Trolis
//
function* getTroliTrolis({params, onCallback}: actions.TroliTrolisRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/troli/trolis`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.TroliTrolisSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.TroliTrolisFailure(error));
    }
    
};

//
// Troli Create Invoice
//
function* getTroliCreateInvoice({params, onCallback}: actions.TroliCreateInvoiceRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/troli/createInvoice`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.TroliCreateInvoiceSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.TroliCreateInvoiceFailure(error));
    }
    
};

//
// Troli Pay Create
//
function* getTroliPayCreate({params, onCallback}: actions.TroliPayCreateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/troli/payment/create`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.TroliPayCreateSuccess(data));
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.TroliPayCreateFailure(error));
    }
    
};

//
// Troli Pay Redirect
//
function* getTroliPayRedirect({params, onCallback}: actions.TroliPayRedirectRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/troli/payment/redirect`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.TroliPayRedirectSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.TroliPayRedirectFailure(error));
    }
    
};

//
// Troli View
//
function* getTroliView({params, onCallback}: actions.TroliViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/troli/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.TroliViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.TroliViewFailure(error));
    }
    
};


//
// Troli Delete
//
function* getTroliDelete({params, onCallback}: actions.TroliDeleteRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/troli/delete`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.TroliDeleteSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.TroliDeleteFailure(error));
    }
    
};


export function* watchTroli() {
    
    yield takeLatest(constants.TROLI_DATATABLE_REQUEST, getTroliDatatable);
    yield takeLatest(constants.TROLI_ADDTROLI_REQUEST, getTroliAddTroli);
    yield takeLatest(constants.TROLI_TROLIS_REQUEST, getTroliTrolis);
    yield takeLatest(constants.TROLI_CREATEINVOICE_REQUEST, getTroliCreateInvoice);
    yield takeLatest(constants.TROLI_PAY_CREATE_REQUEST, getTroliPayCreate);
    yield takeLatest(constants.TROLI_PAY_REDIRECT_REQUEST, getTroliPayRedirect);
    yield takeLatest(constants.TROLI_VIEW_REQUEST, getTroliView);
    yield takeLatest(constants.TROLI_DELETE_REQUEST, getTroliDelete);
};


