import * as actions from '../action/mapstatus';
import * as constants from '../constant/mapstatus';


//
// MAPSTATUS Datatable
//
export function MapstatusDatatableRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.MapstatusDatatableRequestAction {
    return {
        type: constants.MAPSTATUS_DATATABLE_REQUEST,
        params,
        onCallback
    }
}
export function MapstatusDatatableSuccess(data: object): actions.MapstatusDatatableSuccessAction {
    return {
        type: constants.MAPSTATUS_DATATABLE_SUCCESS,
        data
    }
}
export function MapstatusDatatableFailure(error: string): actions.MapstatusDatatableFailureAction {
    return {
        type: constants.MAPSTATUS_DATATABLE_FAILURE,
        error
    }
}


//
// MAPSTATUS Edit
//
export function MapstatusEditRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.MapstatusEditRequestAction {
    return {
        type: constants.MAPSTATUS_EDIT_REQUEST,
        params,
        onCallback
    }
}
export function MapstatusEditSuccess(data: object): actions.MapstatusEditSuccessAction {
    return {
        type: constants.MAPSTATUS_EDIT_SUCCESS,
        data
    }
}
export function MapstatusEditFailure(error: string): actions.MapstatusEditFailureAction {
    return {
        type: constants.MAPSTATUS_EDIT_FAILURE,
        error
    }
}


//
// MAPSTATUS Add
//
export function MapstatusAddRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.MapstatusAddRequestAction {
    return {
        type: constants.MAPSTATUS_ADD_REQUEST,
        params,
        onCallback
    }
}
export function MapstatusAddSuccess(data: object): actions.MapstatusAddSuccessAction {
    return {
        type: constants.MAPSTATUS_ADD_SUCCESS,
        data
    }
}
export function MapstatusAddFailure(error: string): actions.MapstatusAddFailureAction {
    return {
        type: constants.MAPSTATUS_ADD_FAILURE,
        error
    }
}


//
// MAPSTATUS View
//
export function MapstatusViewRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.MapstatusViewRequestAction {
    return {
        type: constants.MAPSTATUS_VIEW_REQUEST,
        params,
        onCallback
    }
}
export function MapstatusViewSuccess(data: object): actions.MapstatusViewSuccessAction {
    return {
        type: constants.MAPSTATUS_VIEW_SUCCESS,
        data
    }
}
export function MapstatusViewFailure(error: string): actions.MapstatusViewFailureAction {
    return {
        type: constants.MAPSTATUS_VIEW_FAILURE,
        error
    }
}

//
// MAPSTATUS Delete
//
export function MapstatusDeleteRequest(params: any, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.MapstatusDeleteRequestAction {
    return {
        type: constants.MAPSTATUS_DELETE_REQUEST,
        params,
        onCallback
    }
}
export function MapstatusDeleteSuccess(data: object): actions.MapstatusDeleteSuccessAction {
    return {
        type: constants.MAPSTATUS_DELETE_SUCCESS,
        data
    }
}
export function MapstatusDeleteFailure(error: string): actions.MapstatusDeleteFailureAction {
    return {
        type: constants.MAPSTATUS_DELETE_FAILURE,
        error
    }
}

