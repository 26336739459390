import * as actions from "../action/bil";
import * as constants from "../constant/bil";

//
// Bil Datatable
//
export function BilDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BilDatatableRequestAction {
  return {
    type: constants.BIL_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}

export function BilDatatableSuccess(
  data: object
): actions.BilDatatableSuccessAction {
  return {
    type: constants.BIL_DATATABLE_SUCCESS,
    data,
  };
}

export function BilDatatableFailure(
  error: string
): actions.BilDatatableFailureAction {
  return {
    type: constants.BIL_DATATABLE_FAILURE,
    error,
  };
}

//VIEW
export function BilViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.BilViewRequestAction {
  return {
    type: constants.BIL_VIEW_REQUEST,
    params,
    onCallback,
  };
}

export function BilViewSuccess(data: object): actions.BilViewSuccessAction {
  return {
    type: constants.BIL_VIEW_SUCCESS,
    data,
  };
}

export function BilViewFailure(error: string): actions.BilViewFailureAction {
  return {
    type: constants.BIL_VIEW_FAILURE,
    error,
  };
}
