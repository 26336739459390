import * as actions from "../action/cukai";
import * as constants from "../constant/cukai";

export interface CukaiState {
  data: object;
  error: string;
  loading: boolean;
}

export const cukaiReducer = (
  state: CukaiState = {
    data: {},
    error: "",
    loading: false,
  },
  action: actions.CukaiAction
) => {
  switch (action.type) {
    //
    // CUKAI SEARCH
    //
    case constants.CUKAI_SEARCH_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_SEARCH_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI DATATABLE
    //
    case constants.CUKAI_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI PRICE
    //
    case constants.CUKAI_PRICE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_PRICE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_PRICE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI DRAFT
    //
    case constants.CUKAI_DRAFT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_DRAFT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_DRAFT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI EDIT
    //
    case constants.CUKAI_EDIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_EDIT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_EDIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI SUBMIT
    //
    case constants.CUKAI_SUBMIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_SUBMIT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_SUBMIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI VIEW
    //
    case constants.CUKAI_VIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_VIEW_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_VIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI PAY CREATE
    //
    case constants.CUKAI_PAY_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_PAY_CREATE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_PAY_CREATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI PAY REDIRECT
    //
    case constants.CUKAI_PAY_REDIRECT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_PAY_REDIRECT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_PAY_REDIRECT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI DELETE DRAFT
    //
    case constants.CUKAI_DELETEDRAFT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_DELETEDRAFT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_DELETEDRAFT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // SENARAI AKAUN
    //
    case constants.CUKAI_ACCOUNT_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_ACCOUNT_DATATABLE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_ACCOUNT_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI VIEW ACCOUNT
    //
    case constants.CUKAI_VIEW_BILL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_VIEW_BILL_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_VIEW_BILL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI VIEW ACCOUNT
    //
    case constants.CUKAI_VIEW_ACCOUNT_INFO_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_VIEW_ACCOUNT_INFO_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_VIEW_ACCOUNT_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // PINDAHMILIK SUBMIT
    //
    case constants.CUKAI_PINDAHMILIK_SUBMIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.CUKAI_PINDAHMILIK_SUBMIT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }
    case constants.CUKAI_PINDAHMILIK_SUBMIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    
    //
    // KEMASKINI SUBMIT
    //
    case constants.CUKAI_KEMASKINI_SUBMIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_KEMASKINI_SUBMIT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_KEMASKINI_SUBMIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

     //
    // KEMASKINI SUBMIT
    //
    case constants.CUKAI_PENGURANGAN_SUBMIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_PENGURANGAN_SUBMIT_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_PENGURANGAN_SUBMIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI VIEW BY ACCOUNT NO
    //
    case constants.CUKAI_VIEW_BY_ACCOUNT_NO_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_VIEW_BY_ACCOUNT_NO_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_VIEW_BY_ACCOUNT_NO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI VIEW LIST PERMOHONAN
    //
    case constants.CUKAI_VIEW_LIST_PERMOHONAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_VIEW_LIST_PERMOHONAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_VIEW_LIST_PERMOHONAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI VIEW LIST PINDAHMILIK
    //
    case constants.CUKAI_VIEW_LIST_PINDAHMILIK_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_VIEW_LIST_PINDAHMILIK_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_VIEW_LIST_PINDAHMILIK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI VIEW LIST KEMASKINI
    //
    case constants.CUKAI_VIEW_LIST_KEMASKINI_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_VIEW_LIST_KEMASKINI_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_VIEW_LIST_KEMASKINI_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // CUKAI VIEW LIST PENGURANGAN
    //
    case constants.CUKAI_VIEW_LIST_PENGURANGAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_VIEW_LIST_PENGURANGAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_VIEW_LIST_PENGURANGAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

     //
    // CUKAI VIEW DRAF PINDAHMILIK
    //
    case constants.CUKAI_VIEW_DRAF_PINDAHMILIK_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_VIEW_DRAF_PINDAHMILIK_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_VIEW_DRAF_PINDAHMILIK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

     //
    // CUKAI VIEW DRAF KEMASKINI
    //
    case constants.CUKAI_VIEW_DRAF_KEMASKINI_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_VIEW_DRAF_KEMASKINI_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_VIEW_DRAF_KEMASKINI_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

     //
    // CUKAI VIEW DRAF PENGURANGAN
    //
    case constants.CUKAI_VIEW_DRAF_PENGURANGAN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    
    case constants.CUKAI_VIEW_DRAF_PENGURANGAN_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.CUKAI_VIEW_DRAF_PENGURANGAN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
