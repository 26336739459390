//
// COMPANY DATATABLE
//
export const COMPANY_DATATABLE_REQUEST = 'USER/COMPANY_DATATABLE_REQUEST';
export const COMPANY_DATATABLE_SUCCESS = 'USER/COMPANY_DATATABLE_SUCCESS';
export const COMPANY_DATATABLE_FAILURE = 'USER/COMPANY_DATATABLE_FAILURE';

//
// COMPANY ADD
//
export const COMPANY_ADD_REQUEST = 'USER/COMPANY_ADD_REQUEST';
export const COMPANY_ADD_SUCCESS = 'USER/COMPANY_ADD_SUCCESS';
export const COMPANY_ADD_FAILURE = 'USER/COMPANY_ADD_FAILURE';

//
// COMPANY EDIT
//
export const COMPANY_EDIT_REQUEST = 'USER/COMPANY_EDIT_REQUEST';
export const COMPANY_EDIT_SUCCESS = 'USER/COMPANY_EDIT_SUCCESS';
export const COMPANY_EDIT_FAILURE = 'USER/COMPANY_EDIT_FAILURE';

//
// COMPANY VIEW
//
export const COMPANY_VIEW_REQUEST = 'USER/COMPANY_VIEW_REQUEST';
export const COMPANY_VIEW_SUCCESS = 'USER/COMPANY_VIEW_SUCCESS';
export const COMPANY_VIEW_FAILURE = 'USER/COMPANY_VIEW_FAILURE';

//
// COMPANY DELETE
//
export const COMPANY_DELETE_REQUEST = 'USER/COMPANY_DELETE_REQUEST';
export const COMPANY_DELETE_SUCCESS = 'USER/COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_FAILURE = 'USER/COMPANY_DELETE_FAILURE';

//
// COMPANY LIST
//
export const COMPANY_LIST_REQUEST = 'USER/COMPANY_LIST_REQUEST';
export const COMPANY_LIST_SUCCESS = 'USER/COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_FAILURE = 'USER/COMPANY_LIST_FAILURE';