import * as actions from '../action/show';
import * as constants from '../constant/show';

export interface ShowState {
  show: boolean;
  error: string;
}

export const showReducer = (
  state: ShowState = {
    show: true,
    error: "",
  },
  action: actions.ShowAction) => {    
    switch (action.type) {
      case constants.SET_SHOW: {
        return {
          ...state,
        };
      }
      case constants.SUCCESS_SET_SHOW: {
        return {
          ...state,
          show: action.data as any,
        };
      }

      
      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  