// 1. /////////////////////////////////////////////////////////////////////////////// PTJ
//
// PTJ DATATABLE
//
export const PTJ_DATATABLE_REQUEST = 'PTJ/PTJ_DATATABLE_REQUEST';
export const PTJ_DATATABLE_SUCCESS = 'PTJ/PTJ_DATATABLE_SUCCESS';
export const PTJ_DATATABLE_FAILURE = 'PTJ/PTJ_DATATABLE_FAILURE';

//
// PTJ VIEW
//
export const PTJ_VIEW_REQUEST = 'PTJ/PTJ_VIEW_REQUEST';
export const PTJ_VIEW_SUCCESS = 'PTJ/PTJ_VIEW_SUCCESS';
export const PTJ_VIEW_FAILURE = 'PTJ/PTJ_VIEW_FAILURE';

//
// PTJ PAY CREATE
//
export const PTJ_PAY_CREATE_REQUEST = 'PTJ/PTJ_PAY_CREATE_REQUEST';
export const PTJ_PAY_CREATE_SUCCESS = 'PTJ/PTJ_PAY_CREATE_SUCCESS';
export const PTJ_PAY_CREATE_FAILURE = 'PTJ/PTJ_PAY_CREATE_FAILURE';

//
// PTJ PAY REDIRECT
//
export const PTJ_PAY_REDIRECT_REQUEST = 'PTJ/PTJ_PAY_REDIRECT_REQUEST';
export const PTJ_PAY_REDIRECT_SUCCESS = 'PTJ/PTJ_PAY_REDIRECT_SUCCESS';
export const PTJ_PAY_REDIRECT_FAILURE = 'PTJ/PTJ_PAY_REDIRECT_FAILURE';


// 2. /////////////////////////////////////////////////////////////////////////////// PB
//
// PB DATATABLE
//
export const PB_DATATABLE_REQUEST = 'PB/PB_DATATABLE_REQUEST';
export const PB_DATATABLE_SUCCESS = 'PB/PB_DATATABLE_SUCCESS';
export const PB_DATATABLE_FAILURE = 'PB/PB_DATATABLE_FAILURE';

//
// PB VIEW
//
export const PB_VIEW_REQUEST = 'PB/PB_VIEW_REQUEST';
export const PB_VIEW_SUCCESS = 'PB/PB_VIEW_SUCCESS';
export const PB_VIEW_FAILURE = 'PB/PB_VIEW_FAILURE';

//
// PB PAY CEEATE
//
export const PB_PAY_CREATE_REQUEST = 'PB/PB_PAY_CREATE_REQUEST';
export const PB_PAY_CREATE_SUCCESS = 'PB/PB_PAY_CREATE_SUCCESS';
export const PB_PAY_CREATE_FAILURE = 'PB/PB_PAY_CREATE_FAILURE';
//
// PB PAY REDIRECT
//
export const PB_PAY_REDIRECT_REQUEST = 'PB/PB_PAY_REDIRECT_REQUEST';
export const PB_PAY_REDIRECT_SUCCESS = 'PB/PB_PAY_REDIRECT_SUCCESS';
export const PB_PAY_REDIRECT_FAILURE = 'PB/PB_PAY_REDIRECT_FAILURE';

//
// PB SUBMIT
//
export const PB_SUBMIT_REQUEST = 'PB/PB_SUBMIT_REQUEST';
export const PB_SUBMIT_SUCCESS = 'PB/PB_SUBMIT_SUCCESS';
export const PB_SUBMIT_FAILURE = 'PB/PB_SUBMIT_FAILURE';

//
// PB DRAFT
//
export const PB_DRAFT_REQUEST = 'PB/PB_DRAFT_REQUEST';
export const PB_DRAFT_SUCCESS = 'PB/PB_DRAFT_SUCCESS';
export const PB_DRAFT_FAILURE = 'PB/PB_DRAFT_FAILURE';

//
// PB EDIT
//
export const PB_EDIT_REQUEST = 'PB/PB_EDIT_REQUEST';
export const PB_EDIT_SUCCESS = 'PB/PB_EDIT_SUCCESS';
export const PB_EDIT_FAILURE = 'PB/PB_EDIT_FAILURE';

//
// PB PELAN
//
export const PB_PELAN_REQUEST = 'PB/PB_PELAN_REQUEST';
export const PB_PELAN_SUCCESS = 'PB/PB_PELAN_SUCCESS';
export const PB_PELAN_FAILURE = 'PB/PB_PELAN_FAILURE';

//
// PB DELETEDRAFT
//
export const PB_DELETEDRAFT_REQUEST = 'PB/PB_DELETEDRAFT_REQUEST';
export const PB_DELETEDRAFT_SUCCESS = 'PB/PB_DELETEDRAFT_SUCCESS';
export const PB_DELETEDRAFT_FAILURE = 'PB/PB_DELETEDRAFT_FAILURE';



// 3. /////////////////////////////////////////////////////////////////////////////// PMBB
//
// PMBB DATATABLE
//
export const PMBB_DATATABLE_REQUEST = 'PMBB/PMBB_DATATABLE_REQUEST';
export const PMBB_DATATABLE_SUCCESS = 'PMBB/PMBB_DATATABLE_SUCCESS';
export const PMBB_DATATABLE_FAILURE = 'PMBB/PMBB_DATATABLE_FAILURE';

//
// PMBB VIEW
//
export const PMBB_VIEW_REQUEST = 'PMBB/PMBB_VIEW_REQUEST';
export const PMBB_VIEW_SUCCESS = 'PMBB/PMBB_VIEW_SUCCESS';
export const PMBB_VIEW_FAILURE = 'PMBB/PMBB_VIEW_FAILURE';

//
// PMBB PAY CREATE
//
export const PMBB_PAY_CREATE_REQUEST = 'PMBB/PMBB_PAY_CREATE_REQUEST';
export const PMBB_PAY_CREATE_SUCCESS = 'PMBB/PMBB_PAY_CREATE_SUCCESS';
export const PMBB_PAY_CREATE_FAILURE = 'PMBB/PMBB_PAY_CREATE_FAILURE';

//
// PMBB PAY REDIRECT
//
export const PMBB_PAY_REDIRECT_REQUEST = 'PMBB/PMBB_PAY_REDIRECT_REQUEST';
export const PMBB_PAY_REDIRECT_SUCCESS = 'PMBB/PMBB_PAY_REDIRECT_SUCCESS';
export const PMBB_PAY_REDIRECT_FAILURE = 'PMBB/PMBB_PAY_REDIRECT_FAILURE';

//
// PMBB SUBMIT
//
export const PMBB_SUBMIT_REQUEST = 'PMBB/PMBB_SUBMIT_REQUEST';
export const PMBB_SUBMIT_SUCCESS = 'PMBB/PMBB_SUBMIT_SUCCESS';
export const PMBB_SUBMIT_FAILURE = 'PMBB/PMBB_SUBMIT_FAILURE';

//
// PMBB DRAFT
//
export const PMBB_DRAFT_REQUEST = 'PMBB/PMBB_DRAFT_REQUEST';
export const PMBB_DRAFT_SUCCESS = 'PMBB/PMBB_DRAFT_SUCCESS';
export const PMBB_DRAFT_FAILURE = 'PMBB/PMBB_DRAFT_FAILURE';

//
// PMBB EDIT
//
export const PMBB_EDIT_REQUEST = 'PMBB/PMBB_EDIT_REQUEST';
export const PMBB_EDIT_SUCCESS = 'PMBB/PMBB_EDIT_SUCCESS';
export const PMBB_EDIT_FAILURE = 'PMBB/PMBB_EDIT_FAILURE';

//
// PMBB PELAN
//
export const PMBB_PELAN_REQUEST = 'PMBB/PMBB_PELAN_REQUEST';
export const PMBB_PELAN_SUCCESS = 'PMBB/PMBB_PELAN_SUCCESS';
export const PMBB_PELAN_FAILURE = 'PMBB/PMBB_PELAN_FAILURE';

//
// PMBB DELETEDRAFT
//
export const PMBB_DELETEDRAFT_REQUEST = 'PMBB/PMBB_DELETEDRAFT_REQUEST';
export const PMBB_DELETEDRAFT_SUCCESS = 'PMBB/PMBB_DELETEDRAFT_SUCCESS';
export const PMBB_DELETEDRAFT_FAILURE = 'PMBB/PMBB_DELETEDRAFT_FAILURE';


// 4. /////////////////////////////////////////////////////////////////////////////// PKT
//
// PKT DATATABLE
//
export const PKT_DATATABLE_REQUEST = 'PKT/PKT_DATATABLE_REQUEST';
export const PKT_DATATABLE_SUCCESS = 'PKT/PKT_DATATABLE_SUCCESS';
export const PKT_DATATABLE_FAILURE = 'PKT/PKT_DATATABLE_FAILURE';

//
// PKT VIEW
//
export const PKT_VIEW_REQUEST = 'PKT/PKT_VIEW_REQUEST';
export const PKT_VIEW_SUCCESS = 'PKT/PKT_VIEW_SUCCESS';
export const PKT_VIEW_FAILURE = 'PKT/PKT_VIEW_FAILURE';

//
// PKT PAY CREATE
//
export const PKT_PAY_CREATE_REQUEST = 'PKT/PKT_PAY_CREATE_REQUEST';
export const PKT_PAY_CREATE_SUCCESS = 'PKT/PKT_PAY_CREATE_SUCCESS';
export const PKT_PAY_CREATE_FAILURE = 'PKT/PKT_PAY_CREATE_FAILURE';

//
// PKT PAY REDIRECT
//
export const PKT_PAY_REDIRECT_REQUEST = 'PKT/PKT_PAY_REDIRECT_REQUEST';
export const PKT_PAY_REDIRECT_SUCCESS = 'PKT/PKT_PAY_REDIRECT_SUCCESS';
export const PKT_PAY_REDIRECT_FAILURE = 'PKT/PKT_PAY_REDIRECT_FAILURE';

//
// PKT SUBMIT
//
export const PKT_SUBMIT_REQUEST = 'PKT/PKT_SUBMIT_REQUEST';
export const PKT_SUBMIT_SUCCESS = 'PKT/PKT_SUBMIT_SUCCESS';
export const PKT_SUBMIT_FAILURE = 'PKT/PKT_SUBMIT_FAILURE';

//
// PKT DRAFT
//
export const PKT_DRAFT_REQUEST = 'PKT/PKT_DRAFT_REQUEST';
export const PKT_DRAFT_SUCCESS = 'PKT/PKT_DRAFT_SUCCESS';
export const PKT_DRAFT_FAILURE = 'PKT/PKT_DRAFT_FAILURE';

//
// PKT EDIT
//
export const PKT_EDIT_REQUEST = 'PKT/PKT_EDIT_REQUEST';
export const PKT_EDIT_SUCCESS = 'PKT/PKT_EDIT_SUCCESS';
export const PKT_EDIT_FAILURE = 'PKT/PKT_EDIT_FAILURE';

//
// PKT PELAN
//
export const PKT_PELAN_REQUEST = 'PKT/PKT_PELAN_REQUEST';
export const PKT_PELAN_SUCCESS = 'PKT/PKT_PELAN_SUCCESS';
export const PKT_PELAN_FAILURE = 'PKT/PKT_PELAN_FAILURE';

//
// PKT DELETEDRAFT
//
export const PKT_DELETEDRAFT_REQUEST = 'PKT/PKT_DELETEDRAFT_REQUEST';
export const PKT_DELETEDRAFT_SUCCESS = 'PKT/PKT_DELETEDRAFT_SUCCESS';
export const PKT_DELETEDRAFT_FAILURE = 'PKT/PKT_DELETEDRAFT_FAILURE';

//
// PKT SEKSYENJALAN
//
export const PKT_SEKSYENJALAN_REQUEST = 'PKT/PKT_SEKSYENJALAN_REQUEST';
export const PKT_SEKSYENJALAN_SUCCESS = 'PKT/PKT_SEKSYENJALAN_SUCCESS';
export const PKT_SEKSYENJALAN_FAILURE = 'PKT/PKT_SEKSYENJALAN_FAILURE';
