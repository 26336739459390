import * as actions from '../action/pskediaman';
import * as constants from '../constant/pskediaman';

export interface PsKediamanState {
  data: object;
  error: string;
  loading: boolean;
}

export const pskediamanReducer = (
  state: PsKediamanState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.PsKediamanAction) => {    
    switch (action.type) {
      
      //
      // PSKEDIAMAN DATATABLE
      //
      case constants.PSKEDIAMAN_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDIAMAN_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDIAMAN_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDIAMAN DRAFT
      //
      case constants.PSKEDIAMAN_DRAFT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDIAMAN_DRAFT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDIAMAN_DRAFT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDIAMAN EDIT
      //
      case constants.PSKEDIAMAN_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDIAMAN_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDIAMAN_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDIAMAN SUBMIT
      //
      case constants.PSKEDIAMAN_SUBMIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDIAMAN_SUBMIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDIAMAN_SUBMIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDIAMAN VIEW
      //
      case constants.PSKEDIAMAN_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDIAMAN_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDIAMAN_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDIAMAN SEARCH
      //
      case constants.PSKEDIAMAN_SEARCH_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDIAMAN_SEARCH_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDIAMAN_SEARCH_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  