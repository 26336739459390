import * as actions from "../action/user";
import * as constants from "../constant/user";

//
// DELETE USER
//
export function deleteUserRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.DeleteUserRequestAction {
  return {
    type: constants.DELETE_USER_REQUEST,
    params: params,
    onCallback,
  };
}
export function deleteUserSuccess(
  data: object
): actions.DeleteUserSuccessAction {
  return {
    type: constants.DELETE_USER_SUCCESS,
    data,
  };
}
export function deleteUserFailure(
  error: string
): actions.DeleteUserFailureAction {
  return {
    type: constants.DELETE_USER_FAILURE,
    error,
  };
}

//
// UPDATE PROFILE
//
export function updateProfileRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UpdateProfileRequestAction {
  return {
    type: constants.UPDATEPROFILE_REQUEST,
    params: params,
    onCallback,
  };
}
export function updateProfileSuccess(
  data: object
): actions.UpdateProfileSuccessAction {
  return {
    type: constants.UPDATEPROFILE_SUCCESS,
    data,
  };
}
export function updateProfileFailure(
  error: string
): actions.UpdateProfileFailureAction {
  return {
    type: constants.UPDATEPROFILE_FAILURE,
    error,
  };
}

//
// CHANGE PASSWORD
//
export function updatePasswordRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UpdatePasswordRequestAction {
  return {
    type: constants.UPDATEPASSWORD_REQUEST,
    params,
    onCallback,
  };
}
export function updatePasswordSuccess(
  data: object
): actions.UpdatePasswordSuccessAction {
  return {
    type: constants.UPDATEPASSWORD_SUCCESS,
    data,
  };
}
export function updatePasswordFailure(
  error: string
): actions.UpdatePasswordFailureAction {
  return {
    type: constants.UPDATEPASSWORD_FAILURE,
    error,
  };
}

//
// ADMIN CHANGE PASSWORD
//
export function adminUpdatePasswordRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.AdminUpdatePasswordRequestAction {
  return {
    type: constants.ADMINUPDATEPASSWORD_REQUEST,
    params,
    onCallback,
  };
}
export function adminUpdatePasswordSuccess(
  data: object
): actions.AdminUpdatePasswordSuccessAction {
  return {
    type: constants.ADMINUPDATEPASSWORD_SUCCESS,
    data,
  };
}
export function adminUpdatePasswordFailure(
  error: string
): actions.AdminUpdatePasswordFailureAction {
  return {
    type: constants.ADMINUPDATEPASSWORD_FAILURE,
    error,
  };
}

//
// USER DETAILS
//
export function userDetailsRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UserDetailsRequestAction {
  return {
    type: constants.USERDETAILS_REQUEST,
    params,
    onCallback,
  };
}
export function userDetailsSuccess(
  data: object
): actions.UserDetailsSuccessAction {
  return {
    type: constants.USERDETAILS_SUCCESS,
    data,
  };
}
export function userDetailsFailure(
  error: string
): actions.UserDetailsFailureAction {
  return {
    type: constants.USERDETAILS_FAILURE,
    error,
  };
}

//
// UPDATE ADDRESS
//
export function updateAddressRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UpdateAddressRequestAction {
  return {
    type: constants.UPDATEADDRESS_REQUEST,
    params,
    onCallback,
  };
}
export function updateAddressSuccess(
  data: object
): actions.UpdateAddressSuccessAction {
  return {
    type: constants.UPDATEADDRESS_SUCCESS,
    data,
  };
}
export function updateAddressFailure(
  error: string
): actions.UpdateAddressFailureAction {
  return {
    type: constants.UPDATEADDRESS_FAILURE,
    error,
  };
}

//
// UPDATE CONTACT
//
export function updateContactRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UpdateContactRequestAction {
  return {
    type: constants.UPDATECONTACT_REQUEST,
    params,
    onCallback,
  };
}
export function updateContactSuccess(
  data: object
): actions.UpdateContactSuccessAction {
  return {
    type: constants.UPDATECONTACT_SUCCESS,
    data,
  };
}
export function updateContactFailure(
  error: string
): actions.UpdateContactFailureAction {
  return {
    type: constants.UPDATECONTACT_FAILURE,
    error,
  };
}

//
// ADDRESS
//
export function addressRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.AddressRequestAction {
  return {
    type: constants.ADDRESS_REQUEST,
    params,
    onCallback,
  };
}
export function addressSuccess(data: object): actions.AddressSuccessAction {
  return {
    type: constants.ADDRESS_SUCCESS,
    data,
  };
}
export function addressFailure(error: string): actions.AddressFailureAction {
  return {
    type: constants.ADDRESS_FAILURE,
    error,
  };
}

//
// CONTACT
//
export function contactRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.ContactRequestAction {
  return {
    type: constants.CONTACT_REQUEST,
    params,
    onCallback,
  };
}
export function contactSuccess(data: object): actions.ContactSuccessAction {
  return {
    type: constants.CONTACT_SUCCESS,
    data,
  };
}
export function contactFailure(error: string): actions.ContactFailureAction {
  return {
    type: constants.CONTACT_FAILURE,
    error,
  };
}

//
// EMPLOYER
//
export function employerRequest(
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.EmployerRequestAction {
  return {
    type: constants.EMPLOYER_REQUEST,
    onCallback,
  };
}
export function employerSuccess(data: object): actions.EmployerSuccessAction {
  return {
    type: constants.EMPLOYER_SUCCESS,
    data,
  };
}
export function employerFailure(error: string): actions.EmployerFailureAction {
  return {
    type: constants.EMPLOYER_FAILURE,
    error,
  };
}

//
// UPDATE EMPLOYER
//
export function updateEmployerRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UpdateEmployerRequestAction {
  return {
    type: constants.UPDATEEMPLOYER_REQUEST,
    params: params,
    onCallback,
  };
}
export function updateEmployerSuccess(
  data: object
): actions.UpdateEmployerSuccessAction {
  return {
    type: constants.UPDATEEMPLOYER_SUCCESS,
    data,
  };
}
export function updateEmployerFailure(
  error: string
): actions.UpdateEmployerFailureAction {
  return {
    type: constants.UPDATEEMPLOYER_FAILURE,
    error,
  };
}

//
// UPDATE LOCALE
//
export function updateLocaleRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UpdateLocaleRequestAction {
  return {
    type: constants.UPDATELOCALE_REQUEST,
    params: params,
    onCallback,
  };
}
export function updateLocaleSuccess(
  data: object
): actions.UpdateLocaleSuccessAction {
  return {
    type: constants.UPDATELOCALE_SUCCESS,
    data,
  };
}
export function updateLocaleFailure(
  error: string
): actions.UpdateLocaleFailureAction {
  return {
    type: constants.UPDATELOCALE_FAILURE,
    error,
  };
}

//
// UPDATE EMAIL
//
export function updateEmailRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.UpdateEmailRequestAction {
  return {
    type: constants.UPDATEEMAIL_REQUEST,
    params: params,
    onCallback,
  };
}
export function updateEmailSuccess(
  data: object
): actions.UpdateEmailSuccessAction {
  return {
    type: constants.UPDATEEMAIL_SUCCESS,
    data,
  };
}
export function updateEmailFailure(
  error: string
): actions.UpdateEmailFailureAction {
  return {
    type: constants.UPDATEEMAIL_FAILURE,
    error,
  };
}
