import Carousel from "react-multi-carousel";
import arrow from "../../../assets/icons/svg/Arrow 2 Right.svg";
import { FormattedMessage } from "react-intl";

const Announcement = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const promotionList = [
    // {
    //   imageUrl: "images/carousel/promotion/ulang.jpeg",
    // },
    {
      imageUrl: "images/carousel/promotion/two.jpeg",
    },
    // {
    //   imageUrl: "images/carousel/promotion/a9.jpg",
    // },
    {
      imageUrl: "images/carousel/promotion/a6.jpg",
    },
    {
      imageUrl: "images/carousel/promotion/a4.jpg",
    },
    {
      imageUrl: "images/carousel/promotion/a5.jpg",
    },
    {
      imageUrl: "images/carousel/promotion/a7.jpg",
    },
    {
      imageUrl: "images/carousel/promotion/a8.jpg",
    },
    {
      imageUrl: "images/carousel/promotion/a10.jpg",
    },
    {
      imageUrl: "images/carousel/promotion/a11.jpg",
    },
    {
      imageUrl: "images/carousel/promotion/a13.jpeg",
    },
    {
      imageUrl: "images/carousel/promotion/a14.jpeg",
    },
    {
      imageUrl: "images/carousel/promotion/a15.jpeg",
    },
  ];

  const announcementList = [
    {
      title: "System Maintenance",
      content: (
        <>
          Sistem Mybsa ditutup selama 2 hari dari 1 Feb ke 2 Feb kerana
          kerja-kerja naik taraf sistem
        </>
      ),
      link: "http://www.mdks.gov.my/en/node/2901",
    },
  ];

  const notificationList = [
    // {
    //   title: "PENGUMUMAN",
    //   content: "notice.maintenance.line2",
    //   link: "",
    // },
    // {
    //   title: "PENGUMUMAN",
    //   content: "annoucement.2",
    //   link: "",
    // },
    // {
    //   title: "PENGUMUMAN",
    //   content: "tempahan.annoucement",
    //   link: "",
    // },
  ];

  return (
    <div className="grid grid-cols-12 content-center gap-10 xs:gap-0 mb-5">
      <div className="col-span-7 xs:col-span-12">
        <div className="sm:h-96 bg-white shadow-xl rounded-xl">
          <Carousel
            autoPlaySpeed={5000}
            infinite={true}
            autoPlay={true}
            responsive={responsive}
          >
            {promotionList.map((d) => {
              return (
                <img
                  key={Math.random()}
                  src={d.imageUrl}
                  className="sm:h-96 w-full rounded-xl object-fill"
                  alt="announcement"
                />
              );
            })}
          </Carousel>
        </div>
      </div>
      {/* <div className="sm:w-full xs:col-span-3">
        <div className="flex flex-col bg-white rounded-xl shadow-xl">
          <div>
            <Carousel autoPlaySpeed={5000} infinite={true} autoPlay={true} responsive={responsive}>
              {announcementList.map((d) => {
                return (
                  <div key={Math.random()} className="w-full h-52 p-5 rounded-xl">
                    <h1 className="text-3xl font-bold">
                      <a href={d.link} target="_blank">
                        {d.title}
                      </a>
                    </h1>
                    <div className="pt-5">{d.content}</div>
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="bg-mymbsa-primary">
            <h1 className="text-2xl font-bold text-white p-4">Pengumuman Baru</h1>
          </div>
          <div className="rounded-b-xl ">
            <h1 className="font-bold px-4 py-5 flex items-center">
              Baca Lagi{" "}
              <span className="ml-2">
                <img src={arrow} alt="next" />
              </span>
            </h1>
          </div>
        </div>
      </div> */}
      <div className="col-span-5 xs:col-span-12">
        <div className="overflow-y-auto h-max pr-3">
          <div className="flex flex-col h-96">
            <h1 className="text-2xl font-bold p-4">Pemberitahuan</h1>
            {/* <Carousel autoPlaySpeed={5000} infinite={true} autoPlay={true} responsive={responsive}> */}
            {notificationList.map((item, index) => (
              <div
                key={index}
                className="w-full p-5 mb-3 border-l-8 border-mymbsa-primary bg-white rounded-xl shadow-lg"
              >
                <h1 className="text-lg font-bold">{item.title}</h1>
                <p className="text-sm text-justify">
                  <FormattedMessage id={item.content} />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
