import * as actions from "../action/transaction";
import * as constants from "../constant/transaction";

//
// Transaction Datatable
//
export function TransactionDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.TransactionDatatableRequestAction {
  return {
    type: constants.TRANSACTION_DATATABLE_REQUEST,
    params,
    onCallback
  };
}
export function TransactionDatatableSuccess(data: object): actions.TransactionDatatableSuccessAction {
  return {
    type: constants.TRANSACTION_DATATABLE_SUCCESS,
    data,
  };
}
export function TransactionDatatableFailure(error: string): actions.TransactionDatatableFailureAction {
  return {
    type: constants.TRANSACTION_DATATABLE_FAILURE,
    error,
  };
}
