import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/auth';
import * as constants from '../constant/auth';
import * as functions from '../function/auth';


//
// LOGIN
//
function* login({params, onCallback }: actions.LoginRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: "/login",
        method: "POST",
        ignoreRedirect401: true,
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.loginSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.loginFailure(error));
    }
    
};

//
// IMPERSONATE
//
function* impersonate({params, onCallback }: actions.ImpersonateRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: "/secured/impersonate",
        method: "POST",
        ignoreRedirect401: true,
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.impersonateSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.impersonateFailure(error));
    }
    
};

//
// TOKEN
//
function* token({token, onCallback }: actions.TokenRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: "/token",
        method: "POST",
        ignoreRedirect401: true,
        data: {
            token: token
        }
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.tokenSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.tokenFailure(error));
    }
    
};


//
// LOGOUT
//
function* logout({token, userId,  onCallback}: actions.LogoutRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: "/logout",
        method: "POST",
        data: {
            token: token,
            userId: userId
        }
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.logoutSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.logoutFailure(error));
    }
    
};


//
// FORGOT PASSWORD
//
function* forgotPassword({email, onCallback }: actions.ForgotPasswordRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: "/login/forgotpassword2",
        method: "POST",
        data: {
            email: email
        }
    });
    if(status === 200) {        
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.forgotPasswordSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.forgotPasswordFailure(error));
    }
    
};

//
// UPDATE PASSWORD
//
function* updatePassword({token, password, onCallback }: actions.UpdatePasswordRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: "/login/updatepassword",
        method: "POST",
        data: {
            token: token,
            password: password
        }
    });
    if(status === 200) {        
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.updatePasswordSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.updatePasswordFailure(error));
    }
    
};



export function* watchAuth() {
    yield takeLatest(constants.LOGIN_REQUEST, login);
    yield takeLatest(constants.IMPERSONATE_REQUEST, impersonate);
    yield takeLatest(constants.TOKEN_REQUEST, token);
    yield takeLatest(constants.FORGOTPASSWORD_REQUEST, forgotPassword);
    yield takeLatest(constants.UPDATEPASSWORD_REQUEST, updatePassword);    
    yield takeLatest(constants.LOGOUT_REQUEST, logout);
};
