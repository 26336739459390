import * as actions from "../action/syarikat";
import * as constants from "../constant/syarikat";

//
// Syarikat Datatable
//
export function SyarikatDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatDatatableRequestAction {
  return {
    type: constants.SYARIKAT_DATATABLE_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatDatatableSuccess(
  data: object
): actions.SyarikatDatatableSuccessAction {
  return {
    type: constants.SYARIKAT_DATATABLE_SUCCESS,
    data,
  };
}

export function SyarikatDatatableFailure(
  error: string
): actions.SyarikatDatatableFailureAction {
  return {
    type: constants.SYARIKAT_DATATABLE_FAILURE,
    error,
  };
}

//
// Syarikat GetMOFPembekal
//
export function SyarikatGetMOFPembekalRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetMOFPembekalRequestAction {
  return {
    type: constants.SYARIKAT_GET_MOF_PEMBEKAL_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetMOFPembekalSuccess(
  data: object
): actions.SyarikatGetMOFPembekalSuccessAction {
  return {
    type: constants.SYARIKAT_GET_MOF_PEMBEKAL_SUCCESS,
    data,
  };
}

export function SyarikatGetMOFPembekalFailure(
  error: string
): actions.SyarikatGetMOFPembekalFailureAction {
  return {
    type: constants.SYARIKAT_GET_MOF_PEMBEKAL_FAILURE,
    error,
  };
}

//
// Syarikat GetListMOFPembekal
//
export function SyarikatGetListMOFPembekalRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetListMOFPembekalRequestAction {
  return {
    type: constants.SYARIKAT_GET_LIST_MOF_PEMBEKAL_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetListMOFPembekalSuccess(
  data: object
): actions.SyarikatGetListMOFPembekalSuccessAction {
  return {
    type: constants.SYARIKAT_GET_LIST_MOF_PEMBEKAL_SUCCESS,
    data,
  };
}

export function SyarikatGetListMOFPembekalFailure(
  error: string
): actions.SyarikatGetListMOFPembekalFailureAction {
  return {
    type: constants.SYARIKAT_GET_LIST_MOF_PEMBEKAL_FAILURE,
    error,
  };
}

//
// Syarikat GetBidang
//
export function SyarikatGetBidangRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetBidangRequestAction {
  return {
    type: constants.SYARIKAT_GET_BIDANG_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetBidangSuccess(
  data: object
): actions.SyarikatGetBidangSuccessAction {
  return {
    type: constants.SYARIKAT_GET_BIDANG_SUCCESS,
    data,
  };
}

export function SyarikatGetBidangFailure(
  error: string
): actions.SyarikatGetBidangFailureAction {
  return {
    type: constants.SYARIKAT_GET_BIDANG_FAILURE,
    error,
  };
}

//
// Syarikat GetSubBidang
//
export function SyarikatGetSubBidangRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetSubBidangRequestAction {
  return {
    type: constants.SYARIKAT_GET_SUB_BIDANG_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetSubBidangSuccess(
  data: object
): actions.SyarikatGetSubBidangSuccessAction {
  return {
    type: constants.SYARIKAT_GET_SUB_BIDANG_SUCCESS,
    data,
  };
}

export function SyarikatGetSubBidangFailure(
  error: string
): actions.SyarikatGetSubBidangFailureAction {
  return {
    type: constants.SYARIKAT_GET_SUB_BIDANG_FAILURE,
    error,
  };
}

//
// Syarikat GetPecahan
//
export function SyarikatGetPecahanRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetPecahanRequestAction {
  return {
    type: constants.SYARIKAT_GET_PECAHAN_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetPecahanSuccess(
  data: object
): actions.SyarikatGetPecahanSuccessAction {
  return {
    type: constants.SYARIKAT_GET_PECAHAN_SUCCESS,
    data,
  };
}

export function SyarikatGetPecahanFailure(
  error: string
): actions.SyarikatGetPecahanFailureAction {
  return {
    type: constants.SYARIKAT_GET_PECAHAN_FAILURE,
    error,
  };
}

//
// Syarikat AddMOFPembekal
//
export function SyarikatAddMOFPembekalRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatAddMOFPembekalRequestAction {
  return {
    type: constants.SYARIKAT_ADD_MOF_PEMBEKAL_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatAddMOFPembekalSuccess(
  data: object
): actions.SyarikatAddMOFPembekalSuccessAction {
  return {
    type: constants.SYARIKAT_ADD_MOF_PEMBEKAL_SUCCESS,
    data,
  };
}

export function SyarikatAddMOFPembekalFailure(
  error: string
): actions.SyarikatAddMOFPembekalFailureAction {
  return {
    type: constants.SYARIKAT_ADD_MOF_PEMBEKAL_FAILURE,
    error,
  };
}

//
// Syarikat DeleteMOFPembekal
//
export function SyarikatDeleteMOFPembekalRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatDeleteMOFPembekalRequestAction {
  return {
    type: constants.SYARIKAT_DELETE_MOF_PEMBEKAL_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatDeleteMOFPembekalSuccess(
  data: object
): actions.SyarikatDeleteMOFPembekalSuccessAction {
  return {
    type: constants.SYARIKAT_DELETE_MOF_PEMBEKAL_SUCCESS,
    data,
  };
}

export function SyarikatDeleteMOFPembekalFailure(
  error: string
): actions.SyarikatDeleteMOFPembekalFailureAction {
  return {
    type: constants.SYARIKAT_DELETE_MOF_PEMBEKAL_FAILURE,
    error,
  };
}

//
// Syarikat GetCIDBPembekal
//
export function SyarikatGetCIDBPembekalRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetCIDBPembekalRequestAction {
  return {
    type: constants.SYARIKAT_GET_CIDB_PEMBEKAL_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetCIDBPembekalSuccess(
  data: object
): actions.SyarikatGetCIDBPembekalSuccessAction {
  return {
    type: constants.SYARIKAT_GET_CIDB_PEMBEKAL_SUCCESS,
    data,
  };
}

export function SyarikatGetCIDBPembekalFailure(
  error: string
): actions.SyarikatGetCIDBPembekalFailureAction {
  return {
    type: constants.SYARIKAT_GET_CIDB_PEMBEKAL_FAILURE,
    error,
  };
}

//
// Syarikat GetListCIDBPembekal
//
export function SyarikatGetListCIDBPembekalRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetListCIDBPembekalRequestAction {
  return {
    type: constants.SYARIKAT_GET_LIST_CIDB_PEMBEKAL_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetListCIDBPembekalSuccess(
  data: object
): actions.SyarikatGetListCIDBPembekalSuccessAction {
  return {
    type: constants.SYARIKAT_GET_LIST_CIDB_PEMBEKAL_SUCCESS,
    data,
  };
}

export function SyarikatGetListCIDBPembekalFailure(
  error: string
): actions.SyarikatGetListCIDBPembekalFailureAction {
  return {
    type: constants.SYARIKAT_GET_LIST_CIDB_PEMBEKAL_FAILURE,
    error,
  };
}

//
// Syarikat GetGred
//
export function SyarikatGetGredRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetGredRequestAction {
  return {
    type: constants.SYARIKAT_GET_GRED_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetGredSuccess(
  data: object
): actions.SyarikatGetGredSuccessAction {
  return {
    type: constants.SYARIKAT_GET_GRED_SUCCESS,
    data,
  };
}

export function SyarikatGetGredFailure(
  error: string
): actions.SyarikatGetGredFailureAction {
  return {
    type: constants.SYARIKAT_GET_GRED_FAILURE,
    error,
  };
}

//
// Syarikat GetKategori
//
export function SyarikatGetKategoriRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetKategoriRequestAction {
  return {
    type: constants.SYARIKAT_GET_KATEGORI_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetKategoriSuccess(
  data: object
): actions.SyarikatGetKategoriSuccessAction {
  return {
    type: constants.SYARIKAT_GET_KATEGORI_SUCCESS,
    data,
  };
}

export function SyarikatGetKategoriFailure(
  error: string
): actions.SyarikatGetKategoriFailureAction {
  return {
    type: constants.SYARIKAT_GET_KATEGORI_FAILURE,
    error,
  };
}

//
// Syarikat GetPengkhususan
//
export function SyarikatGetPengkhususanRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetPengkhususanRequestAction {
  return {
    type: constants.SYARIKAT_GET_PENGKHUSUSAN_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetPengkhususanSuccess(
  data: object
): actions.SyarikatGetPengkhususanSuccessAction {
  return {
    type: constants.SYARIKAT_GET_PENGKHUSUSAN_SUCCESS,
    data,
  };
}

export function SyarikatGetPengkhususanFailure(
  error: string
): actions.SyarikatGetPengkhususanFailureAction {
  return {
    type: constants.SYARIKAT_GET_PENGKHUSUSAN_FAILURE,
    error,
  };
}

//
// Syarikat AddCIDBPembekal
//
export function SyarikatAddCIDBPembekalRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatAddCIDBPembekalRequestAction {
  return {
    type: constants.SYARIKAT_ADD_CIDB_PEMBEKAL_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatAddCIDBPembekalSuccess(
  data: object
): actions.SyarikatAddCIDBPembekalSuccessAction {
  return {
    type: constants.SYARIKAT_ADD_CIDB_PEMBEKAL_SUCCESS,
    data,
  };
}

export function SyarikatAddCIDBPembekalFailure(
  error: string
): actions.SyarikatAddCIDBPembekalFailureAction {
  return {
    type: constants.SYARIKAT_ADD_CIDB_PEMBEKAL_FAILURE,
    error,
  };
}

//
// Syarikat DeleteCIDBPembekal
//
export function SyarikatDeleteCIDBPembekalRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatDeleteCIDBPembekalRequestAction {
  return {
    type: constants.SYARIKAT_DELETE_CIDB_PEMBEKAL_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatDeleteCIDBPembekalSuccess(
  data: object
): actions.SyarikatDeleteCIDBPembekalSuccessAction {
  return {
    type: constants.SYARIKAT_DELETE_CIDB_PEMBEKAL_SUCCESS,
    data,
  };
}

export function SyarikatDeleteCIDBPembekalFailure(
  error: string
): actions.SyarikatDeleteCIDBPembekalFailureAction {
  return {
    type: constants.SYARIKAT_DELETE_CIDB_PEMBEKAL_FAILURE,
    error,
  };
}

//
// Syarikat SaveCIDB
//
export function SyarikatSaveCIDBRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveCIDBRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_CIDB_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveCIDBSuccess(
  data: object
): actions.SyarikatSaveCIDBSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_CIDB_SUCCESS,
    data,
  };
}

export function SyarikatSaveCIDBFailure(
  error: string
): actions.SyarikatSaveCIDBFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_CIDB_FAILURE,
    error,
  };
}

//
// Syarikat SaveMOF
//
export function SyarikatSaveMOFRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveMOFRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_MOF_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveMOFSuccess(
  data: object
): actions.SyarikatSaveMOFSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_MOF_SUCCESS,
    data,
  };
}

export function SyarikatSaveMOFFailure(
  error: string
): actions.SyarikatSaveMOFFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_MOF_FAILURE,
    error,
  };
}

//
// Syarikat SavePembekalSyarikat
//
export function SyarikatSavePembekalSyarikatRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSavePembekalSyarikatRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSavePembekalSyarikatSuccess(
  data: object
): actions.SyarikatSavePembekalSyarikatSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_SUCCESS,
    data,
  };
}

export function SyarikatSavePembekalSyarikatFailure(
  error: string
): actions.SyarikatSavePembekalSyarikatFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_FAILURE,
    error,
  };
}

//
// Syarikat GetJenisPemilikanPejabat
//
export function SyarikatGetJenisPemilikanPejabatRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetJenisPemilikanPejabatRequestAction {
  return {
    type: constants.SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetJenisPemilikanPejabatSuccess(
  data: object
): actions.SyarikatGetJenisPemilikanPejabatSuccessAction {
  return {
    type: constants.SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_SUCCESS,
    data,
  };
}

export function SyarikatGetJenisPemilikanPejabatFailure(
  error: string
): actions.SyarikatGetJenisPemilikanPejabatFailureAction {
  return {
    type: constants.SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_FAILURE,
    error,
  };
}

//
// Syarikat GetJenisSyarikat
//
export function SyarikatGetJenisSyarikatRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetJenisSyarikatRequestAction {
  return {
    type: constants.SYARIKAT_GET_JENIS_SYARIKAT_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetJenisSyarikatSuccess(
  data: object
): actions.SyarikatGetJenisSyarikatSuccessAction {
  return {
    type: constants.SYARIKAT_GET_JENIS_SYARIKAT_SUCCESS,
    data,
  };
}

export function SyarikatGetJenisSyarikatFailure(
  error: string
): actions.SyarikatGetJenisSyarikatFailureAction {
  return {
    type: constants.SYARIKAT_GET_JENIS_SYARIKAT_FAILURE,
    error,
  };
}

//
// Syarikat GetJenisPendaftaran
//
export function SyarikatGetJenisPendaftaranRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetJenisPendaftaranRequestAction {
  return {
    type: constants.SYARIKAT_GET_JENIS_PENDAFTARAN_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetJenisPendaftaranSuccess(
  data: object
): actions.SyarikatGetJenisPendaftaranSuccessAction {
  return {
    type: constants.SYARIKAT_GET_JENIS_PENDAFTARAN_SUCCESS,
    data,
  };
}

export function SyarikatGetJenisPendaftaranFailure(
  error: string
): actions.SyarikatGetJenisPendaftaranFailureAction {
  return {
    type: constants.SYARIKAT_GET_JENIS_PENDAFTARAN_FAILURE,
    error,
  };
}

//
// Syarikat GetDokForm49
//
export function SyarikatGetDokForm49Request(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetDokForm49RequestAction {
  return {
    type: constants.SYARIKAT_GET_DOK_FORM49_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetDokForm49Success(
  data: object
): actions.SyarikatGetDokForm49SuccessAction {
  return {
    type: constants.SYARIKAT_GET_DOK_FORM49_SUCCESS,
    data,
  };
}

export function SyarikatGetDokForm49Failure(
  error: string
): actions.SyarikatGetDokForm49FailureAction {
  return {
    type: constants.SYARIKAT_GET_DOK_FORM49_FAILURE,
    error,
  };
}

//
// Syarikat GetDokMof
//
export function SyarikatGetDokMofRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetDokMofRequestAction {
  return {
    type: constants.SYARIKAT_GET_DOK_MOF_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetDokMofSuccess(
  data: object
): actions.SyarikatGetDokMofSuccessAction {
  return {
    type: constants.SYARIKAT_GET_DOK_MOF_SUCCESS,
    data,
  };
}

export function SyarikatGetDokMofFailure(
  error: string
): actions.SyarikatGetDokMofFailureAction {
  return {
    type: constants.SYARIKAT_GET_DOK_MOF_FAILURE,
    error,
  };
}

//
// Syarikat GetDokCidb
//
export function SyarikatGetDokCidbRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetDokCidbRequestAction {
  return {
    type: constants.SYARIKAT_GET_DOK_CIDB_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetDokCidbSuccess(
  data: object
): actions.SyarikatGetDokCidbSuccessAction {
  return {
    type: constants.SYARIKAT_GET_DOK_CIDB_SUCCESS,
    data,
  };
}

export function SyarikatGetDokCidbFailure(
  error: string
): actions.SyarikatGetDokCidbFailureAction {
  return {
    type: constants.SYARIKAT_GET_DOK_CIDB_FAILURE,
    error,
  };
}

//
// Syarikat GetDokSst
//
export function SyarikatGetDokSstRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetDokSstRequestAction {
  return {
    type: constants.SYARIKAT_GET_DOK_SST_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetDokSstSuccess(
  data: object
): actions.SyarikatGetDokSstSuccessAction {
  return {
    type: constants.SYARIKAT_GET_DOK_SST_SUCCESS,
    data,
  };
}

export function SyarikatGetDokSstFailure(
  error: string
): actions.SyarikatGetDokSstFailureAction {
  return {
    type: constants.SYARIKAT_GET_DOK_SST_FAILURE,
    error,
  };
}

//
// Syarikat GetDokUpen
//
export function SyarikatGetDokUpenRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetDokUpenRequestAction {
  return {
    type: constants.SYARIKAT_GET_DOK_UPEN_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetDokUpenSuccess(
  data: object
): actions.SyarikatGetDokUpenSuccessAction {
  return {
    type: constants.SYARIKAT_GET_DOK_UPEN_SUCCESS,
    data,
  };
}

export function SyarikatGetDokUpenFailure(
  error: string
): actions.SyarikatGetDokUpenFailureAction {
  return {
    type: constants.SYARIKAT_GET_DOK_UPEN_FAILURE,
    error,
  };
}

//
// Syarikat GetDokBank
//
export function SyarikatGetDokBankRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetDokBankRequestAction {
  return {
    type: constants.SYARIKAT_GET_DOK_BANK_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetDokBankSuccess(
  data: object
): actions.SyarikatGetDokBankSuccessAction {
  return {
    type: constants.SYARIKAT_GET_DOK_BANK_SUCCESS,
    data,
  };
}

export function SyarikatGetDokBankFailure(
  error: string
): actions.SyarikatGetDokBankFailureAction {
  return {
    type: constants.SYARIKAT_GET_DOK_BANK_FAILURE,
    error,
  };
}

//
// Syarikat GetDokIc
//
export function SyarikatGetDokIcRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetDokIcRequestAction {
  return {
    type: constants.SYARIKAT_GET_DOK_IC_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetDokIcSuccess(
  data: object
): actions.SyarikatGetDokIcSuccessAction {
  return {
    type: constants.SYARIKAT_GET_DOK_IC_SUCCESS,
    data,
  };
}

export function SyarikatGetDokIcFailure(
  error: string
): actions.SyarikatGetDokIcFailureAction {
  return {
    type: constants.SYARIKAT_GET_DOK_IC_FAILURE,
    error,
  };
}
//
// Syarikat GetDokSSM
//
export function SyarikatGetDokSsmRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatGetDokSsmRequestAction {
  return {
    type: constants.SYARIKAT_GET_DOK_SSM_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatGetDokSsmSuccess(
  data: object
): actions.SyarikatGetDokSsmSuccessAction {
  return {
    type: constants.SYARIKAT_GET_DOK_SSM_SUCCESS,
    data,
  };
}

export function SyarikatGetDokSsmFailure(
  error: string
): actions.SyarikatGetDokSsmFailureAction {
  return {
    type: constants.SYARIKAT_GET_DOK_SSM_FAILURE,
    error,
  };
}

//
// Syarikat SaveDokForm49
//
export function SyarikatSaveDokForm49Request(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveDokForm49RequestAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_FORM49_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveDokForm49Success(
  data: object
): actions.SyarikatSaveDokForm49SuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_FORM49_SUCCESS,
    data,
  };
}

export function SyarikatSaveDokForm49Failure(
  error: string
): actions.SyarikatSaveDokForm49FailureAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_FORM49_FAILURE,
    error,
  };
}

//
// Syarikat SaveDokMof
//
export function SyarikatSaveDokMofRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveDokMofRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_MOF_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveDokMofSuccess(
  data: object
): actions.SyarikatSaveDokMofSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_MOF_SUCCESS,
    data,
  };
}

export function SyarikatSaveDokMofFailure(
  error: string
): actions.SyarikatSaveDokMofFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_MOF_FAILURE,
    error,
  };
}

//
// Syarikat SaveDokCidb
//
export function SyarikatSaveDokCidbRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveDokCidbRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_CIDB_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveDokCidbSuccess(
  data: object
): actions.SyarikatSaveDokCidbSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_CIDB_SUCCESS,
    data,
  };
}

export function SyarikatSaveDokCidbFailure(
  error: string
): actions.SyarikatSaveDokCidbFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_CIDB_FAILURE,
    error,
  };
}

//
// Syarikat SaveDokSst
//
export function SyarikatSaveDokSstRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveDokSstRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_SST_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveDokSstSuccess(
  data: object
): actions.SyarikatSaveDokSstSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_SST_SUCCESS,
    data,
  };
}

export function SyarikatSaveDokSstFailure(
  error: string
): actions.SyarikatSaveDokSstFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_SST_FAILURE,
    error,
  };
}

//
// Syarikat SaveDokUpen
//
export function SyarikatSaveDokUpenRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveDokUpenRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_UPEN_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveDokUpenSuccess(
  data: object
): actions.SyarikatSaveDokUpenSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_UPEN_SUCCESS,
    data,
  };
}

export function SyarikatSaveDokUpenFailure(
  error: string
): actions.SyarikatSaveDokUpenFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_UPEN_FAILURE,
    error,
  };
}

//
// Syarikat SaveDokBank
//
export function SyarikatSaveDokBankRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveDokBankRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_BANK_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveDokBankSuccess(
  data: object
): actions.SyarikatSaveDokBankSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_BANK_SUCCESS,
    data,
  };
}

export function SyarikatSaveDokBankFailure(
  error: string
): actions.SyarikatSaveDokBankFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_BANK_FAILURE,
    error,
  };
}

//
// Syarikat SaveDokIc
//
export function SyarikatSaveDokIcRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveDokIcRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_IC_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveDokIcSuccess(
  data: object
): actions.SyarikatSaveDokIcSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_IC_SUCCESS,
    data,
  };
}

export function SyarikatSaveDokIcFailure(
  error: string
): actions.SyarikatSaveDokIcFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_IC_FAILURE,
    error,
  };
}

//
// Syarikat SaveDokSSM
//
export function SyarikatSaveDokSsmRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>
): actions.SyarikatSaveDokSsmRequestAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_SSM_REQUEST,
    params,
    onCallback,
  };
}

export function SyarikatSaveDokSsmSuccess(
  data: object
): actions.SyarikatSaveDokSsmSuccessAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_SSM_SUCCESS,
    data,
  };
}

export function SyarikatSaveDokSsmFailure(
  error: string
): actions.SyarikatSaveDokSsmFailureAction {
  return {
    type: constants.SYARIKAT_SAVE_DOK_SSM_FAILURE,
    error,
  };
}
