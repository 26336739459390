//
// BIL DATATABLE
//
export const BIL_DATATABLE_REQUEST = 'BIL/BIL_DATATABLE_REQUEST';
export const BIL_DATATABLE_SUCCESS = 'BIL/BIL_DATATABLE_SUCCESS';
export const BIL_DATATABLE_FAILURE = 'BIL/BIL_DATATABLE_FAILURE';

//
//SEARCH BY VIEW
//
export const BIL_VIEW_REQUEST = 'BIL/BIL_VIEW_REQUEST';
export const BIL_VIEW_SUCCESS = 'BIL/BIL_VIEW_SUCCESS';
export const BIL_VIEW_FAILURE = 'BIL/BIL_VIEW_FAILURE';