import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/syarikat";
import * as constants from "../constant/syarikat";
import * as functions from "../function/syarikat";

//
// SYARIKAT DATATABLE
//
function* getSyarikatDatatable({
	params,
	onCallback,
}: actions.SyarikatDatatableRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/datatable`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatDatatableSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatDatatableFailure(error));
	}
}

//
// SYARIKAT GET_MOF_PEMBEKAL
//
function* getSyarikatGetMOFPembekal({
	params,
	onCallback,
}: actions.SyarikatGetMOFPembekalRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/mof/${params.ic_no}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetMOFPembekalSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetMOFPembekalFailure(error));
	}
}

//
// SYARIKAT GET_LIST_MOF_PEMBEKAL
//
function* getSyarikatGetListMOFPembekal({
	params,
	onCallback,
}: actions.SyarikatGetListMOFPembekalRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/mof/senaraiMofPembekal/${params.ic_no}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetListMOFPembekalSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetListMOFPembekalFailure(error));
	}
}

//
// SYARIKAT GET_BIDANG
//
function* getSyarikatGetBidang({
	params,
	onCallback,
}: actions.SyarikatGetBidangRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/mof/bidangMof`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetBidangSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetBidangFailure(error));
	}
}

//
// SYARIKAT GET_SUB_BIDANG
//
function* getSyarikatGetSubBidang({
	params,
	onCallback,
}: actions.SyarikatGetSubBidangRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/mof/subBidangMof/${params.bidang_id}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetSubBidangSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetSubBidangFailure(error));
	}
}

//
// SYARIKAT GET_PECAHAN
//
function* getSyarikatGetPecahan({
	params,
	onCallback,
}: actions.SyarikatGetPecahanRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/mof/pecahanMof/${params.sub_bidang_id}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetPecahanSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetPecahanFailure(error));
	}
}

function* getSyarikatAddMOFPembekal({
	params,
	onCallback,
}: actions.SyarikatAddMOFPembekalRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/mof/saveBidangMofPembekal`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatAddMOFPembekalSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatAddMOFPembekalFailure(error));
	}
}

function* getSyarikatDeleteMOFPembekal({
	params,
	onCallback,
}: actions.SyarikatDeleteMOFPembekalRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/mof/hapusMofPembekal/${params.pecahan_id}/${params.id}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatDeleteMOFPembekalSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatDeleteMOFPembekalFailure(error));
	}
}

//
// SYARIKAT GET_CIDB_PEMBEKAL
//
function* getSyarikatGetCIDBPembekal({
	params,
	onCallback,
}: actions.SyarikatGetCIDBPembekalRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/cidb/${params.ic_no}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetCIDBPembekalSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetCIDBPembekalFailure(error));
	}
}

//
// SYARIKAT GET_LIST_CIDB_PEMBEKAL
//
function* getSyarikatGetListCIDBPembekal({
	params,
	onCallback,
}: actions.SyarikatGetListCIDBPembekalRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/cidb/senaraiCidbPembekal/${params.ic_no}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetListCIDBPembekalSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetListCIDBPembekalFailure(error));
	}
}

//
// SYARIKAT GET_BIDANG
//
function* getSyarikatGetGred({
	params,
	onCallback,
}: actions.SyarikatGetGredRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/cidb/getCidbGred`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetGredSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetGredFailure(error));
	}
}

//
// SYARIKAT GET_SUB_BIDANG
//
function* getSyarikatGetKategori({
	params,
	onCallback,
}: actions.SyarikatGetKategoriRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/cidb/getCidbKategori`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetKategoriSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetKategoriFailure(error));
	}
}

//
// SYARIKAT GET_PECAHAN
//
function* getSyarikatGetPengkhususan({
	params,
	onCallback,
}: actions.SyarikatGetPengkhususanRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/cidb/getCidbPengkhususan/${params.kategori_id}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetPengkhususanSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetPengkhususanFailure(error));
	}
}

function* getSyarikatAddCIDBPembekal({
	params,
	onCallback,
}: actions.SyarikatAddCIDBPembekalRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/cidb/saveBidangCidbPembekal`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatAddCIDBPembekalSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatAddCIDBPembekalFailure(error));
	}
}

function* getSyarikatDeleteCIDBPembekal({
	params,
	onCallback,
}: actions.SyarikatDeleteCIDBPembekalRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/cidb/hapusCidbPembekal/${params.pengkhususan_id}/${params.id}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatDeleteCIDBPembekalSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatDeleteCIDBPembekalFailure(error));
	}
}

function* getSyarikatSaveCIDB({
	params,
	onCallback,
}: actions.SyarikatSaveCIDBRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/cidb`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveCIDBSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveCIDBFailure(error));
	}
}

function* getSyarikatSaveMOF({
	params,
	onCallback,
}: actions.SyarikatSaveMOFRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/mof`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveMOFSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveMOFFailure(error));
	}
}

function* getSyarikatSavePembekalSyarikat({
	params,
	onCallback,
}: actions.SyarikatSavePembekalSyarikatRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/savePembekalSyarikat`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSavePembekalSyarikatSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSavePembekalSyarikatFailure(error));
	}
}

function* getSyarikatGetJenisPemilikanPejabat({
	params,
	onCallback,
}: actions.SyarikatGetJenisPemilikanPejabatRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/getJenisPemilikan`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetJenisPemilikanPejabatSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetJenisPemilikanPejabatFailure(error));
	}
}

function* getSyarikatGetJenisPendaftaran({
	params,
	onCallback,
}: actions.SyarikatGetJenisPendaftaranRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/getJenisPendaftaran`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetJenisPendaftaranSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetJenisPendaftaranFailure(error));
	}
}

function* getSyarikatGetJenisSyarikat({
	params,
	onCallback,
}: actions.SyarikatGetJenisSyarikatRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/getJenisSyarikat/${params.pendaftaran_id}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetJenisSyarikatSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetJenisSyarikatFailure(error));
	}
}

function* getSyarikatGetDokForm49({
	params,
	onCallback,
}: actions.SyarikatGetDokForm49RequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/form49/${params.id_pel}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetDokForm49Success(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetDokForm49Failure(error));
	}
}

function* getSyarikatGetDokMof({
	params,
	onCallback,
}: actions.SyarikatGetDokMofRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/mof/${params.id_pel}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetDokMofSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetDokMofFailure(error));
	}
}

function* getSyarikatGetDokCidb({
	params,
	onCallback,
}: actions.SyarikatGetDokCidbRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/cidb/${params.id_pel}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetDokCidbSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetDokCidbFailure(error));
	}
}

function* getSyarikatGetDokSst({
	params,
	onCallback,
}: actions.SyarikatGetDokSstRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/sst/${params.id_pel}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetDokSstSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetDokSstFailure(error));
	}
}

function* getSyarikatGetDokUpen({
	params,
	onCallback,
}: actions.SyarikatGetDokUpenRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/upen/${params.id_pel}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetDokUpenSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetDokUpenFailure(error));
	}
}
function* getSyarikatGetDokSsm({
	params,
	onCallback,
}: actions.SyarikatGetDokSsmRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/ssm/${params.id_pel}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetDokSsmSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetDokSsmFailure(error));
	}
}

function* getSyarikatGetDokBank({
	params,
	onCallback,
}: actions.SyarikatGetDokBankRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/penyata-bank/${params.id_pel}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetDokBankSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetDokBankFailure(error));
	}
}

function* getSyarikatGetDokIc({
	params,
	onCallback,
}: actions.SyarikatGetDokIcRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/kad-pengenal/${params.id_pel}`,
		method: "GET",
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatGetDokIcSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatGetDokIcFailure(error));
	}
}

function* getSyarikatSaveDokForm49({
	params,
	onCallback,
}: actions.SyarikatSaveDokForm49RequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/form49`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveDokForm49Success(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveDokForm49Failure(error));
	}
}

function* getSyarikatSaveDokMof({
	params,
	onCallback,
}: actions.SyarikatSaveDokMofRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/mof`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveDokMofSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveDokMofFailure(error));
	}
}

function* getSyarikatSaveDokCidb({
	params,
	onCallback,
}: actions.SyarikatSaveDokCidbRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/cidb`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveDokCidbSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveDokCidbFailure(error));
	}
}

function* getSyarikatSaveDokSst({
	params,
	onCallback,
}: actions.SyarikatSaveDokSstRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/sst`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveDokSstSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveDokSstFailure(error));
	}
}

function* getSyarikatSaveDokUpen({
	params,
	onCallback,
}: actions.SyarikatSaveDokUpenRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/upen`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveDokUpenSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveDokUpenFailure(error));
	}
}

function* getSyarikatSaveDokBank({
	params,
	onCallback,
}: actions.SyarikatSaveDokBankRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/penyata-bank`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveDokBankSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveDokBankFailure(error));
	}
}

function* getSyarikatSaveDokIc({
	params,
	onCallback,
}: actions.SyarikatSaveDokIcRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/kad-pengenal`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveDokIcSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveDokIcFailure(error));
	}
}

function* getSyarikatSaveDokSsm({
	params,
	onCallback,
}: actions.SyarikatSaveDokSsmRequestAction) {
	const { status, data, error } = yield call(axios.call, {
		url: `/secured/syarikat/doc/ssm`,
		method: "POST",
		data: params,
	});
	if (status === 200) {
		onCallback({
			status: 1,
			data: data,
		});
		yield put(functions.SyarikatSaveDokSsmSuccess(data));
	} else {
		onCallback({
			status: 0,
			error: error,
		});
		yield put(functions.SyarikatSaveDokIcFailure(error));
	}
}

export function* watchSyarikat() {
	yield takeLatest(constants.SYARIKAT_DATATABLE_REQUEST, getSyarikatDatatable);
	yield takeLatest(constants.SYARIKAT_GET_MOF_PEMBEKAL_REQUEST,getSyarikatGetMOFPembekal);
	yield takeLatest(constants.SYARIKAT_GET_LIST_MOF_PEMBEKAL_REQUEST,getSyarikatGetListMOFPembekal);
	yield takeLatest(constants.SYARIKAT_GET_BIDANG_REQUEST, getSyarikatGetBidang);
	yield takeLatest(constants.SYARIKAT_GET_SUB_BIDANG_REQUEST,getSyarikatGetSubBidang);
	yield takeLatest(constants.SYARIKAT_GET_PECAHAN_REQUEST,getSyarikatGetPecahan);
	yield takeLatest(constants.SYARIKAT_ADD_MOF_PEMBEKAL_REQUEST,getSyarikatAddMOFPembekal);
	yield takeLatest(constants.SYARIKAT_DELETE_MOF_PEMBEKAL_REQUEST,getSyarikatDeleteMOFPembekal);
	yield takeLatest(constants.SYARIKAT_GET_CIDB_PEMBEKAL_REQUEST,getSyarikatGetCIDBPembekal);
	yield takeLatest(constants.SYARIKAT_GET_LIST_CIDB_PEMBEKAL_REQUEST,getSyarikatGetListCIDBPembekal);
	yield takeLatest(constants.SYARIKAT_GET_GRED_REQUEST, getSyarikatGetGred);
	yield takeLatest(constants.SYARIKAT_GET_KATEGORI_REQUEST,getSyarikatGetKategori);
	yield takeLatest(constants.SYARIKAT_GET_PENGKHUSUSAN_REQUEST,getSyarikatGetPengkhususan);
	yield takeLatest(constants.SYARIKAT_ADD_CIDB_PEMBEKAL_REQUEST,getSyarikatAddCIDBPembekal);
	yield takeLatest(constants.SYARIKAT_DELETE_CIDB_PEMBEKAL_REQUEST,getSyarikatDeleteCIDBPembekal);
	yield takeLatest(constants.SYARIKAT_SAVE_MOF_REQUEST, getSyarikatSaveMOF);
	yield takeLatest(constants.SYARIKAT_SAVE_CIDB_REQUEST, getSyarikatSaveCIDB);
	yield takeLatest(constants.SYARIKAT_SAVE_PEMBEKAL_SYARIKAT_REQUEST,getSyarikatSavePembekalSyarikat);
	yield takeLatest(constants.SYARIKAT_GET_JENIS_PEMILIKAN_PEJABAT_REQUEST,getSyarikatGetJenisPemilikanPejabat);
	yield takeLatest(constants.SYARIKAT_GET_JENIS_PENDAFTARAN_REQUEST,getSyarikatGetJenisPendaftaran);
	yield takeLatest(constants.SYARIKAT_GET_JENIS_SYARIKAT_REQUEST,getSyarikatGetJenisSyarikat);
	yield takeLatest(constants.SYARIKAT_GET_DOK_FORM49_REQUEST,getSyarikatGetDokForm49);
	yield takeLatest(constants.SYARIKAT_GET_DOK_MOF_REQUEST,getSyarikatGetDokMof);
	yield takeLatest(constants.SYARIKAT_GET_DOK_CIDB_REQUEST,getSyarikatGetDokCidb);
	yield takeLatest(constants.SYARIKAT_GET_DOK_SST_REQUEST,getSyarikatGetDokSst);
	yield takeLatest(constants.SYARIKAT_GET_DOK_UPEN_REQUEST,getSyarikatGetDokUpen);
	yield takeLatest(constants.SYARIKAT_GET_DOK_BANK_REQUEST,getSyarikatGetDokBank);
	yield takeLatest(constants.SYARIKAT_GET_DOK_IC_REQUEST,getSyarikatGetDokIc);
	yield takeLatest(constants.SYARIKAT_GET_DOK_SSM_REQUEST,getSyarikatGetDokSsm);
	yield takeLatest(constants.SYARIKAT_SAVE_DOK_FORM49_REQUEST,getSyarikatSaveDokForm49);
	yield takeLatest(constants.SYARIKAT_SAVE_DOK_MOF_REQUEST,getSyarikatSaveDokMof);
	yield takeLatest(constants.SYARIKAT_SAVE_DOK_CIDB_REQUEST,getSyarikatSaveDokCidb);
	yield takeLatest(constants.SYARIKAT_SAVE_DOK_SST_REQUEST,getSyarikatSaveDokSst);
	yield takeLatest(constants.SYARIKAT_SAVE_DOK_UPEN_REQUEST,getSyarikatSaveDokUpen);
	yield takeLatest(constants.SYARIKAT_SAVE_DOK_SSM_REQUEST,getSyarikatSaveDokSsm);
	yield takeLatest(constants.SYARIKAT_SAVE_DOK_BANK_REQUEST,getSyarikatSaveDokBank);
	yield takeLatest(constants.SYARIKAT_SAVE_DOK_IC_REQUEST,getSyarikatSaveDokIc);
}
