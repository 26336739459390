import * as actions from '../action/registration';
import * as constants from '../constant/registration';


//
// REGISTRATION
//
export function registrationRequest(
    params: any,
    onCallback: React.FC<{
        status: number,
        data?: any,
        error?: any}>): actions.RegistrationRequestAction {
    return {
        type: constants.REGISTRATION_REQUEST,
        params,
        onCallback
    }
}
export function registrationSuccess(data: object): actions.RegistrationSuccessAction {
    return {
        type: constants.REGISTRATION_SUCCESS,
        data
    }
}
export function registrationFailure(error: string): actions.RegistrationFailureAction {
    return {
        type: constants.REGISTRATION_FAILURE,
        error
    }
}



//
// VERIFYTAC
//
export function verifyTacRequest(tac: string,ic: string, token: string, onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.VerifyTacRequestAction {
    return {
        type: constants.VERIFYTAC_REQUEST,
        tac,
        ic,
        token,
        onCallback
    }
}

export function verifyTacSuccess(data: object): actions.VerifyTacSuccessAction {
    return {
        type: constants.VERIFYTAC_SUCCESS,
        data
    }
}

export function verifyTacFailure(error: string): actions.VerifyTacFailureAction {
    return {
        type: constants.VERIFYTAC_FAILURE,
        error
    }
}


//
// NEWPASSWORD
//
export function newPasswordRequest(token: string, password: string,  onCallback: React.FC<{
    status: number,
    data?: any,
    error?: any}>): actions.NewPasswordRequestAction {
    return {
        type: constants.NEWPASSWORD_REQUEST,
        token,
        password,
        onCallback
    }
}

export function newPasswordSuccess(data: object): actions.NewPasswordSuccessAction {
    return {
        type: constants.NEWPASSWORD_SUCCESS,
        data
    }
}

export function newPasswordFailure(error: string): actions.NewPasswordFailureAction {
    return {
        type: constants.NEWPASSWORD_FAILURE,
        error
    }
}
