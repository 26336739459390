import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/sewaan";
import * as constants from "../constant/sewaan";
import * as functions from "../function/sewaan";

//
// SEWAAN DATATABLE
//
function* getSewaanDatatable({
  params,
  onCallback,
}: actions.SewaanDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sewaan/list`,
    method: "GET",
    data: params,
  });

  // asdasdsadad

  if (status === 200) {
    const newData = [];

    // Check "individu" array
    if (data.individu) {
      data.individu.forEach((item) => {
        if (item.data.RET !== "0") {
          newData.push(...item.data.SENARAIAKAUN);
        }
      });
    }

    // Check "syarikat" array
    if (data.syarikat) {
      data.syarikat.forEach((item) => {
        if (item.data.RET !== "0") {
          newData.push(...item.data.SENARAIAKAUN);
        }
      });
    }

    onCallback({
      status: 1,
      data: { SENARAIAKAUN: newData },
    });
    yield put(
      functions.SewaanDatatableSuccess({
        status: 1,
        data: { SENARAIAKAUN: newData },
      })
    );
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SewaanDatatableFailure(error));
  }
}

function* getSewaanViewElectric({
  params,
  onCallback,
}: actions.SewaanViewElectricRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sewaan/bil-elektrik/${params.accNo}`,
    method: "GET",
    params: {
      bulan_bil: params.bulan_bil,
      tahun_bil: params.tahun_bil,
      no_bil: params.no_bil,
    },
  });
  if (status === 200) {
    console.log("true");

    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.SewaanViewElectricSuccess(data.url));
  } else {
    console.log("error");
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SewaanViewElectricFailure(error));
  }
}

function* getSewaanViewWater({
  params,
  onCallback,
}: actions.SewaanViewWaterRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sewaan/bil-air/${params.accNo}`,
    method: "GET",
    params: {
      bulan_bil: params.bulan_bil,
      tahun_bil: params.tahun_bil,
      no_bil: params.no_bil,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.SewaanViewWaterSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SewaanViewWaterFailure(error));
  }
}

function* getSewaanViewRent({
  params,
  onCallback,
}: actions.SewaanViewRentRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sewaan/bil/${params.accNo}`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.SewaanViewRentSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SewaanViewRentFailure(error));
  }
}

function* getSewaanViewNotice({
  params,
  onCallback,
}: actions.SewaanViewNoticeRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sewaan/notis/${params.accNo}`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.SewaanViewNoticeSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SewaanViewNoticeFailure(error));
  }
}

function* getSewaanViewLicense({
  params,
  onCallback,
}: actions.SewaanViewLicenseRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sewaan/lesen/${params.accNo}`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.SewaanViewLicenseSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SewaanViewLicenseFailure(error));
  }
}

function* getSewaanViewTranscElectric({
  params,
  onCallback,
}: actions.SewaanViewTranscElectricRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sewaan/transaksi/bil-elektrik/${params.accNo}`,
    method: "GET",
    params: {
      tarikh_dari: params.tarikh_dari,
      tarikh_hingga: params.tarikh_hingga,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.SewaanViewTranscElectricSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SewaanViewTranscElectricFailure(error));
  }
}

function* getSewaanViewTranscWater({
  params,
  onCallback,
}: actions.SewaanViewTranscWaterRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sewaan/transaksi/bil-air/${params.accNo}`,
    method: "GET",
    params: {
      tarikh_dari: params.tarikh_dari,
      tarikh_hingga: params.tarikh_hingga,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.SewaanViewTranscWaterSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SewaanViewTranscWaterFailure(error));
  }
}

function* getSewaanViewTranscRent({
  params,
  onCallback,
}: actions.SewaanViewTranscRentRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/sewaan/transaksi/${params.accNo}`,
    method: "GET",
    params: {
      tarikh_dari: params.tarikh_dari,
      tarikh_hingga: params.tarikh_hingga,
    },
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data.url,
    });
    yield put(functions.SewaanViewTranscRentSuccess(data.url));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.SewaanViewTranscRentFailure(error));
  }
}

export function* watchSewaan() {
  yield takeLatest(constants.SEWAAN_DATATABLE_REQUEST, getSewaanDatatable);
  yield takeLatest(
    constants.SEWAAN_VIEW_ELECTRIC_REQUEST,
    getSewaanViewElectric
  );
  yield takeLatest(constants.SEWAAN_VIEW_WATER_REQUEST, getSewaanViewWater);
  yield takeLatest(constants.SEWAAN_VIEW_RENT_REQUEST, getSewaanViewRent);
  yield takeLatest(constants.SEWAAN_VIEW_NOTICE_REQUEST, getSewaanViewNotice);
  yield takeLatest(constants.SEWAAN_VIEW_LICENSE_REQUEST, getSewaanViewLicense);
  yield takeLatest(
    constants.SEWAAN_VIEW_TRANSC_ELECTRIC_REQUEST,
    getSewaanViewTranscElectric
  );
  yield takeLatest(
    constants.SEWAAN_VIEW_TRANSC_WATER_REQUEST,
    getSewaanViewTranscWater
  );
  yield takeLatest(
    constants.SEWAAN_VIEW_TRANSC_RENT_REQUEST,
    getSewaanViewTranscRent
  );
}
