
import { CgSpinnerTwoAlt } from "react-icons/cg";

export interface SpinnerProps {
    className? : string;
    size?: string;
}

const Spinner = ({className, size = "1.25rem"} : SpinnerProps)  => {

    return <CgSpinnerTwoAlt size={size} className={`animate-spin ${className}`} />

}

export default Spinner;