import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/registration';
import * as constants from '../constant/registration';
import * as functions from '../function/registration';


//
// REGISTRATION
//
function* createRegistration({params, onCallback }: actions.RegistrationRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/registration`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.registrationSuccess(data));
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.registrationFailure(error));
    }
    
};

//
// VERIFYTAC
//
function* verifyTac({token, ic, tac, onCallback }: actions.VerifyTacRequestAction) {

    let url = `/login/verifyTac`;
    if(token){
        url = `/registration/verifyTac`;
    }
    const {status, data, error } = yield call(axios.call, {
        url: url,
        method: "POST",
        data: {
            token: token,
            tac: tac
        }
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.verifyTacSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.verifyTacFailure(error));
    }
    
};


//
// NEW PASSWORD
//
function* updateNewPassword({token, password, onCallback }: actions.NewPasswordRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/registration/newPassword`,
        method: "POST",
        data: {
            token: token,
            password: password
        }
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.newPasswordSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.newPasswordFailure(error));
    }
    
};



export function* watchRegistration() {
    yield takeLatest(constants.REGISTRATION_REQUEST, createRegistration);
    yield takeLatest(constants.VERIFYTAC_REQUEST, verifyTac);
    yield takeLatest(constants.NEWPASSWORD_REQUEST, updateNewPassword);
};
