import { call, put, takeLatest } from "redux-saga/effects";
import * as axios from "../../utils/axios";
import * as actions from "../action/kompaun";
import * as constants from "../constant/kompaun";
import * as functions from "../function/kompaun";

//
// KOMPAUN DATATABLE
//
function* getKompaunDatatable({
  params,
  onCallback,
}: actions.KompaunDatatableRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/kompaun/list/${params.accNo}`,
    method: "GET",
  });

  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.KompaunDatatableSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.KompaunDatatableFailure(error));
  }
}

function* getKompaunView({
  params,
  onCallback,
}: actions.KompaunViewRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/kompaun/detail/${params.accNo}`,
    method: "GET",
  });

  // status === 500; TAK SEPATUTNYA DEKAT CONDITION NI
  // SBB SAIISS MMG RETURN 500, WALAUPUN OK
  if (status === 200 || status === 500) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.KompaunViewSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.KompaunViewFailure(error));
  }
}

function* getKompaunTunda({
  params,
  onCallback,
}: actions.KompaunTundaRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/kompaun/list/tunda?page=${params.page}`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.KompaunTundaSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.KompaunTundaFailure(error));
  }
}

function* getKompaunCarian({
  params,
  onCallback,
}: actions.KompaunCarianRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/secured/kompaun/list/carian?no_id=${params.accNo}&type=${params.type}`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data: data,
    });
    yield put(functions.KompaunCarianSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.KompaunCarianFailure(error));
  }
}

function* getKompaunCarianNotSecured({
  params,
  onCallback,
}: actions.KompaunCarianNotSecuredRequestAction) {
  const { status, data, error } = yield call(axios.call, {
    url: `/kompaun/list-carian?no_id=${params.accNo}&type=${params.type}`,
    method: "GET",
  });
  if (status === 200) {
    onCallback({
      status: 1,
      data,
    });
    yield put(functions.KompaunCarianNotSecuredSuccess(data));
  } else {
    onCallback({
      status: 0,
      error: error,
    });
    yield put(functions.KompaunCarianNotSecuredFailure(error));
  }
}

export function* watchKompaun() {
  yield takeLatest(constants.KOMPAUN_DATATABLE_REQUEST, getKompaunDatatable);
  yield takeLatest(constants.KOMPAUN_VIEW_REQUEST, getKompaunView);
  yield takeLatest(constants.KOMPAUN_TUNDA_REQUEST, getKompaunTunda);
  yield takeLatest(constants.KOMPAUN_CARIAN_REQUEST, getKompaunCarian);
  yield takeLatest(
    constants.KOMPAUN_CARIAN_NOT_SECURED_REQUEST,
    getKompaunCarianNotSecured
  );
}
