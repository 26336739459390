import React, { useMemo, useState } from "react";
import { IntlProvider, MessageFormatElement } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { LOCALE_ALL_REQUEST } from "../redux/constant/locale";
import ErrorPage from "../views/ErrorPage";
import LoadingPage from "../views/LoadingPage";
import { lang } from "moment";

export interface LocalesProps {
  children: any;
}

const Locales = ({ children }: LocalesProps) => {
  const dispatch = useDispatch();
  // const customize = useSelector((state: any) => state.customize);
  const langCode = localStorage.getItem("lang");
  const { error } = useSelector((state: any) => state.locale);
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<
    Record<string, string> | Record<string, MessageFormatElement[]> | undefined
  >();
  const onCallback = (props: any) => {
    if (props.status === 1) {
      setMessages(props.data);
    }
    setLoading(false);
  };
  const handeError = () => {};

  useMemo(() => {
    if (langCode === null) {
      dispatch({
        type: LOCALE_ALL_REQUEST,
        localeCode: "ms",
        onCallback,
      });
    }

    if (langCode !== null) {
      dispatch({
        type: LOCALE_ALL_REQUEST,
        localeCode: langCode,
        onCallback,
      });
    }
  }, [langCode]);

  return (
    <>
      {loading && <LoadingPage />}
      {error && <ErrorPage error={error} />}
      {messages && (
        <IntlProvider
          locale={langCode}
          onError={handeError}
          defaultLocale={langCode}
          messages={messages}
        >
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default Locales;
