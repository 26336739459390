
//
// CUKAI DATATABLE
//
export const CUKAI_DATATABLE_REQUEST = 'CUKAI/CUKAI_DATATABLE_REQUEST';
export const CUKAI_DATATABLE_SUCCESS = 'CUKAI/CUKAI_DATATABLE_SUCCESS';
export const CUKAI_DATATABLE_FAILURE = 'CUKAI/CUKAI_DATATABLE_FAILURE';

//
// CUKAI DRAFT
//
export const CUKAI_DRAFT_REQUEST = 'CUKAI/CUKAI_DRAFT_REQUEST';
export const CUKAI_DRAFT_SUCCESS = 'CUKAI/CUKAI_DRAFT_SUCCESS';
export const CUKAI_DRAFT_FAILURE = 'CUKAI/CUKAI_DRAFT_FAILURE';

//
// CUKAI EDIT
//
export const CUKAI_EDIT_REQUEST = 'CUKAI/CUKAI_EDIT_REQUEST';
export const CUKAI_EDIT_SUCCESS = 'CUKAI/CUKAI_EDIT_SUCCESS';
export const CUKAI_EDIT_FAILURE = 'CUKAI/CUKAI_EDIT_FAILURE';

//
// CUKAI SUBMIT
//
export const CUKAI_SUBMIT_REQUEST = 'CUKAI/CUKAI_SUBMIT_REQUEST';
export const CUKAI_SUBMIT_SUCCESS = 'CUKAI/CUKAI_SUBMIT_SUCCESS';
export const CUKAI_SUBMIT_FAILURE = 'CUKAI/CUKAI_SUBMIT_FAILURE';

//
// CUKAI SEARCH
//
export const CUKAI_SEARCH_REQUEST = 'CUKAI/CUKAI_SEARCH_REQUEST';
export const CUKAI_SEARCH_SUCCESS = 'CUKAI/CUKAI_SEARCH_SUCCESS';
export const CUKAI_SEARCH_FAILURE = 'CUKAI/CUKAI_SEARCH_FAILURE';

//
// CUKAI VIEW
//
export const CUKAI_VIEW_REQUEST = 'CUKAI/CUKAI_VIEW_REQUEST';
export const CUKAI_VIEW_SUCCESS = 'CUKAI/CUKAI_VIEW_SUCCESS';
export const CUKAI_VIEW_FAILURE = 'CUKAI/CUKAI_VIEW_FAILURE';

//
// CUKAI PAY CREATE
//
export const CUKAI_PAY_CREATE_REQUEST = 'CUKAI/CUKAI_PAY_CREATE_REQUEST';
export const CUKAI_PAY_CREATE_SUCCESS = 'CUKAI/CUKAI_PAY_CREATE_SUCCESS';
export const CUKAI_PAY_CREATE_FAILURE = 'CUKAI/CUKAI_PAY_CREATE_FAILURE';

//
// CUKAI PAY REDIRECT
//
export const CUKAI_PAY_REDIRECT_REQUEST = 'CUKAI/CUKAI_PAY_REDIRECT_REQUEST';
export const CUKAI_PAY_REDIRECT_SUCCESS = 'CUKAI/CUKAI_PAY_REDIRECT_SUCCESS';
export const CUKAI_PAY_REDIRECT_FAILURE = 'CUKAI/CUKAI_PAY_REDIRECT_FAILURE';

//
// CUKAI DELETE DRAFT
//
export const CUKAI_DELETEDRAFT_REQUEST = 'CUKAI/CUKAI_DELETEDRAFT_REQUEST';
export const CUKAI_DELETEDRAFT_SUCCESS = 'CUKAI/CUKAI_DELETEDRAFT_SUCCESS';
export const CUKAI_DELETEDRAFT_FAILURE = 'CUKAI/CUKAI_DELETEDRAFT_FAILURE';

//
// CUKAI PRICE
//
export const CUKAI_PRICE_REQUEST = 'CUKAI/CUKAI_PRICE_REQUEST';
export const CUKAI_PRICE_SUCCESS = 'CUKAI/CUKAI_PRICE_SUCCESS';
export const CUKAI_PRICE_FAILURE = 'CUKAI/CUKAI_PRICE_FAILURE';

//
// SENARAI AKAUN
//
export const CUKAI_ACCOUNT_DATATABLE_REQUEST = 'CUKAI/CUKAI_ACCOUNT_DATATABLE_REQUEST';
export const CUKAI_ACCOUNT_DATATABLE_SUCCESS = 'CUKAI/CUKAI_ACCOUNT_DATATABLE_SUCCESS';
export const CUKAI_ACCOUNT_DATATABLE_FAILURE = 'CUKAI/CUKAI_ACCOUNT_DATATABLE_FAILURE';

//
// CUKAI VIEW ACCOUNT
//
export const CUKAI_VIEW_BILL_REQUEST = 'CUKAI/CUKAI_VIEW_BILL_REQUEST';
export const CUKAI_VIEW_BILL_SUCCESS = 'CUKAI/CUKAI_VIEW_BILL_SUCCESS';
export const CUKAI_VIEW_BILL_FAILURE = 'CUKAI/CUKAI_VIEW_BILL_FAILURE';

//
// CUKAI VIEW ACCOUNT INFO
//
export const CUKAI_VIEW_ACCOUNT_INFO_REQUEST = 'CUKAI/CUKAI_VIEW_ACCOUNT_INFO_REQUEST';
export const CUKAI_VIEW_ACCOUNT_INFO_SUCCESS = 'CUKAI/CUKAI_VIEW_ACCOUNT_INFO_SUCCESS';
export const CUKAI_VIEW_ACCOUNT_INFO_FAILURE = 'CUKAI/CUKAI_VIEW_ACCOUNT_INFO_FAILURE';

//
// PINDAHMILIK SUBMIT
//
export const CUKAI_PINDAHMILIK_SUBMIT_REQUEST = 'CUKAI/CUKAI_PINDAHMILIK_SUBMIT_REQUEST';
export const CUKAI_PINDAHMILIK_SUBMIT_SUCCESS = 'CUKAI/CUKAI_PINDAHMILIK_SUBMIT_SUCCESS';
export const CUKAI_PINDAHMILIK_SUBMIT_FAILURE = 'CUKAI/CUKAI_PINDAHMILIK_SUBMIT_FAILURE';

//
// KEMASKINI SUBMIT
//
export const CUKAI_KEMASKINI_SUBMIT_REQUEST = 'CUKAI/CUKAI_KEMASKINI_SUBMIT_REQUEST';
export const CUKAI_KEMASKINI_SUBMIT_SUCCESS = 'CUKAI/CUKAI_KEMASKINI_SUBMIT_SUCCESS';
export const CUKAI_KEMASKINI_SUBMIT_FAILURE = 'CUKAI/CUKAI_KEMASKINI_SUBMIT_FAILURE';

//
// PENGURANGAN SUBMIT
//
export const CUKAI_PENGURANGAN_SUBMIT_REQUEST = 'CUKAI/CUKAI_PENGURANGAN_SUBMIT_REQUEST';
export const CUKAI_PENGURANGAN_SUBMIT_SUCCESS = 'CUKAI/CUKAI_PENGURANGAN_SUBMIT_SUCCESS';
export const CUKAI_PENGURANGAN_SUBMIT_FAILURE = 'CUKAI/CUKAI_PENGURANGAN_SUBMIT_FAILURE';

//
// CUKAI VIEW BY ACCOUNT NO
//
export const CUKAI_VIEW_BY_ACCOUNT_NO_REQUEST = 'CUKAI/CUKAI_VIEW_BY_ACCOUNT_NO_REQUEST';
export const CUKAI_VIEW_BY_ACCOUNT_NO_SUCCESS = 'CUKAI/CUKAI_VIEW_BY_ACCOUNT_NO_SUCCESS';
export const CUKAI_VIEW_BY_ACCOUNT_NO_FAILURE = 'CUKAI/CUKAI_VIEW_BY_ACCOUNT_NO_FAILURE';

//
// CUKAI VIEW LIST PERMOHONAN
//
export const CUKAI_VIEW_LIST_PERMOHONAN_REQUEST = 'CUKAI/CUKAI_VIEW_LIST_PERMOHONAN_REQUEST';
export const CUKAI_VIEW_LIST_PERMOHONAN_SUCCESS = 'CUKAI/CUKAI_VIEW_LIST_PERMOHONAN_SUCCESS';
export const CUKAI_VIEW_LIST_PERMOHONAN_FAILURE = 'CUKAI/CUKAI_VIEW_LIST_PERMOHONAN_FAILURE';

//
// CUKAI VIEW LIST PINDAHMILIK
//
export const CUKAI_VIEW_LIST_PINDAHMILIK_REQUEST = 'CUKAI/CUKAI_VIEW_LIST_PINDAHMILIK_REQUEST';
export const CUKAI_VIEW_LIST_PINDAHMILIK_SUCCESS = 'CUKAI/CUKAI_VIEW_LIST_PINDAHMILIK_SUCCESS';
export const CUKAI_VIEW_LIST_PINDAHMILIK_FAILURE = 'CUKAI/CUKAI_VIEW_LIST_PINDAHMILIK_FAILURE';

//
// CUKAI VIEW LIST KEMASKINI
//
export const CUKAI_VIEW_LIST_KEMASKINI_REQUEST = "CUKAI/CUKAI_VIEW_LIST_KEMASKINI_REQUEST";
export const CUKAI_VIEW_LIST_KEMASKINI_SUCCESS = "CUKAI/CUKAI_VIEW_LIST_KEMASKINI_SUCCESS";
export const CUKAI_VIEW_LIST_KEMASKINI_FAILURE = "CUKAI/CUKAI_VIEW_LIST_KEMASKINI_FAILURE";

//
// CUKAI VIEW LIST PENGURANGAN 
//
export const CUKAI_VIEW_LIST_PENGURANGAN_REQUEST = "CUKAI/CUKAI_VIEW_LIST_PENGURANGAN_REQUEST";
export const CUKAI_VIEW_LIST_PENGURANGAN_SUCCESS = "CUKAI/CUKAI_VIEW_LIST_PENGURANGAN_SUCCESS";
export const CUKAI_VIEW_LIST_PENGURANGAN_FAILURE = "CUKAI/CUKAI_VIEW_LIST_PENGURANGAN_FAILURE";

// VIEW DRAF PINDAHMILIK
//
export const CUKAI_VIEW_DRAF_PINDAHMILIK_REQUEST = 'CUKAI/CUKAI_VIEW_DRAF_PINDAHMILIK_REQUEST';
export const CUKAI_VIEW_DRAF_PINDAHMILIK_SUCCESS = 'CUKAI/CUKAI_VIEW_DRAF_PINDAHMILIK_SUCCESS';
export const CUKAI_VIEW_DRAF_PINDAHMILIK_FAILURE = 'CUKAI/CUKAI_VIEW_DRAF_PINDAHMILIK_FAILURE';

// VIEW DRAF KEMASKINI
//
export const CUKAI_VIEW_DRAF_KEMASKINI_REQUEST = 'CUKAI/CUKAI_VIEW_DRAF_KEMASKINI_REQUEST';
export const CUKAI_VIEW_DRAF_KEMASKINI_SUCCESS = 'CUKAI/CUKAI_VIEW_DRAF_KEMASKINI_SUCCESS';
export const CUKAI_VIEW_DRAF_KEMASKINI_FAILURE = 'CUKAI/CUKAI_VIEW_DRAF_KEMASKINI_FAILURE';

// VIEW DRAF PENGURANGAN
//
export const CUKAI_VIEW_DRAF_PENGURANGAN_REQUEST = 'CUKAI/CUKAI_VIEW_DRAF_PENGURANGAN_REQUEST';
export const CUKAI_VIEW_DRAF_PENGURANGAN_SUCCESS = 'CUKAI/CUKAI_VIEW_DRAF_PENGURANGAN_SUCCESS';
export const CUKAI_VIEW_DRAF_PENGURANGAN_FAILURE = 'CUKAI/CUKAI_VIEW_DRAF_PENGURANGAN_FAILURE';