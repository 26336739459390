import * as actions from "../action/pskediaman";
import * as constants from "../constant/pskediaman";

//
// PsKediaman Datatable
//
export function PsKediamanDatatableRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKediamanDatatableRequestAction {
  return {
    type: constants.PSKEDIAMAN_DATATABLE_REQUEST,
    params,
    onCallback
  };
}
export function PsKediamanDatatableSuccess(data: object): actions.PsKediamanDatatableSuccessAction {
  return {
    type: constants.PSKEDIAMAN_DATATABLE_SUCCESS,
    data,
  };
}
export function PsKediamanDatatableFailure(error: string): actions.PsKediamanDatatableFailureAction {
  return {
    type: constants.PSKEDIAMAN_DATATABLE_FAILURE,
    error,
  };
}

//
// PsKediaman Draft
//
export function PsKediamanDraftRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKediamanDraftRequestAction {
  return {
    type: constants.PSKEDIAMAN_DRAFT_REQUEST,
    params,
    onCallback
  };
}
export function PsKediamanDraftSuccess(data: object): actions.PsKediamanDraftSuccessAction {
  return {
    type: constants.PSKEDIAMAN_DRAFT_SUCCESS,
    data,
  };
}
export function PsKediamanDraftFailure(error: string): actions.PsKediamanDraftFailureAction {
  return {
    type: constants.PSKEDIAMAN_DRAFT_FAILURE,
    error,
  };
}

//
// PsKediaman Edit
//
export function PsKediamanEditRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKediamanEditRequestAction {
  return {
    type: constants.PSKEDIAMAN_EDIT_REQUEST,
    params,
    onCallback
  };
}
export function PsKediamanEditSuccess(data: object): actions.PsKediamanEditSuccessAction {
  return {
    type: constants.PSKEDIAMAN_EDIT_SUCCESS,
    data,
  };
}
export function PsKediamanEditFailure(error: string): actions.PsKediamanEditFailureAction {
  return {
    type: constants.PSKEDIAMAN_EDIT_FAILURE,
    error,
  };
}

//
// PsKediaman Submit
//
export function PsKediamanSubmitRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKediamanSubmitRequestAction {
  return {
    type: constants.PSKEDIAMAN_SUBMIT_REQUEST,
    params,
    onCallback
  };
}
export function PsKediamanSubmitSuccess(data: object): actions.PsKediamanSubmitSuccessAction {
  return {
    type: constants.PSKEDIAMAN_SUBMIT_SUCCESS,
    data,
  };
}
export function PsKediamanSubmitFailure(error: string): actions.PsKediamanSubmitFailureAction {
  return {
    type: constants.PSKEDIAMAN_SUBMIT_FAILURE,
    error,
  };
}


//
// PsKediaman View
//
export function PsKediamanViewRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKediamanViewRequestAction {
  return {
    type: constants.PSKEDIAMAN_VIEW_REQUEST,
    params,
    onCallback
  };
}
export function PsKediamanViewSuccess(data: object): actions.PsKediamanViewSuccessAction {
  return {
    type: constants.PSKEDIAMAN_VIEW_SUCCESS,
    data,
  };
}
export function PsKediamanViewFailure(error: string): actions.PsKediamanViewFailureAction {
  return {
    type: constants.PSKEDIAMAN_VIEW_FAILURE,
    error,
  };
}


//
// PsKediaman Search
//
export function PsKediamanSearchRequest(
  params: any,
  onCallback: React.FC<{
    status: number;
    data?: any;
    error?: any;
  }>,
): actions.PsKediamanSearchRequestAction {
  return {
    type: constants.PSKEDIAMAN_SEARCH_REQUEST,
    params,
    onCallback
  };
}
export function PsKediamanSearchSuccess(data: object): actions.PsKediamanSearchSuccessAction {
  return {
    type: constants.PSKEDIAMAN_SEARCH_SUCCESS,
    data,
  };
}
export function PsKediamanSearchFailure(error: string): actions.PsKediamanSearchFailureAction {
  return {
    type: constants.PSKEDIAMAN_SEARCH_FAILURE,
    error,
  };
}