import axios from "axios";
import { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import kompounIcon from "../../assets/icons/svg/Jumlah Kompoun.svg";
import pointIcon from "../../assets/icons/svg/Jumlah Mata 2.svg";
import jpdIcon from "../../assets/icons/svg/Jumlah Perlu Dijelaskan.svg";
import lulusIcon from "../../assets/icons/svg/Jumlah Permohonan.svg";
import Header from "../../components/ui/Header";
import { API_URL } from "../../config";
import { KOMPAUN_DATATABLE_REQUEST } from "../../redux/constant/kompaun";
import { LOYALTI_EVENTS_REQUEST, LOYALTI_REWARD_REQUEST } from "../../redux/constant/loyalti";
import { SEKAT_DATATABLE_REQUEST } from "../../redux/constant/sekat";

const DashboardPage = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: any) => state.loyalti);
  const auth = useSelector((state: any) => state.auth);
  const [listing1, setListing1] = useState([]);
  const [listing2, setListing2] = useState([]);
  const [jumlahSekatRM, setJumlahSekatRM] = useState(0);
  const [jumlahSekat, setJumlahSekat] = useState(0);
  const [jumlahKompaunRM, setJumlahKompaunRM] = useState(0);
  const [jumlahKompaun, setJumlahKompaun] = useState(0);
  
  const [points, setPoints] = useState(0);

  const refreshData = async () => {
    // events
    dispatch({
      type: LOYALTI_EVENTS_REQUEST,
      onCallback: (props: any) => {
        if (props.status === 1 && props.data) {
          setListing1(props.data.data);
        }
      },
    });

    //reward
    dispatch({
      type: LOYALTI_REWARD_REQUEST,
      onCallback: (props: any) => {
        if (props.status === 1 && props.data) {
          console.log(props.data);
          // setPoints(props.data.points);
          setListing2(props.data.transactions);
        }
      },
    });

    //isekat
    dispatch({
      type: SEKAT_DATATABLE_REQUEST,
      onCallback: (props: any) => {
        if (props.status === 1 && props.data) {
          if(Array.isArray(props.data)) {
            let jumlah = 0;
            let kira = 0;
            props.data.map(m => {
              kira++;
              jumlah +=  parseFloat(m.amaun_bayar);              
            });
            setJumlahSekatRM(jumlah);
            setJumlahSekat(kira);
          }
        }
      },
    });

    console.log();
    if(auth.data.username) {
      fetch(API_URL + "/secured/kompaun/list/" + auth.data.username, {
        method: "get",
        headers: new Headers({
          "X-Auth": "Bearer " + auth.data.token,
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let jumlah = 0;
          let kira = 0;
          data.data.map(d => {
            if(d.can_pay == 1) {
              kira++;
              jumlah += d.jumlah_perlu_bayar;
            }
          });
          setJumlahKompaunRM(jumlah);
          setJumlahKompaun(kira);
        });
    }
  
    

    // need to change, for fat only
    const token = localStorage.getItem("mymbsa-token");
    const impersonateToken = localStorage.getItem("mymbsa-impersonate-token");
    const defaultOptions = {
      baseURL: API_URL,
      headers: {
        "X-Auth": impersonateToken ? `Bearer ${impersonateToken}` : `Bearer ${token}`,
      },
    };

    // Create instance
    let instance = axios.create(defaultOptions);
    await instance.get("/secured/loyalti/user-points").then((res) => {
      if (res.data && Array.isArray(res.data.data)) {
        console.log(res.data.data[0].overallpoints);
        setPoints(Math.round(res.data.data[0].overallpoints ?? 0));
      }
    });
  };

  useEffect(() => {
    refreshData();
  }, []);

  {
    /* render date */
  }
  const renderLoyaltiDate = (data: any) => {
    if (data.eventdate === data.eventdateto) {
      return (
        <div className="flex flex-row justify-end">
          <div className="text-sm text-mymbsa-primary font-semibold bg-mymbsa-primary bg-opacity-10 px-3 py-1 rounded-lg">
            <Moment format="DD/MM/YYYY">{data.eventdate}</Moment>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row justify-end">
          <div className="flex justify-between my-2">
            <div className="text-sm text-mymbsa-primary font-semibold bg-mymbsa-primary bg-opacity-10 px-3 py-1 rounded-lg">
              {/* <b>
                <FormattedMessage id="date.from" />
              </b>{" "} */}
              <Moment format="DD/MM/YYYY">{data.eventdate}</Moment>{" "}
            </div>
            <BsArrowRight className="mx-5 text-mymbsa-primaryaccent" />
            <div className="text-sm text-mymbsa-primary font-semibold bg-mymbsa-primary bg-opacity-10 px-3 py-1 rounded-lg">
              {/* <b>
                <FormattedMessage id="date.to" />
              </b>{" "} */}
              <Moment format="DD/MM/YYYY">{data.eventdateto}</Moment>{" "}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderLoyaltiCreateDate = (data: any) => {
    return (
      <div className="flex flex-row justify-end">
        <div className="text-sm text-mymbsa-primary font-semibold bg-mymbsa-primary bg-opacity-10 px-3 py-1 rounded-lg">
          <Moment format="DD/MM/YYYY hh:mm:ss a">{new Date()}</Moment>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* title */}
      <Header title={"titles-" + location.pathname} showWelcome={true} />

      {/* numbers */}
      <div className="p-3">
        <div className="flex xs:flex-col xs:gap-2 gap-2 flex-row item-between content-center">
          <div className="xs:w-full w-1/3">
            <div className="flex item-center content-center pl-5 pr-5">
              <div className="w-full rounded-lg flex flex-col justify-start p-5 shadow-lg gap-2 bg-white">
                <img src={pointIcon} alt="point" className="w-10" />
                <h1 className="font-sans text-3xl font-bold">
                  {intl.formatNumber(points, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </h1>
                <h1 className="font-sans text-sm">
                  <FormattedMessage id="dashboard.totalMataLoyalti" />
                </h1>
              </div>
            </div>
          </div>

          {/* <div className="xs:w-full w-1/4">
            <div className="flex item-center content-center pl-5 pr-5">
              <div className="w-full rounded-lg flex flex-col justify-start p-5 shadow-lg gap-2 bg-white">
                <img src={lulusIcon} alt="point" className="w-10" />
                <h1 className="font-sans text-3xl font-bold">0</h1>
                <h1 className="font-sans text-sm">
                  <FormattedMessage id="dashboard.application.success" />
                </h1>
              </div>
            </div>
          </div> */}

          <div className="xs:w-full w-1/3 cursor-pointer" onClick={ () => { navigate('/sekat'); } }>
            <div className="flex item-center content-center pl-5 pr-5">
              <div className="w-full  rounded-lg flex flex-col justify-start p-5 shadow-lg gap-2 bg-white">
                <img src={kompounIcon} alt="point" className="w-10" />
                <h1 className="font-sans text-3xl font-bold">RM {intl.formatNumber(jumlahSekatRM, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}</h1>
                <h1 className="font-sans text-sm">
                  <FormattedMessage id="dashboard.total.fine" /> {jumlahSekat}
                </h1>
              </div>
            </div>
          </div>

          <div className="xs:w-full w-1/3 cursor-pointer" onClick={ () => { navigate('/kompaun'); } }>
            <div className="flex item-center content-center pl-5 pr-5">
              <div className="w-full rounded-lg flex flex-col justify-start p-5 shadow-lg gap-2 bg-white">
                <img src={jpdIcon} alt="point" className="w-10" />
                <h1 className="font-sans text-3xl font-bold">RM {intl.formatNumber(jumlahKompaunRM, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}</h1>
                <h1 className="font-sans text-sm">
                  <FormattedMessage id="dashboard.outstanding.fine" /> {jumlahKompaun}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-2">&nbsp;</div>

      {/* listing */}
      <div className="flex xs:flex-col xs:gap-2 flex-row item-between content-center p-3">
        <div className="xs:w-full w-1/2">
          {/* Senarai aktiviti dalam program loyalti */}

          <div className="bg-white rounded-lg p-5 m-5 shadow-lg">
            <div
              className="flex item-center content-center overflow-y-scroll"
              style={{
                maxHeight: "500px",
              }}>
              <div className="w-full p-5">
                <div className="pb-5">
                  <h1 className="font-sans text-xl font-semibold">
                    <FormattedMessage id="dashboard.loyalti.events.listing" />
                  </h1>
                </div>
                {listing1 && listing1.length === 0 && (
                  <div className="p-3">
                    <div className="flex justify-center items-center">
                      <div>
                        <FormattedMessage id="listing.no.record" />
                      </div>
                    </div>
                  </div>
                )}
                {listing1 &&
                  listing1.map((event: any) => {
                    return (
                      <div key={Math.random()} className="">
                        <div className="flex xs:flex-col flex-row item-between content-center">
                          <div className="xs:w-full w-1/2">
                            <div className="font-sans text-nd">{event.name}</div>
                            <div className="font-sans text-xs">
                              <a
                                href={event.maplink}
                                target="_blank"
                                rel="noreferrer"
                                className="text-mymbsa-primary hover:cursor-pointer">
                                {event.location}
                              </a>
                            </div>
                          </div>
                          <div className="xs:w-full w-1/2">
                            <div className="font-sans text-md">{renderLoyaltiDate(event)}</div>
                          </div>
                        </div>
                        <hr className="bg-gray-100 mt-2" />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="xs:w-full w-1/2">
          {/* Transaksi Terkini Loyalti */}
          <div className="bg-white rounded-lg p-5 m-5 shadow-lg">
            <div
              className="flex xs:flex-col flex-row item-center content-center overflow-y-scroll"
              style={{
                maxHeight: "500px",
              }}>
              <div className="bg-white rounded-lg w-full p-5">
                <div className="pb-5">
                  <h1 className="font-sans text-xl font-semibold">
                    <FormattedMessage id="dashboard.loyalti.transaction.listing" />
                  </h1>
                </div>
                {listing2 && listing2.length === 0 && (
                  <div key={Math.random()} className="p-5">
                    <div className="flex justify-center items-center">
                      <div className="font-sans text-md font-regular">
                        <FormattedMessage id="listing.no.record" />
                      </div>
                    </div>
                  </div>
                )}
                {listing2 &&
                  listing2.map((event: any) => {
                    return (
                      <div key={Math.random()} className="">
                        <div className="flex item-between content-center">
                          <div className="w-1/2">
                            <div className="font-sans text-nd">{event.eventname}</div>
                            <div className="font-sans text-xs">{event.eventcategory}</div>
                          </div>
                          <div className="w-1/2">
                            <div className="font-sans text-md">
                              {renderLoyaltiCreateDate(event)}
                            </div>
                          </div>
                        </div>
                        <hr className="bg-gray-100 mt-2" />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
