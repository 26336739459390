import * as actions from '../action/pskedai';
import * as constants from '../constant/pskedai';

export interface PsKedaiState {
  data: object;
  error: string;
  loading: boolean;
}

export const pskedaiReducer = (
  state: PsKedaiState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.PsKedaiAction) => {    
    switch (action.type) {
      
      //
      // PSKEDAI DATATABLE
      //
      case constants.PSKEDAI_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDAI_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDAI_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDAI DRAFT
      //
      case constants.PSKEDAI_DRAFT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDAI_DRAFT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDAI_DRAFT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDAI EDIT
      //
      case constants.PSKEDAI_EDIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDAI_EDIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDAI_EDIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDAI SUBMIT
      //
      case constants.PSKEDAI_SUBMIT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDAI_SUBMIT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDAI_SUBMIT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDAI VIEW
      //
      case constants.PSKEDAI_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDAI_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDAI_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // PSKEDAI SEARCH
      //
      case constants.PSKEDAI_SEARCH_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.PSKEDAI_SEARCH_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.PSKEDAI_SEARCH_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  