//
// PS KEDAI DATATABLE
//
export const PSKEDAI_DATATABLE_REQUEST = 'PS/KEDAI_DATATABLE_REQUEST';
export const PSKEDAI_DATATABLE_SUCCESS = 'PS/KEDAI_DATATABLE_SUCCESS';
export const PSKEDAI_DATATABLE_FAILURE = 'PS/KEDAI_DATATABLE_FAILURE';

//
// PS KEDAI - DRAFT
//
export const PSKEDAI_DRAFT_REQUEST = 'PS/KEDAI_DRAFT_REQUEST';
export const PSKEDAI_DRAFT_SUCCESS = 'PS/KEDAI_DRAFT_SUCCESS';
export const PSKEDAI_DRAFT_FAILURE = 'PS/KEDAI_DRAFT_FAILURE';

//
// PS KEDAI - EDIT
//
export const PSKEDAI_EDIT_REQUEST = 'PS/KEDAI_EDIT_REQUEST';
export const PSKEDAI_EDIT_SUCCESS = 'PS/KEDAI_EDIT_SUCCESS';
export const PSKEDAI_EDIT_FAILURE = 'PS/KEDAI_EDIT_FAILURE';

//
// PS KEDAI - SUBMIT
//
export const PSKEDAI_SUBMIT_REQUEST = 'PS/KEDAI_SUBMIT_REQUEST';
export const PSKEDAI_SUBMIT_SUCCESS = 'PS/KEDAI_SUBMIT_SUCCESS';
export const PSKEDAI_SUBMIT_FAILURE = 'PS/KEDAI_SUBMIT_FAILURE';

//
// PS KEDAI - VIEW
//
export const PSKEDAI_VIEW_REQUEST = 'PS/KEDAI_VIEW_REQUEST';
export const PSKEDAI_VIEW_SUCCESS = 'PS/KEDAI_VIEW_SUCCESS';
export const PSKEDAI_VIEW_FAILURE = 'PS/KEDAI_VIEW_FAILURE';

//
// PS KEDAI - SEARCH
//
export const PSKEDAI_SEARCH_REQUEST = 'PS/KEDAI_SEARCH_REQUEST';
export const PSKEDAI_SEARCH_SUCCESS = 'PS/KEDAI_SEARCH_SUCCESS';
export const PSKEDAI_SEARCH_FAILURE = 'PS/KEDAI_SEARCH_FAILURE';

