import * as actions from '../action/troli';
import * as constants from '../constant/troli';

export interface TroliState {
  data: object;
  error: string;
  loading: boolean;
}

export const troliReducer = (
  state: TroliState = {
    data: {},
    error: "",
    loading: false
  },
  action: actions.TroliAction) => {    
    switch (action.type) {

      //
      // Troli Datatable
      //
      case constants.TROLI_DATATABLE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.TROLI_DATATABLE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.TROLI_DATATABLE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // Troli Add to Troli
      //
      case constants.TROLI_ADDTROLI_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.TROLI_ADDTROLI_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.TROLI_ADDTROLI_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // Troli Trolis
      //
      case constants.TROLI_TROLIS_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.TROLI_TROLIS_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.TROLI_TROLIS_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // Troli Create Invoice
      //
      case constants.TROLI_CREATEINVOICE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.TROLI_CREATEINVOICE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.TROLI_CREATEINVOICE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

       //
      // Troli Pay Create
      //
      case constants.TROLI_PAY_CREATE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.TROLI_PAY_CREATE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.TROLI_PAY_CREATE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // Troli Pay Redirect
      //
      case constants.TROLI_PAY_REDIRECT_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.TROLI_PAY_REDIRECT_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.TROLI_PAY_REDIRECT_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // Troli View
      //
      case constants.TROLI_VIEW_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.TROLI_VIEW_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.TROLI_VIEW_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }

      //
      // Troli Delete
      //
      case constants.TROLI_DELETE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: '',
        };
      }
      case constants.TROLI_DELETE_SUCCESS: {
        return {
          ...state,
          data: action.data as any,
          loading: false,
        };
      }
      case constants.TROLI_DELETE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }


      //
      // DEFAULT
      //
      default: {
        return state;
      }
    }
  };
  