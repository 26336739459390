import * as actions from "../action/bayaran";
import * as constants from "../constant/bayaran";

export interface BayaranState {
  data: object;
  btlData: any;
  error: string;
  searchData: any;
  searchLoading: boolean;
  loading: boolean;
}

export const bayaranReducer = (
  state: BayaranState = {
    data: {},
    btlData: [],
    searchData: null,
    searchLoading: false,
    error: "",
    loading: false,
  },
  action: actions.BayaranAction
) => {
  switch (action.type) {
    //
    // BAYARAN DATATABLE
    //
    case constants.BAYARAN_DATATABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.BAYARAN_DATATABLE_SUCCESS: {
      return {
        ...state,
        btlData: action.data as any,
        loading: false,
      };
    }

    case constants.BAYARAN_DATATABLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // BAYARAN CREATE
    //
    case constants.BAYARAN_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.BAYARAN_CREATE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.BAYARAN_CREATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // BAYARAN DELETE
    //
    case constants.BAYARAN_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.BAYARAN_DELETE_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.BAYARAN_DELETE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // BAYARAN SEMAK
    //
    case constants.BAYARAN_SEMAK_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case constants.BAYARAN_SEMAK_SUCCESS: {
      return {
        ...state,
        data: action.data as any,
        loading: false,
      };
    }

    case constants.BAYARAN_SEMAK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // BAYARAN SEARCH
    //
    case constants.BAYARAN_SEARCH_REQUEST: {
      return {
        ...state,
        searchLoading: true,
        error: "",
      };
    }

    case constants.BAYARAN_SEARCH_SUCCESS: {
      return {
        ...state,
        searchData: action.data as any,
        searchLoading: false,
      };
    }

    case constants.BAYARAN_SEARCH_FAILURE: {
      return {
        ...state,
        searchLoading: false,
        error: action.error,
      };
    }

    //
    // BAYARAN PAY CREATE
    //
    case constants.BAYARAN_PAY_CREATE_REQUEST: {
      return {
        ...state,
        searchLoading: true,
        error: "",
      };
    }

    case constants.BAYARAN_PAY_CREATE_SUCCESS: {
      return {
        ...state,
        searchData: action.data as any,
        searchLoading: false,
      };
    }

    case constants.BAYARAN_PAY_CREATE_FAILURE: {
      return {
        ...state,
        searchLoading: false,
        error: action.error,
      };
    }

    //
    // BAYARAN PAY REDIRECT
    //
    case constants.BAYARAN_PAY_REDIRECT_REQUEST: {
      return {
        ...state,
        searchLoading: true,
        error: "",
      };
    }

    case constants.BAYARAN_PAY_REDIRECT_SUCCESS: {
      return {
        ...state,
        searchData: action.data as any,
        searchLoading: false,
      };
    }

    case constants.BAYARAN_PAY_REDIRECT_FAILURE: {
      return {
        ...state,
        searchLoading: false,
        error: action.error,
      };
    }

    //
    // BAYARAN FETCH
    //
    case constants.BAYARAN_FETCH_REQUEST: {
      return {
        ...state,
        searchLoading: true,
        error: "",
      };
    }

    case constants.BAYARAN_FETCH_SUCCESS: {
      return {
        ...state,
        searchData: action.data as any,
        searchLoading: false,
      };
    }

    case constants.BAYARAN_FETCH_FAILURE: {
      return {
        ...state,
        searchLoading: false,
        error: action.error,
      };
    }

    //
    // BAYARAN UPDATE
    //
    case constants.BAYARAN_UPDATE_REQUEST: {
      return {
        ...state,
        searchLoading: true,
        error: "",
      };
    }

    case constants.BAYARAN_UPDATE_SUCCESS: {
      return {
        ...state,
        searchData: action.data as any,
        searchLoading: false,
      };
    }

    case constants.BAYARAN_UPDATE_FAILURE: {
      return {
        ...state,
        searchLoading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
