import * as actions from "../action/loyalti";
import * as constants from "../constant/loyalti";

export interface LoyaltiState {
  error: string;
  loading: boolean;
  loadingCarts: boolean;
  loadingOrders: boolean;
  loadingProducts: boolean;
  loadingEvents: boolean;
  loadingReward: boolean;
  events: any;
  event: any;
  products: any;
  product: any;
  reward: any;
  carts: any;
  orders: any;
  order: any;
  paymentCategories: any;
  paymentCategory: any;
  paymentTypes: any;
  paymentType: any;
  transaction: any;
  transactions: any;
}

export const loyaltiReducer = (
  state: LoyaltiState = {
    error: "",
    events: [],
    event: [],
    products: [],
    product: [],
    reward: {},
    carts: [],
    orders: [],
    order: {},
    paymentCategories: [],
    paymentCategory: {},
    paymentTypes: [],
    paymentType: {},
    transactions: [],
    transaction: {},
    loading: false,
    loadingCarts: false,
    loadingOrders: false,
    loadingProducts: false,
    loadingEvents: false,
    loadingReward: false,
  },
  action: actions.LoyaltiAction
) => {
  switch (action.type) {
    //
    // LOYALTI EVENTS
    //
    case constants.LOYALTI_EVENTS_REQUEST: {
      return {
        ...state,
        loadingEvents: true,
        error: "",
      };
    }
    case constants.LOYALTI_EVENTS_SUCCESS: {
      return {
        ...state,
        loadingEvents: false,
        events: (action.data as any).data,
      };
    }
    case constants.LOYALTI_EVENTS_FAILURE: {
      return {
        ...state,
        loadingEvents: false,
        error: action.error,
      };
    }

    //
    // LOYALTI EVENT
    //
    case constants.LOYALTI_EVENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        event: (action.data as any).data,
      };
    }
    case constants.LOYALTI_EVENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI PRODUCTS
    //
    case constants.LOYALTI_PRODUCTS_REQUEST: {
      return {
        ...state,
        loadingProducts: true,
        error: "",
      };
    }
    case constants.LOYALTI_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loadingProducts: false,
        products: (action.data as any).data,
      };
    }
    case constants.LOYALTI_PRODUCTS_FAILURE: {
      return {
        ...state,
        loadingProducts: false,
        error: action.error,
      };
    }

    //
    // LOYALTI PRODUCT
    //
    case constants.LOYALTI_PRODUCT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        product: (action.data as any).data,
      };
    }
    case constants.LOYALTI_PRODUCT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI REWARD
    //
    case constants.LOYALTI_REWARD_REQUEST: {
      return {
        ...state,
        loadingReward: true,
        error: "",
      };
    }
    case constants.LOYALTI_REWARD_SUCCESS: {
      return {
        ...state,
        loadingReward: false,
        reward: action.data,
      };
    }
    case constants.LOYALTI_REWARD_FAILURE: {
      return {
        ...state,
        loadingReward: false,
        error: action.error,
      };
    }

    //
    // LOYALTI ADD_TO_CART
    //
    case constants.LOYALTI_ADD_TO_CART_REQUEST: {
      return {
        ...state,
        loadingCarts: true,
        error: "",
      };
    }
    case constants.LOYALTI_ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        loadingCarts: false,
        carts: (action.data as any).data,
      };
    }
    case constants.LOYALTI_ADD_TO_CART_FAILURE: {
      return {
        ...state,
        loadingCarts: false,
        error: action.error,
      };
    }

    //
    // LOYALTI DELETE_FROM_CART
    //
    case constants.LOYALTI_DELETE_FROM_CART_REQUEST: {
      return {
        ...state,
        loadingCarts: true,
        error: "",
      };
    }
    case constants.LOYALTI_DELETE_FROM_CART_SUCCESS: {
      return {
        ...state,
        loadingCarts: false,
        carts: (action.data as any).data,
      };
    }
    case constants.LOYALTI_DELETE_FROM_CART_FAILURE: {
      return {
        ...state,
        loadingCarts: false,
        error: action.error,
      };
    }

    //
    // LOYALTI VIEW_CART
    //
    case constants.LOYALTI_VIEW_CART_REQUEST: {
      return {
        ...state,
        loadingCarts: true,
        error: "",
      };
    }
    case constants.LOYALTI_VIEW_CART_SUCCESS: {
      return {
        ...state,
        loadingCarts: false,
        carts: (action.data as any).data,
      };
    }
    case constants.LOYALTI_VIEW_CART_FAILURE: {
      return {
        ...state,
        loadingCarts: false,
        error: action.error,
      };
    }

    //
    // LOYALTI VIEW_ALL_ORDER
    //
    case constants.LOYALTI_VIEW_ALL_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_VIEW_ALL_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        orders: (action.data as any).data,
      };
    }
    case constants.LOYALTI_VIEW_ALL_ORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI VIEW_ORDER
    //
    case constants.LOYALTI_VIEW_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_VIEW_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: (action.data as any).data,
      };
    }
    case constants.LOYALTI_VIEW_ORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI VIEW_ALL_PAYMENT_CATEGORY
    //
    case constants.LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        paymentCategories: (action.data as any).data,
      };
    }
    case constants.LOYALTI_VIEW_ALL_PAYMENT_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI VIEW_PAYMENT_CATEGORY
    //
    case constants.LOYALTI_VIEW_PAYMENT_CATEGORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_VIEW_PAYMENT_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        paymentCategory: (action.data as any).data,
      };
    }
    case constants.LOYALTI_VIEW_PAYMENT_CATEGORY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI VIEW_ALL_PAYMENT_TYPE
    //
    case constants.LOYALTI_VIEW_ALL_PAYMENT_TYPE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_VIEW_ALL_PAYMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        paymentTypes: (action.data as any).data,
      };
    }
    case constants.LOYALTI_VIEW_ALL_PAYMENT_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI VIEW_PAYMENT_TYPE
    //
    case constants.LOYALTI_VIEW_PAYMENT_TYPE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_VIEW_PAYMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        paymentType: (action.data as any).data,
      };
    }
    case constants.LOYALTI_VIEW_PAYMENT_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

     //
    // LOYALTI VIEW_ALL_TRANSACTION
    //
    case constants.LOYALTI_VIEW_ALL_TRANSACTION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_VIEW_ALL_TRANSACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        transactions: (action.data as any).data,
      };
    }
    case constants.LOYALTI_VIEW_ALL_TRANSACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI CREATE_TRANSACTION
    //
    case constants.LOYALTI_CREATE_NEW_TRANSACTION_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_CREATE_NEW_TRANSACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        transaction: (action.data as any).data,
      };
    }
    case constants.LOYALTI_CREATE_NEW_TRANSACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI CREATE_NEW_ORDER
    //
    case constants.LOYALTI_CREATE_NEW_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_CREATE_NEW_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: (action.data as any).data,
      };
    }
    case constants.LOYALTI_CREATE_NEW_ORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // LOYALTI UPDATE_ORDER
    //
    case constants.LOYALTI_UPDATE_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case constants.LOYALTI_UPDATE_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: (action.data as any).data,
      };
    }
    case constants.LOYALTI_UPDATE_ORDER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    //
    // DEFAULT
    //
    default: {
      return state;
    }
  }
};
