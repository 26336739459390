import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/psbanglo';
import * as constants from '../constant/psbanglo';
import * as functions from '../function/psbanglo';



//
// PsBanglo DATATABLE (BTP)
//
function* getPsBangloDatatable({params, onCallback}: actions.PsBangloDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: '/secured/ps/banglo/datatable',
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsBangloDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsBangloDatatableFailure(error));
    }    
};

//
// PsBanglo Draft
//
function* getPsBangloDraft({params, onCallback}: actions.PsBangloDraftRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/banglo/draft`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsBangloDraftSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsBangloDraftFailure(error));
    }
};

//
// PsBanglo Edit
//
function* getPsBangloEdit({params, onCallback}: actions.PsBangloEditRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/banglo/edit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsBangloEditSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsBangloEditFailure(error));
    }
};

//
// PsBanglo Submit
//
function* getPsBangloSubmit({params, onCallback}: actions.PsBangloSubmitRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/banglo/submit`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsBangloSubmitSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsBangloSubmitFailure(error));
    }
};


//
// PsBanglo View
//
function* getPsBangloView({params, onCallback}: actions.PsBangloViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/banglo/view`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsBangloViewSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsBangloViewFailure(error));
    }
};

//
// PsBanglo Search
//
function* getPsBangloSearch({params, onCallback}: actions.PsBangloViewRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/ps/banglo/search`,
        method: "GET",
        params: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.PsBangloSearchSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.PsBangloSearchFailure(error));
    }
};


export function* watchPsBanglo() {
    
    yield takeLatest(constants.PSBANGLO_DATATABLE_REQUEST, getPsBangloDatatable);
    yield takeLatest(constants.PSBANGLO_DRAFT_REQUEST, getPsBangloDraft);
    yield takeLatest(constants.PSBANGLO_EDIT_REQUEST, getPsBangloEdit);
    yield takeLatest(constants.PSBANGLO_SUBMIT_REQUEST, getPsBangloSubmit);
    yield takeLatest(constants.PSBANGLO_VIEW_REQUEST, getPsBangloView);
    yield takeLatest(constants.PSBANGLO_SEARCH_REQUEST, getPsBangloSearch);

};


