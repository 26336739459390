import { Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardPage from "./views/DashboardPage";
import KillSwitchPage from "./views/KillSwitchPage";
import LoadingPage from "./views/LoadingPage";
import NotFoundPage from "./views/NotFoundPage";
import { TOKEN_REQUEST } from "./redux/constant/auth";

export interface RouteProps {
  path: string;
  element: any;
  role?: number;
}

const AppRoutes = () => {
  const auth = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const token = localStorage.getItem("mymbsa-token");
  const impersonateToken = localStorage.getItem("mymbsa-impersonate-token");

  useEffect(() => {
    //verify token
    if (auth.isLoggedIn) {
      if (auth.isImpersonating) {
        dispatch({
          type: TOKEN_REQUEST,
          token: impersonateToken,
          onCallback: (props: any) => {},
        });
      } else {
        dispatch({
          type: TOKEN_REQUEST,
          token: token,
          onCallback: (props: any) => {},
        });
      }
    }
  }, []);

  const LoginPage = React.lazy(() => import("./views/LoginPage"));
  const ForgotPassword = React.lazy(
    () => import("./views/LoginPage/ForgotPassword")
  );
  const UpdatePassword = React.lazy(
    () => import("./views/LoginPage/UpdatePassword")
  );
  const RegistrationPage = React.lazy(() => import("./views/RegistrationPage"));
  const AccountPage = React.lazy(() => import("./views/AccountPage"));
  const NewPasswordPage = React.lazy(
    () => import("./views/RegistrationPage/NewPassword")
  );
  const VerifyTacPage = React.lazy(
    () => import("./views/RegistrationPage/VerifyTac")
  );
  const LoginVerifyTacPage = React.lazy(
    () => import("./views/LoginPage/VerifyTac")
  );
  const TermPage = React.lazy(() => import("./views/TermPage/TermPage"));
  const ELoyaltiInfoPage = React.lazy(
    () => import("./views/ELoyaltiInfoPage/ELoyaltiInfoPage")
  );
  const PrivacyPage = React.lazy(
    () => import("./views/PrivacyPage/PrivacyPage")
  );
  const KeselamatanPage = React.lazy(
    () => import("./views/KeselamatanPage/KeselamatanPage")
  );
  const PenafianPage = React.lazy(
    () => import("./views/PenafianPage/PenafianPage")
  );

  //kpm
  const KPMPage = React.lazy(() => import("./views/KPMPage"));
  const KpmNew = React.lazy(() => import("./views/KPMPage/KpmNew"));
  const KpmEdit = React.lazy(() => import("./views/KPMPage/KpmEdit"));
  const KpmReEdit = React.lazy(() => import("./views/KPMPage/KpmReEdit"));
  const KpmReEditView = React.lazy(
    () => import("./views/KPMPage/KpmReEditView")
  );
  const KpmView = React.lazy(() => import("./views/KPMPage/KpmView"));
  const KpmCardView = React.lazy(() => import("./views/KPMPage/KpmCardView"));
  const KpmViewInvoice = React.lazy(
    () => import("./views/KPMPage/KpmViewInvoice")
  );
  const KpmPay = React.lazy(() => import("./views/KPMPage/KpmPay"));
  const KpmPayView = React.lazy(() => import("./views/KPMPage/KpmPayView"));
  const KpmReceiptView = React.lazy(
    () => import("./views/KPMPage/KpmReceiptView")
  );
  const KpmPayStatus = React.lazy(() => import("./views/KPMPage/KpmPayStatus"));
  const KpmPayStatusView = React.lazy(
    () => import("./views/KPMPage/KpmPayStatusView")
  );

  //ppm
  const PPMPage = React.lazy(() => import("./views/PPMPage"));
  const PpmNew = React.lazy(() => import("./views/PPMPage/PpmNew"));
  const PpmEdit = React.lazy(() => import("./views/PPMPage/PpmEdit"));
  const PpmEditView = React.lazy(() => import("./views/PPMPage/PpmEditView"));
  const PpmView = React.lazy(() => import("./views/PPMPage/PpmView"));
  const PpmPay = React.lazy(() => import("./views/PPMPage/PpmPay"));
  const PpmPayView = React.lazy(() => import("./views/PPMPage/PpmPayView"));
  const PpmReceiptView = React.lazy(
    () => import("./views/PPMPage/PpmReceiptView")
  );
  const PpmPayStatus = React.lazy(() => import("./views/PPMPage/PpmPayStatus"));
  const PpmPayStatusView = React.lazy(
    () => import("./views/PPMPage/PpmPayStatusView")
  );

  //roro
  const RoroPage = React.lazy(() => import("./views/RoroPage"));
  const RoroNew = React.lazy(() => import("./views/RoroPage/RoroNew"));
  const RoroEdit = React.lazy(() => import("./views/RoroPage/RoroEdit"));
  const RoroView = React.lazy(() => import("./views/RoroPage/RoroView"));
  const RoroConfirm = React.lazy(() => import("./views/RoroPage/RoroConfirm"));
  const RoroPay = React.lazy(() => import("./views/RoroPage/RoroPay"));
  const RoroPayView = React.lazy(() => import("./views/RoroPage/RoroPayView"));
  const RoroReceiptView = React.lazy(
    () => import("./views/RoroPage/RoroReceiptView")
  );
  const RoroPayStatus = React.lazy(
    () => import("./views/RoroPage/RoroPayStatus")
  );
  const RoroPayStatusView = React.lazy(
    () => import("./views/RoroPage/RoroPayStatusView")
  );

  //permit
  const PermitPage = React.lazy(() => import("./views/PermitPage"));
  const PermitPTJ = React.lazy(() => import("./views/PermitPage/PTJ"));
  const PTJPay = React.lazy(() => import("./views/PermitPage/PTJ/PTJPay"));
  const PTJPayView = React.lazy(
    () => import("./views/PermitPage/PTJ/PTJPayView")
  );
  const PTJReceiptView = React.lazy(
    () => import("./views/PermitPage/PTJ/PTJReceiptView")
  );
  const PTJPayStatus = React.lazy(
    () => import("./views/PermitPage/PTJ/PTJPayStatus")
  );
  const PTJPayStatusView = React.lazy(
    () => import("./views/PermitPage/PTJ/PTJPayStatusView")
  );
  const PTJLetterView = React.lazy(
    () => import("./views/PermitPage/PTJ/PTJLetterView")
  );

  const PermitPB = React.lazy(() => import("./views/PermitPage/PB"));
  const PBPay = React.lazy(() => import("./views/PermitPage/PB/PBPay"));
  const PBPayView = React.lazy(() => import("./views/PermitPage/PB/PBPayView"));
  const PBReceiptView = React.lazy(
    () => import("./views/PermitPage/PB/PBReceiptView")
  );
  const PBPayStatus = React.lazy(
    () => import("./views/PermitPage/PB/PBPayStatus")
  );
  const PBPayStatusView = React.lazy(
    () => import("./views/PermitPage/PB/PBPayStatusView")
  );
  const PBLetterView = React.lazy(
    () => import("./views/PermitPage/PB/PBLetterView")
  );
  const PBNew = React.lazy(() => import("./views/PermitPage/PB/PBNew"));
  const PBEdit = React.lazy(() => import("./views/PermitPage/PB/PBEdit"));

  const PermitPMBB = React.lazy(() => import("./views/PermitPage/PMBB"));
  const PMBBPay = React.lazy(() => import("./views/PermitPage/PMBB/PMBBPay"));
  const PMBBPayView = React.lazy(
    () => import("./views/PermitPage/PMBB/PMBBPayView")
  );
  const PMBBReceiptView = React.lazy(
    () => import("./views/PermitPage/PMBB/PMBBReceiptView")
  );
  const PMBBPayStatus = React.lazy(
    () => import("./views/PermitPage/PMBB/PMBBPayStatus")
  );
  const PMBBPayStatusView = React.lazy(
    () => import("./views/PermitPage/PMBB/PMBBPayStatusView")
  );
  const PMBBLetterView = React.lazy(
    () => import("./views/PermitPage/PMBB/PMBBLetterView")
  );
  const PMBBNew = React.lazy(() => import("./views/PermitPage/PMBB/PMBBNew"));
  const PMBBEdit = React.lazy(() => import("./views/PermitPage/PMBB/PMBBEdit"));

  const PermitPKT = React.lazy(() => import("./views/PermitPage/PKT"));
  const PKTPay = React.lazy(() => import("./views/PermitPage/PKT/PKTPay"));
  const PKTPayView = React.lazy(
    () => import("./views/PermitPage/PKT/PKTPayView")
  );
  const PKTReceiptView = React.lazy(
    () => import("./views/PermitPage/PKT/PKTReceiptView")
  );
  const PKTPayStatus = React.lazy(
    () => import("./views/PermitPage/PKT/PKTPayStatus")
  );
  const PKTPayStatusView = React.lazy(
    () => import("./views/PermitPage/PKT/PKTPayStatusView")
  );
  const PKTLetterView = React.lazy(
    () => import("./views/PermitPage/PKT/PKTLetterView")
  );
  const PKTAttachmentAView = React.lazy(
    () => import("./views/PermitPage/PKT/PKTAttachmentAView")
  );
  const PKTNew = React.lazy(() => import("./views/PermitPage/PKT/PKTNew"));
  const PKTEdit = React.lazy(() => import("./views/PermitPage/PKT/PKTEdit"));

  //cagaran
  const CagaranPage = React.lazy(() => import("./views/CagaranPage"));
  const CagaranPay = React.lazy(() => import("./views/CagaranPage/CagaranPay"));
  const CagaranPayView = React.lazy(
    () => import("./views/CagaranPage/CagaranPayView")
  );
  const CagaranReceiptView = React.lazy(
    () => import("./views/CagaranPage/CagaranReceiptView")
  );
  const CagaranPayStatus = React.lazy(
    () => import("./views/CagaranPage/CagaranPayStatus")
  );
  const CagaranPayStatusView = React.lazy(
    () => import("./views/CagaranPage/CagaranPayStatusView")
  );

  //troli
  const TroliPage = React.lazy(() => import("./views/TroliPage"));
  const TroliPay = React.lazy(() => import("./views/TroliPage/TroliPay"));
  const TroliPayStatus = React.lazy(
    () => import("./views/TroliPage/TroliPayStatus")
  );
  const TroliPayStatusView = React.lazy(
    () => import("./views/TroliPage/TroliPayStatusView")
  );
  const TroliInvoiceView = React.lazy(
    () => import("./views/TroliPage/TroliInvoiceView")
  );

  //lesen
  const LesenListingRenew = React.lazy(
    () => import("./views/LesenPage/LesenListingRenew")
  );
  const LesenListingApply = React.lazy(
    () => import("./views/LesenPage/LesenListingApply")
  );
  const LesenPage = React.lazy(() => import("./views/LesenPage"));
  const LesenNew = React.lazy(() => import("./views/LesenPage/LesenNew"));
  const LesenNewPilih = React.lazy(
    () => import("./views/LesenPage/LesenNewPilih")
  );
  const LesenNewPilihView = React.lazy(
    () => import("./views/LesenPage/LesenNewPilihView")
  );
  const LesenEdit = React.lazy(() => import("./views/LesenPage/LesenEdit"));
  const LesenEditTidakLengkap = React.lazy(
    () => import("./views/LesenPage/LesenEditTidakLengkap")
  );
  const LesenView = React.lazy(() => import("./views/LesenPage/LesenView"));
  const LesenPayView = React.lazy(
    () => import("./views/LesenPage/LesenPayView")
  );
  const LesenSijilView = React.lazy(
    () => import("./views/LesenPage/LesenSijilView")
  );
  const LesenSuratLulusView = React.lazy(
    () => import("./views/LesenPage/LesenSuratLulusView")
  );
  const LesenSuratTolakView = React.lazy(
    () => import("./views/LesenPage/LesenSuratTolakView")
  );

  //pelan setaras
  const PelanPage = React.lazy(() => import("./views/PelanSetaraPage"));
  const PsBuku = React.lazy(() => import("./views/PelanSetaraPage/buku"));
  const PsKediaman = React.lazy(
    () => import("./views/PelanSetaraPage/kediaman")
  );
  const PsKediamanEdit = React.lazy(
    () => import("./views/PelanSetaraPage/kediaman/PsKediamanEdit")
  );
  const PsKediamanView = React.lazy(
    () => import("./views/PelanSetaraPage/kediaman/PsKediamanView")
  );
  const PsKediamanSurat = React.lazy(
    () => import("./views/PelanSetaraPage/kediaman/PsKediamanSurat")
  );
  const PsKediamanPermit = React.lazy(
    () => import("./views/PelanSetaraPage/kediaman/PsKediamanPermit")
  );
  const PsKedai = React.lazy(() => import("./views/PelanSetaraPage/kedai"));
  const PsKedaiEdit = React.lazy(
    () => import("./views/PelanSetaraPage/kedai/PsKedaiEdit")
  );
  const PsKedaiView = React.lazy(
    () => import("./views/PelanSetaraPage/kedai/PsKedaiView")
  );
  const PsKilang = React.lazy(() => import("./views/PelanSetaraPage/kilang"));
  const PsKilangEdit = React.lazy(
    () => import("./views/PelanSetaraPage/kilang/PsKilangEdit")
  );
  const PsKilangView = React.lazy(
    () => import("./views/PelanSetaraPage/kilang/PsKilangView")
  );
  const PsTaska = React.lazy(() => import("./views/PelanSetaraPage/taska"));
  const PsTaskaEdit = React.lazy(
    () => import("./views/PelanSetaraPage/taska/PsTaskaEdit")
  );
  const PsTaskaView = React.lazy(
    () => import("./views/PelanSetaraPage/taska/PsTaskaView")
  );
  const PsTelco = React.lazy(() => import("./views/PelanSetaraPage/telco"));
  const PsTelcoEdit = React.lazy(
    () => import("./views/PelanSetaraPage/telco/PsTelcoEdit")
  );
  const PsTelcoView = React.lazy(
    () => import("./views/PelanSetaraPage/telco/PsTelcoView")
  );
  const PsPayView = React.lazy(
    () => import("./views/PelanSetaraPage/pay/PsPayView")
  );
  const PsPayReceipt = React.lazy(
    () => import("./views/PelanSetaraPage/pay/PsPayReceipt")
  );
  const PsPayInvoice = React.lazy(
    () => import("./views/PelanSetaraPage/pay/PsPayInvoice")
  );
  const PsPayReceiptBook = React.lazy(
    () => import("./views/PelanSetaraPage/pay/PsPayReceiptBook")
  );
  const PsBTP = React.lazy(() => import("./views/PelanSetaraPage/banglo/BTP"));
  const PsPKPL = React.lazy(
    () => import("./views/PelanSetaraPage/banglo/PKPL")
  );
  const PsBangloNew = React.lazy(
    () => import("./views/PelanSetaraPage/banglo/PsBangloNew")
  );
  const PsBangloEdit = React.lazy(
    () => import("./views/PelanSetaraPage/banglo/PsBangloEdit")
  );
  const PsBangloView = React.lazy(
    () => import("./views/PelanSetaraPage/banglo/PsBangloView")
  );
  const PsDoskmep = React.lazy(() => import("./views/PelanSetaraPage/doskmep"));
  const PsDoskmepEdit = React.lazy(
    () => import("./views/PelanSetaraPage/doskmep/PsDoskmepEdit")
  );
  const PsDoskmepView = React.lazy(
    () => import("./views/PelanSetaraPage/doskmep/PsDoskmepView")
  );

  const MaintenancePage = React.lazy(() => import("./views/MaintenancePage"));
  const UserPage = React.lazy(() => import("./views/UserPage"));
  const UserEdit = React.lazy(() => import("./views/UserPage/UserEdit"));
  const LocalePage = React.lazy(() => import("./views/LocalePage"));
  const LocaleAdd = React.lazy(() => import("./views/LocalePage/LocaleAdd"));
  const LocaleEdit = React.lazy(() => import("./views/LocalePage/LocaleEdit"));
  const MapstatusPage = React.lazy(() => import("./views/MapstatusPage"));
  const MapstatusAdd = React.lazy(
    () => import("./views/MapstatusPage/MapstatusAdd")
  );
  const MapstatusEdit = React.lazy(
    () => import("./views/MapstatusPage/MapstatusEdit")
  );

  // killswitch
  const KillSwitch = React.lazy(() => import("./views/KillSwitchPage"));

  //syarikat
  const SyarikatPage = React.lazy(() => import("./views/SyarikatPage"));
  const SyarikatNewPage = React.lazy(
    () => import("./views/SyarikatPage/SyarikatNew")
  );
  const KemaskiniSyarikat = React.lazy(
    () => import("./views/SyarikatPage/KemaskiniSyarikat")
  );
  const SyarikatTuntutanPage = React.lazy(
    () => import("./views/SyarikatPage/SyarikatTuntutan")
  );

  //cukai
  const CukaiPage = React.lazy(() => import("./views/CukaiPage"));
  const CukaiNew = React.lazy(() => import("./views/CukaiPage/CukaiNew"));
  const CukaiView = React.lazy(() => import("./views/CukaiPage/CukaiView"));
  const CukaiCarianMaklumatView = React.lazy(
    () => import("./views/CukaiPage/CukaiCarianMaklumatView")
  );
  const CukaiCarianMaklumatHartaView = React.lazy(
    () => import("./views/CukaiPage/CukaiCarianMaklumatHartaView")
  );
  const CukaiCarianMaklumatPenyataCukaiView = React.lazy(
    () => import("./views/CukaiPage/CukaiCarianMaklumatPenyataCukaiView")
  );
  const CukaiCarianMaklumatBilCukaiView = React.lazy(
    () => import("./views/CukaiPage/CukaiCarianMaklumatBilCukaiView")
  );
  const CukaiEdit = React.lazy(() => import("./views/CukaiPage/CukaiEdit"));
  const CukaiConfirm = React.lazy(
    () => import("./views/CukaiPage/CukaiConfirm")
  );
  const CukaiPay = React.lazy(() => import("./views/CukaiPage/CukaiPay"));
  const CukaiAkaunPayEdit = React.lazy(
    () => import("./views/CukaiPage/CukaiAkaunPayEdit")
  );
  const CukaiPayView = React.lazy(
    () => import("./views/CukaiPage/CukaiPayView")
  );
  const CukaiReceiptView = React.lazy(
    () => import("./views/CukaiPage/CukaiReceiptView")
  );
  const CukaiPayStatus = React.lazy(
    () => import("./views/CukaiPage/CukaiPayStatus")
  );
  const CukaiPayStatusView = React.lazy(
    () => import("./views/CukaiPage/CukaiPayStatusView")
  );
  const CukaiPenguranganPage = React.lazy(
    () => import("./views/CukaiPage/CukaiPengurangan/CukaiPengurangan")
  );
  const CukaiNewPenguranganPage = React.lazy(
    () => import("./views/CukaiPage/CukaiPengurangan/CukaiNewPengurangan")
  );
  const CukaiPenguranganView = React.lazy(
    () => import("./views/CukaiPage/CukaiPengurangan/CukaiPenguranganView")
  );
  const CukaiPindahMilikPage = React.lazy(
    () => import("./views/CukaiPage/CukaiPindahMilik/CukaiPindahMilik")
  );
  const CukaiNewPindahMilikPage = React.lazy(
    () => import("./views/CukaiPage/CukaiPindahMilik/CukaiNewPindahMilik")
  );
  const CukaiPindahMilikViewPage = React.lazy(
    () => import("./views/CukaiPage/CukaiPindahMilik/CukaiPindahMilikView")
  );
  const CukaiPindahMilikPay = React.lazy(
    () => import("./views/CukaiPage/CukaiPindahMilik/CukaiPindahMilikPay")
  );
  const CukaiPembersihanPage = React.lazy(
    () => import("./views/CukaiPage/CukaiPembersihan")
  );
  const CukaiWaranPage = React.lazy(
    () => import("./views/CukaiPage/CukaiWaran")
  );
  const CukaiAccountPage = React.lazy(
    () => import("./views/CukaiPage/CukaiAccount")
  );
  const CukaiKemaskiniPage = React.lazy(
    () => import("./views/CukaiPage/CukaiKemaskini")
  );
  const CukaiNewKemaskiniPage = React.lazy(
    () => import("./views/CukaiPage/CukaiNewKemaskini")
  );
  const CukaiKemaskiniViewPage = React.lazy(
    () => import("./views/CukaiPage/CukaiKemaskiniView")
  );
  const CukaiPayViewBill = React.lazy(
    () => import("./views/CukaiPage/CukaiPayViewBill")
  );
  const CukaiViewPenyata = React.lazy(
    () => import("./views/CukaiPage/CukaiViewPenyata")
  );
  const CukaiViewAccountInfo = React.lazy(
    () => import("./views/CukaiPage/CukaiViewAccountInfo")
  );

  // sewaan
  const SewaanPage = React.lazy(() => import("./views/SewaanPage"));
  const SewaanPayViewElectric = React.lazy(
    () => import("./views/SewaanPage/SewaanPayViewElectric")
  );
  const SewaanPayViewWater = React.lazy(
    () => import("./views/SewaanPage/SewaanPayViewWater")
  );
  const SewaanPayViewRent = React.lazy(
    () => import("./views/SewaanPage/SewaanPayViewRent")
  );
  const SewaanViewNotice = React.lazy(
    () => import("./views/SewaanPage/SewaanViewNotice")
  );
  const SewaanViewLicense = React.lazy(
    () => import("./views/SewaanPage/SewaanViewLicense")
  );
  const SewaanViewTranscRent = React.lazy(
    () => import("./views/SewaanPage/SewaanViewTranscRent")
  );
  const SewaanViewTranscElectric = React.lazy(
    () => import("./views/SewaanPage/SewaanViewTranscElectric")
  );
  const SewaanViewTranscWater = React.lazy(
    () => import("./views/SewaanPage/SewaanViewTranscWater")
  );

  //bil
  const BilPage = React.lazy(() => import("./views/BilPage"));
  const BilPayView = React.lazy(() => import("./views/BilPage/BilPayView"));

  //jualan
  const JualanPage = React.lazy(() => import("./views/JualanPage"));
  const JualanPembelianPage = React.lazy(
    () => import("./views/JualanPage/JualanPembelian")
  );
  const JualanProdukDetailsPage = React.lazy(
    () => import("./views/JualanPage/JualanProdukDetails")
  );
  const JualanPayView = React.lazy(
    () => import("./views/JualanPage/JualanPayView")
  );
  const JualanTransactionView = React.lazy(
    () => import("./views/JualanPage/JualanTransactionView")
  );

  //sekat
  const SekatPage = React.lazy(() => import("./views/SekatPage"));
  const SekatPayView = React.lazy(
    () => import("./views/SekatPage/SekatPayView")
  );

  //kompaun
  const KompaunPage = React.lazy(() => import("./views/KompaunPage"));
  const KompaunCarian = React.lazy(
    () => import("./views/KompaunPage/KompaunCarian")
  );
  const KompaunTunda = React.lazy(
    () => import("./views/KompaunPage/KompaunTunda")
  );
  const KompaunBilView = React.lazy(
    () => import("./views/KompaunPage/KompaunBilView")
  );

  //bayaran
  const BayaranPage = React.lazy(() => import("./views/BayaranPage"));
  const BayaranTransactionPage = React.lazy(
    () => import("./views/BayaranPage/BayaranTransaction")
  );
  const BayaranLuarPage = React.lazy(
    () => import("./views/BayaranPage/BayaranLuarPage")
  );
  const BayaranLuarPay = React.lazy(
    () => import("./views/BayaranPage/BayaranLuarPay")
  );
  const BayaranLuarPayStatus = React.lazy(
    () => import("./views/BayaranPage/BayaranLuarPayStatus")
  );
  const BayaranLuarReceipt = React.lazy(
    () => import("./views/BayaranPage/BayaranLuarReceipt")
  );
  const BayaranCarianPage = React.lazy(
    () => import("./views/BayaranPage/BayaranCarianPage")
  );

  // tempahan
  const TempahanPage = React.lazy(() => import("./views/TempahanPage"));
  const TempahanNew = React.lazy(
    () => import("./views/TempahanPage/TempahanNew")
  );
  const TempahanSukanNew = React.lazy(
    () => import("./views/TempahanPage/TempahanNew")
  );
  const TempahanBilView = React.lazy(
    () => import("./views/TempahanPage/TempahanBilView")
  );
  const TempahanReceiptView = React.lazy(
    () => import("./views/TempahanPage/TempahanReceiptView")
  );

  // loyalti
  const LoyaltiPage = React.lazy(() => import("./views/LoyaltiPage"));
  const LoyaltiProgramPage = React.lazy(
    () => import("./views/LoyaltiPage/LoyaltiProgram")
  );
  const LoyaltiPenebusanPage = React.lazy(
    () => import("./views/LoyaltiPage/LoyaltiPenebusan")
  );
  const LoyaltiTransaksiPage = React.lazy(
    () => import("./views/LoyaltiPage/LoyaltiTransaksi")
  );
  const LoyaltiProdukPage = React.lazy(
    () => import("./views/LoyaltiPage/LoyaltiProduk")
  );
  const LoyaltiProdukDetailsPage = React.lazy(
    () => import("./views/LoyaltiPage/LoyaltiProdukDetails")
  );
  const LoyaltiCheckoutPage = React.lazy(
    () => import("./views/LoyaltiPage/LoyaltiCheckout")
  );
  const LoyaltiPengesahanPage = React.lazy(
    () => import("./views/LoyaltiPage/LoyaltiPengesahan")
  );
  const LoyaltiPenebusanView = React.lazy(
    () => import("./views/LoyaltiPage/LoyaltiPenebusanView")
  );

  //user manual
  const UsermanualPage = React.lazy(() => import("./views/UsermanualPage"));
  const UsermanualView = React.lazy(
    () => import("./views/UsermanualPage/UsermanualView")
  );

  //transaction
  const TransactionPage = React.lazy(() => import("./views/TransactionPage"));
  const TransactionView = React.lazy(
    () => import("./views/TransactionPage/TransactionView")
  );

  // audit
  const AuditPage = React.lazy(() => import("./views/Audit"));

  // laporan
  const LaporanPage = React.lazy(() => import("./views/LaporanPage"));
  const LaporanUserRegistrationPage = React.lazy(
    () => import("./views/LaporanPage/LaporanListingUserRegistration")
  );
  const LaporanUserAccessPage = React.lazy(
    () => import("./views/LaporanPage/LaporanListingUserAccess")
  );

  //define route (public)
  const publicRoutes = [
    //login
    { path: "/", element: <DashboardPage /> },
    { path: "/termandcondition", element: <TermPage /> },
    { path: "/eloyalti", element: <ELoyaltiInfoPage /> },
    { path: "/privacy", element: <PrivacyPage /> },
    { path: "/security", element: <KeselamatanPage /> },
    { path: "/penafian", element: <PenafianPage /> },
    { path: "/login", element: <LoginPage /> },
    { path: "/login/forgotpassword", element: <ForgotPassword /> },
    { path: "/login/verifytac", element: <LoginVerifyTacPage /> },
    { path: "/login/updatepassword", element: <UpdatePassword /> },

    //registration
    { path: "/registration", element: <RegistrationPage /> },
    { path: "/registration/verifytac", element: <VerifyTacPage /> },
    { path: "/registration/newpassword", element: <NewPasswordPage /> },

    { path: "/bayaran/luar", element: <BayaranLuarPage /> },
    { path: "/bayaran/luar/pay/:id", element: <BayaranLuarPay /> },
    {
      path: "/bayaran/luar/pay/:id/:status",
      element: <BayaranLuarPayStatus />,
    },
    {
      path: "/bayaran/luar/pay/:id/:status/receipt",
      element: <BayaranLuarReceipt />,
    },
  ].map((r) => {
    if (auth.isLoggedIn && r.path === "/login") {
      return {
        path: r.path,
        element: <Navigate to="/" />,
      };
    } else {
      return r;
    }
  });

  //define route (secured)
  const securedRoutes = [
    //account
    { path: "/account", element: <AccountPage /> },

    //audit
    { path: "/audit", element: <AuditPage /> },

    //kpm
    { path: "/kpm", element: <KPMPage /> },
    { path: "/kpm/new", element: <KpmNew /> },
    { path: "/kpm/edit/:id", element: <KpmEdit /> },
    { path: "/kpm/reedit/:id", element: <KpmReEdit /> },
    { path: "/kpm/reedit/:id/view", element: <KpmReEditView /> },
    { path: "/kpm/view/:id", element: <KpmView /> },
    { path: "/kpm/view/invoice/:id", element: <KpmViewInvoice /> },
    { path: "/kpm/pay/:id", element: <KpmPay /> },
    { path: "/kpm/pay/:id/view", element: <KpmPayView /> },
    { path: "/kpm/pay/:id/:status", element: <KpmPayStatus /> },
    { path: "/kpm/pay/:id/:status/:view", element: <KpmPayStatusView /> },
    { path: "/kpm/receipt/:id/view", element: <KpmReceiptView /> },
    { path: "/kpm/card/:id/view", element: <KpmCardView /> },

    //ppm
    { path: "/ppm", element: <PPMPage /> },
    { path: "/ppm/new", element: <PpmNew /> },
    { path: "/ppm/edit/:id", element: <PpmEdit /> },
    { path: "/ppm/edit/:id/view", element: <PpmEditView /> },
    { path: "/ppm/view/:id", element: <PpmView /> },
    { path: "/ppm/pay/:id", element: <PpmPay /> },
    { path: "/ppm/pay/:id/view", element: <PpmPayView /> },
    { path: "/ppm/pay/:id/:status", element: <PpmPayStatus /> },
    { path: "/ppm/pay/:id/:status/:view", element: <PpmPayStatusView /> },
    { path: "/ppm/receipt/:id/view", element: <PpmReceiptView /> },

    //roro
    { path: "/roro", element: <RoroPage /> },
    { path: "/roro/new", element: <RoroNew /> },
    { path: "/roro/edit/:id", element: <RoroEdit /> },
    { path: "/roro/view/:id", element: <RoroView /> },
    { path: "/roro/confirm/:id", element: <RoroConfirm /> },
    { path: "/roro/pay/:id", element: <RoroPay /> },
    { path: "/roro/pay/:id/view", element: <RoroPayView /> },
    { path: "/roro/pay/:id/:status", element: <RoroPayStatus /> },
    { path: "/roro/pay/:id/:status/:view", element: <RoroPayStatusView /> },
    { path: "/roro/receipt/:id/view", element: <RoroReceiptView /> },

    //permit
    { path: "/permit", element: <PermitPage /> },

    { path: "/ptj", element: <PermitPTJ /> },
    { path: "/ptj/pay/:id/:invId/", element: <PTJPay /> },
    { path: "/ptj/pay/:id/:invId/view", element: <PTJPayView /> },
    { path: "/ptj/pay/:id/:invId/:status", element: <PTJPayStatus /> },
    {
      path: "/ptj/pay/:id/:invId/:status/:view",
      element: <PTJPayStatusView />,
    },
    { path: "/ptj/receipt/:id/:invId/view", element: <PTJReceiptView /> },
    { path: "/ptj/letter/:id", element: <PTJLetterView /> },
    { path: "/ptj/new", element: <PBNew /> },

    { path: "/pb", element: <PermitPB /> },
    { path: "/pb/pay/:id/:invId", element: <PBPay /> },
    { path: "/pb/pay/:id/:invId/view", element: <PBPayView /> },
    { path: "/pb/pay/:id/:invId/:status", element: <PBPayStatus /> },
    { path: "/pb/pay/:id/:invId/:status/:view", element: <PBPayStatusView /> },
    { path: "/pb/receipt/:id/:invId/view", element: <PBReceiptView /> },
    { path: "/pb/letter/:id", element: <PBLetterView /> },
    { path: "/pb/new", element: <PBNew /> },
    { path: "/pb/edit/:id", element: <PBEdit /> },

    { path: "/pmbb", element: <PermitPMBB /> },
    { path: "/pmbb/pay/:id/:invId", element: <PMBBPay /> },
    { path: "/pmbb/pay/:id/:invId/view", element: <PMBBPayView /> },
    { path: "/pmbb/pay/:id/:invId/:status", element: <PMBBPayStatus /> },
    {
      path: "/pmbb/pay/:id/:invId/:status/:view",
      element: <PMBBPayStatusView />,
    },
    { path: "/pmbb/receipt/:id/:invId/view", element: <PMBBReceiptView /> },
    { path: "/pmbb/letter/:id", element: <PMBBLetterView /> },
    { path: "/pmbb/new", element: <PMBBNew /> },
    { path: "/pmbb/edit/:id", element: <PMBBEdit /> },

    { path: "/pkt", element: <PermitPKT /> },
    { path: "/pkt/pay/:id/:invId", element: <PKTPay /> },
    { path: "/pkt/pay/:id/:invId/view", element: <PKTPayView /> },
    { path: "/pkt/pay/:id/:invId/:status", element: <PKTPayStatus /> },
    {
      path: "/pkt/pay/:id/:invId/:status/:view",
      element: <PKTPayStatusView />,
    },
    { path: "/pkt/receipt/:id/:invId/view", element: <PKTReceiptView /> },
    { path: "/pkt/letter/:id", element: <PKTLetterView /> },
    { path: "/pkt/attachmentA/:id", element: <PKTAttachmentAView /> },
    { path: "/pkt/new", element: <PKTNew /> },
    { path: "/pkt/edit/:id", element: <PKTEdit /> },

    //cagaran
    { path: "/cagaran", element: <CagaranPage /> },
    { path: "/cagaran/pay/:id", element: <CagaranPay /> },
    { path: "/cagaran/pay/:id/view", element: <CagaranPayView /> },
    { path: "/cagaran/pay/:id/:status", element: <CagaranPayStatus /> },
    {
      path: "/cagaran/pay/:id/:status/:view",
      element: <CagaranPayStatusView />,
    },
    { path: "/cagaran/receipt/:id/view", element: <CagaranReceiptView /> },

    //maintenance
    { path: "/maintenance", element: <MaintenancePage />, role: 1 },
    { path: "/user", element: <UserPage />, role: 1 },
    { path: "/user/edit/:id", element: <UserEdit />, role: 1 },
    { path: "/locale", element: <LocalePage />, role: 1 },
    { path: "/locale/add", element: <LocaleAdd />, role: 1 },
    { path: "/locale/edit/:id", element: <LocaleEdit />, role: 1 },
    { path: "/mapstatus", element: <MapstatusPage />, role: 1 },
    { path: "/mapstatus/add", element: <MapstatusAdd />, role: 1 },
    { path: "/mapstatus/edit/:id", element: <MapstatusEdit />, role: 1 },

    // kill switch mobile
    { path: "/killSwitch", element: <KillSwitchPage />, role: 1 },

    //cukai
    { path: "/cukai", element: <CukaiPage /> },
    { path: "/cukai/new", element: <CukaiNew /> },
    { path: "/cukai/edit/:id", element: <CukaiEdit /> },
    { path: "/cukai/confirm/:id", element: <CukaiConfirm /> },
    { path: "/cukai/pay/:id", element: <CukaiPay /> },
    { path: "/cukai/pay/:id/view", element: <CukaiPayView /> },
    { path: "/cukai/pay/:id/:status", element: <CukaiPayStatus /> },
    { path: "/cukai/pay/:id/:status/:view", element: <CukaiPayStatusView /> },
    { path: "/cukai/view/:id", element: <CukaiView /> },
    {
      path: "/cukai/viewCarianMaklumat/:id",
      element: <CukaiCarianMaklumatView />,
    },
    {
      path: "/cukai/viewMaklumatHarta/:id",
      element: <CukaiCarianMaklumatHartaView />,
    },
    {
      path: "/cukai/viewPenyataCukai/:id",
      element: <CukaiCarianMaklumatPenyataCukaiView />,
    },
    {
      path: "/cukai/viewBilCukai/:id",
      element: <CukaiCarianMaklumatBilCukaiView />,
    },
    { path: "/cukai/receipt/:id/view", element: <CukaiReceiptView /> },
    { path: "/cukai/pengurangan", element: <CukaiPenguranganPage /> },
    { path: "/cukai/pengurangan/new", element: <CukaiNewPenguranganPage /> },
    {
      path: "/cukai/pengurangan/new/:appNumber",
      element: <CukaiNewPenguranganPage />,
    },
    {
      path: "/cukai/pengurangan/view/:appNumber",
      element: <CukaiPenguranganView />,
    },
    { path: "/cukai/pindahmilik", element: <CukaiPindahMilikPage /> },
    { path: "/cukai/pindahmilik/new", element: <CukaiNewPindahMilikPage /> },
    {
      path: "/cukai/pindahmilik/new/:appNumber",
      element: <CukaiNewPindahMilikPage />,
    },
    {
      path: "/cukai/pindahmilik/view/:appNumber",
      element: <CukaiPindahMilikViewPage />,
    },
    {
      path: "/cukai/pindahmilik/pay/view/:accNo",
      element: <CukaiPindahMilikPay />,
    },
    { path: "/cukai/pembersihan", element: <CukaiPembersihanPage /> },
    { path: "/cukai/waran", element: <CukaiWaranPage /> },
    { path: "/cukai/akaun", element: <CukaiAccountPage /> },
    { path: "/cukai/akaun/payEdit/:id", element: <CukaiAkaunPayEdit /> },
    { path: "/cukai/kemaskini", element: <CukaiKemaskiniPage /> },
    { path: "/cukai/kemaskini/new", element: <CukaiNewKemaskiniPage /> },
    {
      path: "/cukai/kemaskini/new/:appNumber",
      element: <CukaiNewKemaskiniPage />,
    },
    {
      path: "/cukai/kemaskini/view/:appNumber",
      element: <CukaiKemaskiniViewPage />,
    },
    { path: "/cukai/view/bill/:cukaiNo/", element: <CukaiPayViewBill /> },
    {
      path: "/cukai/view/penyata/:idPermohonan/:lotId",
      element: <CukaiViewPenyata />,
    },
    {
      path: "/cukai/view/info/account/:cukaiNo",
      element: <CukaiViewAccountInfo />,
    },

    //user manual
    { path: "/usermanual", element: <UsermanualPage /> },
    { path: "/usermanualView/:view", element: <UsermanualView /> },

    //troli
    { path: "/troli", element: <TroliPage /> },
    { path: "/troli/pay/:id", element: <TroliPay /> },
    { path: "/troli/pay/:id/:status", element: <TroliPayStatus /> },
    { path: "/troli/pay/:id/:status/:view", element: <TroliPayStatusView /> },
    { path: "/troli/invoice/:moduleId/:id", element: <TroliInvoiceView /> },

    //lesen
    { path: "/lesen", element: <LesenPage /> },
    { path: "/lesen/listing/renew", element: <LesenListingRenew /> },
    { path: "/lesen/listing/apply", element: <LesenListingApply /> },
    { path: "/lesen/new/pilih", element: <LesenNewPilih /> },
    { path: "/lesen/new/pilih/view/:category", element: <LesenNewPilihView /> },
    { path: "/lesen/new/:category", element: <LesenNew /> },
    { path: "/lesen/edit/:id", element: <LesenEdit /> },
    {
      path: "/lesen/edit/tidaklengkap/:id",
      element: <LesenEditTidakLengkap />,
    },
    { path: "/lesen/edit/:id/:tab", element: <LesenEdit /> },
    { path: "/lesen/view/:id", element: <LesenView /> },
    { path: "/lesen/pay/:id/view", element: <LesenPayView /> },
    { path: "/lesen/sijil/:id/view", element: <LesenSijilView /> },
    { path: "/lesen/suratLulus/:id/view", element: <LesenSuratLulusView /> },
    { path: "/lesen/suratTolak/:id/view", element: <LesenSuratTolakView /> },

    //pelan
    { path: "/pelan", element: <PelanPage /> },

    //ps: buku
    { path: "/ps/buku/:bukuId/:moduleId", element: <PsBuku /> },

    //ps: kediaman
    { path: "/ps/kediaman", element: <PsKediaman /> },
    { path: "/ps/kediaman/edit/:id", element: <PsKediamanEdit /> },
    { path: "/ps/kediaman/edit/:id/:action", element: <PsKediamanEdit /> },
    { path: "/ps/kediaman/view/:id", element: <PsKediamanView /> },
    { path: "/ps/kediaman/surat/:id", element: <PsKediamanSurat /> },
    { path: "/ps/kediaman/permit/:id", element: <PsKediamanPermit /> },

    //ps: kedai
    { path: "/ps/kedai", element: <PsKedai /> },
    { path: "/ps/kedai/edit/:id", element: <PsKedaiEdit /> },
    { path: "/ps/kedai/edit/:id/:action", element: <PsKedaiEdit /> },
    { path: "/ps/kedai/view/:id", element: <PsKedaiView /> },

    //ps: kilang
    { path: "/ps/kilang", element: <PsKilang /> },
    { path: "/ps/kilang/edit/:id", element: <PsKilangEdit /> },
    { path: "/ps/kilang/edit/:id/:action", element: <PsKilangEdit /> },
    { path: "/ps/kilang/view/:id", element: <PsKilangView /> },

    //ps: taska
    { path: "/ps/taska", element: <PsTaska /> },
    { path: "/ps/taska/edit/:id", element: <PsTaskaEdit /> },
    { path: "/ps/taska/edit/:id/:action", element: <PsTaskaEdit /> },
    { path: "/ps/taska/view/:id", element: <PsTaskaView /> },

    //ps: telco
    { path: "/ps/telco", element: <PsTelco /> },
    { path: "/ps/telco/edit/:id", element: <PsTelcoEdit /> },
    { path: "/ps/telco/edit/:id/:action", element: <PsTelcoEdit /> },
    { path: "/ps/telco/view/:id", element: <PsTelcoView /> },

    //ps: bayaran
    { path: "/ps/:ps/pay/:id/:inv_id/view", element: <PsPayView /> },
    { path: "/ps/:ps/pay/:id/:inv_id/receipt", element: <PsPayReceipt /> },
    { path: "/ps/:ps/pay/:id/:inv_id/invoice", element: <PsPayInvoice /> },
    { path: "/ps/:ps/pay/:id/viewReceiptBook", element: <PsPayReceiptBook /> },

    //ps: BTP
    { path: "/ps/btp", element: <PsBTP /> },
    { path: "/ps/pkpl", element: <PsPKPL /> },
    { path: "/ps/:pstype/new", element: <PsBangloNew /> },
    { path: "/ps/:pstype/edit/:id", element: <PsBangloEdit /> },
    { path: "/ps/:pstype/view/:id", element: <PsBangloView /> },

    //ps: doskmep
    { path: "/ps/doskmep", element: <PsDoskmep /> },
    { path: "/ps/doskmep/edit/:id", element: <PsDoskmepEdit /> },
    { path: "/ps/doskmep/view/:id", element: <PsDoskmepView /> },

    //transaction
    { path: "/transaction", element: <TransactionPage /> },
    { path: "/transaction/view/:id", element: <TransactionView /> },

    // sewaaan
    { path: "/sewaan", element: <SewaanPage /> },
    {
      path: "/sewaan/pay/view/electric/:accNo/:bilNo",
      element: <SewaanPayViewElectric />,
    },
    {
      path: "/sewaan/pay/view/water/:accNo/:bilNo",
      element: <SewaanPayViewWater />,
    },
    { path: "/sewaan/pay/view/rent/:accNo", element: <SewaanPayViewRent /> },
    {
      path: "/sewaan/view/transaction/electric/:accNo/:dateStart/:dateEnd",
      element: <SewaanViewTranscElectric />,
    },
    {
      path: "/sewaan/view/transaction/rent/:accNo/:dateStart/:dateEnd",
      element: <SewaanViewTranscRent />,
    },
    {
      path: "/sewaan/view/transaction/water/:accNo/:dateStart/:dateEnd",
      element: <SewaanViewTranscWater />,
    },
    { path: "/sewaan/view/notice/:accNo", element: <SewaanViewNotice /> },
    { path: "/sewaan/view/license/:accNo", element: <SewaanViewLicense /> },

    //bil
    { path: "/bil", element: <BilPage /> },
    { path: "/bil/pay/view/:accNo", element: <BilPayView /> },

    //jualan
    { path: "/jualan", element: <JualanPage /> },
    { path: "/jualan/pembelian", element: <JualanPembelianPage /> },
    { path: "/jualan/produk/:id", element: <JualanProdukDetailsPage /> },
    { path: "/jualan/pay/view/:accNo", element: <JualanPayView /> },
    {
      path: "/jualan/transaction/view/:id",
      element: <JualanTransactionView />,
    },

    //sekat
    { path: "/sekat", element: <SekatPage /> },
    {
      path: "/sekat/pay/view/:accNo/:referNo/:billType/:kodHasil",
      element: <SekatPayView />,
    },

    //kompaun
    { path: "/kompaun", element: <KompaunPage /> },
    { path: "/kompaun/carian", element: <KompaunCarian /> },
    { path: "/kompaun/tunda", element: <KompaunTunda /> },
    { path: "/kompaun/bil/view/:accNo/:amount", element: <KompaunBilView /> },

    //bayaran
    { path: "/bayaran", element: <BayaranPage /> },
    { path: "/bayaran/transaction", element: <BayaranTransactionPage /> },
    { path: "/bayaran/carian", element: <BayaranCarianPage /> },

    // tempahan
    { path: "/tempahan", element: <TempahanPage /> },
    { path: "/tempahan/new", element: <TempahanNew /> },
    { path: "/tempahan/sukan/new", element: <TempahanSukanNew /> },
    { path: "/tempahan/bil/:id", element: <TempahanBilView /> },
    { path: "/tempahan/resit/:id", element: <TempahanReceiptView /> },

    // loyalti
    { path: "/loyalti", element: <LoyaltiPage /> },
    { path: "/loyalti/program", element: <LoyaltiProgramPage /> },
    { path: "/loyalti/penebusan", element: <LoyaltiPenebusanPage /> },
    { path: "/loyalti/transaksi", element: <LoyaltiTransaksiPage /> },
    { path: "/loyalti/produk", element: <LoyaltiProdukPage /> },
    { path: "/loyalti/produk/:id", element: <LoyaltiProdukDetailsPage /> },
    { path: "/loyalti/penebusanproduk", element: <LoyaltiCheckoutPage /> },
    { path: "/loyalti/penebusan/:id", element: <LoyaltiPenebusanView /> },
    { path: "/loyalti/pengesahan", element: <LoyaltiPengesahanPage /> },

    //syarikat
    { path: "/syarikat", element: <SyarikatPage /> },
    { path: "/syarikat/kemaskini", element: <KemaskiniSyarikat /> },
    { path: "/syarikat/new", element: <SyarikatNewPage /> },
    { path: "/syarikat/tuntutan", element: <SyarikatTuntutanPage /> },

    // report
    //lesen
    { path: "/report", element: <LaporanPage /> },
    {
      path: "/report/listing/userregistration",
      element: <LaporanUserRegistrationPage />,
    },
    { path: "/report/listing/useraccess", element: <LaporanUserAccessPage /> },
  ].map((r: RouteProps) => {
    if (auth.isLoggedIn) {
      if (auth.data.role_id != "1") {
        if (r.role && r.role !== 1) {
          return r;
        }
      }
      return r;
    } else {
      return {
        path: r.path,
        element: <Navigate to="/login" />,
      };
    }
  });

  //combine public & secured route
  const combineRoutes = [...publicRoutes, ...securedRoutes];

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <Routes>
        {combineRoutes.map((r) => {
          return (
            <Route
              key={Math.random()}
              path={`${r.path}`}
              element={r.element}
            />
          );
        })}
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Routes>
    </React.Suspense>
  );
};

export default AppRoutes;
