//
// SEWAAN DATATABLE
//
export const SEWAAN_DATATABLE_REQUEST = 'SEWAAN/SEWAAN_DATATABLE_REQUEST';
export const SEWAAN_DATATABLE_SUCCESS = 'SEWAAN/SEWAAN_DATATABLE_SUCCESS';
export const SEWAAN_DATATABLE_FAILURE = 'SEWAAN/SEWAAN_DATATABLE_FAILURE';

//
//SEARCH BY VIEW
//
export const SEWAAN_VIEW_ELECTRIC_REQUEST = 'SEWAAN/SEWAAN_VIEW_ELECTRIC_REQUEST';
export const SEWAAN_VIEW_ELECTRIC_SUCCESS = 'SEWAAN/SEWAAN_VIEW_ELECTRIC_SUCCESS';
export const SEWAAN_VIEW_ELECTRIC_FAILURE = 'SEWAAN/SEWAAN_VIEW_ELECTRIC_FAILURE';

export const SEWAAN_VIEW_WATER_REQUEST = 'SEWAAN/SEWAAN_VIEW_WATER_REQUEST';
export const SEWAAN_VIEW_WATER_SUCCESS = 'SEWAAN/SEWAAN_VIEW_WATER_SUCCESS';
export const SEWAAN_VIEW_WATER_FAILURE = 'SEWAAN/SEWAAN_VIEW_WATER_FAILURE';

export const SEWAAN_VIEW_RENT_REQUEST = 'SEWAAN/SEWAAN_VIEW_RENT_REQUEST';
export const SEWAAN_VIEW_RENT_SUCCESS = 'SEWAAN/SEWAAN_VIEW_RENT_SUCCESS';
export const SEWAAN_VIEW_RENT_FAILURE = 'SEWAAN/SEWAAN_VIEW_RENT_FAILURE';

export const SEWAAN_VIEW_NOTICE_REQUEST = 'SEWAAN/SEWAAN_VIEW_NOTICE_REQUEST';
export const SEWAAN_VIEW_NOTICE_SUCCESS = 'SEWAAN/SEWAAN_VIEW_NOTICE_SUCCESS';
export const SEWAAN_VIEW_NOTICE_FAILURE = 'SEWAAN/SEWAAN_VIEW_NOTICE_FAILURE';

export const SEWAAN_VIEW_LICENSE_REQUEST = 'SEWAAN/SEWAAN_VIEW_LICENSE_REQUEST';
export const SEWAAN_VIEW_LICENSE_SUCCESS = 'SEWAAN/SEWAAN_VIEW_LICENSE_SUCCESS';
export const SEWAAN_VIEW_LICENSE_FAILURE = 'SEWAAN/SEWAAN_VIEW_LICENSE_FAILURE';

export const SEWAAN_VIEW_TRANSC_ELECTRIC_REQUEST = 'SEWAAN/SEWAAN_VIEW_TRANSC_ELECTRIC_REQUEST';
export const SEWAAN_VIEW_TRANSC_ELECTRIC_SUCCESS = 'SEWAAN/SEWAAN_VIEW_TRANSC_ELECTRIC_SUCCESS';
export const SEWAAN_VIEW_TRANSC_ELECTRIC_FAILURE = 'SEWAAN/SEWAAN_VIEW_TRANSC_ELECTRIC_FAILURE';

export const SEWAAN_VIEW_TRANSC_WATER_REQUEST = 'SEWAAN/SEWAAN_VIEW_TRANSC_WATER_REQUEST';
export const SEWAAN_VIEW_TRANSC_WATER_SUCCESS = 'SEWAAN/SEWAAN_VIEW_TRANSC_WATER_SUCCESS';
export const SEWAAN_VIEW_TRANSC_WATER_FAILURE = 'SEWAAN/SEWAAN_VIEW_TRANSC_WATER_FAILURE';

export const SEWAAN_VIEW_TRANSC_RENT_REQUEST = 'SEWAAN/SEWAAN_VIEW_TRANSC_RENT_REQUEST';
export const SEWAAN_VIEW_TRANSC_RENT_SUCCESS = 'SEWAAN/SEWAAN_VIEW_TRANSC_RENT_SUCCESS';
export const SEWAAN_VIEW_TRANSC_RENT_FAILURE = 'SEWAAN/SEWAAN_VIEW_TRANSC_RENT_FAILURE';