import { call, put, takeLatest } from 'redux-saga/effects';
import * as axios from '../../utils/axios';
import * as actions from '../action/transaction';
import * as constants from '../constant/transaction';
import * as functions from '../function/transaction';



//
// TRANSACTION DATATABLE
//
function* getTransactionDatatable({params, onCallback}: actions.TransactionDatatableRequestAction) {

    const {status, data, error } = yield call(axios.call, {
        url: `/secured/transaction/datatable`,
        method: "POST",
        data: params
    });
    if(status === 200) {
        onCallback({
            status: 1,
            data: data
        });
        yield put(functions.TransactionDatatableSuccess(data));     
    }else {
        onCallback({
            status: 0,
            error: error
        });
        yield put(functions.TransactionDatatableFailure(error));
    }
    
};

export function* watchTransaction() {
    
    yield takeLatest(constants.TRANSACTION_DATATABLE_REQUEST, getTransactionDatatable);

};


